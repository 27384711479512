<div class="materlog-item-container custom-scrollbar-close">
    <input #inputTextEl *ngIf="items.length > 4"
        class="materlog-dropdown-filter-item"
        [placeholder]="'Search...'"
        [(ngModel)]="filterText"
    >
    <div class="materlog-item-scroll" #materlogItemScroll>
        <div *ngFor="let item of filteredItems; let i = index" class="materlog-item-close">
            <div class="materlog-item-delete">
                <i class="materlog-item-delete-icon bi bi-trash3" (click)="deleteItem(item)"></i>
                <div class="materlog-item-delete-tooltip">Delete item?</div>
            </div>
            <div class="materlog-item-close-header" (click)="openItem(item)">
                <div class="materlog-item-text">
                    <div class="materlog-item-chevron">
                        {{'ITEM ' + (i + 1)}}
                        <i class="materlog-item-chevron-icon bi"
                            [ngClass]="item.isOpen ? 'bi-chevron-down' : 'bi-chevron-right'"></i>
                    </div>
                </div>
                <div (click)="dropClick($event)">
                    <materlog-status-component
                    class="materlog-field-type"
                    [options]="dropdownOptions?.status"
                    [valueField]="'value'"
                    [colors]="dropdownOptions?.status_colors"
                    [items]="item"
                    [maxWidth]="120"
                    (onChange)="onStatusChange($event, item)"
                    >
                    </materlog-status-component>
                </div>
            </div>
            <div class="materlog-item-close-picture" (click)="openItem(item)">
                <materlog-image-component
                    [item]="item"
                    [msgData]="msgData"
                    (onChange)="onImageChange($event, item)"
                >
                </materlog-image-component>
                <div (click)="dropClick($event)">
                    <materlog-text-component
                    class="materlog-field-type"
                    [order]="item"
                    [fieldName]="'name'"
                    [maxInput]="150"
                    [maxWidth]="140"
                    (onChange)="onTextChange($event, item)"
                    [placeholder]="'Item name'">
                    </materlog-text-component>
                </div>
            </div>
            <div *ngIf="item.isOpen" class="materlog-item-open">
                <div class="materlog-single-item-value" *ngFor="let field of fields; let isLast = last">
                    <div class="materlog-single-item-text" *ngIf="!isLast">
                    {{field.name}}
                    </div>
                    <div class="materlog-single-order-fieldvalue" *ngIf="!isLast">
                        <materlog-text-component
                            *ngIf="(field.type == 'text' || field.type == 'number')"
                            class="materlog-field-type"
                            [order]="item"
                            [fieldName]="field.field"
                            [isNumber]="field.type == 'number'"
                            [isCost]="field.cost"
                            [maxInput]="field.maxInput || 255"
                            [vgrowth]="field.vgrowth"
                            [maxWidth]="openWidth"
                            [minHeight]="field.minHeight"
                            (onChange)="onTextChange($event, item)"
                            >
                        </materlog-text-component>
                        <materlog-date-component
                            *ngIf="field.type == 'date'"
                            class="materlog-field-type"
                            [items]="item"
                            [is_item]="true"
                            [fieldName]="field.field"
                            [forceChanger]="forceChanger"
                            (onChange)="onDateChange($event, item)"
                            >
                        </materlog-date-component>
                        <materlog-shiplink-component
                            *ngIf="field.type == 'link'"
                            class="materlog-field-type"
                            [fieldName]="field.field"
                            [items]="item[field.field]"
                            [maxWidth]="openWidth"
                            (onChange)="onShippingLinkChange($event, item, field.field)"
                        >
                        </materlog-shiplink-component>
                        <materlog-dropdown-component
                            *ngIf="field.type == 'dropdown'"
                            class="materlog-field-type"
                            [options]="optionsForField(item, field)"
                            [selected]="selectedForField(item, field)"
                            [inputText]="'Search...'"
                            [allowCreateNew]="canCreateNewDropdown(field)"
                            [createNewText]="getCreateNewText(field)"
                            [dropWidth]="field.dropWidth || dropWidth"
                            [maxWidth]="openWidth"
                            (onOptionsChange)="onOptionsChange($event, item, field)"
                            [allowNull]="field.allow_null"
                            (onChange)="onDropdownChange($event, item, field.field)"
                            >
                        </materlog-dropdown-component>
                    </div>
                </div>
            </div>
        </div>
        <div class="materlog-item-close materlog-item-new" (click)="createNewItem()">
            Create new item
        </div>
    </div>
</div>