import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { EventEmitter, Output, ChangeDetectorRef} from '@angular/core';
import { ElementRef, HostListener } from '@angular/core';
import { setScrollBar } from 'src/app/common/utils/general';
import { isPDF, isJPEG, isPNG, generateRandomString } from 'src/app/common/utils/general';
import { createDate } from 'src/app/common/utils/general';
import { OrderService } from 'src/app/dashboard/services/order.service';


@Component({
    selector: 'materlog-image-component',
    templateUrl: './materlog-image-component.component.html',
    styleUrls: ['./materlog-image-component.component.css'],
  })
export class MaterLogImages implements OnInit {
    @Input() item: any = {};
    @Input() msgData: any = {};

    @Output() onChange = new EventEmitter<any>();
  
    @ViewChild('imgDropContainer') imgDropContainer!: ElementRef;
    filterOptions: any = [];

    showImgDropdown = false;
    curButtonLocation: any = undefined;
    images: any = [];
  
  
    constructor(
        private cdr: ChangeDetectorRef,
        private orderService: OrderService,
    ) {
    }
  
    ngOnChanges(changes: any) {
        if (this.item.id) {
            this.collectItemImages();
        }
    }
  
  
    ngOnInit() {
        (window as any)['mimg'] = this;
    }
  
    ngAfterViewInit() {

    }

    get picWidth() {
        return `100px`;
      }

    deletePicture(event: any, item: any, img: any) {
        event.stopPropagation();
        this.images = this.images?.filter((i: any) => i != img) || [];
        this.orderService.deleteImageExtract(img.id).subscribe(() => {});
        let fitem = this.msgData?.order?.item_fields?.find((i: any) => i.id == item.id);
        if (fitem?.images) {
            fitem.images = fitem.images.filter((i: any) => i.id != img.id);
        }
        this.item.images = this.item.images.filter((i: any) => i.id != img.id);
        this.cdr.detectChanges();
    }
    
    addNewImg(event: any) {
        (window as any)['mimg'] = this;
        event.stopPropagation();
    }

    toggleShowImgDropdown(event: any) {
        (window as any)['mimg'] = this;
        event.stopPropagation();
        this.showImgDropdown = !this.showImgDropdown;
        this.curButtonLocation = event;
        let self = this;
        var pollInterval = setInterval(function() {
          if (self.imgDropContainer?.nativeElement) {
            clearInterval(pollInterval);
            self.setLocation();
          }
        }, 5);
        let options = this.msgData?.picture_paths || [];
        options = options.filter((o: any) => o.aws_url);
        this.filterOptions = options;
        this.cdr.detectChanges();
    }


    onFileSelected(event: any, item: any) {
        const input = event.target as HTMLInputElement;
        const formData = new FormData();
        if (input.files) {
            for (let i = 0; i < input.files.length; i++) {
                let cur = input.files[i];
                let rstring = generateRandomString();
                let location = 'order_item_images/' + rstring;

                formData.append('file', cur, cur.name);
                formData.append('path', location);
                let att = {
                    name: cur.name, id: rstring, image: location,
                    item: item.id, needs_upload: true
                };

                formData.append('item_id', item.id);
                this.ensureItemImages();
                this.item.images.push(att);
                this.images.push(att);
                this.orderService.uploadDocumentExtract(formData).subscribe((result: any) => {
                    if (!result.success) {
                        this.item.images = this.item?.images?.filter((i: any) => i.id != rstring) || [];
                        this.images = this.images.filter((i: any) => i.id != rstring);
                    } else {
                        let fitem = this.msgData?.order?.item_fields?.find((i: any) => i.id == item.id);
                        if (fitem) {
                            let imgs = fitem?.images?.filter((i: any) => i.id != rstring) || [];
                            let newImg = {...result, image: result.filename};
                            imgs.push(newImg);
                            this.ensureItemImages();
                            fitem.images = this.item.images = imgs;
                        }
                    }
                    this.collectItemImages();
                })
            }
        }
        event.target.value = '';
    }


    collectItemImages() {
        let images: any = [];
        images = []

        this.item?.images?.forEach((img: any) => {
            if (!img.aws_url) {
                return;
            }
            if (!images.find((i: any) => i.id == img.id)) {
                images.push(img)
            }
        });

        images = images.filter((i: any) => !i.delete);
        this.images = images;
        this.cdr.detectChanges();

    }

    onImageChosen(event: any) {
        this.showImgDropdown = false;
        this.ensureItemImages();
        this.item.images.push(event.opt);
        let payload = {item_id: this.item.id, image: event.opt.image};
        this.orderService.writeItemImageExtract(payload).subscribe((result: any) => {
            let fitem = this.msgData?.order?.item_fields?.find((i: any) => i.id == this.item.id);
            if (fitem) {
                let imgs = fitem?.images?.filter((i: any) => i.id != event.opt.id) || [];
                let newImg = {...event.opt, id: result.id};
                imgs.push(newImg);
                this.ensureItemImages();
                fitem.images = this.item.images = imgs;
                this.collectItemImages();
            }
        });
    }

    get hasEmailPictures() {
        return this.msgData?.picture_paths?.length;
    }
    
    setLocation() {
        if (!this.imgDropContainer?.nativeElement) return;
        const rect = this.imgDropContainer?.nativeElement?.getBoundingClientRect();
        let top = this.curButtonLocation.y + 10;
        let left = rect.left;
        this.imgDropContainer.nativeElement.style.top = `${top}px`;
        this.imgDropContainer.nativeElement.style.left = `${left}px`;
        this.cdr.detectChanges();
    }


    onOptionSelect(event: any, opt: any) {
        event.stopPropagation();
        this.onImageChosen({event: event, opt: opt});
    }

    isSelected(opt: any) {
        return this.msgData?.order?.id == opt.id;
    }

    ensureItemImages() {
        if (!this.item?.images) {
            this.item.images = [];
        }
    }

  }