<div class="card-container">
  <div class="all-view card-view card-enders" (click)="changeViewStyle(1)"
        [ngClass]="{
          'active-view': viewStyle == 1 && !isLoading,
          'inactive-view': viewStyle != 1 && !isLoading,
          'active-view-loading': viewStyle == 1 && isLoading,
          'inactive-view-loading': viewStyle != 1 && isLoading,
        }">
    <i class="bi bi-grid" style="position: relative; left: 2px"></i>
  </div>
  <div class="all-view" (click)="changeViewStyle(0)"
      [ngClass]="{
        'active-view': viewStyle == 0 && !isLoading,
        'inactive-view': viewStyle != 0 && !isLoading,
        'active-view-loading': viewStyle == 0 && isLoading,
        'inactive-view-loading': viewStyle != 0 && isLoading,
      }">
      <i class="bi bi-list-task"></i>
  </div>
  <div class="all-view item-view card-enders" (click)="changeViewStyle(2)"
      [ngClass]="{
        'active-view': viewStyle == 2 && !isLoading,
        'inactive-view': viewStyle != 2 && !isLoading,
        'active-view-loading': viewStyle == 2 && isLoading,
        'inactive-view-loading': viewStyle != 2 && isLoading,
      }">
      <i class="bi bi-calendar" style="position: relative; right: 2px"></i>
  </div>
  <!--
  <div class="all-view filter-control"
      [ngClass]="filterActive ? 'active-view': 'inactive-view'"    
      (click)="filterToggle()">
    <i class="bi bi-filter"></i>
    <div class="filter-text">
      Filter
    </div>
  </div>
  -->

</div>