<div class="notifications-container" [ngStyle]="{'max-width': cardViewWidth}">
  <div *ngIf="isLoading">
    <div class="notification-header">
      Notifications
    </div>
    <div *ngFor="let item of skeletonItems" style="padding: 4px 8px 4px 12px;">
      <p-skeleton [width]="item.width" [height]="'50px'" styleClass="mb-2" borderRadius="16px"></p-skeleton>
    </div>
  </div>
  <div *ngIf="!isLoading && notifications.length" class="icon-container ic-open">
  </div>
  <div *ngIf="!isLoading && !notifications.length" class="no-alert-banner">
    No notifications
  </div>
  <div class="notifications-list" *ngIf="!isLoading && notifications.length">
    <div class="notification-header">
      <div class="new-option-dropdown" *ngIf="showSettings" style="top: 28px; right: -5px">
        <div class="new-option" (click)="goToSettingsPage()">
            Manage settings
        </div>
      </div>
      {{notificationBanner}}
      <i class="bi bi-gear notification-gear" (click)="onShowSettingsGear()"></i>
    </div>
    <div class="notification-options" *ngIf="notifications.length">
      <div class="note-frow">
        <div class="note-opt-text" (click)="showNotifications(true)"
          [ngStyle]="{'color': showAll ? '#000': '#7F7F7F'}">
          All
        </div>
        <div class="note-opt-text" (click)="showNotifications(false)"
          [ngStyle]="{'color': showAll ? '#7F7F7F': '#000'}">
          {{unreadNotifications}}
        </div>
      </div>
      <div *ngIf="unreadNotificationsCount" class="note-opt-text"
          style="color: #3C7DF4; font-weight: 400" (click)="setAllAsSeen()">
        Mark all read
      </div>
    </div>
    <div class="notifications-list-short custom-scrollbar-close" *ngIf="notifications.length">
      <div class="single-notification"
          [ngClass]="item.id == currentItem?.id ? 'selected-item' : null"
          (click)="itemClicked(item)" *ngFor="let item of filteredNotes">
        <div class="item-information">
          <div class="item-row-1">
            <div class="new-option-dropdown" *ngIf="item.dropdown">
              <div class="new-option" (click)="onChooseNoteOption($event, item, which)"
                  *ngFor="let which of noteOptions">
                  {{which.name}}
              </div>
            </div>
            <div class="delete-alert" (click)="showItemDropdown($event, item)">
              <i class="bi bi-three-dots-vertical delete-alert-icon"></i>
            </div>

            <i class="bi bi-dot unseen-dot" *ngIf="!item.seen"></i>
            <div class="item-subject"
                  [ngClass]="item.date ? 'item-with-date': 'item-no-date'"
                  [ngStyle]="{'font-weight': item.seen ? '': 'bold'}"
            >
              {{item.text}}
            </div>
            <div *ngIf="item.date" class="ml-auto item-date">
              {{ formatDate(item) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="note-display-container" *ngIf="currentItem && !newItem && !isLoading && notifications.length">
    <div class="note-header">
      <div class="note-display-text" [ngStyle]="{'max-width': cardViewWidth}">
        <span style="padding-right: 6px" *ngIf="currentItem.emoji_icon">{{currentItem.emoji_icon}}</span>
        {{currentItem.text}}
      </div>
      <div *ngIf="currentItem.sub_text" class="note-subtext">
        {{currentItem.sub_text}}
      </div>
    </div>

    <div class="all-info-container custom-scrollbar-close">
      <div class="note-order" #noteOrder *ngIf="currentItem.order_id && dropdownRetrieved && currentItem.type == 'Order'">
        <div class="note-supplier-text" [ngStyle]="{'max-width': cardViewWidth}">
          Order info
        </div>
        <div class="note-card-view">
          <card-view
          [notificationsView]="true"
          [ngStyle]="{'max-width': cardViewWidth}"
          [order_id]="currentItem.order_id"
          [dropdownOptions]="dropdownOptions"
          [panelOpen]="sidePanelOpen"
          [allMailData]="allMailData"
          (cardIsOpen)="cardIsOpenChange($event)"
          (orderDeleted)="orderDeleted()"
          [needsSupplier]="currentItem.order_error_supplier"
          [needsOrderDoc]="currentItem.order_error_orderdoc"
          [needsTracking]="currentItem.order_error_tracking"
          (onChange)="onInfoChange($event, currentItem)"
          >
          </card-view>
        </div>
      </div>
      <div class="note-supplier" #noteSupplier *ngIf="currentItem.supplier_id && currentItem.type == 'Supplier'">
        <div class="note-supplier-text" [ngStyle]="{'max-width': cardViewWidth}">
          Supplier info
        </div>
        <materlog-suppliers-credentials-page
          [notificationsView]="true"
          [supplierId]="currentItem.supplier_id"
          [panelOpen]="sidePanelOpen"
          [ngStyle]="{'max-width': cardViewWidth}"
          [allMailData]="allMailData"
          [supplierNeedsEmail]="currentItem.supplier_error_email"
          (onChange)="onInfoChange($event, currentItem)"
        ></materlog-suppliers-credentials-page>
      </div>
      <div class="note-supplier" #noteJob *ngIf="currentItem.job_id && currentItem.type == 'Job'">
        <div class="note-supplier-text" [ngStyle]="{'max-width': cardViewWidth}">
          Job info
        </div>
        <materlog-view-jobs
          [notificationsView]="true"
          [jobId]="currentItem.job_id"
          [panelOpen]="sidePanelOpen"
          [ngStyle]="{'max-width': cardViewWidth}"
          [allMailData]="allMailData"
          [dropdownOptions]="dropdownOptions"
          [addressError]="currentItem.address_error"
          [receiverErrorAddress]="currentItem.receiver_error_address"
          [receiverErrorEmail]="currentItem.receiver_error_email"
          (onChange)="onInfoChange($event, currentItem)"
        ></materlog-view-jobs>
      </div>
      <!--
      <div class="note-activity" *ngIf="currentItem.order_id">
        <activity-history
          [dropdownOptions]="dropdownOptions"
          [orderId]="currentItem.order_id"
        >
        </activity-history>
      </div>
      -->
    </div>
  </div>
</div>