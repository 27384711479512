import { node1Customizations } from 'src/app/dashboard/components/flow-assistants/assistants/new-order-default';

let node2Customizations: any = [
    {
        number: '1.',
        name: 'What app do you issue POs out of?',
        type: 'dropdown1',
        options: 'po_providers',
        org_data_value: 'po_provider',
        top: 68,
        main_texts: [
            {
                text: 'Once you click “Save”, you’ll receive an email with instructions and next steps to connect Meti with your ERP.',
                color: '#828282',
                font_weight: '350',

            }
        ]
    },
    {
        number: '2.',
        name: 'Is a PO required for every order?',
        type: 'selector',
        indicator_text: 'TODO Mariah',
        org_data_value: 'uses_pos',
        options: 'does_use_po',
    }
]

let nodeWidth = '330px';

export let nodesERP: any = [
    {
        id: 'node1',
        icon: 'bi-gear-wide-connected',
        icon_color: '#C2DBFF',
        subtext: 'New orders into Meti',
        sidebar_main_text: 'Choose how to get new orders into Meti',
        customizations: node1Customizations,
        text_color: '#616161',
        post_text: 'via ERP',
        node_width: nodeWidth,
        style_left: 270, row: 1,
    },
    {
        id: 'node2',
        icon: 'bi-file-plus',
        icon_color: '#C2DBFF',
        subtext: 'New PO issued',
        customizations: node2Customizations,
        node_width: nodeWidth,
        style_left: 270, row: 2,
    },
    {
        id: 'node3',
        icon: 'meti',
        subtext: 'Pull in order details',
        node_width: nodeWidth,
        style_left: 270, row: 3,
    },
];

export let switchesERP = [];
export let connectionsERP: any = [
    {from: 'node1', to: 'node2', cut: undefined, space: true},
    {from: 'node2', to: 'node3', cut: undefined, space: false},
]