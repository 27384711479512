<div class="graph-sidebar">
    <div class=sidebar-header>
        <flow-node [data]="dataForNode" [sidebar]="true"></flow-node>
        <p-inputSwitch *ngIf="data.toggle"
            class="sidebar-toggle"
            [(ngModel)]="toggle"
            name="choiceon"
            (onChange)="nodeToggleEvent($event)">
        </p-inputSwitch>
    </div>
    <div class="flow-sidebar-container" #customContainer>
        <div *ngFor="let custom of customizations" class="flow-sidebar-main">
            <div class="flow-sidebar-header">
                <div class="flow-sidebar-count">
                    {{custom.number}}
                </div>
                <div class="flow-sidebar-maintext">
                    {{custom.name}}
                </div>
            </div>
            <div *ngIf="custom.type == 'selector'" class="flow-selector-container flow-sb-mleft">
                <div *ngFor="let option of optionsForSelector(custom)" class="flow-selector-one"
                    (click)="setCustomOption(custom, option)"
                    [ngClass]="{
                        'flow-selector-one-chosen': optionSelectorChosen(custom, option)
                    }"
                    [ngStyle]="{
                        'min-width': minWidthForSelector(option.name)
                    }"
                >
                    {{option.name}}
                </div>
            </div>

            <div *ngIf="custom.type == 'dropdown3'"
                class="flow-sb-opt-text flow-sb-dropdown flow-sb-dropdown-chosen flow-sb-mleft"
                (click)="showDropdownOptions(custom)"
            >
                <div class="drop3-container flow-sb-opt-text">
                    <img *ngIf="dropdown3Chosen(custom)?.path" [src]="dropdown3Chosen(custom).path" alt="logo" class="drop3-logo">
                    <i class="drop3-logo bi bi-envelope" *ngIf="dropdown3Chosen(custom)?.icon"></i>
                    {{dropdown2Text(custom)}}
                </div>
                <i class="bi bi-chevron-down"></i>
            </div>
            <div *ngIf="custom.type == 'dropdown2' || custom.type == 'dropdown1'"
                class="flow-sb-opt-text flow-sb-dropdown flow-sb-dropdown-chosen flow-sb-mleft"
                (click)="showDropdownOptions(custom)"
                [ngClass]="{
                    'toggle-off-dropdown': !isToggleEnabled,
                }"
            >
                {{dropdown2Text(custom)}}
                <i class="bi bi-chevron-down"></i>
            </div>
            <div *ngIf="custom.dropdown_open && (custom.type == 'dropdown2' || custom.type == 'dropdown3' || custom.type == 'dropdown1')"
                    class="flow-sb-dropdown flow-sb-opt-text flow-sb-dropdown-open flow-sb-mleft">
                <div *ngFor="let option of getCustomOptions(custom)" class="flow-sb-dropdown2-choice"
                    [ngClass]="{
                        'flow-sb-coming-soon': option.coming_soon
                    }"
                    (click)="setCustomOption(custom, option)"
                    >
                    <div class="flow-sidebar-header">
                        <div *ngIf="custom.type == 'dropdown3'" style="margin-right: 12px;">
                            <img *ngIf="option.path" [src]="option.path" alt="logo" class="drop3-logo">
                            <i class="drop3-logo bi bi-envelope" *ngIf="option.icon"></i>
                        </div>
                        <i class="drop3-logo bi bi-check-lg" style="margin-right: 6px;"
                            *ngIf="custom.type == 'dropdown1' && optionSelectorChosen(custom, option)"
                        ></i>

                        <div class="flow-sb-opt-text flow-sb-opt-text-main"
                        [ngStyle]="{
                            'color': option.coming_soon ? '#828282' : '#000',
                        }"
                        >
                            {{option.name}}
                        </div>
                        <div *ngIf="option.coming_soon" class="flow-sb-opt-text"
                                style="color: #4671F6; font-size: 10px; margin-left: 8px;">
                            Coming soon!
                        </div>
                    </div>
                    <div class="flow-sb-opt-text flow-sb-opt-text-sub" *ngIf="option.subtext">
                        {{option.subtext}}
                    </div>
                </div>
            </div>
            <materlog-workflows-slug
                *ngIf="custom.slug" class="flow-sb-mleft"
                [orgData]="orgData"
                [graphView]="true"
                [gmailText]="false"
            > 
            </materlog-workflows-slug>
            <div class="flow-main-texts flow-sb-mleft">
                <div *ngFor="let text of custom.main_texts" class="flow-main-text-one"
                    (click)="goToPage(text)"
                    [ngClass]="{
                        'flow-main-text-underline': text.underline,
                        'flow-main-text-click': text.where
                    }"
                    [ngStyle]="{
                        'color': text.color ? text.color: '#000',
                        'font-size': text.font_size ? text.font_size: '11px',
                        'font-weight': text.font_weight ? text.font_weight: '400',
                    }"
                >
                    {{text.text}}
                </div>
            </div>
        </div>
    </div>
</div>

