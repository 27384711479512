import { ChangeDetectorRef, Component, OnInit, ViewChild, Input } from '@angular/core';
import { ElementRef, HostListener } from '@angular/core';
import { getArrowArray } from '../flow-utils';


@Component({
  selector: 'flow-arrow',
  templateUrl: './flow-arrow.component.html',
  styleUrls: [
    './flow-arrow.component.css',
]
})
export class MetiFlowArrow implements OnInit {
    @Input() arrowCount: any = 0;
    @Input() useLines: boolean = false;
    @Input() dashedCount: number = 1;

    arrowArray: any = [];

    constructor(
    ) {
    }

    ngOnInit(): void {
        (window as any)['arrow'] = this;
    }

    ngOnChanges() {
        if (!this.arrowArray.length) {
            this.arrowArray = getArrowArray(this.arrowCount);
        }
    }

}
