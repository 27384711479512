import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { EventEmitter, Output, ChangeDetectorRef} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { IApplicationState } from 'src/app/common/state/models/app.state.model';
import { OrderService } from '../../services/order.service';
import { JobsService } from '../../services/job.service';
import { DashboardService } from '../../services/dashboard.service';
import { TableConstants } from 'src/app/common/constants/table.constants';


@Component({
  selector: 'activity-history',
  templateUrl: './activity-history.component.html',
  styleUrls: ['./activity-history.component.css'],
})
export class ActivityHistoryComponent implements OnInit {
  @Input() dropdownOptions?: any;
  @Input() orderId?: any;
  @Input() maxHeight: any;

  minHeight: number = 80;
  headerTypeAll: string = 'All';
  headerTypeOrder: string = 'Order';
  headerTypeItems: string = 'Items';
  headerTypes: any = [this.headerTypeAll, this.headerTypeOrder, this.headerTypeItems];
  activeHeaderType: any = this.headerTypeAll;
  showItemDropdown: boolean = false;
  currentItems: any = [];
  tableColumns = TableConstants.jobItemsTableColumns;
  allHistories: any = [];
  filteredHistories: any = [];

  nonTableFields = [
    'last_email_sent', 'last_tracking_date'
  ]

  selectedItem: any = undefined;
  isLoading: boolean = true;
  todayDate: any = undefined;
  beenInited: boolean = false;

  constructor(
    private jobService: JobsService,
    private cdr: ChangeDetectorRef,
    private orderService: OrderService,
    private store: Store<IApplicationState>,
    private dashboardService: DashboardService
  ) {
    (window as any)['ah'] = this;
  }

  ngOnInit() {
    this.getTodayDateInEST();
  }

  ngAfterViewInit() {
    
  }

  ngOnChanges(changes: any) {
    if (changes?.orderId?.currentValue) {
      this.getOrderHistory(changes.orderId.currentValue);
    }
  }

  changeActiveType(event: any, which: any) {
    let no_refilter = which == this.headerTypeItems && this.headerTypeItems == this.activeHeaderType;
    this.activeHeaderType = which;
    this.showItemDropdown = this.activeHeaderType == this.headerTypeItems && !this.showItemDropdown;
    if (this.showItemDropdown) {
      setTimeout(() => {
        this.setScrollbar('.items-dropdown-container');
      }, 200);
    }
    if (no_refilter) return;
    this.selectedItem = undefined;
    this.filteredHistories = this.filterHistories();
  }

  outsideItemClick() {
    setTimeout(() => this.showItemDropdown = false, 200);
  }

  get getMaxHeight() {
    let defaultHeight = 300;
    if (!this.maxHeight || this.maxHeight > defaultHeight) return `${defaultHeight}px`;
    return this.maxHeight < this.minHeight ? `${this.minHeight}px`: `${this.maxHeight}px`;
  }

  filterItems(event: any, which: any) {
    event.stopPropagation();
    if (which == 'All') {
      this.filteredHistories = this.allHistories.filter((h: any) => h.item);
      this.selectedItem = undefined;
    } else {
      this.filteredHistories = this.allHistories.filter((h: any) => h.item && h.id == which.id);
      this.selectedItem = which;
    }
    this.showItemDropdown = false;
  }

  filterHistories() {
    if (this.activeHeaderType == this.headerTypeAll) {
      return this.allHistories;
    } else if (this.activeHeaderType == this.headerTypeOrder) {
      return this.allHistories.filter((h: any) => h.order);
    } else {
      return this.allHistories.filter((h: any) => h.item);
    }
  }

  itemDisplay() {
    if (this.isLoading) return this.headerTypeItems;
    if (this.selectedItem?.id) return `${this.headerTypeItems} (${this.selectedItem.name})`;
    else {
      let count = this.currentItems.length;
      count = count ? count - 1: count;
      return `${this.headerTypeItems} (${count})`;
    }
  }

  singleItemDisplay(which: any) {
    if (which == 'All') return 'All';
    let count = this.allHistories.filter((h: any) => h.item && h.id == which.id).length;
    return `(${count}) ${which.name}`;
  }

  displayHistory(history: any) {
    if (history.item) {
      return this.selectedItem?.id == history.id && this.activeHeaderType == this.headerTypeItems
            ? history.history: `(${history.name}) ${history.history}`;
    }
    return history.history;
  }

  getTodayDateInEST() {
    const formatter = new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      timeZone: 'America/New_York'
    });
  
    const parts: any = formatter.formatToParts(new Date());
    const year = parts.find((part: any) => part.type === 'year').value;
    const month = parts.find((part: any) => part.type === 'month').value;
    const day = parts.find((part: any) => part.type === 'day').value;
  
    this.todayDate =`${year}-${month}-${day}`;
  }

  sortByDate(values: any) {
    values.sort((a: any, b: any) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      if (dateA < dateB) {
        return 1;
      }
      if (dateA > dateB) {
        return -1;
      }
      return 0;
    });
  }

  setScrollbar(v: string) {
    let jdisplay = document.querySelector(v);
    if (jdisplay) {
      jdisplay.addEventListener('mouseenter', function() {
        (jdisplay as any).classList.add('custom-scrollbar');
        (jdisplay as any).classList.remove('custom-scrollbar-close');
      });
      jdisplay.addEventListener('mouseleave', function() {
        (jdisplay as any).classList.remove('custom-scrollbar');
        (jdisplay as any).classList.add('custom-scrollbar-close');
      });
    }
  }

  changeToString(changes: any) {
    let out: any = [];
    changes.forEach((c: any) => {
      let field = this.tableColumns.find((t: any) => t.field == c.field);
      if (!field) {
        if (c.field == 'last_email_sent') {
          out.push('Email was sent');
        } else if (c.field == 'last_tracking_date') {
          out.push('Link was tracked');
      }
        return;
      }
      let val = '';
      if (field.type == 'dropdown') {
        let found = this.dropdownOptions[field.field];
        if (!found) {
          val = 'was changed';
        } else {
          found = found.find((d: any) => d.id == c.to);
          val = found ? `changed to ${found.value}` : 'was changed'; 
        }
      } else if (['object', 'link', 'ship_link', 'dependencies'].includes(field.type as any)) {
        val = 'was changed';
      } else {
        val = `to ${c.to}`;
      }
      out.push(`${field.header} ${val}`);
    })
    return out;
  }

  changeFieldNames(changes: any, is_doc: boolean = false) {
    changes.forEach((c: any) => {
      if (c.field == 'changed_job') c.field = 'job_id';
      else if (c.field == 'job') c.field = 'job_id';
      else if (c.field == 'supplier') c.field = 'supplier_id';
      else if (c.field == 'order_id') c.field = '';
      else if (c.field == 'room') c.field = 'room_id';
      else if (is_doc) {
        if (c.field == 'number') c.field = 'supplier_documents_numbers';
        else if (c.field == 'document_type') c.field = 'supplier_documents_types';
        else if (c.field == 'order_document_date') c.field = 'supplier_documents_earliest_document_date';
      }
    })
  }

  createHistories(data: any, is_doc: boolean = false) {
    let histories: any = [];
    let prev_user: any = undefined;
    let prev_date: any = undefined;
    let prev_changes: any = [];
    data.forEach((h: any) => {
      let cur: any = {};
      cur.user = h.user.endsWith('@materlog.com') || h.user == 'System' || h.user.endsWith('@getmeti.com')
              ? 'System': h.username;
      let fullname = cur.user.split(' ');
      if (fullname.length == 2) {
        cur.user = `${fullname[0]} ${fullname[1][0]}.`;
      }
      cur.date = h.date;
      this.changeFieldNames(h.changes, is_doc);
      let changes = h.changes.filter((c: any) => this.tableColumns.find((t: any) => 
          t.field == c.field || this.nonTableFields.includes(c.field)
      ));

      if (!changes.length) return;
      if (prev_user == cur.user && prev_date == cur.date) {
        changes = changes.filter((c: any) => !prev_changes.find((p: any) => p.field == c.field));
        if (!changes.length) return;
        let pchanges = histories[histories.length - 1].changes;
        let cur_changes = this.changeToString(changes);
        prev_changes = prev_changes.concat(changes);
        histories[histories.length - 1].changes = pchanges.concat(cur_changes);
        return;
      }
      prev_user = cur.user;
      prev_date = cur.date;
      prev_changes = changes;
      cur.changes = this.changeToString(changes);
      histories.push(cur);
    })
    return histories;
  }

  getOrderHistory(order_id: any) {
    if (!this.orderId) return undefined;
    this.isLoading = true;
    this.orderService.getOrderHistory({order_id: order_id}).subscribe((result: any) => {
      let item_id_names: any = [];
      result.item_history.forEach((i: any) => {
        item_id_names.push({id: i.id, name: i.name});
      });
      this.currentItems = item_id_names;
      if (this.currentItems.length) {
        this.currentItems = ['All'].concat(this.currentItems);
      }
      let histories: any = [];
      let order_history = this.createHistories(result.order_history);
      let order_doc_history = this.createHistories(result.order_doc_history, true);

      order_history.forEach((h: any) => {
        let dochist = order_doc_history.filter((dh: any) => dh.date == h.date && dh.user == h.user);
        let ochanges = h.changes;
        dochist.forEach((dh: any) => {
          ochanges = ochanges.concat(dh.changes);
        })
        histories.push({order: true, history: ochanges.join(', '), date: h.date, user: h.user});
        order_doc_history = order_doc_history.filter((dh: any) => !(dh.date == h.date && dh.user == h.user));
      })

      order_doc_history.forEach((h: any) => {
        histories.push({order: true, history: h.changes.join(', '), date: h.date, user: h.user});
      })

      result.item_history.forEach((i: any) => {
        let history = this.createHistories(i.history);
        history.forEach((h: any) => {
          histories.push({item: true, id: i.id, name: i.name, history: h.changes.join(', '), date: h.date, user: h.user});
        })
      })
      this.sortByDate(histories);
      histories.forEach((h: any) => {
        if (h.date == this.todayDate) {
          h.date = 'today';
        } else {
          let d = h.date.split('-');
          h.date = d.slice(1).concat(d[0].slice(2)).join('/');
        }
      })
      this.allHistories = histories;
      this.filteredHistories = this.allHistories;
      this.isLoading = false;

      setTimeout(() => {
        this.setScrollbar('.activity-history-container');
      }, 800);
    });
    return undefined;
  }

}