import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { EventEmitter, Output, ChangeDetectorRef} from '@angular/core';
import { ElementRef, HostListener } from '@angular/core';
import { setScrollBar } from 'src/app/common/utils/general';
import { isPDF, isJPEG, isPNG, generateRandomString } from 'src/app/common/utils/general';
import { OrderService } from '../../services/order.service';
import { JobsService } from '../../services/job.service';
import { DashboardActions } from '../../state/actions/dashboard.actions';
import { downloadCSV } from './auto-upload.utils';

@Component({
  selector: 'materlog-auto-upload',
  templateUrl: './auto-upload.component.html',
  styleUrls: ['./auto-upload.component.css'],
})
export class AutoUploadComponent implements OnInit {
    @Input() allMailData: any;
    @Input() dropdownOptions?: any;
    @Input() maxWidth: any;

    uploadPurposes: any = [
        {name: 'New order', selected: true, how: 'new'},
        /* {name: 'Update order', selected: false, how: 'update'}, */
        {name: 'Extract only', selected: false, how: 'new'},
    ];

    extractionStarted: boolean = false;
    uploadDocs: any = [];
    selectedId: any = '';
    isOrderChosen: boolean = true;

    ordersReady: any = [];

    @ViewChild('uploadContainer') uploadContainer!: ElementRef;

    ngOnInit() {
        this.getAllAutoUploads();
        this.pollOnExtractProcessing();
    }
    
    ngOnChanges(changes: any) {
    }
  
    ngAfterViewInit() {
        setScrollBar(this.uploadContainer, '.upload-container-blocks');
        setScrollBar(this.uploadContainer, '.upload-confirm-order-item-datas');
    }

    constructor(
        private orderService: OrderService,
        private dashboardActions: DashboardActions
    ) {
        (window as any)['upload'] = this;
    }

    beginExtraction() {
        if (this.extractionStarted || !this.uploadDocs.length) return;
        this.extractionStarted = true;
        this.onFakeExtractionCreate();
        this.onUploadParseDoc();
    }
    
    get dropdownRetrieved() {
        return this.dropdownOptions.document_types  &&
        this.dropdownOptions.job_id  &&
        this.dropdownOptions.status  &&
        this.dropdownOptions.status_colors  &&
        this.dropdownOptions.supplier_id  &&
        this.dropdownOptions.unit_of_measure &&
        this.allMailData?.curOrders &&
        this.allMailData?.supArray;
    }

    onChosenPurpose(choice: any) {
        this.uploadPurposes.forEach((c: any) => c.selected = false);
        choice.selected = true;
    }

    onFakeExtractionCreate() {
        let when = this.formatDate(new Date());
        this.uploadDocs.forEach((d: any) => {
            let cur = {created_datetime: when, status: -1}
            this.ordersReady.push(cur);
        })
    }

    onUploadParseDoc() {
        let completedUploads = 0;
        let how = this.uploadPurposes.find((s: any) => s.selected);
        how = how?.how || 'new';
        this.uploadDocs.forEach((o: any) => {
            let formData = o.form;
            formData.append('order_id', '');
            formData.append('how', how);
            this.orderService.uploadAutoParseDoc(formData).subscribe((result: any) => {
                completedUploads++;
                if (completedUploads == this.uploadDocs.length) {
                    this.extractionStarted = false;
                    this.uploadDocs = [];
                    let data = [...result];
                    this.onFormatNewReviewables(data);
                }
            })
        })
    }
    
    uploadFile(event: any) {
        event.stopPropagation();
    }

    onFileSelected(event: any) {
        const input = event.target as HTMLInputElement;
        if (input.files) {
          for (let i = 0; i < input.files.length; i++) {
            const cur = input.files[i];
            const reader = new FileReader();
    
            reader.onloadend = () => {
              const arrayBuffer = reader.result as ArrayBuffer;
              const bytes = new Uint8Array(arrayBuffer);
    
              if (isPDF(bytes) || isJPEG(bytes) || isPNG(bytes)) {
                if (!this.uploadDocs.find((a: any) => a.filename == cur.name)) {
                  const formData = new FormData();
                  formData.append('file', cur, cur.name);
                  this.uploadDocs.push({ form: formData, filename: cur.name, id: generateRandomString() });
                }
              } else {
                alert('File type not supported!');
              }
            };
    
            reader.onerror = (error) => {
              console.error('Error reading file:', error);
            };
    
            reader.readAsArrayBuffer(cur.slice(0, 8)); // Read the first 8 bytes
          }
        }
        event.target.value = ''; // reset form
    }

    get canUploadMore() {
        return !this.extractionStarted && this.uploadDocs.length < 4;
    }

    deleteDoc(doc: any) {
        this.uploadDocs = this.uploadDocs.filter((d: any) => d != doc);
    }

    onReviewChosen(review: any) {
        if (review.status != 2) {
            this.selectedId = null;
            return;
        };
        this.selectedId = review.id;
        this.isOrderChosen = true;
    }


    onFormatNewReviewables(data: any) {
        if (data.length > 1) {
            data.sort((a: any, b: any) => new Date(a.created_datetime).getTime() - new Date(b.created_datetime).getTime());
        }
        this.ordersReady = data;
        setScrollBar(this.uploadContainer, '.upload-on-ready-select');
    }


    pollOnExtractProcessing() {
        let outer = this;
        var pollInterval = setInterval(function() {
            let processing = outer.ordersReady?.find((o: any) => o.status != 2)
            if (processing) {
                outer.getAllAutoUploads();
            }
          }, 1000 * 10);
    }
    
    getAllAutoUploads() {
        this.orderService.getAllAutoUploads().subscribe((result: any) => {
            if (!result || !result.length) {
                return;
            }
            let data = [...result];
            this.onFormatNewReviewables(data);
        })
    }

    formatDate(dateStr: any) {
        const dateObj = new Date(dateStr);
        
        let month = dateObj.getMonth() + 1; // Months are zero-based in JavaScript
        let day = dateObj.getDate();
        let year = dateObj.getFullYear() % 100; // Get last two digits of the year
        let hours = dateObj.getHours();
        let minutes: any = dateObj.getMinutes();
        minutes = minutes < 10 ? '0' + minutes : minutes;

        const ampm = hours >= 12 ? 'pm' : 'am';
        
        hours = hours % 12;
        hours = hours ? hours : 12; // Adjust hour 0 to 12

        const formattedDate = `${month}/${day} ${hours}:${minutes} ${ampm}`;
        return formattedDate
        
    }

    textForStatus(status: any) {
        switch (status) {
            case 2:
                return 'Ready for review';
            case 0:
            case 1:
                return 'Processing';
            case -1:
                return 'Uploading';
            default:
                return 'Processing'
        }
    }

    classForStatus(status: any) {
        switch (status) {
            case 2:
                return 'bi-check2';
            case 0:
            case 1:
                return 'bi-arrow-clockwise';
            case -1:
                return 'bi-cloud-arrow-up';
            default:
                return 'bi-arrow-clockwise'
        }
    }

    get isDocDefault() {
        if (this.extractionStarted) {
            return true;
        }
        return !this.uploadDocs.length;
    }

    deleteReviewReady(event: any, review: any) {
        event.stopPropagation();
        this.ordersReady = this.ordersReady.filter((r: any) => r != review);
        if (this.selectedId == review.id) {
            this.selectedId = null;
        }
        this.orderService.deleteMessageExtract(review.id).subscribe(() => {});
    }

    setChooseOrderItem(which: any) {
        if (!this.selectedId) return;
        this.isOrderChosen = which == 'order';
        setScrollBar(this.uploadContainer, '.upload-confirm-order-item-datas');
    }

    get orderOrItemText() {
        return this.isOrderChosen ? 'Order data': 'Item data';
    }

    get msgData() {
        return this.ordersReady.find((r: any) => r.id == this.selectedId);
    }

    orderConfirmInProgress: boolean = false;
    csvDownloadInProgress: boolean = false;
    onConfirmOrder(is_order: boolean = true) {
        if (this.orderConfirmInProgress || this.csvDownloadInProgress) return;
        if (!this.selectedId) return;

        if (is_order) {
            this.orderConfirmInProgress = true;
        } else {
            this.csvDownloadInProgress = true;
        }
        let payload: any = {msg_id: this.selectedId, keep: is_order}
        this.orderService.msgSaveExtract(payload).subscribe((result: any) => {
            if (!is_order) {
                downloadCSV(result?.results || [], this.msgData?.order, this.dropdownOptions);
            }
            let shadowOrder = this.msgData?.shadow?.order;
            if (shadowOrder && is_order) {
                if (shadowOrder?.job_id) {
                    this.dashboardActions.requestActiveJobs();
                }
                if (shadowOrder?.supplier_id) {
                    this.dashboardActions.requestSuppliersSmall();
                }
            }
            setTimeout(() => {
                this.ordersReady = this.ordersReady.filter((r: any) => r.id != this.selectedId);
                this.selectedId = null;
                this.orderConfirmInProgress = false;
                this.csvDownloadInProgress = false;
                this.isOrderChosen = true;
            }, 500);
        })

    }

    get addOrderText() {
        return this.orderConfirmInProgress ? 'Order added!' : 'Add Order';
    }

    get downloadCSVText() {
        return this.csvDownloadInProgress ? 'Converting...' : 'Download to csv';
    }

    get blockMinHeight() {
        let heights = [window.innerHeight - 630, 200];
        let height = Math.max(...heights);
        return `${height}px`
    }
}