<div class="ship-link-container card-container custom-scrollbar-close" #container>
  <div *ngFor="let link of links; let i = index">
    <div class="ship-link"
        [ngStyle]="{
          'margin': cardView ? '2px 0px': '0px 0px 0px 6px'
        }">
      <input #shipLink class="ship-input" type="text" *ngIf="isEditable"
      [ngStyle]="{
        'display': link.editing || !link.isurl ? 'block': 'none',
        'max-width.px': maxWidth - 15,  'min-width.px': maxWidth - 15, 
      }"
      [(ngModel)]="link.value" [placeholder]="link.placeholder"
      (focus)="onShipFocus($event, link)" (blur)="onShipBlur($event, link)"
      (click)="onShipClick($event, link)"
      (keydown)="shipKeydown($event, link)"/>
      <a *ngIf="link.isurl" [href]="link.value" id="placeholderLink" 
          target="_blank" rel="noopener noreferrer"
          [ngStyle]="{
            'display': !link.editing ? 'block': 'none',
            'max-width.px': maxWidth - 15,  'min-width.px': maxWidth - 15, 
          }"
          class="placeholder-link">{{link.placeholder}}</a>
      <div *ngIf="!link.isurl && !isEditable" class="ship-input"
        [ngStyle]="{
          'max-width.px': maxWidth - 15,  'min-width.px': maxWidth - 15, 
        }">
        {{link.original}}
      </div>
      <div class="ship-tools" *ngIf="isEditable">
        <i class="bi bi-pencil remove-link" 
          [ngStyle]="{'display': !link.editing ? 'block': 'none'}"
          style="position: relative; font-size: 9px" (click)="editLink($event, link, i)"></i>
        <i class="bi bi-x remove-link"
            style="position: relative; padding-left: 4px" (click)="removeLink($event, link)"></i>
      </div>

    </div>
  </div>
  <div *ngIf="isEditable" class="add-link">
    <i class="bi bi-plus-lg" style="position: relative" (click)="addLink($event)"></i>
  </div>
</div>