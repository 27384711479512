import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DashboardJobView {
  private viewChosenSubject = new Subject<any>();
  viewChoseButtonClick$ = this.viewChosenSubject.asObservable();
  get_view_data(data: any) {
    this.viewChosenSubject.next(data);
  }

  private loadingViewsSubject = new Subject<any>();
  loadingViews$ = this.loadingViewsSubject.asObservable();
  loading_views(loading: boolean) {
    this.loadingViewsSubject.next(loading);
  }

  private loadingSubject = new Subject<any>();
  loading$ = this.loadingSubject.asObservable();
  is_loading(loading: boolean) {
    this.loadingSubject.next(loading);
  }

  private newViewSubject = new Subject<void>();
  newView$ = this.newViewSubject.asObservable();
  make_new_view() {
    this.newViewSubject.next();
  }

  private sidePanelSubject = new Subject<any>();
  sidePanelClick$ = this.sidePanelSubject.asObservable();
  side_panel_click(data: any) {
    this.sidePanelSubject.next(data);
  }

  private notificationCountSubject = new Subject<any>();
  notificationCount$ = this.notificationCountSubject.asObservable();
  set_notification_count(data: any) {
    this.notificationCountSubject.next(data);
  }

  private viewClickedSubject = new Subject<void>();
  viewClick$ = this.viewClickedSubject.asObservable();
  view_clicked() {
    this.viewClickedSubject.next();
  }

  private commentOccurSubject = new Subject<void>();
  commentOccur$ = this.commentOccurSubject.asObservable();
  on_comment() {
    this.commentOccurSubject.next();
  }

  private emailOccurSubject = new Subject<void>();
  emailOccur$ = this.emailOccurSubject.asObservable();
  on_email() {
    this.emailOccurSubject.next();
  }

  private closeCommentsSubject = new Subject<void>();
  closeComments$ = this.closeCommentsSubject.asObservable();
  close_comments() {
    this.closeCommentsSubject.next();
  }

  private closeEmailSubject = new Subject<void>();
  closeEmail$ = this.closeEmailSubject.asObservable();
  close_email() {
    this.closeEmailSubject.next();
  }

  private requestMetaDataSubject = new Subject<void>();
  requestMetadata$ = this.requestMetaDataSubject.asObservable();
  request_metadata() {
    this.requestMetaDataSubject.next();
  }

  private closeCardsSubject = new Subject<any>();
  toggleCloseCard$ = this.closeCardsSubject.asObservable();
  toggle_close_cards(data: any) {
    this.closeCardsSubject.next(data);
  }

  private cardViewIconToggleSubject = new Subject<any>();
  cardViewToggle$ = this.cardViewIconToggleSubject.asObservable();
  cardview_icon_toggle(data: any) {
    this.cardViewIconToggleSubject.next(data);
  }

  private rowDataUpdatedSubject = new Subject<any>();
  rowDataUpdated$ = this.rowDataUpdatedSubject.asObservable();
  row_data_updated(data: any) {
    this.rowDataUpdatedSubject.next(data);
  }

  private getNewViewsSubject = new Subject<void>();
  getNewViews$ = this.getNewViewsSubject.asObservable();
  get_new_views() {
    this.getNewViewsSubject.next();
  }

  private pollMetaDataSubject = new Subject<void>();
  pollMetaData$ = this.pollMetaDataSubject.asObservable();
  do_poll_metadata() {
    this.pollMetaDataSubject.next();
  }

  private searchFilterSubject = new Subject<void>();
  searchFilterObs$ = this.searchFilterSubject.asObservable();
  do_reset_search_filter() {
    this.searchFilterSubject.next();
  }

  private notificationCntSubject = new Subject<void>();
  notificationCntObs$ = this.notificationCntSubject.asObservable();
  do_set_notification_cnt(data: any) {
    this.notificationCntSubject.next(data);
  }

  private homePageSubject = new Subject<void>();
  homePageClickObs$ = this.homePageSubject.asObservable();
  do_home_page_clicked(data: any) {
    this.homePageSubject.next(data);
  }

  private alertClickSubject = new Subject<void>();
  alertClickObs$ = this.alertClickSubject.asObservable();
  do_alert_clicked(data: any) {
    this.alertClickSubject.next(data);
  }

  private hasUncatSubject = new Subject<void>();
  hasNoUncat$ = this.hasUncatSubject.asObservable();
  set_no_uncat(data: any) {
    this.hasUncatSubject.next(data);
  }

  private trySetNewJobSubject = new Subject<void>();
  setNewJob$ = this.trySetNewJobSubject.asObservable();
  set_new_job() {
    this.trySetNewJobSubject.next();
  }

  private imageUploadSubject = new Subject<void>();
  imageIsUploading$ = this.imageUploadSubject.asObservable();
  image_is_uploading() {
    this.imageUploadSubject.next();
  }

  private workflowSubject = new Subject<void>();
  workflowClicked$ = this.workflowSubject.asObservable();
  do_workflow_clicked(data: any) {
    this.workflowSubject.next(data);
  }

  private workflowSettingSubject = new Subject<void>();
  workflowSettingClicked$ = this.workflowSettingSubject.asObservable();
  do_workflow_setting_clicked(data: any) {
    this.workflowSettingSubject.next(data);
  }

  private statusSubject = new Subject<void>();
  statusClicked$ = this.statusSubject.asObservable();
  do_status_clicked(data: any) {
    this.statusSubject.next(data);
  }

  private sidePanelNotifSubject = new Subject<void>();
  sidePanelNotifGet$ = this.sidePanelNotifSubject.asObservable();
  do_side_panel_notifs() {
    this.sidePanelNotifSubject.next();
  }

  private reinitDashDataSubject = new Subject<void>();
  reinitDashData$ = this.reinitDashDataSubject.asObservable();
  do_reinit_data() {
    this.reinitDashDataSubject.next();
  }
}
