import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { EventEmitter, Output, ChangeDetectorRef} from '@angular/core';
import { ElementRef, HostListener } from '@angular/core';
import { setScrollBar } from 'src/app/common/utils/general';
import { isPDF, isJPEG, isPNG, generateRandomString } from 'src/app/common/utils/general';
import { createDate } from 'src/app/common/utils/general';

@Component({
    selector: 'materlog-shiplink-component',
    templateUrl: './materlog-shiplink-component.component.html',
    styleUrls: ['./materlog-shiplink-component.component.css'],
  })
export class MaterLogShiplink implements OnInit {
  @Input() items: any = [];
  @Input() confirmed: boolean = false;
  @Input() maxWidth: number = 150;
  @Output() onChange = new EventEmitter<any>();
  @Input() fieldName: string = ''
  @Input() active: boolean = true;

  @ViewChild('shipLinkEl') shipLinkEl!: ElementRef;
  shipLinks: any = [];

  constructor(
    private cdr: ChangeDetectorRef
  ) {
  }

  ngOnChanges(changes: any) {
    this.shipLinks = this.urlifyLinks(this.items) || [];
  }

  ngOnInit() {
  }

  urlifyLinks(links: any) {
    let curLinks = links?.map((l: any) => (this.makeUrlAttrs(l)));
    return curLinks.filter((item: any, index: any, self: any) =>
        index === self.findIndex((t: any) => t.value === item.value)
    );
  }

  makeUrlAttrs(link: any) {
    return {
      link: link,
      editing: false,
      isurl: this.isURL(link),
      value: this.makeURLFull(link),
      placeholder: this.getHostName(this.makeURLFull(link)),
    }
  }

  get maxPixelWidth() {
    return `${this.maxWidth}px`;
  }

  isURL(v: any) {
    try {
      let url = new URL(v);
      return true;
    } catch (error) {
      return false;
    }
  }

  makeURLFull(v: any): string {
    try {
      let url = new URL(v);
      return url.href;
    } catch (error) {
      return v;
    }
  }

  getHostName(v: any) {
    try {
      const url = new URL(v);
      return `${url.hostname}`;
    } catch(error) {
      return v;
    }
  }

  editLink(link: any) {
    link.editing = true;
    this.cdr.detectChanges();
  }

  get canAddUrl() {
    if (this.shipLinks.find((s: any) => s.editing || !s.value)) {
      return false;
    }
    return true;
  }

  onShipFocus(link: any): void {
    (window as any)['mlinks'] = this;
    link.editing = true;
    link.placeholder = '';
    this.cdr.detectChanges();
  }

  onShipBlur(link: any) {
    link.editing = false;
    let originalValue = link.link;
    let curLinks = this.shipLinks.filter((s: any) => s.value);
    curLinks = curLinks.filter((item: any, index: any, self: any) =>
        index === self.findIndex((t: any) => t.value === item.value)
    );
    this.shipLinks = curLinks.map((s: any) => (this.makeUrlAttrs(s.value)));
    this.cdr.detectChanges();
    this.onChange.emit({data: this.shipLinks, field: this.fieldName, original: originalValue});
  }


  removeLink(link: any) {
    this.shipLinks = this.shipLinks?.filter((l: any) => l != link) || [];
    this.cdr.detectChanges();
    this.onChange.emit({data: this.shipLinks, field: this.fieldName});
  }

  shipKeydown(event: any, link: any) {
    if (event.key === "ArrowUp" || event.key === "ArrowDown" || event.key === "ArrowLeft" || event.key === "ArrowRight") {
      event.stopPropagation();
      return;
    }
    if (event.key == 'Enter') {
      event.target.blur();
    }
    if (event.code == 'KeyV') {
      event.stopPropagation();
    }
    if (event.code == 'KeyC') {
      event.stopPropagation();
      navigator.clipboard.writeText(link.value).then(() => {});
    }
  }

  addLink() {
    this.shipLinks.forEach((l: any) => l.editing = false);
    this.shipLinks.push({
      editing: true,
      value: '',
      link: '',
      placeholder: '',
      isurl: false,
    });
    this.cdr.detectChanges();
    setTimeout(() => {
      let links = this.shipLinkEl?.nativeElement?.querySelectorAll('.materlog-ship-input');
      if (links?.length) {
        let first = links[links.length - 1];
        first.focus();
      }
    }, 100);
  }

}
