<div *ngIf="orgNeedsConfirm" class="home-page-confirm-banner">
    <div style="margin-bottom: 8px; padding-left: 8px">
        Get set up
    </div>
    <div *ngFor="let org of orgConfirms" class="home-page-org-todos" (click)="onOrgConfirm(org)">
        <i *ngIf="org.confirm" class="bi bi-check-circle-fill home-page-org-confirmed home-page-org-confirmed-icon"></i>
        <div *ngIf="!org.confirm" class="home-page-org-confirmed home-page-org-confirmed-circle"></div>
        <div class="home-page-org-text"
            [ngStyle]="{
                'color': org.confirm ? '#DBDBDB': '#fff',
                'text-decoration': org.confirm ? 'line-through': '',
                'margin': '0px 8px 0px 12px'
            }"
            >
            {{org.text}}
        </div>
        <i class="bi bi-chevron-right home-page-org-text"
            [ngStyle]="{
                'color': org.confirm ? '#DBDBDB': '#fff',
                'font-size': '12px'
            }"
        ></i>
    </div>

</div>
<div class="home-page-container custom-scrollbar-close" #homePageContainer
    [ngStyle]="{
        'max-height': createMaxHeight,
     }"
    >
    <div class="home-page-container-scroll">
        <div class="home-page-welcome-container">
            {{welcomeMessage}}
        </div>
        <div class="home-page-row1 home-page-box home-page-box-meta">
            <div class="home-page-row1-meta-container">
                <div class="home-page-row1-body" *ngFor="let row of row1Values" (click)="goToPage(row.name)">
                    <div class="home-page-row1-number">
                        {{numberForRow(row.name)}}
                        <div class="materlog-tooltip-container" (click)="onHoverClick()">
                            {{row.hover_pre}}
                            <span *ngIf="row.span" (click)="onRowClick($event, row.name)">{{row.span}}</span>
                            {{row.hover_post}}
                        </div>
                    </div>
                    <div class="home-page-row1-text">
                        {{row.name}}
                    </div>
                </div>
            </div>
            <div class="home-page-row1-meta-calendar">
                <i class="bi bi-calendar"></i>
                today
            </div>
        </div>
        <div class="home-page-row2">
            <div class="home-page-box home-page-status-box">
                <div class="home-page-status-top" (click)="goToPage('open orders')">
                    Item status
                </div>
                <div class="home-page-item-status-scroll custom-scrollbar-close" style="gap: 24px;">
                    <div class="home-page-item-status" *ngFor="let status of itemStatuses">
                        <div class="home-page-item-status-meta" (click)="statusValueClicked(status)">
                            <div class="home-page-item-status-meta-color"
                                [ngStyle]="{'background-color': status.color}">
                            </div>
                            <div class="home-page-item-status-meta-text">
                                {{status.name}}
                            </div>
                        </div>
                        <div class="home-page-item-status-meta-text">
                            {{countForStatus(status)}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="home-page-box home-page-status-box home-page-alert-box">
                <div class="home-page-header-top">
                    <div class="home-page-status-top" (click)="goToPage('alerts')">
                        Recent alerts
                    </div>
                    <div class="home-page-faint-text" (click)="clearAllAlerts()">
                        Clear all
                    </div>
                </div>
                <div class="home-page-item-status-scroll custom-scrollbar-close" *ngIf="!alertsLoading">
                    <div class="home-page-single-alert" (click)="goToAlert(alert)"
                            *ngFor="let alert of allAlerts; let isFirst=first; let isLast=last"
                        [ngClass]="{
                            'home-page-first-alert': isFirst,
                            'home-page-other-alerts': !isFirst,
                            'home-page-single-alert-bottom': !isLast,
                        }"
                        >
                        <span class="home-page-single-alert-emoji" *ngIf="alert.emoji">{{alert.emoji}}</span>
                        <span class="home-page-single-alert-type">{{alert.text}}</span>
                        <span class="home-page-single-alert-info">{{alert.info}}</span>
                    </div>
                </div>
                <ng-container *ngIf="alertsLoading" class="flex flex-col">
                    <div *ngFor="let skeleton of alertSkeletons">
                        <p-skeleton height="36px" [width]="skeleton.width" borderRadius="16px"></p-skeleton>
                    </div>
                </ng-container>
            </div>
        </div>
        <materlog-calendar class="home-page-calendar"
            [dropdownOptions]="dropdownOptions"
            [allMailData]="allMailData"
        ></materlog-calendar>
    </div>
</div>