import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { HttpClientModule } from '@angular/common/http';

import { PdfViewerModule } from 'ng2-pdf-viewer';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MessageService, ConfirmationService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { AvatarModule } from 'primeng/avatar';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MultiSelectModule } from 'primeng/multiselect';
import { CalendarModule } from 'primeng/calendar';
import { DividerModule } from 'primeng/divider';
import { InputSwitchModule } from 'primeng/inputswitch';
import { SplitterModule } from 'primeng/splitter';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ChipsModule } from 'primeng/chips';
import { TabViewModule } from 'primeng/tabview';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { TableModule } from 'primeng/table';
import { InputTextModule } from 'primeng/inputtext';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { KeyFilterModule } from 'primeng/keyfilter';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { CheckboxModule } from 'primeng/checkbox';
import { CascadeSelectModule } from 'primeng/cascadeselect';
import { ContextMenuModule } from 'primeng/contextmenu';
import { ImageModule } from 'primeng/image';
import { TooltipModule } from 'primeng/tooltip';
import { ToastModule } from 'primeng/toast';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SkeletonModule } from 'primeng/skeleton';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { PasswordModule } from 'primeng/password';

import { AppRoutingModule } from "../../root/modules/app-routing.module";
import { SharedModule } from '../../common/modules/shared.module';
import { ViewTabListComponent } from '../components/view-tab-list/view-tab-list.component';
import { DashboardV2Component } from '../dashboard-v2.component';
import { DashboardFiltersComponent } from '../components/dashboard-filters/dashboard-filters.component';
import { HideFieldsComponent } from '../components/hide-fields/hide-fields.component';
import { MlogFiltersComponent } from '../components/mlog-filters/mlog-filters.component';
import { SortComponent } from '../components/sort/sort.component';
import { TemplatesComponent } from '../components/templates/templates.component';
import { TemplatesDialogComponent } from 'src/app/templates/components/templates/templates-dialog.component';
import { ShareComponent } from '../components/share/share.component';
import { ActiveJobListComponent } from '../components/active-job-list/active-job-list.component';
import { JobTableComponent } from '../components/job-table/job-table.component';
import { AddEditDocComponent } from '../components/add-edit-doc/add-edit-doc.component';
import { GetTrackingComponent } from '../components/get-tracking/get-tracking.component';
import { AddSupplierContactModalComponent } from '../components/add-supplier-contact-modal/add-supplier-contact-modal.component';
import { AddSupplierModalComponent } from '../components/add-supplier-modal/add-supplier-modal.component';
import { AddItemComponent } from '../components/add-item/add-item.component';
import { CreateUpdateJobComponent } from '../components/create-update-job/create-update-job.component';
import { AddEditPoComponent } from '../components/add-edit-po/add-edit-po.component';
import { DashboardEffects } from '../state/effects/dashboard.effects';
import { dashboardReducer } from '../state/reducers/dashboard.reducer';
import { DashboardService } from '../services/dashboard.service';
import { JobsService } from '../services/job.service';
import { CommentsService } from '../services/comment.service';
import { ReceiversService } from '../services/receivers.service';
import { TemplatesService } from '../services/templates.service';
import { ViewJobsComponent } from '../components/view-jobs/view-jobs.component';
import { GeoapifyGeocoderAutocompleteModule } from '@geoapify/angular-geocoder-autocomplete';
import { environment } from 'src/environments/environment';
import { HistoryComponent } from '../components/history/history.component';
import { JobTableV2Component } from '../components/job-table-v2/job-table-v2.component';
import { ImagesCellRenderer } from '../components/job-table-v2/images/images-cell-renderer.component';
import { DropDownEditor } from '../components/job-table-v2/dropdown/dropdown.component';
import { CustomNoRowsOverlay } from '../components/job-table-v2/custom-no-rows-overlay.component';
import { OrdersCellRenderer } from '../components/job-table-v2/orders/orders-cell-renderer.component';
import { OrdersDocCellRenderer } from '../components/job-table-v2/orders-doc/orders-doc-cell-renderer.component';
import { CalendarEditor } from '../components/job-table-v2/calendar/calendar.component';
import { AutoCompleteEditor } from '../components/job-table-v2/autocomplete/autocomplete.component';
import { AddRoomModalComponent } from '../components/job-table-v2/add-room-dialog/add-room-modal.component';
import { ResizerComponent } from "../components/resizer/resizer.component";
import { GroupFieldsComponent } from "../components/group-fields/group-fields.component";
import { PinFieldsComponent } from "../components/pin-fields/pin-fields.component";
import {AngularEditorModule} from "@kolkov/angular-editor";
import { OrdersRowRenderer } from "../components/job-table-v2/orders/orders-row-renderer.component";
import {NgPipesModule} from 'ngx-pipes';

import { AgGridModule } from "ag-grid-angular";
import { CalendarDateEditor } from "../components/job-table-v2/calendar-date/calendar-date.component";

import {
  ImagesReadonlyCellRenderer
} from "../components/job-table-v2/images-read-only/images-readonly-cell-renderer.component";
import {
  OrdersDocReadonlyCellRenderer
} from "../components/job-table-v2/orders-doc-readonly/orders-doc-readonly-cell-renderer.component";
import { OrdersReadonlyCellRenderer } from "../components/job-table-v2/orders/orders-readonly-cell-renderer.component";
import { OrdersCellTypeRenderer } from "../components/job-table-v2/orders/orders-cell-type-renderer.component";

import { MailListComponent } from '../components/mail-view/mail-list';
import { DropDownCellRenderer } from '../components/job-table-v2/dropdownv2/dropdownv2.component';
import { PopupComment } from '../components/job-table-v2/popup-comment/popup-comment.component';
import { CommentPaneComponent } from '../components/comment-view/comment-pane.component';
import { MentionModule } from 'angular-mentions';
import { SplitItemComponent } from '../components/job-table-v2/split-item/split-item.component';
import { SubitemsComponent } from '../components/subitems/subitems.component';
import { TrackingPaneComponent } from '../components/tracking-panel/tracking-component';
import { SidePanelComponent } from '../components/side-panel/side-panel.component';
import { SupplierCredentialsService } from 'src/app/suppliers/services/suppliers.credentials.service';
import { suppliersCredentialsPageReducer } from 'src/app/suppliers/state/reducers/suppliers-credentials-page.reducer';
import { SupplierCredentialsPageComponent } from 'src/app/suppliers/components/suppliers-credentials-page/suppliers-credentials-page.component';
import { ApprovalsComponent } from '../components/approvals/approvals-component';
import { UserSettingsComponent } from 'src/app/user-settings/components/user-settings/user-settings.component';
import { CompanySettingsComponent } from 'src/app/company-settings/components/company-settings/company-settings.component';
import { MaterlogWorkflows } from 'src/app/company-settings/components/workflows/workflows.component';
import { CompanyManagementComponent } from 'src/app/company-settings/components/company-management/company-management.component';
import { PreferencesComponent } from 'src/app/user-settings/components/preferences/preferences.component';
import { UserManagementComponent } from 'src/app/user-settings/components/user-management/user-management.component';
import { ShipLinkCellRenderer } from '../components/job-table-v2/ShipLinkCellRenderer/shiplink.component';
import { CardControllerComponent } from '../components/card-controller/card-controller.component';
import { CardViewComponent } from '../components/card-view/card-view.component';
import { CardViewFilter } from '../components/cardview-filter/cardview-filter.component';
import { ChangeItemJob } from '../components/job-table-v2/change-item-job/change-item-job.component';
import { NotificationsComponent } from '../components/notifications/notifications.component';
import { ActivityHistoryComponent } from '../components/activity-history/activity-history.component';
import { SubmittalsComponent } from '../components/submittals/submittals.component';
import { GlobalSubmittalsComponent } from '../components/global-submittals/global-submittals.component';
import { ExportSubmittalsComponent } from '../components/submittals-export/submittals-export.component';
import { DependencyRenderer } from '../components/job-table-v2/dependency-renderer/dependency-renderer.component';
import { HomePageComponent } from 'src/app/home-page/home-page.component';
import { AutoUploadComponent } from '../components/auto-upload/auto-upload.component';
import { OrderAutoUploadComponent } from '../components/order-auto-upload/order-auto-upload.component';
import { MaterlogDropdown } from '../components/materlog-types/materlog-dropdown-component/materlog-dropdown-component.component';
import { MaterLogDate } from '../components/materlog-types/materlog-date-component/materlog-date-component.component';
import { MaterLogText } from '../components/materlog-types/materlog-text-component/materlog-text-component.component';
import { MaterlogStatus } from '../components/materlog-types/materlog-status-component/materlog-status-component.component';
import { MaterLogShiplink } from '../components/materlog-types/materlog-shiplink-component/materlog-shiplink-component.comonent';
import { ItemAutoUploadComponent } from '../components/item-auto-upload/item-auto-upload.component';
import {OrderDocsAutoUploadComponent} from '../components/order-docs-auto-upload/order-docs-auto-upload.component'
import { MaterLogToolTip } from '../components/materlog-types/materlog-tooltip-component/materlog-tooltip-component.component';
import { CalendarComponent } from '../components/calendar/calendar.component';
import { MaterLogImages } from '../components/materlog-types/materlog-image-component/materlog-image-component.component';
import { MaterlogWorkflowsChoose } from 'src/app/company-settings/components/workflow-types/workflow-choose/workflow-choose.component';
import { MaterlogWorkflowsConfirm } from 'src/app/company-settings/components/workflow-types/workflow-confirm/workflow-confirm.component';
import { MaterlogWorkflowsSelect } from 'src/app/company-settings/components/workflow-types/workflow-select/workflow-select.component';
import { MaterlogWorkflowsStart } from 'src/app/company-settings/components/workflow-types/workflow-start/workflow-start.component';
import { MetaSettingsComponent } from 'src/app/dashboard/components/meta-settings/meta-settings.component';
import { MaterlogSettingsPreferences } from 'src/app/dashboard/components/settings-preferences/settings-preferences.component';
import { MetiAssistants } from 'src/app/dashboard/components/assistants/assistants.component';
import {MetiFlowNode} from 'src/app/dashboard/components/flow-assistants/flow-node/flow-node.component';
import {MetiFlowArrow} from 'src/app/dashboard/components/flow-assistants/flow-arrow/flow-arrow.component'
import {MetiFlowSidebar} from 'src/app/dashboard/components/flow-assistants/flow-sidebar/flow-sidebar.component';
import {MaterlogWorkflowsSlug} from 'src/app/company-settings/components/workflow-types/workflow-slug/workflow-slug.component';


@NgModule({
  declarations: [
    DashboardV2Component,
    DashboardFiltersComponent,
    ViewTabListComponent,
    HideFieldsComponent,
    MlogFiltersComponent,
    HistoryComponent,
    SortComponent,
    TemplatesComponent,
    TemplatesDialogComponent,
    ShareComponent,
    ResizerComponent,
    SubitemsComponent,
    GroupFieldsComponent,
    PinFieldsComponent,
    ActiveJobListComponent,
    JobTableComponent,
    JobTableV2Component,
    ImagesCellRenderer,
    OrdersReadonlyCellRenderer,
    OrdersDocReadonlyCellRenderer,
    ImagesReadonlyCellRenderer,
    OrdersDocReadonlyCellRenderer,
    OrdersCellRenderer,
    OrdersCellTypeRenderer,
    OrdersRowRenderer,
    OrdersDocCellRenderer,
    DropDownEditor,
    AutoCompleteEditor,
    CalendarEditor,
    CalendarDateEditor,
    CustomNoRowsOverlay,
    AddEditDocComponent,
    GetTrackingComponent,
    CreateUpdateJobComponent,
    AddEditPoComponent,
    AddItemComponent,
    ViewJobsComponent,
    MailListComponent,
    CommentPaneComponent,
    CardViewFilter,
    CardControllerComponent,
    TrackingPaneComponent,
    SidePanelComponent,
    SupplierCredentialsPageComponent,
    ApprovalsComponent,
    UserSettingsComponent,
    CompanySettingsComponent,
    MaterlogWorkflows,
    CompanyManagementComponent,
    HomePageComponent,
    PreferencesComponent,
    UserManagementComponent,
    CardViewComponent,
    NotificationsComponent,
    ActivityHistoryComponent,
    SubmittalsComponent,
    GlobalSubmittalsComponent,
    ExportSubmittalsComponent,
    AutoUploadComponent,
    OrderAutoUploadComponent,
    ItemAutoUploadComponent,
    MaterlogDropdown,
    MaterLogDate,
    MaterLogText,
    MaterlogStatus,
    MaterLogShiplink,
    MaterLogImages,
    OrderDocsAutoUploadComponent,
    MaterLogToolTip,
    CalendarComponent,
    MaterlogWorkflowsChoose,
    MaterlogWorkflowsConfirm,
    MaterlogWorkflowsSelect,
    MaterlogWorkflowsStart,
    MetaSettingsComponent,
    MaterlogSettingsPreferences,
    MetiAssistants,
    MetiFlowNode,
    MetiFlowArrow,
    MetiFlowSidebar,
    MaterlogWorkflowsSlug
  ],
  imports: [
    AgGridModule,
    RadioButtonModule,
    AppRoutingModule,
    DropDownCellRenderer,
    ShipLinkCellRenderer,
    PopupComment,
    SplitItemComponent,
    AddSupplierModalComponent,
    MentionModule,
    NgPipesModule,
    AddRoomModalComponent,
    AddSupplierContactModalComponent,
    HttpClientModule,
    StoreModule.forFeature('dashboard', dashboardReducer),
    EffectsModule.forFeature([DashboardEffects]),
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
    TableModule,
    DialogModule,
    ButtonModule,
    DropdownModule,
    AvatarModule,
    MultiSelectModule,
    InputTextModule,
    AutoCompleteModule,
    DividerModule,
    InputSwitchModule,
    SplitterModule,
    SelectButtonModule,
    ProgressSpinnerModule,
    KeyFilterModule,
    MessagesModule,
    MessageModule,
    ToastModule,
    ConfirmDialogModule,
    CheckboxModule,
    InputTextareaModule,
    CalendarModule,
    FontAwesomeModule,
    ImageModule,
    PdfViewerModule,
    ContextMenuModule,
    TooltipModule,
    ChipsModule,
    TabViewModule,
    CascadeSelectModule,
    SkeletonModule,
    PasswordModule,
    GeoapifyGeocoderAutocompleteModule.withConfig(environment.ENV.GEOAPIFY_API),
    BreadcrumbModule,
    AngularEditorModule,
    ChangeItemJob,
    DependencyRenderer,
  ],
  providers: [
    DashboardService, JobsService, TemplatesService,
    ReceiversService, CommentsService, MessageService,
    ConfirmationService, SupplierCredentialsService,
  ]
})
export class DashboardModule { }
