import { ChangeDetectorRef, Component, OnInit, ViewChild, Input } from '@angular/core';
import { ElementRef, HostListener } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'materlog-workflows-select',
  templateUrl: './workflow-select.component.html',
  styleUrls: [
    '../../workflows/workflows.component.css',
  ]
})
export class MaterlogWorkflowsSelect implements OnInit {
    @Input() dropdownOptions: any;
    @Input() allMailData: any;
    @Input() orgData: any = {};
    @Input() box: any = {};
    @Input() chosenPic: string = '';
    @Output() applyUpdate = new EventEmitter<any>();

    usePOs = [
      {name: 'Yes, they’re required', active: true, value: 0},
      {name: 'Sometimes', active: false, value: 1},
      {name: 'No', active: false, value: 2},
    ]

    chooseHow = [
      {
        name: 'ERP',
        rec: true,
        text: 'Pulls your PO and supplier contact data directly from your ERP when you issue a PO. Ensure data alignment and interoperability with your current systems.',
        active: true,
        value: 0,
      },
      {
        name: 'Gmail extension',
        soon: true,
        text: 'Install the extension for gmail users. The extension lets you automatically add, update and view order information directly from your inbox.',
        active: false,
        value: 1,
      },
      {
        name: 'Forward emails',
        soon: false,
        text: 'Send emails to a unique email address. Anything received will be parsed and added to your account.',
        active: false,
        value: 2,
      },
    ]

    ngOnChanges(changes: any) {
      if (changes.orgData) {
        let uses_po = this.orgData.uses_pos;
        let orders_in = this.orgData.orders_in;
        if (uses_po >= 0 && uses_po <= 2) {
          let po = this.usePOs.find((p: any) => p.value == uses_po);
          this.setUsePO(po, false);
        }
        if (orders_in >= 0 && orders_in <= 2) {
          let how = this.chooseHow.find((p: any) => p.value == orders_in);
          this.setChooseHowActive(how, false);
        }
      }
    }

    ngOnInit(): void {
        (window as any)['wf_select'] = this;
    }


    setUsePO(po: any, emit: boolean = true) {
      if (emit) {
        this.orgData.uses_pos = po.value;
        this.applyUpdate.emit();
      }

      this.usePOs.forEach((p: any) => {
        if (p == po) {
          p.active = true;
        } else {
          p.active = false;
        }
      })
    }

    setChooseHowActive(how: any, emit: boolean = true) {
      if (emit) {
        this.orgData.orders_in = how.value;
        this.applyUpdate.emit();
      }
      if (how.soon) return;
      this.chooseHow.forEach((p: any) => {
        if (p == how) {
          p.active = true;
        } else {
          p.active = false;
        }
      })
    }

    get erpChosen() {
      let found = this.chooseHow.find((ch: any) => ch.active);
      if (!found) return false;
      return found.name == 'ERP';
    }
}
