
let nodeWidth = '330px';

export let node1Customizations: any = [
    {
        number: '1.',
        name: 'Choose how to get new orders into Meti',
        type: 'dropdown2',
        org_data_value: 'orders_in',
        options: [
            {
                name: 'ERP', value: 0,
                subtext: 'Pulls your PO and supplier contact data directly from your ERP when you issue a PO. Ensure data alignment and interoperability with your current systems.'
            },
            {
                name: 'Gmail extension', value: 1, color: '#828282',
                subtext: 'Install the extension for gmail users. The extension lets you automatically add, update and view order information directly from your inbox.',
                coming_soon: true,
            },
            {
                name: 'Forward emails', value: 2,
                subtext: 'Send emails to a unique email address. Anything received will be parsed and added to your account.',
            },

        ]
    }
]

export let newOrderDefaultNodes: any = [
    {
        id: 'node1',
        icon: 'bi-gear',
        icon_color: '#C2DBFF',
        subtext: 'Choose how to get new orders into Meti',
        node_width: nodeWidth,
        customizations: node1Customizations,
        style_left: 270, row: 1,
    },
    {
        id: 'node2',
        icon: 'bi-exclamation-circle',
        icon_color: '#C2DBFF',
        subtext: 'New PO issued',
        node_width: nodeWidth,
        customizations: node1Customizations,
        style_left: 270, row: 2,
    },
    {
        id: 'node3',
        icon: 'meti',
        subtext: 'Pull in order details',
        node_width: nodeWidth,
        customizations: node1Customizations,
        style_left: 270, row: 3
    },
];

export let newOrderSwitchesDefault = [];

export let newOrderConnectionsDefault: any = [
    {from: 'node1', to: 'node2', cut: undefined, space: true},
    {from: 'node2', to: 'node3', cut: undefined, space: false},
]