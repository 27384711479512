import { Component, OnInit, ViewChild, Input, Renderer2 } from '@angular/core';
import { EventEmitter, Output, ChangeDetectorRef} from '@angular/core';
import { ElementRef, HostListener } from '@angular/core';
import { setScrollBar } from 'src/app/common/utils/general';
import { isPDF, isJPEG, isPNG, generateRandomString } from 'src/app/common/utils/general';
import { createDate } from 'src/app/common/utils/general';

@Component({
    selector: 'materlog-dropdown-component',
    templateUrl: './materlog-dropdown-component.component.html',
    styleUrls: ['./materlog-dropdown-component.component.css'],
  })
export class MaterlogDropdown implements OnInit {
  @Input() options: any = [];
  @Input() fullOptions: any = [];
  @Input() selected: any = undefined;
  @Input() inputText: any = '';
  @Input() allowCreateNew: boolean = false;
  @Input() createNewText: any = '';
  @Input() enbold: boolean = false;
  @Input() defaultText: any = '--';
  @Input() valueField: any = 'value';
  @Input() maxWidth: number = 200;
  @Input() dropWidth: number = 200;
  @Input() alignRight: boolean = true;
  @Input() defaultCSS: boolean = true;
  @Input() maxHeight: number = 200;
  @Input() onTop: boolean = false;
  @Input() fixed: boolean = true;
  @Input() active: boolean = true;
  @Input() space_between: boolean = true;
  @Input() chosenColor: string = '#000';
  @Input() keepOrder: boolean = false;
  @Input() allowNull: boolean = false;
  @Input() includeChevron: boolean = true;
  @Input() dedup: boolean = true;
  @Input() dropheaderClass: any = undefined;
  @Input() dropheaderTextClass: any = undefined;
  @Input() dropIconClass: any = undefined;
  @Input() alphaSort: boolean = true;
  @Input() leftPixels: any = 0;
  @Input() topPixels: any = 0;
  @Input() openOnInit: boolean = false;
  @Input() forceSetLocation: boolean = false;
  
  @Output() onChange = new EventEmitter<any>();
  @Output() onToggleDropdown = new EventEmitter<any>();
  @Output() onOptionsChange = new EventEmitter<any>();

  top: number = 0;
  moveLeft: number = 0;
  dropdownVisible: boolean = false;
  filterText: any = '';
  @ViewChild('inputTextEl') inputTextEl!: ElementRef;
  @ViewChild('MaterlogDropdownText') MaterlogDropdownText!: ElementRef;
  @ViewChild('dropdownScroller') dropdownScroller!: ElementRef;
  @ViewChild('dropdownContainer') dropdownContainer!: ElementRef;

  filterOptions: any = [];
  sortedOptions: any = [];
  fullSortedOptions: any = [];

  constructor(
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2,
  ) {
  }

  ngOnChanges(changes: any) {
    let cur_options = this.options;
    if (changes.options) {
      if (this.fullOptions?.length) {
        let found = this.options?.find((o: any) => o.id == this.selected);
        if (!found) {
          found = this.fullOptions?.find((o: any) => o.id == this.selected);
          if (found) {
            cur_options = this.options.concat([found]);
          }
        }
      }
      this.sortedOptions = this.filterOptions = this.sortOptions(cur_options);
      this.fullSortedOptions = this.sortOptions(this.fullOptions, false);
    }
  }

  ngAfterViewInit() {
    if (this.openOnInit) {
      setTimeout(() => {
        this.toggleDropdown(null);
      }, 10);
    }
  }

  ngOnInit() {
    this.renderer.listen('document', 'click', (event: Event) => {
      const clickedInside = this.dropdownContainer.nativeElement.contains(event.target);
      if (!clickedInside && this.dropdownVisible) {
        this.dropdownVisible = false;
        this.onToggleDropdown.emit(false);
      }
    });
  }

  sortOptions(options: any[], allow_null: boolean = true): any[] {
    if (!options) return [];
    let addl = [{id: null, [this.valueField]: '--'}];
    if (options.length == 1) {
      if (this.allowNull && this.selected) {
        return addl.concat(options);
      } else {
        return options;
      }
    }

    if (this.dedup && this.alphaSort) {
      options = this.dedupOptions(options);
    }
    if (options.length == 1 || this.keepOrder) return options;
    let allOpts = options;
    if (this.alphaSort) {
      allOpts = options.sort((a, b) => 
          a[this.valueField].localeCompare(b[this.valueField])
      );
    }

    if (allow_null && this.allowNull && this.selected) {
      return addl.concat(allOpts);
    }
    return allOpts;
  }

  get leftMove() {
    return `${this.moveLeft}px`;
  }

  get topPosition() {
    return `${this.top}px`;
  }

  get getMaxWidth() {
    return `${this.maxWidth - 12}px`;
  }

  get heightMax() {
    return `${this.maxHeight}px`;
  }

  get ddHeightMax() {
    if (!this.inputText) return this.heightMax;
    return `${this.maxHeight - 16}px`;
  }

  get dropdownWidth() {
    return `${this.dropWidth}px`;
  }

  get selectedText() {
    if (this.selected == undefined) return this.defaultText;
    let found = this.options?.find((o: any) => o.id == this.selected);
    if (!found) {
      found = this.fullOptions?.find((o: any) => o.id == this.selected);
    }
    if (!found || !this.valueField) return this.defaultText;
    let value = found[this.valueField];
    return value && value.toLowerCase() == 'not selected' && found.id == 0 
      ? this.defaultText: value;
  }

  dedupOptions(options: any): any {
    let output: any= [];
    let found = options?.find((o: any) => o.id == this.selected);
    if (found) {
      output.push(found);
    }
    options.forEach((o: any) => {
      if (!o || !o[this.valueField]) return;
      let lftext = o[this.valueField]?.trim()?.toLowerCase();
      let found = output.find((x: any) => x[this.valueField]?.toLowerCase()?.trim() == lftext);
      if (!found) {
        output.push(o)
      }
    });
    return output;
  }

  setFixedLocation() {
    if (this.forceSetLocation) {
      this.setAbsoluteLocation();
      return;
    }
    const rect = this.MaterlogDropdownText?.nativeElement?.getBoundingClientRect();
    if (rect) {
      this.moveLeft = this.alignRight
        ? rect.left - (this.dropWidth - this.maxWidth) - 17
        : rect.left - 5;
      this.top = !this.onTop
        ? rect.bottom + 4
        : rect.top - this.maxHeight - (rect.bottom - rect.top) - 5;
    } else {
      this.moveLeft = 0;
      this.top = 0;
    }
  }

  setAbsoluteLocation() {
    this.moveLeft = this.leftPixels;
    this.top = this.topPixels;
  }

  toggleDropdown(event: any) {
    (window as any)['drop'] = this;
    this.filterText = '';
    this.filterOptions = this.sortedOptions;
    if (!this.active) return;
    this.dropdownVisible = !this.dropdownVisible;
    this.onToggleDropdown.emit(this.dropdownVisible);
    if (this.dropdownVisible) {
      if (this.fixed) {
        this.setFixedLocation();
        this.renderer.listen('window', 'wheel', this.onWindowScroll.bind(this));
      } else {
        this.setAbsoluteLocation();
      }
    }
    this.cdr.detectChanges();
    setTimeout(() => {
      if (this.inputTextEl?.nativeElement) {
        this.inputTextEl.nativeElement.focus();
      }
    }, 200);
  }

  /*
  @HostListener('document:click', ['$event'])
  clickOutside(event: any) {
    this.dropdownVisible = false;
  }
  */

  onWindowScroll(event: Event) {
    const target = event.target as HTMLElement;
    if (!this.dropdownScroller?.nativeElement?.contains(target) && this.dropdownVisible) {
      this.dropdownVisible = false;
    }
  }

  onFilterText() {
    if (!this.filterText) {
      this.filterOptions = this.sortedOptions;
      this.cdr.detectChanges();
      return;
    }
    const lowerFilterText = this.filterText.toLowerCase();

    if (lowerFilterText.length > 3 && this.fullSortedOptions.length) {
      this.filterOptions = this.fullSortedOptions?.filter((o: any) =>
        o[this.valueField] &&
        o[this.valueField]?.toLowerCase().includes(lowerFilterText)
        );
    } else {
      this.filterOptions = this.sortedOptions?.filter((o: any) =>
        o[this.valueField] &&
        o[this.valueField]?.toLowerCase().includes(lowerFilterText)
        );
    }
    this.cdr.detectChanges();

  }

  isSelected(opt: any) {
    return opt?.id == this.selected;
  }

  optionText(opt: any) {
    return opt ? opt[this.valueField]: '--';
  }

  get createNewValueText() {
    if (!this.filterText) {
      return `Type to ${this.createNewText}`;
    }
    let well: any = this.createNewText;
    well = well[0].toUpperCase() + well.slice(1,);
    return `${well}: ${this.filterText}`;
  }

  get isNewValue() {
    const lowerFilterText = this.filterText?.toLowerCase() || '';
    return this.sortedOptions?.find((o: any) => 
      o && o[this.valueField]?.toLowerCase() == lowerFilterText
    );
  }

  get canMakeNewValue() {
    return this.filterText && !this.isNewValue;
  }

  onOptionSelect(event: any, opt: any) {
    event.stopPropagation();
    this.options = this.filterOptions = this.sortedOptions;
    this.filterText = '';
    this.dropdownVisible = false;
    this.selected = opt.id;
    this.onChange.emit(opt);
    this.cdr.detectChanges();
  }

  chooseNewOption(event: any) {
    if (!this.canMakeNewValue) return;
    let val = this.filterText;
    val = val[0].toUpperCase() + val.slice(1,);
    let newOpt = {id: generateRandomString(), [this.valueField]: val, new: true};
    let curOptions = this.options;
    curOptions.push(newOpt);
    let newOptions = this.sortOptions(curOptions);
    this.dropdownVisible = false;
    this.filterText = '';
    this.selected = newOpt.id;
    let payload = {options: newOptions, chosen: newOpt};
    this.onOptionsChange.emit(payload);
    /*
    setTimeout(() => {
      this.sortedOptions = this.filterOptions = newOptions;
      this.selected = newOpt.id;
      this.cdr.detectChanges();
    }, 60);
    */
    this.cdr.detectChanges();
  }

  get dropdownHeaderStyles() {
    let out: any = {
      'max-width': this.getMaxWidth,
      'min-width': this.getMaxWidth,
      'font-weight': this.enbold ? 'bold': 'inherit',
      'justify-content': this.space_between ? 'space-between': '',
    }
    if (this.dropheaderClass) {
      out = {...out, ...this.dropheaderClass}
    }
    return out;
  }

  get dropdownHeaderClass() {
    let out: any = {
      'materlog-dropdown-open': !this.dropheaderClass && this.active && this.dropdownVisible,
      'materlog-default-dd': !this.dropheaderClass && this.active && this.dropdownVisible && this.defaultCSS,
      'materlog-default-dd-hover': !this.dropheaderClass && this.active && this.defaultCSS,
      'materlog-dcursor': !this.dropheaderClass && !this.active,
      'materlog-dropdown-header': !this.dropheaderClass,
    }
    return out;
  }

  get dropdownIconStyle() {
    let out: any = {};
    if (this.dropIconClass) {
      out = {...out, ...this.dropIconClass};
    }
    return out;
  }

  get dropdownIconClass() {
    let out: any = {
      'materlog-dropdown-header-icon-hover': this.dropdownVisible,
      'materlog-dropdown-header-icon': !this.dropIconClass,
      [this.dropIconClass || '']: this.dropIconClass
    }
    return out;
  }

  get dropdownTextStyle() {
    let out: any = {
      'color': this.chosenColor
    }
    if (this.dropheaderTextClass) {
      out = {...out, ...this.dropheaderTextClass}
    }
    return out;
  }

  get dropdownTextClass() {
    let out: any = {
      'materlog-dropdown-empty-text': this.selectedText == '--',
      'materlog-dropdown-header-text': !this.dropheaderTextClass,
    }
    return out;
  }
}
