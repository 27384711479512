export class CommonConstants {
  public static readonly logType: { [name: string]: string } = {
    DEBUG: 'debug',
    WARNING: 'warning',
    ERROR: 'error',
    INFO: 'info',
  };

  public static readonly errorMessages: { [name: string]: string } = {
    non_field_errors: 'non_field_errors',
    ship_date: 'ship_date',
    delivery_date: 'delivery_date',
    in_stock_date: 'in_stock_date',
    name: 'name',
    shipping_zip: 'shipping_zip',
    last_4_card_digits: 'last_4_card_digits',
    total_cost: 'total_cost',
    supplier_email: 'supplier_email',
    supplier_contact_email: 'supplier_contact_email',
    quantity: 'quantity',
    filters: 'filters',
    supplier_id: 'supplier_id',
  };

  public static readonly filterTypes: { [name: string]: string } = {
    ALPHANUMERIC: 'ALPHANUMERIC',
    NUMERIC: 'NUMERIC',
    SELECT: 'SELECT',
    DATE: 'DATE',
  };

  public static readonly include_items: { [name: string]: number } = {
    all: 0,
    uncategorized: 1,
    with_job: 2,
  };

  public static readonly defaultView: any[] = [{ name: '', is_selected: true }];
  public static readonly defaultError: string = 'Something went wrong. Please try again later. If this issue persists, contact support@getmeti.com.';
  public static readonly defaultServerError: string = 'Something went wrong (server error). Please try again later.If this issue persists, contact support@getmeti.com.';

  public static readonly resultsPerPage: number = 30000;
  public static readonly AUTH_TOKEN: string = 'AUTH_TOKEN';
  public static readonly emailRegex: RegExp = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[\d/]{1,3}\.[\d/]{1,3}\.[\d/]{1,3}\.[\d/]{1,3}\])|(([a-zA-Z\-\d/]+\.)+[a-zA-Z]{2,}))$/;
  public static readonly passwordRegex: RegExp = /^(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[a-zA-Z0-9!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,32}$/; // at least 8 characters, including 1 special character
  public static readonly decimalRegex: RegExp = /^-?\d{1,6}(\.\d{1,2})?$/; // matches numbers with 6 digits before dot and 2 digits after dot EX: 123456.12
  public static readonly dateRegex: RegExp = /^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d$/; // matches mm/dd/yyyy date format
}
