import { EventEmitter, Component, Input, Output } from '@angular/core';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { Observable, ReplaySubject } from 'rxjs';
import { DashboardActions } from 'src/app/dashboard/state/actions/dashboard.actions';
import { PayloadService } from "../../../services/payload.service";
import { ImageCacheService } from "../../../../common/services/image-cache/image-cache.service";
import { HttpClient } from "@angular/common/http";
import { JobTableService } from "../../../services/job-table.service";
import { TableConstants } from "../../../../common/constants/table.constants";
import { deepCopy } from 'src/app/common/utils/general';
import { DashboardJobView } from 'src/app/dashboard/services/dashboard_job_view.service';

@Component({
  selector: 'images-cell-renderer',
  templateUrl: './images-cell-renderer.component.html',
  styleUrls: ['images-cell-renderer.component.sass'],
})

export class ImagesCellRenderer implements ICellRendererAngularComp {
  @Input() inputData!: any;
  @Input() cardView: boolean = false;
  @Input() editable: boolean = true;
  @Output() onImagesUpdated = new EventEmitter<any>()

  public displayValue!: any;
  readonly faEye = faEye;
  public size = TableConstants.row_small;
  pixelSize = '25px';
  params!: any;
  imagesList: any[] = [];
  valid: boolean = true;
  isSharedView: boolean = false;
  private imageProcessingCache = new Map();  // for memoization


  constructor(
    private httpClient: HttpClient,
    private dashboardActions: DashboardActions,
    private jobTableService: JobTableService,
    private payloadService: PayloadService,
    private imageCacheService: ImageCacheService,
    private imageCacheMiss: ImageCacheService,
    private djv: DashboardJobView
  ) { }

  ngOnInit() {
    if (this.cardView) {
      this.params = this.inputData;
      this.size = 60;
      this.pixelSize = `${this.size - 5}px`;
    }
  }

  ngOnChanges(changes: any) {
    if (this.cardView && changes?.inputData?.currentValue) {
      this.params = this.inputData = changes.inputData.currentValue;
    }
  }

  agInit(params: ICellRendererParams<any, number>): void {
    /*
    this.jobTableService.imageSizeObs.subscribe(result => {
      if(result) {
        if(result == 'large'){
          this.size = TableConstants.row_large;
        }else if(result == 'medium'){
          this.size = TableConstants.row_medium;
        }else{
          this.size = TableConstants.row_small;
        }
      }
    });
    */
    this.setCell(params);
  }

  setCell(params: ICellRendererParams) {
    this.params = params;
    this.isSharedView = this.params.context.editable;
    this.size = this.params.node.rowHeight;
    this.pixelSize = `${this.size - 5}px`;
  }

  public handleImagesPre(images: any) {
    const cacheKey = JSON.stringify(images);
    if (this.imageProcessingCache.has(cacheKey)) {
      return this.imageProcessingCache.get(cacheKey);
    }
    if (!images || !images.length) return images;
    let out_images: any = [];
    const processImageUrl = (url: string) => {
      if (!url) return '';
      const baseUrl = url.split('?')[0];
      let image_found = this.imageCacheMiss.hasImage(baseUrl) && !this.imageCacheMiss.getImage(baseUrl);
      if (image_found) return '';

      image_found = this.imageCacheService.hasImage(baseUrl) && !this.imageCacheService.getImage(baseUrl);
      if (image_found) return image_found; 
      return url;
    };

    images.forEach(
      (img: any) => {
        let new_img = {
          image: processImageUrl(img.image),
          image_thumbnail: processImageUrl(img.image_thumbnail),
          name: img.name,
          image_id: img.image_id,
        }
        out_images.push(new_img);
      }
    );
    this.imageProcessingCache.set(cacheKey, out_images);
    return out_images;
  }

  public handleMissingImage(event: Event, image: any) {
    let url = image.image_thumbnail ? image.image_thumbnail : image.image;
    if (url) {
      url = url.split('?')[0];
      this.imageCacheMiss.setImage(url, '');
    }
    image.error = true;
  }

  refresh(params: ICellRendererParams) {
    this.setCell(params);
    return true;
  }

  setSize(size:number){
    this.size = size;
  }

  addImages(key: string, files: File[], id: string) {
    this.imagesList = [];

    for (const item of files) {
      this.onSendImages(key, item, id, files.length);
    }
  }

  onSendImages(key: string, file: any, id: string, length: number) {
    this.onConvertBase64(file).subscribe((base64: string) => {
      this.imagesList.push({
        image_name: file.name,
        image: base64,
      });

      if (this.imagesList.length === length) {
        let payload = { [`${key}`]: this.imagesList, id };
        if (this.isSharedView ) {
          this.params.imagesUpdated(payload);
          return;
        } else if (this.cardView) {
          this.onImagesUpdated.emit({payload: payload, data: this.inputData, delete: false});
        } else {
          this.dashboardActions.requestUpdateViewCell(payload);
          this.djv.image_is_uploading();
        }

      }
    });
  }

  onConvertBase64(file: File): Observable<string> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();

    reader.readAsBinaryString(file);
    reader.onload = (event: any) => result.next(btoa(event.target?.result?.toString()));

    return result;
  }

  deleteFile(attachment_id: string) {
    let payload = { image_id_to_delete: attachment_id, id: this.params.data.id };
    if (this.cardView) {
      this.onImagesUpdated.emit({payload: payload, data: this.inputData, delete: true});
    } else {
      this.dashboardActions.requestAttachmentDelete(payload);
      let images = this.params?.data?.images;
      images = images?.filter((img: any) => img.image_id != attachment_id);
      this.params.data.images = images;
      this.params.api.applyTransaction({ update: [this.params.data] });
    }
  }

  fetchImage(url: string): void {
    const cachedImage = this.imageCacheService.getImage(url);
    if (!cachedImage) {
      // Fetch the image from the S3 bucket
      // For example, using HttpClient
      this.httpClient.get(url, { responseType: 'blob' }).subscribe(blob => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const base64data = reader.result as string;
          this.imageCacheService.setImage(url, base64data);
        };
      });
    }
  }
}
