let smallNodeWidth = '200px';
let nodeWidth = '330px';
let medNodeWidth = '330px';
let largeNodeWidth = '400px';

let scheduleCustoms: any = [
    {
        number: '1.',
        name: 'How often should Meti track supplier links?',
        type: 'dropdown1',
        options: 'link_tracking',
        org_data_value: 'supplier_links_track_cadence',
    }
];

let onlineTrackingCustoms: any = [
    {
        number: '1.',
        name: 'How Meti should check in with your Suppliers?',
        main_texts: [
            {
                text: 'Head to the'
            },
            {
                text: 'Supplier page',
                underline: true,
                where: 'supplier',
                page: true,
            },
            {
                text: 'for supplier-by-supplier control.',
            }
        ]
    },
    {
        number: '2.',
        name: 'When a new supplier is added, what should the default tracking method be?',
        type: 'dropdown1',
        org_data_value: 'supplier_default_tracking',
        options: 'tracking_choices',
    }
]


let flagIssueCustoms: any = [
    {
        number: '1.',
        name: 'How many times should Meti check the link after an error?',
        type: 'dropdown1',
        org_data_value: 'supplier_error_recheck',
        options: 'link_recheck',
    },
    {
        number: '2.',
        name: 'How do you want to be notified of errors?',
        main_texts: [
            {
                text: 'Each user can select how they are notified about updates in'
            },
            {
                text: 'their preferences',
                underline: true,
                page: true,
                where: 'settings',
                subpage: 'Personal',
            },
        ]
    },
]

let updateHowCustoms: any = [
    {
        number: '-',
        name: 'No customizations',
        main_texts: [
            {
                text: 'Each user can select how they are notified about updates in'
            },
            {
                text: 'their preferences',
                underline: true,
                page: true,
                where: 'settings',
                subpage: 'Personal',
            },
        ]
    },
]

export let supplierLinksNodes: any = [
    {
        id: 'node1',
        icon: 'bi-plus-circle',
        icon_color: '#C2DBFF',
        customizations: scheduleCustoms,
        subtext: 'Item status updated to',
        sidebar_main_text: 'Schedule',
        diff_icon: 'bi-calendar',
        post_text: 'any of these statuses',
        post_text_tooltip: 'Ordered, Backordered, In production, In stock or arrived at supplier, In transit, Partially shipped, Shipped, Partially delivered, Ready to be scheduled, Delivery scheduled, Tracking issues',
        post_text_tooltip_width: '300px',
        post_text_tooltip_height: '72px',
        node_width: medNodeWidth,
        style_left: 270, row: 1,
    },
    {
        id: 'node2',
        icon: 'bi-funnel',
        icon_color: '#C2DBFF',
        customizations: onlineTrackingCustoms,
        subtext: 'Is online tracking turned on for the order’s supplier?',
        sidebar_main_text: 'Is online tracking turned on for the supplier?',
        node_width: medNodeWidth,
        style_left: 270, row: 2,
    },
    {
        id: 'node5',
        icon: 'bi-funnel',
        icon_color: '#C2DBFF',
        subtext: 'Does the order have a unique tracking link?',
        node_width: '340px',
        style_left: 490, row: 3,
    },
    {
        id: 'node6',
        icon: 'bi-funnel',
        icon_color: '#C2DBFF',
        subtext: 'Does the supplier have order tracking on their website?',
        node_width: largeNodeWidth,
        style_left: 220, row: 4,
    },
    {
        id: 'node7',
        icon: 'bi-funnel',
        icon_color: '#C2DBFF',
        subtext: 'Do we have necessary details to track the order?',
        sidebar_main_text: 'Do we have the details to track the order?',
        node_width: '360px',
        style_left: 280, row: 5,
    },
    {
        id: 'node8',
        icon: 'meti',
        subtext: 'Go to the link and retrieve data',
        sidebar_main_text: 'Go to link',
        node_width: '340px',
        style_left: 490, row: 6,
    },
    {
        id: 'node9',
        icon: 'bi-flag',
        icon_color: '#C2DBFF',
        subtext: 'Flag issue',
        sidebar_main_text: 'If error',
        customizations: flagIssueCustoms,
        node_width: '150px',
        style_left: 400, row: 7,
    },
    {
        id: 'node10',
        icon: 'meti',
        subtext: 'Parse the data and update order details in Meti ',
        node_width: nodeWidth,
        customizations: updateHowCustoms,
        style_left: 580, row: 7
    },
    {
        id: 'node11',
        icon: 'bi-box-arrow-up-right',
        sidebar_main_text: 'Draft and send check in emails - supplier',
        icon_color: '#C2DBFF',
        multi_texts: [
            {
                text: 'Go to', 
            },
            {
                text: 'Draft and send check in emails - supplier,', 
                underline: true,
                where: 'send_checkin_emails'
            },
            {
                text: 'if turned on; otherwise end.', 
            },
        ],
        node_width: nodeWidth,
        style_left: 30, row: 7
    },
];

export let supplierLinksConnections: any = [
    {from: 'node1', to: 'node2',  cut: undefined, space: true},
    {from: 'node2', to: 'node5',  cut: 18, space: false},
    {from: 'node2', to: 'node11', cut: 18, space: false},
    {from: 'node5', to: 'node6',  cut: undefined, space: false},
    {from: 'node5', to: 'node8',  cut: undefined, space: false},
    {from: 'node6', to: 'node7',  cut: 18, space: false},
    {from: 'node6', to: 'node11', cut: 18, space: false},
    {from: 'node7', to: 'node8',  cut: 18, space: false},
    {from: 'node7', to: 'node11', cut: 18, space: false},
    {from: 'node8', to: 'node9', cut: undefined, space: false},
    {from: 'node8', to: 'node10', cut: undefined, space: false},
]

export let supplierLinksSwitches: any = [
    { id: 'switch3', text: 'No', from: 'node5', to: 'node6', above: 'node6', where: 18 },
    { id: 'switch3', text: 'Yes', from: 'node2', to: 'node5', above: 'node5', where: 18 },
    { id: 'switch4', text: 'Yes', from: 'node7', to: 'node8', above: 'node8', where: 18 },
    { id: 'switch4', text: 'No', from: 'node7', to: 'node11', above: 'node11', where: 18 },
    { id: 'switch4', text: 'Yes', from: 'node6', to: 'node7', above: 'node7', where: 18 },
    { id: 'switch5', text: 'Success', from: 'node8', to: 'node10', above: 'node10', where: 18 },
    { id: 'switch5', text: 'Unsuccessful', from: 'node8', to: 'node9', above: 'node9', where: 18 },

]