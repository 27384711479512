<div class="view-tab-all"
      [ngStyle]="{
        'padding-left': viewTabPaddingLeft,
        'margin-left': viewTabMarginLeft
      }">
  <!--<div class="faint-vertical-line"></div>-->
  <div class="view-menu" [ngStyle]="{ visibility: views.length ? 'visible' : 'hidden' }">
    <div *ngIf="isLoadingViews" style="padding-left: 8px">
      <div *ngFor="let x of skeletonRows(); let i = index" class="view-container skeleton-margin">
        <p-skeleton width="3rem" height="10px" styleClass="mb-2" borderRadius="16px"></p-skeleton>
      </div>
    </div>
    
    <div *ngIf="!isLoadingViews" class="view-container-outer">
      <div *ngFor="let view of views; let i = index" class="view-container">
        <ng-container *ngIf="isChangingView && selectedViewId == view.id">
          <div class="loading-dots view-is-loading">
            <span class="dot"></span><span class="dot"></span><span class="dot"></span>
          </div>
        </ng-container>
        <div *ngIf="!(isChangingView && selectedViewId == view.id)" class="view-default-box"
          [ngClass]="canHighlightView && view.is_selected ? 'view-is-active': 'view-is-inactive'"
          (click)="selectView(view.id)">
          <span class="view-block view-name">{{ view.name }}</span>
          <i *ngIf="view.template_id" class="bi bi-journal-text view-icon-meta"></i>
          <i *ngIf="view.is_shared" class="bi bi-people-fill view-icon-meta"></i>
          <div class="caret-icon" (click)="viewSettings($event, i)">
            <i class="bi bi-caret-down-fill" style="font-size: 0.7rem; color: inherit"></i>
          </div>
        </div>
        <ng-container *ngIf="settingsModal && activeSettingsPanel === i">
          <div #viewToolTip class="view-choice"
            [ngStyle]="{'top.px': viewPosY, 'left.px': viewPosX}">
            <div class="view-option" (click)="renameView(view)">
              <i class="bi bi-pencil-fill"></i>
              Rename view
            </div>
            <div class="view-option" (click)="deleteView(view)">
              <i class="bi bi-trash-fill"></i>
              Delete view
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  
    <ng-container *ngIf="isAddingView">
      <span class="loading-dots" style="padding: 10px">
        <span class="dot"></span><span class="dot"></span><span class="dot"></span>
      </span>
    </ng-container>
  
  </div>
</div>


<form>
  <p-dialog
    [(visible)]="editViewNameModal"
    [modal]="true"
    [resizable]="false"
    [draggable]="false"
    [closable]="false"
    [closeOnEscape]="false"
    [position]="'top'"
    [style]="{ marginTop: '150px', width: '588px' }"
    [maskStyleClass]="'dialog-mask'"
  >
    <ng-template pTemplate="header">
      <div class="dialog__header">
        <span> {{ editViewNameModal ? 'Update view name' : 'Add a view name' }}</span>
      </div>
      <button class="dialog-close" type="button" (click)="closeAddOrEditViewModal()">
        <i class="bi bi-x-lg"></i>
      </button>
    </ng-template>
    <input
      pInputText
      nullValue  
      type="text"
      name="newViewName"
      class="form-controls"
      placeholder="Name"
      [(ngModel)]="newViewName"
    />
    <div class="textfield__error-message" *ngIf="viewNameSubmitted && !newViewName?.trim()">Add a view name</div>

    <ng-template pTemplate="footer">
      <button
        pButton
        type="submit"
        label="{{ editViewNameModal ? 'Update view' : 'Create view' }}"
        class="button-primary p-button-raised mt-4"
        (click)="saveViewName(editViewNameModal)"
      ></button
    ></ng-template>
  </p-dialog>
</form>
