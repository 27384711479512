<div *ngIf="params.appliedFilters()">
  <img class="m-auto mb-4" src="assets/img/empty-table-loop.svg" alt="Magnifying glass empty table" />
  <p class="font-bold mb-2">No results</p>
  <p>We couldn't retrieve any records based on your chosen filters.</p>
</div>

<div *ngIf="!params.appliedFilters()"
    style="pointer-events: all; display: flex; flex-direction: column; align-items: center;">
  <img class="m-auto mb-4" src="assets/img/empty-table-loop.svg" alt="Magnifying glass empty table" />
  <p class="font-bold">Start adding orders</p>
  <p class="slug-email"
  >Forward POs or order confirmations to
    <a [attr.href]="slugHref">{{slug}}</a>
  </p>
  <p>or</p>
  <p (click)="addOrderClicked()" style="cursor: pointer; padding: 4px 8px; border: 1px solid #d9d9d9; border-radius: 8px; width: 100px">Click here</p>

</div>