import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { EventEmitter, Output, ChangeDetectorRef} from '@angular/core';
import { ElementRef, HostListener } from '@angular/core';
import { setScrollBar } from 'src/app/common/utils/general';
import { isPDF, isJPEG, isPNG, generateRandomString } from 'src/app/common/utils/general';

@Component({
    selector: 'materlog-status-component',
    templateUrl: './materlog-status-component.component.html',
    styleUrls: ['./materlog-status-component.component.css'],
})
export class MaterlogStatus implements OnInit {
  @Input() options: any = {};
  @Input() colors: any = {};
  @Input() items: any = [];
  @Input() enbold: boolean = false;
  @Input() valueField: any = 'value';
  @Input() maxWidth: number = 125;
  @Input() active: boolean = true;
  @Output() onChange = new EventEmitter<any>();

  curStatuses: any = [];
  isItem: boolean = false;

  constructor(
    private cdr: ChangeDetectorRef
  ) {
  }

  ngOnChanges(changes: any) {
    let stat: any = []
    if (this.items instanceof Array) {
      stat = this.items?.map((i: any) => i.status || 1);
    } else {
      stat = [this.items.status || 1];
      this.isItem = true;
    }
    this.resetStatuses(stat);
  }

  resetStatuses(stat: any) {
    this.curStatuses = this.options?.filter((o: any) => stat.includes(o.id));
    this.cdr.detectChanges();
  }

  ngOnInit() {
    (window as any)['mstatus'] = this;
  }

  getStatusColor(opt: any) {
    let found = this.colors?.find((c: any) => c.id == opt.id);
    return found ? found[this.valueField] : '';
  }

  onDropdownChange(event: any, opt: any) {
    (window as any)['mstatus'] = this;
    this.onChange.emit({old: opt.id, new: event.id});
    if (this.isItem) {
      this.items.status = event.id;
      this.resetStatuses([event.id]);
    }
  }

}
