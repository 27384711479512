<div class="approvals-container" #approvalContainer>
  <div class="approvals-list">
    <div class="approval-list-mail-short" style="margin: 0px 0px 12px 10px" *ngIf="approvalsLoading">
      <div *ngFor="let item of skeletonEmails" style="margin-bottom: 10px">
        <p-skeleton [width]="item.width" [height]="'40px'" borderRadius="16px"></p-skeleton>
      </div>
    </div>

    <div class="approval-list-mail-short custom-scrollbar-close" *ngIf="!approvalsLoading">
      <div class="single-approval"
          [ngClass]="item.id == selectedId ? 'selected-item' : null"
          (click)="emailClicked(item)" *ngFor="let item of approvals">
        <div class="item-not-confirmed" *ngIf="!item.viewed">
          <i class="bi bi-dot" style="color: blue; font-size: 28px"></i>
        </div>
        <div class="item-update-or-new"> {{ item.is_new_order ? 'New' : 'Update' }}</div>
        <div class="item-information">
          <div class="item-row-1">
            <div class="item-subject">
              {{item.infos?.subject ? item.infos.subject: ''}}
            </div>
            <div class="ml-auto item-date">
              {{ formatDate(item) }}
            </div>
          </div>
          <div class="item-row-2">
            <div class="email-value-display">
              <div class="badge-default status-color-cell">
                <span class="inner-text">{{ item.job }}</span>
              </div>
            </div>
            <div class="email-value-display">
              <div class="badge-outline status-color-cell">
                <span class="inner-text">{{ item.supplier }}</span>
              </div>
            </div>
            <div class="email-value-display">
              <div class="badge-secondary status-color-cell">
                <span class="inner-text">{{ item.order }}</span>
              </div>
            </div>
            <div class="mark-read ml-auto" (click)="markReadTooltip($event, item)">
              <i class="bi bi-three-dots"></i>
            </div>
            <div class="mark-read-tooltip" *ngIf="item.markedRead">
              <div class="new-option flex flex-row" (click)="markItemAsRead($event, item)">
                <i class="bi bi-check-lg"></i>
                <div class="confirm-text">
                  {{ !item.viewed ? 'Mark read' : 'Mark unread'}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="approvals-email-order-container custom-scrollbar-close" *ngIf="!approvalsLoading">
    <div class="email-viewer-container" #emailContainer>
      <div class="email-viewer" [ngStyle]="{'max-width': cardViewWidth, 'min-width': cardViewWidth}" *ngIf="selectedId">
        <div class="email-field-start email-subject" style="padding-top: 0px; font-size: 14px">
          {{formatField('subject')}}
        </div>
        <div class="flex flex-row">
          <div class="email-field-start email-subject" style="font-weight: bold; padding-right: 3px">
            {{ formatEmail(true) }}
          </div>
          <div class="email-subject" style="font-size: 10px; bottom: -2px">
            {{ formatEmail(false) }}
          </div>
        </div>
        <div class="flex flex-row">
          <div class="email-field-start email-subject" style="color: #666; font-size: 10px">
            to
          </div>
          <div class="email-subject" style="padding-left: 3px; font-size: 10px">
            {{formatField('to')}}
          </div>
        </div>
        <div class="flex flex-row" *ngIf="formatField('cc')">
          <div class="email-field-start email-subject" style="color: #666; font-size: 10px">
            cc
          </div>
          <div class="email-subject" style="padding-left: 3px; font-size: 10px">
            {{formatField('cc')}}
          </div>
        </div>
        <div class="attachments-container email-field-start email-subject" *ngIf="itemAttsCount">
          <div class="single-att"
                (click)="selectedAttachment = att.link; documentModal = true;"
                *ngFor="let att of itemAtts">
            <i class="bi bi-paperclip" style="padding-right: 1px"></i>
            {{att.name}}
          </div>
        </div>
        <div class="email-display">
          <div *ngIf="fetchingBody">
            <div *ngFor="let item of skeletonItems" style="margin: 16px 0px">
              <p-skeleton [width]="item.width" [height]="'20px'" borderRadius="16px"></p-skeleton>
            </div>
          </div>
          <div *ngIf="!fetchingBody"
              class="mail-body custom-scrollbar-close" style="font-size: 10px"
              (click)="mailClicked($event)"
              [innerHtml]="itemInnerHtml">
          </div>
        </div>
      </div>
    </div>
    <div class="note-display-container" *ngIf="currentOrder && dropdownRetrieved && !fetchingBody">
      <div class="note-display-text" [ngStyle]="{'max-width': cardViewWidth}" style="padding-left: 18px">
        {{currentOrder.value}}
      </div>
      <div class="all-info-container custom-scrollbar-close">
        <div class="note-order" #noteOrder *ngIf="currentOrder.id">
          <div class="note-supplier-text">
            Order info
          </div>
          <div class="note-card-view">
            <card-view
            [notificationsView]="true"
            [maxWidth]="cardViewWidth"
            [order_id]="currentOrder.id"
            [dropdownOptions]="dropdownOptions"
            [panelOpen]="sidePanelOpen"
            [allMailData]="allMailData"
            (cardIsOpen)="cardIsOpenChange($event)"
            (orderDeleted)="orderDeleted()"
            >
            </card-view>
          </div>
        </div>
        <div class="note-supplier" #noteSupplier *ngIf="currentOrder.supplier_id">
          <div class="note-supplier-text" [ngStyle]="{'max-width': cardViewWidth}">
            Supplier info
          </div>
          <materlog-suppliers-credentials-page
            [notificationsView]="true"
            [maxWidth]="cardViewWidth"
            [supplierId]="currentOrder.supplier_id"
            [panelOpen]="sidePanelOpen"
            [allMailData]="allMailData"
          ></materlog-suppliers-credentials-page>
        </div>
        <div class="note-supplier" #noteJob *ngIf="currentOrder.job_id">
          <div class="note-supplier-text" [ngStyle]="{'max-width': cardViewWidth}">
            Job info
          </div>
          <materlog-view-jobs
            [notificationsView]="true"
            [jobId]="currentOrder.job_id"
            [maxWidth]="cardViewWidth"
            [panelOpen]="sidePanelOpen"
            [allMailData]="allMailData"
            [dropdownOptions]="dropdownOptions"
          ></materlog-view-jobs>
        </div>
      </div>
    </div>
  </div>


</div>

<p-dialog
  [(visible)]="documentModal"
  [style]="{ marginTop: '50px', width: '90vw', height: '90vh' }"
  [closeOnEscape]="true"
  [modal]="true"
  [resizable]="false"
  [draggable]="false"
  [position]="'top'"
  [closable]="false"
  [maskStyleClass]="'dialog-mask'"
  appendTo="body"
>
  <ng-template pTemplate="header">
    <div class="dialog__header">
      <span></span>
    </div>
    <button class="dialog-close" type="button" (click)="documentModal = false"><i class="bi bi-x-lg"></i></button>
  </ng-template>
  <ng-template pTemplate="content">
    <iframe [src]="selectedAttachment | sanitize" allow="fullscreen" width="100%" height="98%"></iframe>
  </ng-template>
</p-dialog>