import { node1Customizations } from 'src/app/dashboard/components/flow-assistants/assistants/new-order-default';


let nodeWidth = '330px';
let largeNodeWidth = '350px';

let n1Custom = {...node1Customizations[0], 'slug': true, top: 68};

let node1CustomizationsEmail: any = [
    n1Custom,
    {
        number: '2.',
        name: 'Which email app do you use?',
        type: 'dropdown3',
        options: [
            {value: 0, name: 'Other', icon: 'bi-envelope'},
            {value: 1, name: 'Outlook', path: '/assets/img/outlook_logo.svg'},
            {value: 2, name: 'Gmail', path: '/assets/img/gmail_logo.svg'},
        ],
        org_data_value: 'new_order_email_app',
    }
]

let node2Customizations: any = [
    {
        number: '1.',
        name: 'Does your company use POs?',
        type: 'selector',
        org_data_value: 'uses_pos',
        indicator_text: '',
        options: 'does_use_po',
    },
    {
        number: '2.',
        name: 'What is the initial required documentation?',
        indicator_text: '',
        type: 'selector',
        org_data_value: 'new_order_initial_docs',
        options: 'initial_docs',
        subtext: 'Note: If manually forwarding emails, to ensure emails are correctly parsed, you can include additional details in the body of the email when you forward it.<br><br>For example: TODO JONAH',
    },
];


let node3Customizations: any = [
    {
        number: '1.',
        name: 'Who should be notified of issues?',
        type: 'dropdown1',
        org_data_value: 'new_order_issues',
        options: 'issue_notifiers',
    },
    {
        number: '2.',
        name: 'How should they be notified?',
        main_texts: [
            {
                text: 'Each user can update this in'
            },
            {
                text: 'their preferences',
                underline: true,
                page: true,
                where: 'settings',
                subpage: 'Personal',
            }

        ]
    },
];

let firstWaitCustomization: any = [
    {
        number: '1.',
        name: 'Wait',
        type: 'dropdown1',
        org_data_value: 'new_order_wait_1',
        options: 'flag_issues',
    },
];

let secondWaitCustomization: any = [
    {
        number: '1.',
        name: 'Wait',
        type: 'dropdown1',
        org_data_value: 'new_order_wait_2',
        options: 'flag_issues',
    },
];


export let newOrderEmailNodes: any = [
    {
        id: 'node1',
        icon: 'bi-gear-wide-connected',
        icon_color: '#C2DBFF',
        subtext: 'New orders into Meti',
        sidebar_main_text: 'Choose how to get new orders into Meti',
        customizations: node1CustomizationsEmail,
        text_color: '#616161',
        post_text: 'via email forwarding',
        node_width: nodeWidth,
        row: 1, style_left: 270,
    },
    {
        id: 'node2',
        icon: 'bi-envelope-arrow-down',
        icon_color: '#C2DBFF',
        subtext: 'User emails initial order document',
        sidebar_main_text: 'User emails initial order document',
        customizations: node2Customizations,
        node_width: nodeWidth,
        row: 2, style_left: 270,
    },
    {
        id: 'node4',
        icon: 'meti',
        subtext: 'Flag issues',
        customizations: node3Customizations,
        toggle: 'new_order_flag_issues',
        node_width: nodeWidth,
        row: 3, style_left: 520,
    },
    {
        id: 'node5',
        icon: 'bi-clock',
        icon_color: '#C2DBFF',
        subtext: 'Wait to send follow up',
        toggle: 'new_order_send_follow_up_1',
        customizations: firstWaitCustomization,
        node_width: nodeWidth,
        row: 4, style_left: 520,
    },
    {
        id: 'node6',
        icon: 'meti',
        subtext: 'Send follow up request #1',
        toggle: 'new_order_send_follow_up_1',
        node_width: nodeWidth,
        row: 5, style_left: 520,
    },
    {
        id: 'node7',
        icon: 'bi-clock',
        icon_color: '#C2DBFF',
        subtext: 'Wait to send follow up',
        toggle: 'new_order_send_follow_up_2',
        customizations: secondWaitCustomization,
        node_width: nodeWidth,
        row: 6, style_left: 520,
    },
    {
        id: 'node8',
        icon: 'meti',
        subtext: 'Send follow up request #2',
        toggle: 'new_order_send_follow_up_2',
        node_width: nodeWidth,
        row: 7, style_left: 520,
    },
    {
        id: 'node3',
        icon: 'meti',
        subtext: 'Order added + next check in date calculated',
        node_width: largeNodeWidth,
        row: 7, style_left: 90,
    },
];

export let newOrderEmailSwitches: any = [
    { id: 'switch1', text: 'No Issues', from: 'node2', to: 'node3', above: 'node3', where: 23 },
    { id: 'switch2', text: 'Error', from: 'node2', to: 'node4', above: 'node4', where: 23 },
]

export let newOrderEmailConnections: any = [
    {from: 'node1', to: 'node2', arrow: 'arrow1', cut: undefined, space: true},
    {from: 'node2', to: 'node3', arrow: 'arrow2', cut: 23, space: false},
    {from: 'node2', to: 'node4', arrow: 'arrow3', cut: 23, space: false},
    {from: 'node4', to: 'node5', arrow: 'arrow4', cut: undefined, space: false},
    {from: 'node5', to: 'node6', arrow: 'arrow5', cut: undefined, space: false},
    {from: 'node6', to: 'node7', arrow: 'arrow6', cut: undefined, space: false},
    {from: 'node7', to: 'node8', arrow: 'arrow7', cut: undefined, space: false},
    {from: 'node5', to: 'node3', arrow: 'arrow8', cut: 18, space: false},
    {from: 'node7', to: 'node3', arrow: 'arrow9', cut: undefined, space: false},
]