<div class="materlog-dropdown-view" #dropdownContainer >
    <div (click)="toggleDropdown($event)"
        [ngStyle]="dropdownHeaderStyles"
        [ngClass]="dropdownHeaderClass">
      <div #MaterlogDropdownText
        [ngClass]="dropdownTextClass"
        [ngStyle]="dropdownTextStyle"
        >
        {{selectedText}}
      </div>
      <i *ngIf="active && includeChevron" class="bi bi-chevron-down"
          [ngClass]="dropdownIconClass"
          [ngStyle]="dropdownIconStyle"
      ></i>
    </div>
    <div *ngIf="dropdownVisible" class="materlog-dropdown-container"
        [ngStyle]="{
          'left': leftMove,
          'max-height': heightMax,
          'top': topPosition,
          'width': dropdownWidth,
          'position': fixed ? 'fixed': 'absolute',
        }"
    >
      <input #inputTextEl
        *ngIf="inputText"
        class="materlog-dropdown-filter"
        [placeholder]="inputText"
        [(ngModel)]="filterText"
        (ngModelChange)="onFilterText()"
      >
      <div class="materlog-dd-scroll-container custom-scrollbar" #dropdownScroller
          [ngStyle]="{
            'max-height': ddHeightMax,
          }">
          <div *ngFor="let opt of filterOptions"
              class="materlog-dropdown-option"
              (click)="onOptionSelect($event, opt)">
              <i *ngIf="isSelected(opt)" class="bi bi-check2 materlog-check-selected"></i>
              <div class="materlog-dropdown-text">
              {{optionText(opt)}}
              </div>
          </div>
          <div class="materlog-dropdown-option materlog-dropdown-text"
              (click)="chooseNewOption($event)"
              *ngIf="allowCreateNew"
              [ngStyle]="{
                'color': canMakeNewValue ? '#000': '#BABABA',
                'cursor': canMakeNewValue ? 'pointer': 'default'
              }">
              {{createNewValueText}}
          </div>
      </div>

    </div>
</div>