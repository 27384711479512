import { ChangeDetectorRef, Component, OnInit, ViewChild, Input } from '@angular/core';
import { ElementRef, HostListener } from '@angular/core';
import { OrganizationService } from 'src/app/company-settings/services/organization.service';
import { connectNodesWithSteps } from '../flow-assistants/flow-utils';
import { newOrderDefaultNodes, newOrderConnectionsDefault, newOrderSwitchesDefault } from 'src/app/dashboard/components/flow-assistants/assistants/new-order-default'
import { nodesERP, switchesERP,  connectionsERP } from 'src/app/dashboard/components/flow-assistants/assistants/new-order-erp';
import { newOrderEmailNodes, newOrderEmailConnections, newOrderEmailSwitches } from 'src/app/dashboard/components/flow-assistants/assistants/new-order-email';
import { updateOrderDefaultNodes, updateOrderDefaultConnections, updateOrderDefaultSwitches} from 'src/app/dashboard/components/flow-assistants/assistants/update-order-default';
import { supplierLinksNodes, supplierLinksConnections, supplierLinksSwitches } from 'src/app/dashboard/components/flow-assistants/assistants/links-supplier';
import { shipperLinksConnections, shipperLinksNodes, shipperLinksSwitches} from 'src/app/dashboard/components/flow-assistants/assistants/links-shipper';
import { supplierDraftConnections, supplierDraftNodes, supplierDraftSwitches } from 'src/app/dashboard/components/flow-assistants/assistants/draft-email-supplier';
import { shipperDraftNodes, shipperDraftConnections, shipperDraftSwitches } from 'src/app/dashboard/components/flow-assistants/assistants/draft-email-shipper';
import { invoiceMatchingNodes, invoiceMatchingConnection, invoiceMatchingSwitches } from 'src/app/dashboard/components/flow-assistants/assistants/invoice-matching';


@Component({
  selector: 'meti-assistants',
  templateUrl: './assistants.component.html',
  styleUrls: [
    './assistants.component.css',
    '../flow-assistants/flow-assistants.component.css'
]
})
export class MetiAssistants implements OnInit {
  @Input() dropdownOptions: any;
  @Input() allMailData: any;
  @Input() sidePanelOpen: any;

  @ViewChild('workflowContainer') workflowContainer!: ElementRef;

  sales_girl: any = undefined;
  ops_ben: any = undefined;
  isLoading: boolean = true;

  orgData: any = {};
  orgOptions: any = {};
  chosenPic: string = 'Operations';

  nodes: any = [];
  connections: any = [];
  switches: any = [];
  rowGaps: any = {};
  arrowCount: number = 0;
  dashedCount: number = 1;
  rowGapDefault: number = 40;
  flushGraph: boolean = true;

  settings: any = [
    {
      name: 'Sales',
      extra: 'Coming soon!',

    },
    {
      name: 'Operations',
    }
  ];

  newOrders = 'Order data entry - new orders';
  orderUpdates = 'Order data entry - order updates';
  supplierLinks = 'Auto track order links - supplier';
  shipperLinks = 'Auto track order links - shipper';
  supplierCheckins = 'Draft and send check in emails - supplier';
  shipperCheckins = 'Draft and send check in emails - shipper';
  invoiceMatching = 'Invoice matching and approval routing';
  requestPricing = 'Request updated pricing';
  exceptionReview = 'Exception review';
  demandForecasting = 'Demand forecasting';

  newOrdersSlider = false;
  orderUpdatesSlider = false;
  supplierLinksSlider = false;
  shipperLinksSlider = false;
  supplierCheckinsSlider = false;
  shipperCheckinsSlider = false;
  invoiceMatchingSlider = false;
  requestPricingSlider = false;
  exceptionReviewSlider = false;
  demandForecastingSlider = false; 
  
  flowOn: boolean = true;
  activeChoice: any = undefined;

  choices: any = [
    {
        name: this.newOrders,
        subtext: 'Pull data from ERPs or extract details from emails, uploads and other sources to generate orders.',
        icon: 'bi-keyboard',
        background: '#A8C5FA',
        color: '#457DF1',
        org_data_value: 'order_data_entry_new',
        on: true,
    },
    {
        name: this.orderUpdates,
        subtext: 'Extract details from emails, uploads, and other sources on an on-going business. Auto-match to orders to seamlessly keep all your order data up to date.',
        icon: 'bi-keyboard',
        background: '#A8C5FA',
        color: '#457DF1',
        org_data_value: 'order_data_entry',
        on: true,
    },
    {
        name: this.supplierLinks,
        subtext: 'Automatically track supplier order links to keep tabs on status updates and changes without manual intervention. Stay informed with real-time updates directly from suppliers.',
        icon: 'bi-link-45deg',
        background: '#FBEBBC',
        color: '#F5BD42',
        org_data_value: 'track_links',
        on: true,
    },
    {
        name: this.shipperLinks,
        subtext: 'Automatically monitor shipping links to track order progress and delivery timelines. Pull data back to ensure seamless visibility into shipping statuses.',
        icon: 'bi-link-45deg',
        background: '#FBEBBC',
        color: '#F5BD42',
        org_data_value: 'track_links_shipper',
        on: true,
    },
    {
        name: this.supplierCheckins,
        subtext: 'Automatically generate and send supplier check-in emails to confirm order statuses, lead times, or other critical updates. Keep communication proactive and streamlined.',
        icon: 'bi-envelope',
        background: '#D8F2F7',
        color: '#259DB2',
        org_data_value: 'send_checkin_emails',
        on: true,
    },
    {
        name: this.shipperCheckins,
        subtext: 'Generate and send check-in emails to shippers to verify shipping progress, ETAs, or delivery updates.',
        icon: 'bi-envelope',
        background: '#D8F2F7',
        color: '#259DB2',
        org_data_value: 'send_checkin_emails_shipper',
        on: true,
    },
    {
        name: this.invoiceMatching,
        subtext: 'Automatically matches submitted invoices to purchase orders and routes them for approval to the appropriate stakeholders. Simplify the process to ensure accurate payments and compliance.',
        icon: 'bi-sign-turn-slight-right',
        background: '#F4D3F9',
        color: '#CB2CA4',
        org_data_value: 'invoice_matching',
        on: true,
    },
    {
        name: this.requestPricing,
        subtext: 'One click reach out to and on going communication with suppliers to request revised pricing for products or services, ensuring you always work with the latest and most competitive rates.',
        icon: 'bi-currency-exchange',
        background: '#BCAFF6',
        color: '#DFDAFA',
        on: true,
        nohover: true,
    },
    {
        name: this.exceptionReview,
        subtext: 'Flag and review purchase order exceptions to resolve discrepancies swiftly. Maintain accuracy and avoid delays in the procurement process.',
        icon: 'bi-exclamation-diamond',
        background: '#D6F4D4',
        color: '#3D8829',
        on: true,
        nohover: true,
    },
    {
        name: this.demandForecasting,
        subtext: 'Analyze historical data and trends to forecast demand accurately, enabling proactive procurement planning and inventory management, at the best rates.',
        icon: 'bi-calendar-event',
        background: '#DFDAFA',
        color: '#733BE6',
        on: true,
        nohover: true,
    },

  ]

  
  constructor(
    private orgService: OrganizationService,
    private cdr: ChangeDetectorRef,
    ) {
  }

  ngOnInit(): void {
    (window as any)['ass'] = this;
    this.getOrgData();
  }

  getOrgData() {
    this.orgService.getOrganization().subscribe((result: any) => {
      this.ops_ben = result.imgs?.ben;
      this.sales_girl = result.imgs?.sales;
      this.orgData = result.result;
      this.orgOptions = result.options;
      this.isLoading = false;
    })
  }

  backgroundForName(name: any) {
    if (name == 'Sales') {
      return '#28B6EC';
    } else if (name == 'Operations') {
      return '#FE9526';
    }
    return '';
  }

  picForName(name: any) {
    if (name == 'Sales') {
      return this.sales_girl;
    } else if (name == 'Operations') {
      return this.ops_ben;
    }
    return '';
  }

  doUpdateChoiceOn() {
    let data: any = {};
    this.choices.forEach((choice: any) => {
        data[choice.org_data_value] = this.orgData[choice.org_data_value];
    })
    this.orgService.updateOrganization({payload: data}).subscribe(() => {});
  }

  get isSliderOpen() {
    return this.newOrdersSlider ||
            this.orderUpdatesSlider ||
            this.supplierLinksSlider ||
            this.shipperLinksSlider ||
            this.supplierCheckinsSlider ||
            this.shipperCheckinsSlider ||
            this.invoiceMatchingSlider ||
            this.requestPricingSlider ||
            this.exceptionReviewSlider ||
            this.demandForecastingSlider;
  }

  resetSliders() {
        this.newOrdersSlider = false;
        this.orderUpdatesSlider = false;
        this.supplierLinksSlider = false;
        this.shipperLinksSlider = false;
        this.supplierCheckinsSlider = false;
        this.shipperCheckinsSlider = false;
        this.invoiceMatchingSlider = false;
        this.requestPricingSlider = false;
        this.exceptionReviewSlider = false;
        this.demandForecastingSlider = false;
        this.nodes = [];
        this.connections = [];
        this.switches = [];
        this.rowGaps = {};
        this.dashedCount = 1;
        this.rowGapDefault = 40;
        this.lastNodeClicked = undefined;
        this.showSideBar = false;
  }

  onChooseSlider(choice: any) {
    this.activeChoice = choice;
    this.flowOn = choice.on;
    switch(choice.name) {
        case this.newOrders:
            this.setNewOrderNodes();
            break;
        case this.orderUpdates:
            this.setOrderUpdateNodes();
            break;
        case this.supplierLinks:
            this.setSupplierLinkNodes();
            break;
        case this.shipperLinks:
            this.setShipperLinksNodes();
            break;
        case this.supplierCheckins:
            this.setSupplierDraftNodes();
            break;
        case this.shipperCheckins:
            this.setShipperDraftNodes();
            break;
        case this.invoiceMatching:
            this.setInvoiceMatchingNodes();
            break;
        case this.requestPricing:
        case this.exceptionReview:
        case this.demandForecasting:
        default:
            return;
    }
    this.arrowCount = this.connections.length;
    this.initGraphView();
  }

  flowOnSave() {
    this.onFlowSidebarChange();
    this.flowSaving = true;
    setTimeout(() => {
        this.flowSaving = false;
    }, 1300);
  }

  flowSaving: boolean = false;
  get flowSaveText() {
    return this.flowSaving ? 'Saved': 'Save'; 
  }

  onFlowBack() {
    this.resetSliders();
    this.activeChoice = undefined;
  }

  get maxWidth() {
    return this.sidePanelOpen ? 'calc(100vw - 290px)': 'calc(100vw - 60px)';
  }

  setNewOrderNodes() {
    if (this.orgData.orders_in == 0) {
        this.nodes = nodesERP;
        this.switches = switchesERP;
        this.connections = connectionsERP
    } else if (this.orgData.orders_in == 1) {
        // gmail extension
    } else if (this.orgData.orders_in == 2) {
        this.nodes = newOrderEmailNodes;
        this.connections = newOrderEmailConnections;
        this.switches = newOrderEmailSwitches;
    } else {
        this.nodes = newOrderDefaultNodes;
        this.connections = newOrderConnectionsDefault;
        this.switches = newOrderSwitchesDefault;
    }
    this.newOrdersSlider = true;
  }

  setOrderUpdateNodes() {
    this.nodes = updateOrderDefaultNodes;
    this.connections = updateOrderDefaultConnections;
    this.switches = updateOrderDefaultSwitches;
    this.orderUpdatesSlider = true;
    this.rowGapDefault = 50;
  }

  setSupplierLinkNodes() {
    this.nodes = supplierLinksNodes;
    this.connections = supplierLinksConnections;
    this.switches = supplierLinksSwitches;
    this.supplierLinksSlider = true;
  }

  setShipperLinksNodes() {
    this.nodes = shipperLinksNodes;
    this.connections = shipperLinksConnections;
    this.switches = shipperLinksSwitches;
    this.shipperLinksSlider = true;
  }

  setSupplierDraftNodes() {
    this.nodes = supplierDraftNodes;
    this.connections = supplierDraftConnections;
    this.switches = supplierDraftSwitches;
    this.dashedCount = 2;
    this.rowGapDefault = 50;
    this.supplierCheckinsSlider = true;
  }

  setShipperDraftNodes() {
    this.nodes = shipperDraftNodes;
    this.connections = shipperDraftConnections;
    this.switches = shipperDraftSwitches;
    this.rowGapDefault = 50;
    this.shipperCheckinsSlider = true;
  }

  setInvoiceMatchingNodes() {
    this.nodes = invoiceMatchingNodes;
    this.connections = invoiceMatchingConnection;
    this.switches = invoiceMatchingSwitches;
    this.rowGapDefault = 50;
    this.invoiceMatchingSlider = true;
  }

  ///// GRAPH PORTION //////

    initGraphView() {
        let outer = this;
        var pollInterval = setInterval(function() {
            const nodeEl: any = document.getElementById('node1');
            const arrow1 = document.getElementById('arrow1');
            if (nodeEl && arrow1 && outer.workflowContainer) {
                clearInterval(pollInterval);
                outer.drawArrows();
                window.addEventListener("resize", outer.drawArrows);
            }
        }, 10);
    }

  drawArrows() {
    let nodes = this.nodes;
    let connections = this.connections;
    let switches = this.switches;
    let rowGaps = this.rowGaps;

    let nodeEls: any = {};
    let rowTops: any = {
        1: 40,
    };
    nodes.forEach((node: any) => {
        const nodeEl: any = document.getElementById(node.id);
        nodeEls[node.id] = nodeEl;
        let top = rowTops[node.row];
        nodeEl.style.top = `${top}px`;
        nodeEl.style.left = `${node.style_left}px`;
        let rowHeight = top + nodeEl.offsetHeight + (rowGaps?.[node.row] || this.rowGapDefault);
        rowTops[node.row + 1] = Math.max(rowHeight, rowTops[node.row + 1] || 0);
    })

    let container = this.workflowContainer.nativeElement;

    let arrows: any = {};
    let arrowCount = connections.length;
    for (let i = 0; i < arrowCount; i++) {
        let name = `arrow${i+1}`
        const arrow1 = document.getElementById(name);
        arrows[name] = arrow1;
    }

    let arrNum = 1;
    connections.forEach((conn: any) => {
        let arrowName = `arrow${arrNum++}`;
        connectNodesWithSteps(nodeEls[conn.from], nodeEls[conn.to], arrows[arrowName],
            container, conn.cut, conn.space);
    });

    switches.forEach((swit: any) => {
        let nodeFrom = nodeEls[swit.from].getBoundingClientRect();
        let nodeTo = nodeEls[swit.to].getBoundingClientRect();
        let top = nodeFrom.bottom;
        if (swit.where) {
            top += swit.where
        } else {
            top += (nodeTo.top - nodeFrom.bottom) / 2;
        }
        let left = nodeEls[swit.above].getBoundingClientRect();
        let leftPos = left.left - (this.sidePanelOpen ? 290 : 74);
        leftPos += ((left.right - left.left) / 2) - 50;
        swit.top = `${top - 72 - 12}px`;
        swit.left = `${leftPos}px`;
    });
    }

    /////// TOGGLE PORTION ///////

    showSideBar: boolean = false;
    lastNodeClicked: any = undefined;
    onNodeClick(node: any) {
        if (!this.lastNodeClicked || !this.showSideBar) {
            this.lastNodeClicked = node;
            this.showSideBar = true;
        } else if (this.showSideBar && this.lastNodeClicked.id == node.id) {
            this.showSideBar = false;
            this.lastNodeClicked = undefined;
        } else {
            this.showSideBar = true;
            this.lastNodeClicked = node;
        }
    }

    gotoPageClick(event: any) {
        if (!this.orgData[event]) {
            this.onFlowBack();
            return;
        }
        let found = this.choices.find((choice: any) => choice.org_data_value == event);
        if (!found) {
            this.activeChoice = undefined;
            return;
        }
        this.onFlowBack();
        this.cdr.detectChanges();
        this.onChooseSlider(found);
    }


    onFlowSidebarChange() {
        let data: any = {};
        this.nodes.forEach((node: any) => {
            if (node.toggle) {
                data[node.toggle] = this.orgData[node.toggle]
            };
            node?.customizations?.forEach((custom: any) => {
                if (custom.org_data_value) {
                    data[custom.org_data_value] = this.orgData[custom.org_data_value]
                }
            })
        });
        this.orgService.updateOrganization({payload: data}).subscribe(() => {});
    }

    ///// POSSIBLE CHANGE OF GRAPH /////


    @HostListener('document:click', ['$event'])
    clickOutside(event: any) {
        if (this.newOrdersSlider) {
            let oin = this.orgData.orders_in;
            if ((oin == 0 && this.nodes != nodesERP) ||
                (oin == 2 && this.nodes != newOrderEmailNodes) ||
                (oin == 3 && this.nodes != newOrderDefaultNodes)
            ) {
                this.flushGraph = false;
                this.cdr.detectChanges();
                this.setNewOrderNodes();
                this.cdr.detectChanges();
                this.arrowCount = this.connections.length;
                this.flushGraph = true;
                this.initGraphView();
                if (this.nodes && this.nodes[0]) {
                    this.lastNodeClicked = this.nodes[0];
                }
            }
        }
    }

}
