<div class="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
  <div class="auth-container sm:mx-auto sm:w-full sm:max-w-sm">
  <div class="logo" >
    <img src="/assets/img/meti_logo.svg" alt="materlog-logo" />
  </div>

  <div class="welcome-center welcome-to-meti">
    Welcome to Meti!
  </div>
  <div class="welcome-center welcome-to-meti-create">
    Create a password to get started
  </div>
  <div class="welcome-center">
    <div class="welcome-to-meti-reset" (click)="resetPassword()">
      Reset password
    </div>
  </div>
</div>
</div>
