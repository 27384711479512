<div class="active-job-container" #jobContainer>
  <!--
  <div class="active-jobs-browse active-jobs-search browse-jobs-text job-margin-input" (click)="browseJobsClicked()"
      *ngIf="!searchClicked && isPanelOpen">
    <i class="bi bi-search job-icon-width job-icon-search"></i>
    Browse Jobs
  </div>
  -->
  <input *ngIf="searchClicked && isPanelOpen" class="job-search-input active-jobs-search job-margin-input" type="text" #jobSearchEl
      (keydown.enter)="onBlurJobs($event)"
      (blur)="jobSearchBlur()"
      [(ngModel)]="filterSearchText"
      (input)="onSearchChange()"
    />
  <div style="padding-top: 2px"></div>
  <div #jobContainerScroll class="custom-scrollbar-close"
      [ngClass]="{
        'job-list-container job-display': isPanelOpen,
        'job-display-closed': !isPanelOpen
      }"
      [ngStyle]="{'align-items': isPanelOpen ? '': 'center'}">
    <div class="job-single-views"
        [ngClass]="{
          'job-margin': isPanelOpen,
          'job-single-active-close': !isPanelOpen,
          'job-is-active': isPanelOpen && isJobActive(job),
        }"
        (click)="selectJob(job.id)"
        *ngFor="let job of curJobList">
      <div class="active-jobs-browse job-single-active"
          [ngClass]="isJobActive(job) ? 'job-is-active-color': ''"
          [ngStyle]="{'max-height': isPanelOpen ? '21px': ''}">
        <div class="job-active-short"
          [ngClass]="{
            'job-icon-width-open': isPanelOpen,
            'job-icon-width-closed': !isPanelOpen,
            'job-is-active': !isPanelOpen && isJobActive(job),
          }"
        >
          {{shortenedName(job)}}
        </div>
        <div class="active-jobs-browse job-long-name" *ngIf="isPanelOpen"
              [ngClass]="isJobActive(job) ? 'job-is-active-color': ''">
          {{job.name}}
        </div>
        <i *ngIf="job.id == 'Uncategorized'" class="bi bi-dot sp-alert-dot"
            [ngClass]="{
              'alert-dot-open': isPanelOpen,
              'alert-dot-closed': !isPanelOpen
            }"
        ></i>
      </div>
      <div *ngIf="canShowViewTabs(job)" class="view-list-container">
        <view-tab-list
          [selectedView]="selectedView"
          [isLoading]="isLoading"
          [isLoadingViews]="isLoadingViews"
          (scrollDown)="scrollDown()"
        ></view-tab-list>
      </div>
    </div>
  </div>
</div>

<!--


<div *ngIf="selectedJobId === job.id"  class="view-list-container">
  <view-tab-list
    [selectedView]="selectedView"
    [isLoading]="isLoading"
    [isLoadingViews]="isLoadingViews"
    (scrollDown)="scrollDown()"
  ></view-tab-list>
</div>
-->