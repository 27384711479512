import { ChangeDetectorRef, Component, OnInit, ViewChild, Input } from '@angular/core';
import { ElementRef, HostListener } from '@angular/core';

import { OrganizationService } from '../../services/organization.service';
import { faLessThanEqual } from '@fortawesome/free-solid-svg-icons';
import { DashboardJobView } from 'src/app/dashboard/services/dashboard_job_view.service';


@Component({
  selector: 'materlog-workflows',
  templateUrl: './workflows2.component.html',
  styleUrls: [
    './workflows.component.css',
    '../company-settings/company-settings.component.css'
  ]
})
export class MaterlogWorkflows implements OnInit {
  @Input() dropdownOptions: any;
  @Input() allMailData: any;
  @Input() whichWorkflowOption: any = undefined;

  @ViewChild('orgSettingsContainer') orgSettingsContainer!: ElementRef<HTMLDivElement>;

  isLoading: boolean = true;
  orgData: any = {};
  users: any = [];
  addresses: any = [];
  userId: any = '';
  userRole: any = undefined;

  statesList: any = [];

  addUsersModal: boolean = false;
  newUsersLoading: boolean = false;
  newUsersString: string = '';

  chosenAddress: any = undefined;
  showAddressModal: boolean = false;
  addressSubmitted: boolean = false;

  sales_girl: any = undefined;
  ops_girl: any = undefined;

  hasName: boolean = false;
  editingName: boolean = false;

  orderDataEntry: string = 'Order data entry';
  autoTrack: string = 'Auto track order links';
  draftEmails: string = 'Draft and send check in emails';

  picLoading: boolean = false;
  chosenPic: string = 'Operations';
  openRow = 0;

  trackPOText: string = 'Track POs';
  trackPOs: any = [
    {id: 0, value: "Don't track POs"},
    {id: 1, value: 'Track POs'},
  ];

  boxOptions: any = [
    {
      header: 'Confirm what you want Meti to help with',
      subheader: 'Meti helps you automate your procurement tasks and saves you time.',
      complete: true,
      open: false,
      optional: false,
      option: 1,
    },
    {
      header: 'Select method for getting your new orders into Meti',
      subheader: 'How do you want to get your new orders into Meti to track?',
      complete: true,
      open: false,
      optional: false,
      option: 2,
    },
    {
      header: 'Choose how to get updates to Meti',
      subheader: 'Meti will process the updates you receive from suppliers and check in on your orders to ensure your data is current.',
      complete: true,
      open: false,
      optional: false,
      option: 3,
    },
    /*
    {
      header: 'Get started',
      subheader: 'Kick off your order tracking and take Meti for a spin.',
      optional: true,
      complete: false,
      open: false,
      option: 4,
    },
    */
  ]

  settings: any = [
    {
      name: 'Sales',
      extra: 'Coming soon!',

    },
    {
      name: 'Operations',
    }
  ];

  constructor(
    private orgService: OrganizationService,
    private djv: DashboardJobView
  ) {
  }

  ngOnInit(): void {
      this.generateItems();
      this.getOrgData();
      (window as any)['workflow'] = this;
  }

  ngOnChanges(changes: any) {
  }


  getOrgData() {
    this.orgService.getOrganization().subscribe((result: any) => {
      this.ops_girl = result.imgs?.ops;
      this.sales_girl = result.imgs?.sales;
      this.orgData = result.result;
      this.userId = result.user_id;
      this.boxOptions[0].complete = this.orgData?.data_confirm;
      this.boxOptions[1].complete = this.orgData?.new_order_confirm;
      this.boxOptions[2].complete = this.orgData?.update_order_confirm;

      let changed = false;
      if (this.whichWorkflowOption) {
        let box = this.boxOptions.find((bo: any) => bo.option == this.whichWorkflowOption);
        if (box) {
          changed = true;
          box.open = true;
        }
      }
      if (!changed) {
        this.boxOptions[0].open = true;
      }
      this.isLoading = false;
    })
  }

  skeletonItems: any[] = [];
  generateItems() {
    for (let i = 0; i < 15; i++) {
      const randomWidth = Math.floor(Math.random() * 25) + 5;
      this.skeletonItems.push({ width: `${randomWidth}rem` });
    }
  }


  picForName(name: any) {
    if (name == 'Sales') {
      return this.sales_girl;
    } else if (name == 'Operations') {
      return this.ops_girl;
    }
    return '';
  }


  backgroundForName(name: any) {
    if (name == 'Sales') {
      return '#28B6EC';
    } else if (name == 'Operations') {
      return '#F3527B';
    }
    return '';
  }

  updateOrgData() {
    let data = {
      name: this.orgData.name.trim(),
      order_data_entry: this.orgData.order_data_entry,
      track_links: this.orgData.track_links,
      send_checkin_emails: this.orgData.send_checkin_emails,
      send_checkin_emails_shipper: this.orgData.send_checkin_emails_shipper,
      po_follow_up: this.orgData.po_follow_up,
      uses_pos: this.orgData.uses_pos,
      orders_in: this.orgData.orders_in,
      order_updates: this.orgData.order_updates,
      data_confirm: this.orgData.data_confirm,
      new_order_confirm: this.orgData.new_order_confirm,
      update_order_confirm: this.orgData.update_order_confirm,      
    };
    this.orgService.updateOrganization({payload: data}).subscribe(() => {});
    setTimeout(() => {
      this.djv.do_side_panel_notifs();
    }, 500);
    
  }

  toggleBoxOpen(box: any) {
    let isOpen = box.open;
    this.boxOptions.forEach((b: any) => {
        b.open = false
    });
    if (!isOpen) {
        box.open = true;
    }
  }

  onConfirm(box: any) {
      box.complete = true;
      if (box.option == 1) {
        this.orgData.data_confirm = true;
      } else if (box.option == 2) {
        this.orgData.new_order_confirm = true;
      } else if (box.option == 3) {
        this.orgData.update_order_confirm = true;
      }
      this.updateOrgData();
  }

}
