<div class="workflow-top-container" #orgSettingsContainer>
    <div class="workflow-box-col workflow-box-scroll custom-scrollbar-close">
        <div *ngFor="let box of boxOptions" class="workflow-box"
        [ngClass]="{
            'workflow-open-box': (box.open && box.complete) || box.optional || isLoading,
            'workflow-close-box-not-done': !isLoading && !box.optional && ((!box.open && !box.complete) || (box.open && !box.complete)) ,
            'workflow-close-box-done': !isLoading && !box.open && box.complete,
        }">
            <div class="workflow-box-row workflow-box-header"
                [ngClass]="{
                    'workflow-open-bar': box.open,
                    'workflow-open-bar-done': !isLoading && box.open && box.complete,
                    'workflow-open-bar-not-done': !isLoading && box.open && !box.complete,
                }"
            >
                <div class="workflow-box-row">
                    <i *ngIf="box.complete && !isLoading" class="bi bi-check-circle-fill workflow-leftmost workflow-check"
                        [ngClass]="box.complete ? 'workflow-check-done' : 'workflow-check-not-done'"
                    ></i>
                    <div *ngIf="isLoading || !box.complete" class="workflow-leftmost workflow-not-done-left"></div>
                    <div class="workflow-box-col" style="gap: 8px">
                        <div class="workflow-box-row" style="gap: 24px">
                            <div class="workflow-header-text-main">
                                {{box.header}}
                            </div>
                            <div *ngIf="!isLoading && !box.optional" class="workflow-button-isdone"
                                [ngClass]="box.complete ? 'workflow-is-done': 'workflow-is-not-done'">
                                {{box.complete ? 'Done': 'To do'}}
                            </div>
                            <div *ngIf="box.optional" class="workflow-button-isdone wf-recommended" style="margin-left: 0px;">
                                Recommended
                            </div>
                        </div>
                        <div class="workflow-subheader-text">
                            {{box.subheader}}
                        </div>
                    </div>
                </div>
                <i *ngIf="box.open" class="bi bi-chevron-down workflow-open-close" (click)="toggleBoxOpen(box)"></i>
                <i *ngIf="!box.open" class="bi bi-chevron-right workflow-open-close" (click)="toggleBoxOpen(box)"></i>
            </div>
            <div *ngIf="box.open" style="margin-top: 12px;">
                <materlog-workflows-confirm *ngIf="box.option == 1"
                    [orgData]="orgData"
                    [box]="box"
                    (applyUpdate)="updateOrgData()"
                ></materlog-workflows-confirm>
                <materlog-workflows-select *ngIf="box.option == 2"
                    [orgData]="orgData"
                    [box]="box"
                    (applyUpdate)="updateOrgData()"
                ></materlog-workflows-select>
                <materlog-workflows-choose *ngIf="box.option == 3"
                    [orgData]="orgData"
                    [box]="box"
                    (applyUpdate)="updateOrgData()"
                ></materlog-workflows-choose>
                <div *ngIf="!box.complete" class="wf-on-confirm">
                    <div class="wf-confirm-button" (click)="onConfirm(box)">
                        Confirm
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>