let smallNodeWidth = '200px';
let nodeWidth = '330px';
let largeNodeWidth = '420px';


let copyWhichUsers: any = [
    {
        number: '1.',
        name: 'Which users should be notified?',
        type: 'dropdown1',
        org_data_value: 'invoice_matching_notify',
        options: 'issue_notifiers',
    }
];

let firstWaitCustomization: any = [
    {
        number: '1.',
        name: 'Wait',
        type: 'dropdown1',
        org_data_value: 'invoice_matching_wait_1',
        options: 'flag_issues',
    },
];

let secondWaitCustomization: any = [
    {
        number: '1.',
        name: 'Wait',
        type: 'dropdown1',
        org_data_value: 'invoice_matching_wait_2',
        options: 'flag_issues',
    },
];


export let invoiceMatchingNodes: any = [
    {
        id: 'node1',
        icon: 'bi-plus-circle',
        icon_color: '#C2DBFF',
        subtext: 'Invoice received + matched to existing order',
        node_width: largeNodeWidth,
        style_left: 155, row: 1,
    },
    {
        id: 'node2',
        icon: 'meti',
        subtext: 'Forward and notify',
        customizations: copyWhichUsers,
        node_width: nodeWidth,
        style_left: 200, row: 2,
    },
    {
        id: 'node3',
        icon: 'bi-clock',
        icon_color: '#C2DBFF',
        subtext: 'Wait to follow up',
        customizations: firstWaitCustomization,
        toggle: 'invoice_matching_follow_up_1',
        node_width: nodeWidth,
        style_left: 200, row: 3,
    },
    {
        id: 'node4',
        icon: 'meti',
        subtext: 'Send follow up request #1',
        toggle: 'invoice_matching_follow_up_1',
        node_width: nodeWidth,
        style_left: 200, row: 4,
    },
    {
        id: 'node5',
        icon: 'bi-clock',
        icon_color: '#C2DBFF',
        subtext: 'Wait to follow up',
        customizations: secondWaitCustomization,
        toggle: 'invoice_matching_follow_up_2',
        node_width: nodeWidth,
        style_left: 200, row: 5,
    },
    {
        id: 'node6',
        icon: 'meti',
        subtext: 'Send follow up request #2',
        toggle: 'invoice_matching_follow_up_2',
        node_width: nodeWidth,
        style_left: 200, row: 6,
    },

];

export let invoiceMatchingConnection: any = [
    {from: 'node1', to: 'node2',  cut: undefined, space: true},
    {from: 'node2', to: 'node3',  cut: undefined, space: false},
    {from: 'node3', to: 'node4',  cut: undefined, space: false},
    {from: 'node4', to: 'node5',  cut: undefined, space: false},
    {from: 'node5', to: 'node6',  cut: undefined, space: false},

]

export let invoiceMatchingSwitches: any = [


]