import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { EventEmitter, Output, ChangeDetectorRef} from '@angular/core';
import { ElementRef, HostListener } from '@angular/core';
import { setScrollBar } from 'src/app/common/utils/general';
import { isPDF, isJPEG, isPNG, generateRandomString } from 'src/app/common/utils/general';
import { createDate } from 'src/app/common/utils/general';


@Component({
    selector: 'materlog-date-component',
    templateUrl: './materlog-date-component.component.html',
    styleUrls: ['./materlog-date-component.component.css'],
  })
export class MaterLogDate implements OnInit {
    @Input() items: any = [];
    @Input() enbold: boolean = false;
    @Input() is_item: boolean = false;
    @Input() chromeURL: any = '';
    @Output() onChange = new EventEmitter<any>();
    @Input() fieldName: string = '';
    @Input() forceChanger: any = '';
    @Input() og_format: boolean = false;
    @Input() openOnInit: boolean = false;
    @Input() extraFormat: any = {};
  
    @ViewChild('materlogDate') materlogDate!: ElementRef;
    dates: any = [];
    createdDate: any = undefined;
    dateVisible: boolean = false;
    defaultEmptyDate: string = '--';
  
  
    constructor(
        private cdr: ChangeDetectorRef
    ) {
    }
  
    ngOnChanges(changes: any) {
      if (this.dateVisible) {
        return;
      }
      let dates = this.is_item ? [this.items]: this.items
      dates = dates?.map((i: any) => i && i[this.fieldName]).filter((i: any) => i);
      dates = [...new Set(dates)];
      this.sortDates(dates);
      this.dates = dates;
      if (this.dates?.length) {
        this.createdDate = createDate(this.dates[0], true, true);
      } else {
        this.createdDate = this.defaultEmptyDate;
      }
      this.cdr.detectChanges();
    }
  
    sortDates(dates: any) {
      if (dates && dates.length > 1) {
        dates.sort((a: string, b: string) => {
          let dateA = new Date(a);
          let dateB = new Date(b);
          return dateA.getTime() - dateB.getTime();
        });
      }
    }
  
    ngOnInit() {
    }
  
    ngAfterViewInit() {
      const observer = new MutationObserver((mutationsList, observer) => {
        for (const mutation of mutationsList) {
          if (mutation.type === 'childList') {
            const children = this.materlogDate?.nativeElement?.children;
            while (children?.length > 1) {
              this.materlogDate.nativeElement.removeChild(children[1]);
            }
          }
        }
      });
  
      const config = { childList: true };
      if (this.materlogDate?.nativeElement) {
        observer.observe(this.materlogDate.nativeElement, config);
      }

      if (this.openOnInit) {
        setTimeout(() => {
          this.onDateClick();
        }, 10)
      }
    }
  
    get curItemDate() {
      return this.dates.length ? this.dates[0]: '--';
    }

    canSetItem() {
      if (this.is_item) {
        this.dates = [this.createdDate];
      }
    }

    onDateBlur(event: any) {
      if (!this.validateDate) return;
      this.dateVisible = false;
      this.canSetItem();
      this.onChange.emit({field: this.fieldName, data: this.createdDate});
    }

    onDateSelect(event: any) {
      if (!this.validateDate) return;
      this.dateVisible = false;
      this.canSetItem();
      this.onChange.emit({field: this.fieldName, data: this.createdDate});
      this.materlogDate.nativeElement.blur();
      this.cdr.detectChanges();
    }

    validInvalid() {
      let isDate: any = new Date(this.createdDate);
      let withTime: any = new Date(this.createdDate + 'T08:00:00Z')
      if (isNaN(isDate) || isNaN(withTime)) {
        return true;
      }
      return false;
    }

    get validateDate() {
      (window as any)['mdate'] = this;
      if (!this.createdDate || this.createdDate == '--' || this.validInvalid()) {
        this.createdDate = undefined;
        return true;
      }
      let isDate: any = new Date(this.createdDate);
      let curDate: any = new Date();
      let to_days = (1000 * 60 * 60 * 24);
      if (isNaN(isDate)) {
        return false;
      }
      let days = Math.abs(isDate - curDate) / to_days;
      if (days > 365 * 3) {
        return false;
      }
      return true;
    }

    onDateClick() {
      (window as any)['mdate'] = this;
      if (this.dates.length <= 1) {
        this.dateVisible = true;
        setTimeout(() => {
          this.canSetItem();
          this.materlogDate?.nativeElement?.focus();
          this.materlogDate?.nativeElement?.showPicker();
        }, 50);
      }
    }
    
    formatDate() {
      if (!this.dates.length) {
        return '--';
      } else if (this.dates.length == 1) {
        return createDate(this.dates[0]);
      } else {
        let newDates = [...this.dates];
        this.sortDates(newDates);
        let d1 = createDate(newDates[0]);
        let d2 = createDate(newDates[newDates.length - 1]);
        return `${d1} - ${d2}`;
      }
    }
  
  }