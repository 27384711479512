

function resultKeys() {
    let keys = [
        "job_name",
        "supplier_name",
        "total_cost",
        "sales_tax",
        "shipping_cost",
        "discount",
        "last_4_card_digits",
        "payment_method",
        "account",
        "billing_zip",
        "billing_name",
        "shipping_name",
        "shipping_address",
        "shipping_city",
        "shipping_zip",
        "shipping_state_name",
        "shipping_to",
        "order_notes",
        "supplier_documents_numbers",
        "name",
        "sku",
        "status",
        "description",
        "in_stock_date",
        "ship_date",
        "delivery_date",
        "quantity",
        "unit_of_measure",
        "cost_per_unit",
        "room_name",
        "notes",
        "tracking_info",
        "shipment_tracking",
    ]
    return keys;
}

function convertSupplierDocs(result: any, dropdownOptions: any) {
    let docs = result.supplier_documents_numbers || [];
    let docTypes = dropdownOptions.document_types;
    let output: any = [];
    docs.forEach((doc: any) => {
        let dtype = docTypes.find((dt: any) => dt.id == doc.document_type);
        dtype = dtype ? dtype.value : 'Order';
        let number = doc.number
        output.push(`${number} (${dtype})`)
    });
    result.supplier_documents_numbers = output.join(', ');
}

function convertDropdowns(result: any, dropdownOptions: any) {
    let dropdownConverts = [
        'status', 'unit_of_measure', 'payment_method', 'shipping_to'
    ];
    dropdownConverts.forEach((dc: any) => {
        let value = result[dc];
        let options = dropdownOptions[dc];
        if (!options) return;
        let found = options.find((o: any) => o.id == value);
        if (!found) return;
        result[dc] = found.value;
    })
}

function preConvertResults(results: any, order: any, dropdownOptions: any) {
    let trackKeys = ['tracking_info', 'shipment_tracking'];

    results.forEach((result: any) => {
        trackKeys.forEach((k: any) => {
            result[k] = result[k].join(',');
        });
        convertSupplierDocs(result, dropdownOptions);
        convertDropdowns(result, dropdownOptions);
        result.account = order?.account;
    })
}

export function downloadCSV(results: any, order: any, dropdownOptions: any) {
    let filename = 'data.csv';
    const csvRows = [];
    const headers = resultKeys();
    csvRows.push(headers.join(','));
  
    preConvertResults(results, order, dropdownOptions);

    // Add rows
    for (const row of results) {
      const values = headers.map(header => {
        const escaped = ('' + (row[header] || '')).replace(/"/g, '""'); // Escape double quotes
        return `"${escaped}"`; // Wrap each value in double quotes
      });
      csvRows.push(values.join(','));
    }
    const csvString = csvRows.join('\n');
  
    // Step 2: Create a Blob
    const blob = new Blob([csvString], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
  
    // Step 3: Create a download link
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  
    // Cleanup: Release the Blob URL
    URL.revokeObjectURL(url);
  }