import { ChangeDetectorRef, Component, OnInit, ViewChild, Input } from '@angular/core';
import { ElementRef, HostListener } from '@angular/core';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { PermissionsService } from 'src/app/root/services/permissions.service';
import { OrderService } from '../../services/order.service';
import { createDate } from 'src/app/common/utils/general';
import { SelectedViewModel } from '../../models/selected-view.model';
import { DashboardActions } from '../../state/actions/dashboard.actions';

@Component({
  selector: 'materlog-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: [
    './calendar.component.css',
  ]
})
export class CalendarComponent extends OnDestroyMixin() implements OnInit {
    @Input() dropdownOptions: any;
    @Input() allMailData: any;
    @Input() isLoading: boolean = false;
    @Input() selectedView?: SelectedViewModel;
    @Input() fromView: boolean = false;

    @ViewChild('itemCalendar') itemCalendar!: ElementRef;

    currentDay = new Date().getDate();
    currentMonth = new Date().getMonth();
    currentYear = new Date().getFullYear();
    now = new Date();
    currentFullDate = new Date(Date.UTC(this.now.getUTCFullYear(), this.now.getUTCMonth(), this.now.getUTCDate()));
    currentJob: any = undefined;

    showAllOrderDates: boolean = false;
    showMoreDates: boolean = false;
    showMoreDatesPos: any = {};
    showAllDatesVal: any = {};

    setMonth = this.currentMonth
    setYear = this.currentYear;
    currentMonthDates: any = {};

    dateTypesDrops = [
        {id: 0, value: 'All'},
        {id: 3, value: 'In stock date'},
        {id: 1, value: 'Ship date'},
        {id: 2, value: 'Delivery date'},
    ]
    setDateType = 0;

    daysInMonth: any = [];
    firstDayOffset: number = 0;
    calDate: any = '';

    curItems: any = [];
    curDates: any = undefined;

    monthButtons = [
        {style: 'bi bi-chevron-left', name: 'left'},
        {style: 'bi bi-chevron-right', name: 'right'},
    ]

    months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',            
    ].map((month, index) => {
        return { id: index, value: month };
    });

    itemHeaders = [
        {name: '', icon: true, width: '45px', iname: '', type: null},
        {name: 'Items', width: '150px', bold: true, iname: 'name', type: 'text'},
        {name: 'Status', width: '110px', iname: 'status', type: 'dropdown'},
        {name: 'In stock', width: '70px', iname: 'in_stock_date', value: true, type: 'date'},
        {name: 'Ship', width: '70px', iname: 'ship_date', value: true, type: 'date'},
        {name: 'Deliver', width: '70px', iname: 'delivery_date', value: true, type: 'date'},
    ]


    constructor(
        private cdr: ChangeDetectorRef,
        private orderService: OrderService,
        private dashboardActions: DashboardActions
    ) {
        super();
    }

    ngOnInit(): void {
        (window as any)['cal'] = this;
    }

    ngAfterViewInit() {
        this.initialRetrieve();
    }

    initialRetrieve() {
        this.getItemValues();
        this.createMonth();
        this.textForDate();
    }

    ngOnChanges(changes: any) {
        if (this.fromView && changes.isLoading) {
            if (this.currentJob != this.getCurrentJob()) {
                this.setMonth = this.currentMonth
                this.setYear = this.currentYear;
                this.initialRetrieve();
            }
        }
    }

    onDropdownChange(event: any) {
        this.setDateType = event.id;
        this.createDatesForMonth();
    }

    materlogCalDropHeader() {
        return {
            'display': 'flex',
            'flex-direction': 'row',
            'align-items': 'center',
            'min-width': '150px',
            'max-width': '150px',
            'padding': '0px 20px',
            'cursor': 'pointer',
            'gap': '4px',
            'color': '#A3A1A1',
        }
    }
    
    materlogCalDroptextHeader() {
        return {
            'overflow': 'hidden',
            'text-overflow': 'ellipsis',
            'white-space': 'nowrap',
            'font-size': '14px',
            'color': '#A3A1A1',
        }
    }
    
    materlogCalDropIcon() {
        return {
            'filter': 'invert(15%) sepia(1%) saturate(533%) hue-rotate(314deg) brightness(91%) contrast(83%);',
            'font-size': '12px'
        }
    }

    createMonth() {
        const year = this.setYear || 2024;
        const month = this.setMonth || 0;
    
        const totalDays = new Date(year, month + 1, 0).getDate();
        let firstDayOffset = new Date(year, month, 1).getDay();
        let daysEmpty = Array.from({ length: firstDayOffset }, (_, i) => -(firstDayOffset - i - 1));
        let daysInMonth = Array.from({ length: totalDays }, (_, i) => i + 1);
        let fullMonth = [...daysEmpty, ...daysInMonth]
        daysInMonth = fullMonth;
        let afterCells = (7 - (fullMonth.length % 7)) % 7;
        let daysAfterEmpty = Array(afterCells).fill(null);
        daysInMonth = [...daysInMonth, ...daysAfterEmpty]
        this.daysInMonth = daysInMonth.map((month, index) => {
            return { id: index, value: month };
        })
    }

    weekForDay(day: any) {
        const daysOfWeek = [
            'SUN',    // 0
            'MON',    // 1
            'TUE',   // 2
            'WED', // 3
            'THU',  // 4
            'FRI',    // 5
            'SAT'   // 6
        ];
        return daysOfWeek[day];
    }

    getCellHeader(day: any) {
        if (!day.value || day.value < 1) return ''
        return day.value;
    }

    isCurrentDay(day: any) {
        if (this.setYear != this.currentYear) return false;
        if (this.setMonth != this.currentMonth) return false;
        if (this.currentDay != day) return false;
        return true;
    }

    isTomorrowToday(day: any) {
        if (!this.isCurrentDay(day + 1)) return false;
        if ((day % 7) == 6) return false;
        return true;
    }

    isLastWeekToday(day: any) {
        return this.isCurrentDay(day - 7);
    }

    textForDate() {
        let month = this.months.find((m: any) => m.id == this.setMonth)?.value;
        let year = this.setYear;
        this.calDate = `${month} ${year}`;
    }

    onDateChange(mb: any) {
        let curMonth: any = this.setMonth;
        if (mb.name == 'left') {
            curMonth -= 1;
            if (curMonth < 0) {
                this.setYear -= 1;
            }
        } else {
            curMonth += 1;
            if (curMonth > 11) {
                this.setYear += 1;
            }
        }
        curMonth = curMonth % 12;
        if (curMonth < 0) {
            curMonth += 12;
        }
        this.setMonth = curMonth;
        this.textForDate();
        this.createMonth();
        this.createDatesForMonth();
    }

    get dropdownRetrieved() {
        return this.allMailData?.curOrders &&
                this.dropdownOptions?.status_colors &&
                this.dropdownOptions?.status;
    }

    getCurrentJob() {
        let curJob = JSON.parse((localStorage.getItem('selectedJob') || null as any)) || '';
        let lowerJob = curJob.toLowerCase();
        if (lowerJob == 'uncategorized') {
            return undefined;
        } else if (lowerJob == 'all') {
            return 'all';
        } else {
            return curJob;
        }
    }

    createCalendarItems(items: any) {
        this.currentJob = this.getCurrentJob();
        this.curItems = items;
        let output: any = {};
        let dtypes = ['ship_date', 'in_stock_date', 'delivery_date'];
        items.forEach((item: any) => {
            dtypes.forEach((dt: any) => {
                let value: any = item[dt];
                if (!value) return;
                if (!output[value]) {
                    output[value] = [];
                }
                let found = output[value].find((o: any) => o.order_id == item.order_id);
                let dstatus = `${dt}_status`;
                if (!found) {
                    let curOrder = this.allMailData?.curOrders?.find((o: any) => o.id == item.order_id);
                    if (!curOrder) return;
                    if (this.fromView) {
                        if (!this.currentJob && curOrder.job_id) {
                            return;
                        } else if (this.currentJob != 'all' && this.currentJob != curOrder.job_id) {
                            return;
                        }
                    }
                    
                    let missed = this.missedDeadline(dt, item);
                    let curValue = {
                        order_id: item.order_id, status: [item.status],
                        job: curOrder?.job_name, supplier: curOrder?.supplier_name,
                        [dt]: true, [dstatus]: [item.status],
                        missed_deadline: missed,
                    }
                    output[value].push(curValue);
                } else {
                    found[dt] = true;
                    if (!found[dstatus]) {
                        found[dstatus] = [item.status]
                    } else {
                        found[dstatus].push(item.status)
                    }
                    found.status.push(item.status);
                }
            })
        });
        Object.keys(output).forEach((key) => {
            let value = output[key];

            if (!value?.length) return;
            value.forEach((val: any) => {
                let status = val?.status;
                if (!status || !status.length) return;
                let color: any = undefined;
                this.dropdownOptions.status_colors.forEach((c: any) => {
                    if (!color && status.includes(c.id)) {
                        color = c.value;
                        val.status_color = color;
                    }
                })
            })
        });

        this.curDates = output;
    }

    createCalendarItemsInterval(items: any) {
        let outer = this;
        var pollInterval = setInterval(function() {
            if (outer.dropdownRetrieved) {
              clearInterval(pollInterval);
                outer.createCalendarItems(items);
                outer.createDatesForMonth();
            }
          }, 20);

    }

    getItemValues() {
        this.orderService.getItemCalendarValues().subscribe((result: any) => {
            this.createCalendarItemsInterval(result);
        })
    }

    dtypeNameForNumber(number: any) {
        switch (number) {
            case 1:
                return 'ship_date';
            case 2:
                return 'delivery_date';
            case 3:
                return 'in_stock_date';
            default:
                return null;
        }
    }

    takeFirstFour(values: any) {
        let count = values.length;
        if (count < 5) return values;
        let data = {count: count}
        return [...values.slice(0, 4), data];
    }

    createDatesForMonth() {
        let output: any = {};
        let i = 1
        const date = new Date(this.setYear, this.setMonth, i);
      
        while (date.getMonth() === this.setMonth) {
            output[i] = this.itemDatesForDate(i);
            output[`no_limit_${i}`] = this.itemDatesForDate(i, false);
            i++;
            date.setDate(date.getDate() + 1);
        }
        this.currentMonthDates = output;
    }

    itemDatesForDate(day: any, limit: boolean = true) {
        if (!this.curDates || day < 1 || !day) return [];
        let month: any = this.setMonth
        let year: any = this.setYear;
        let when = new Date(year, month, day);
        let whenDate = createDate(when, false, true);
        let datesObj = this.curDates[whenDate] || [];
        if (!datesObj?.length) return [];

        if (this.setDateType == 0) {
            return limit ? this.takeFirstFour(datesObj): datesObj;
        }
        let name: any = this.dtypeNameForNumber(this.setDateType);
        if (!name) return [];
        datesObj = datesObj.filter((d: any) => d[name]);
        return limit ? this.takeFirstFour(datesObj): datesObj;;
    }

    formatJobName(job: any) {
        if (this.fromView && this.currentJob && this.currentJob != 'all') {
            return '';
        }
        if (job.length <= 10) {
            return job
        }
        return job.slice(0, 10) + '...'
    }

    formatAndMore(index: any) {
        return `${index + 1 - 4} more`;
    }

    seeMoreDates(event: any, day: any) {
        this.showMoreDatesPos.x = event.clientX - 230;
        this.showMoreDatesPos.y = event.clientY - 200;
        this.showMoreDatesPos.day = day.value;
        this.showMoreDatesPos.values = this.currentMonthDates[`no_limit_${day.value}`];

        let date = new Date(this.setYear, this.setMonth, day.value)
        this.showMoreDatesPos.name = this.weekForDay(date.getUTCDay());

        this.showMoreDates = true;
        this.cdr.detectChanges();
    }

    onCloseShowMore() {
        this.showMoreDates = false;
    }

    onCloseShowAll() {
        this.showAllOrderDates = false;
        this.createCalendarItems(this.curItems);
        this.createDatesForMonth();
    }

    isDateSame(dateString: any, year: any, month: any, day: any) {
        let split = dateString?.split('-')
        if (split?.length != 3) return false;
        return split[0] == year && split[1] == month && split[2] == day;
    }

    showAllDates(event: any, dateObj: any, day: any, force: boolean = false) {
        if (this.showMoreDates && !force) return;
        let date = new Date(this.setYear, this.setMonth, day)
        let wday = this.weekForDay(date.getUTCDay())
        wday = wday[0] + wday.slice(1,).toLowerCase();
        let month = this.months.find(m => m.id == date.getMonth())?.value?.slice(0, 3);
        let dformat = `${wday}, ${month}. ${day}`;
        
        this.showAllDatesVal.date = dformat;
        this.showAllDatesVal.supplier = dateObj.supplier;
        this.showAllDatesVal.job = dateObj.job;
        this.showAllDatesVal.x = (window.innerWidth / 2) - 250;
        this.showAllDatesVal.y = (window.innerHeight / 2) - 250;

        let order = this.allMailData?.curOrders?.find((o: any) => o.id == dateObj.order_id)
        this.showAllDatesVal.order = order?.value || 'Order';

        let theMonth = date.getMonth() + 1
        let theYear = date.getFullYear();
        let items = this.curItems.filter((i: any) => i.order_id == dateObj.order_id);
        let todays: any = [];
        let notTodays: any = []
        let dtypes = ['ship_date', 'in_stock_date', 'delivery_date'];
        items.forEach((item: any) => {
            let cur: any = {};
            cur.id = item.id;
            cur.setDay = {year: theYear, month: theMonth, day: day};
            cur.name = item.name;
            let status = this.dropdownOptions.status.find((s: any) => s.id == item.status)
            cur.status = status?.value;
            cur.status_id = status?.id;
            cur.color = this.dropdownOptions.status_colors.find((s: any) => s.id == item.status)?.value;
            let isToday = false;
            dtypes.forEach((dt: any) => {
                cur[dt] = {value: '', og: item[dt]};
                let value = item[dt]
                if (!value) return;
                if (this.missedDeadline(dt, item)) {
                    cur[dt].missed_deadline = true;
                }
                if (this.isDateSame(value, theYear, theMonth, day)) {
                    cur[dt].today = true;
                    isToday = true;
                }
                let dval: any = new Date(value + 'T08:00:00Z');
                if (isNaN(dval)) {
                    cur[dt].value = '';
                }
                let iMonth = this.months.find(m => m.id == dval.getMonth())?.value?.slice(0, 3);
                cur[dt].value = `${iMonth}. ${dval.getUTCDate()}`
            });
            if (isToday) {
                todays.push(cur);
            } else {
                notTodays.push(cur)
            }
        });
        if (todays?.length) {
            todays[todays.length - 1].border = true;
        }
        let iout = [...todays, ...notTodays];
        this.showAllDatesVal.items = iout;
        this.showMoreDates = false;
        this.showAllOrderDates = true;
    }

    missedDeadline(dt: any, item: any) {
        let finished = [
            6, //"Delivered"
            20, //"At receiver"
            7, //"Ready for pickup"
            9, //"Picked up"
            8, //"Installed"
            10, //"Damaged"
            11, //"Returned"
            12, //"Lost"
            13, //"Cancelled"
            14, //"On hold"
            25, //"Awaiting funding"
            28, //"Expired"
        ];
        if (dt == 'in_stock_date') return false;
        if (finished.includes(item.status)) return false;
        let date = item[dt];
        if (!date) return false
        let prev_date = new Date(date + 'T24:00:00Z').getTime() < this.currentFullDate.getTime();
        if (!prev_date) return false;
        let shipped_status = [
            1, // "Default"
            26, // "PO"
            27, // "Quote"
            2, // "Not ordered"
            19, // "Pending payment"
            17, // "Pre-order"
            18, // "Submittals"
            3, // "Ordered"
            30, // "Backordered"
            16, // "In production"
            29, // "In transit"
            4, // "In stock or arrived at supplier"
        ]
        let delivered_status = [
            24, // "Partially shipped"
            5, // "Shipped"
            15, // "Partially delivered"
            21, // "Ready to be scheduled"
            22, // "Delivery scheduled"
            23, // "Tracking issues"
        ]
        delivered_status = [...delivered_status, ...shipped_status];
        if (dt == 'ship_date' && shipped_status.includes(item.status)) {
            return true;
        } else if (dt == 'delivery_date' && delivered_status.includes(item.status)) {
            return true;
        }
        return false;
    }

    valueForItemHdr(item: any, hdr: any) {
        let hname = hdr.iname;
        if (!item[hname]) return '';
        let value = item[hname];

        if (typeof value != 'string') {
            return value.value ? value.value : ''
        }
        return value || '';
    }

    isItemMissedDeadline(item: any, hdr: any) {
        let hname = hdr.iname;
        if (!item[hname]) return false;
        return item[hname].missed_deadline;
    }

    itemShouldBeBold(item: any, hdr: any) {
        let bold = this.isAnyBold(item);
        if (!bold) return false;
        if (hdr.iname == 'status' || hdr.iname == 'name') {
            return true;
        }
        let cur = item[hdr.iname];
        return cur && cur.today ? true: false;
    }

    isAnyBold(item: any) {
        let dtypes = ['ship_date', 'in_stock_date', 'delivery_date'];
        let bold = false;
        dtypes.forEach((dt: any) => {
            let cur = item[dt];
            if (cur && cur.today) bold = true;
        });
        return bold;
    }

    styleForItem(item: any, hdr: any) {
        let black = '#000';
        let cur: any = {
            'min-width': hdr.width,
            'max-width': hdr.width,
            'color': black,
        }
        let bold = this.itemShouldBeBold(item, hdr);
        let dtypes = ['ship_date', 'in_stock_date', 'delivery_date'];
        if (bold) {
            cur['font-weight'] = '400'
            if (dtypes.includes(hdr.iname)) {
                cur.color = '#187FFC';
            } else {
                cur.color = black
            }
        }
        let anyBold = this.isAnyBold(item);
        if (!anyBold) {
            cur['font-weight'] = '300';
        } else {
            if (dtypes.includes(hdr.iname)) {
                if (!cur.color || cur.color == black) {
                    let date = item[hdr.iname]
                    if (!date || !date.value) {
                        cur.color = black
                    } else {
                        let today = new Date();
                        let when = new Date(item[hdr.iname].og);
                        cur.color = when < today ? '#BABABA': black;
                    }
                }
            }
        }
        return cur;
    }

    itemEditName(hdr: any) {
        return `${hdr.iname}_edit`;
    }

    itemTempName(hdr: any) {
        return `${hdr.iname}_new`;
    }

    isItemEditing(item: any, hdr: any) {
        let field = this.itemEditName(hdr);
        return item[field];
    }

    itemEditX: any = undefined;
    itemEditY: any = undefined;
    forceChanger: boolean = false;
    onItemEditClick(event: any, item: any, hdr: any) {
        let rect = event.target.getBoundingClientRect()
        let field = this.itemEditName(hdr);
        this.itemEditY = rect.y - this.showAllDatesVal.y + 30;
        if (item[field]) return;
        this.itemHeaders.forEach((ih: any) => {
            if (ih.iname && hdr.iname != ih.iname) {
                let cur = this.itemEditName(ih);
                item[cur] = false;
            }
        });
        let fname = this.itemTempName(hdr);
        item[fname] = item[hdr.iname];
        item[field] = !item[field];

        if (hdr.iname == 'name') {
            setTimeout(() => {
                this.itemCalendar?.nativeElement?.querySelector('.item-edit-input')?.focus()
            }, 50);
        }
    }

    setChangeOnMainItems(item: any, field: any, value: any) {
        let foundItem = this.curItems.find((i: any) => i.id == item.id);
        if (foundItem) {
            foundItem[field] = value;
        }
    }

    onItemNameChange(event: any, item: any, hdr: any) {
        this.timeOutClose(item, hdr);
        if (item.name_new == item.name || !item.name_new) {
            item.name_new = item.name;
            return;
        }
        item.name = item.name_new;
        let field = this.itemEditName(hdr);
        item[field] = false;
        let payload = {
            id: item.id,
            name: item.name_new
        }
        this.dashboardActions.requestUpdateViewCell(payload);
        this.setChangeOnMainItems(item, 'name', item.name_new);
    }

    @HostListener('document:click', ['$event'])
    onDocumentClick(event: MouseEvent): void {
        let el = this.itemCalendar?.nativeElement?.querySelector('.item-edit-input')
        if (!el) return;
        if (!el.contains(event.target)) {
            el.blur();
        }
    }

    onItemEnter(event: any) {
        event.target.blur();
    }
    
    onItemStatusChange(event: any, item: any, hdr: any) {
        let payload = {
            id: item.id,
            status: event.id
        }
        this.dashboardActions.requestUpdateViewCell(payload);
        item.status_new = item.status = event.value;
        item.status_id = event.id;
        item.color = this.dropdownOptions.status_colors.find((s: any) => s.id == event.id)?.value;
        this.timeOutClose(item, hdr);
        this.setChangeOnMainItems(item, 'status', event.id);
    }

    timeOutClose(item: any, hdr: any) {
        setTimeout(() => {
            let field = this.itemEditName(hdr);
            item[field] = false;
        }, 10);
    }

    onToggleDropdown(event: any, item: any, hdr: any) {
        if (!event) {
            this.timeOutClose(item, hdr);
        }
    }

    setDateTypeOnItem(item: any, dt: any, date: any) {
        let cur: any = {}
        cur[dt] = {value: '', og: date};
        let value = date
        if (!value) {
            item[dt] = cur[dt];
            return;
        };
        if (this.isDateSame(value, item.setDay.year, item.setDay.month, item.setDay.day)) {
            cur[dt].today = true;
        }
        let dval: any = new Date(value + 'T08:00:00Z');
        if (isNaN(dval)) {
            cur[dt].value = '';
        }
        let iMonth = this.months.find(m => m.id == dval.getMonth())?.value?.slice(0, 3);
        cur[dt].value = `${iMonth}. ${dval.getUTCDate()}`
        item[dt] = cur[dt];
    }

    onItemDateChange(event: any, item: any, hdr: any) {
        let payload = {
            id: item.id,
            [hdr.iname]: event.data || null
        }
        this.dashboardActions.requestUpdateViewCell(payload);
        this.setDateTypeOnItem(item, hdr.iname, event.data || null);
        this.timeOutClose(item, hdr);
        this.setChangeOnMainItems(item, hdr.iname, event.data || null);
    }

    dateForItem(item: any, hdr: any) {
        return {[hdr.iname]: item[hdr.iname].og}
    }

    itemStatusHeader() {
        return {
            'display': 'flex',
            'flex-direction': 'row',
            'align-items': 'center',
            'min-width': '100px',
            'max-width': '100px',
            'max-height': '29px',
            'cursor': 'pointer',
            'gap': '4px',
            'color': '#A3A1A1',
        }
    }

    itemStatusDropHeader() {
        return {
            'overflow': 'hidden',
            'text-overflow': 'ellipsis',
            'white-space': 'nowrap',
            'font-size': '13px',
            'color': '#000',
        }
    }

    itemDateFormatting() {
        return {
            'max-width': '68px',
            'border': '1px solid #d8d8d8',
            'border-radius': '4px',
            'padding-left': '1px',
            'max-height': '30px',
            'font-weight': '400',
        }

    }
}