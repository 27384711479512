<div class="credentials-container" [ngStyle]="getDynamicStyles()" #jobCredentialContainer>
  <div *ngIf="!notificationsView && !isLoading && allDataRetrieved" class="header">
    <div class="header-left supplier-filter-button">
      <div class="supplier-filter-button active-orders supplier-button-padding"
          (click)="doShowJobs(true)"
          [ngClass]="showAllJobs ? 'active-supplier-button' : ''">
        All jobs
      </div>
      <div class="supplier-filter-button all-suppliers supplier-button-padding"
          (click)="doShowJobs(false)"
          [ngClass]="showAllJobs ? '': 'active-supplier-button'">
        Archived
      </div>
    </div>
    <div class="header-right">
      <div class="add-supplier" (click)="addJob()">
        + Job
      </div>
      <div class="search-input">
        <div>
          <input
            class="px-2 py-1 rounded-full text-xs border border-neutral-300 input-element"
            type="text"
            id="filter-text-box"
            placeholder="Search..."
            [(ngModel)] = "searchText"
            (input)="onSearchChange()"
          />
        </div>
      </div>
    </div>
  </div>
    
  <div style="margin-left: 60px; margin-top: 60px" *ngIf="isLoading && !allDataRetrieved">
    <p-skeleton width="30rem" height="30px" styleClass="mb-2" borderRadius="16px"></p-skeleton>
    <div *ngFor="let item of skeletonItems">
      <p-skeleton [width]="item.width" [height]="'20px'" styleClass="mb-2" borderRadius="16px"></p-skeleton>
    </div>
  </div>

  <div class="size-change custom-scrollbar-close" *ngIf="allDataRetrieved"
    [ngStyle]="{'max-width': theMaxWidth}">
    <div class="supplier-headers">
      <div class="supplier-header-name" *ngFor="let c of jobColumns()"
          [ngClass]="columnWidthForName(c)">
        {{c}}
      </div>
    </div>
    <div style="padding-bottom: 4px"></div>
    <div class="single-supplier-container custom-scrollbar-close">
      <div class="single-supplier-row"
            [ngClass]="filteredJobs.length > 1 ? 'multi-supplier-row': ''"
            *ngFor="let job of filteredJobs; let isLast = last">
        <div class="default-col-width credential-input-field" style="font-weight: bold">
          <input class="item-text-input basic-row-input credential-input-field-text"
                  type="text"
                  (keydown.enter)="inputEnter($event)"
                  (blur)="doUpdateJob($event, job, 'name')"
                  [(ngModel)]="job.job.name"/>
        </div>
        <div class="default-col-width credential-input-field">
          <input class="item-text-input basic-row-input credential-input-field-text"
                  type="text"
                  (keydown.enter)="inputEnter($event)"
                  (blur)="doUpdateJob($event, job, 'number')"
                  [(ngModel)]="job.job.number"/>
        </div>
        <div class="long-col-width job-address-input-field-container">
          <div class="job-address-box"
              [ngClass]="{
                'job-address-box-red': addressError && !job.job.address
              }">
            <input class="item-text-input basic-row-input job-input-field-text"
                    type="text"
                    placeholder="Address"
                    (keydown.enter)="inputEnter($event)"
                    (blur)="doUpdateJob($event, job, 'address')"
                    [(ngModel)]="job.job.address"/>
            <input class="item-text-input basic-row-input job-input-field-text"
                    type="text"
                    (keydown.enter)="inputEnter($event)"
                    placeholder="City"
                    (blur)="doUpdateJob($event, job, 'city')"
                    [(ngModel)]="job.job.city"/>
            <dropdown-cell-renderer class="job-mins job-dropdown-mins"
                    [ngStyle]="{'color': !job.job?.state_id ? '#aaa': ''}"
                    [options]="dropdownOptions.state"
                    [isAgGrid]="false"
                    [data]="findState(job.job)"
                    [cardView]="true"
                    [credPage]="true"
                    [cardViewWidth]="150"
                    [colField]="'state_id'"
                    [blankBackup]="true"
                    [noPadding]="true"
                    [backupText]="'State'"
                    (statusChanged)="onUpdateDropdown($event, job.job, 'state')"
                ></dropdown-cell-renderer>
            <input class="item-text-input basic-row-input job-input-field-text"
                type="text"
                (keydown.enter)="inputEnter($event)"
                placeholder="Zipcode"
                (blur)="doUpdateJob($event, job, 'zip')"
                [(ngModel)]="job.job.zip"/>
          </div>
        </div>
        <div class="default-col-width" style="position: relative; top: -1px; height: 24px">
          <div class="job-track-orders" (click)="showTrackOrderDropdown(job)">
            <i class="bi" style="font-size: 12px; "[ngClass]="{
              'bi-check-circle-fill job-track-order': job.job.track_job,
              'bi-x-lg job-no-track-order': !job.job.track_job,

            }"></i>
            {{job.job.track_job ? "Track orders": "Don't track orders"}}
          </div>
          <ng-container *ngIf="job.showOrderTracking">
            <div class="new-option-dropdown">
              <div class="new-option" (click)="onUpdateJobTracking(job, which)"
                  *ngFor="let which of trackJobIcons">
                  <i class="bi" style="font-size: 12px; "[ngClass]="{
                    'bi-check-circle-fill job-track-order': which.value,
                    'bi-x-lg job-no-track-order': !which.value,
      
                  }"></i>
                  {{which.name}}
              </div>
            </div>
          </ng-container>
        </div>
        <div class="job-tracking-choice small-jobs-width">
          <dropdown-cell-renderer class="job-mins"
            [options]="dropdownOptions?.weekly_checkin?.slice(0, -1)"
            [isAgGrid]="false"
            [data]="findWeeklyCheckin(job.job)"
            [cardView]="true"
            [credPage]="true"
            [cardViewWidth]="150"
            [colField]="'weekly_checkin'"
            [blankBackup]="true"
            (statusChanged)="onUpdateDropdown($event, job.job, 'weekly_checkin')"
          ></dropdown-cell-renderer>
        </div>
        <div class="job-tracking-choice small-jobs-width">
          <dropdown-cell-renderer class="job-mins"
            [options]="allUsers"
            [isAgGrid]="false"
            [jobsPage]="true"
            [data]="job.watchers"
            [colField]="'users'"
            [isMultiple]="true"
            (statusChanged)="handleJobWatchersChange($event, job, 'watchers')"
            >
          </dropdown-cell-renderer>
        </div>
        <div class="long-col-width">
          <div class="supplier-contact-container" *ngIf="!job.receiver">
            <div class="supplier-contact-name">
              <dropdown-cell-renderer class="job-mins"
                [options]="allReceivers"
                [isAgGrid]="false"
                [jobsPage]="true"
                [data]="job.receiver"
                [colField]="'receiver'"
                [cardViewWidth]="150"
                (statusChanged)="handleReceiverChange($event, job)"
                >
              </dropdown-cell-renderer>
            </div>
          </div>
          <div class="supplier-contact-container custom-scrollbar-close" *ngIf="job.receiver"
            [ngClass]="{
              'job-address-box-red': (receiverErrorAddress && !job.receiver.address) ||
                                      (receiverErrorEmail && !job.receiver.email)}">
            <div class="supplier-contact job-contact receiver-field">
              <div class="supplier-contact-name receiver-name-edit">
                <div class="supplier-header-name" *ngIf="job.receiver.editing">
                  <input class="item-text-input sc-input"
                        type="text"
                        (keydown.enter)="inputEnter($event)"
                        (blur)="doUpdateReceiverName($event, job.receiver, 'warehouse_name')"
                        placeholder="Warehouse name"
                        [(ngModel)]="job.receiver.warehouse_name"/>
                </div>
                <div class="supplier-header-name" *ngIf="!job.receiver.editing">
                  <dropdown-cell-renderer
                  [options]="allReceivers"
                  [isAgGrid]="false"
                  [jobsPage]="true"
                  [data]="job.receiver"
                  [colField]="'receiver'"
                  [cardViewWidth]="150"
                  (statusChanged)="handleReceiverChange($event, job)"
                  >
                  </dropdown-cell-renderer>
                  <i class="bi bi-pencil edit-receiver" 
                    style="position: relative; font-size: 9px" (click)="editReceiverName($event, job.receiver)"></i>
                </div>
              </div>
              <div class="supplier-contact-secondary-field jobs-page-bad-email">
                <input class="item-text-input sc-input"
                      type="text"
                      (keydown.enter)="inputEnter($event)"
                      (blur)="doUpdateReceiver($event, job.receiver, 'email')"
                      placeholder="Email"
                      [(ngModel)]="job.receiver.email"/>
                <div *ngIf="job.receiver.invalid_email" class="jobs-page-bad-email-alert">
                  Invalid email
                </div>
              </div>
              <div class="supplier-contact-secondary-field">
                <input class="item-text-input sc-input"
                      type="text"
                      (keydown.enter)="inputEnter($event)"
                      (blur)="doUpdateReceiver($event, job.receiver, 'phone')"
                      placeholder="Phone number"
                      [(ngModel)]="job.receiver.phone"/>
              </div>
              <div class="supplier-contact-secondary-field">
                <input class="item-text-input sc-input"
                      type="text"
                      (keydown.enter)="inputEnter($event)"
                      (blur)="doUpdateReceiver($event, job.receiver, 'address')"
                      placeholder="Address"
                      [(ngModel)]="job.receiver.address"/>
              </div>
              <div class="supplier-contact-secondary-field">
                <input class="item-text-input sc-input"
                      type="text"
                      (keydown.enter)="inputEnter($event)"
                      (blur)="doUpdateReceiver($event, job.receiver, 'city')"
                      placeholder="City"
                      [(ngModel)]="job.receiver.city"/>
              </div>
              <div class="job-tracking-choice small-jobs-width supplier-contact-secondary-field"
                    style="top: 0px !important">
                <dropdown-cell-renderer class="receiver-state-dropdown"
                  [options]="dropdownOptions.state"
                  [isAgGrid]="false"
                  [data]="findState(job.receiver)"
                  [cardView]="true"
                  [credPage]="true"
                  [cardViewWidth]="150"
                  [colField]="'state_id'"
                  [blankBackup]="true"
                  (statusChanged)="onUpdateDropdownReceiver($event, job.receiver, 'state')"
              ></dropdown-cell-renderer>
            </div>
            </div>
          </div>
        </div>
        <div class="long-col-width">
          <div class="supplier-contact-container job-contact-container custom-scrollbar-close" *ngIf="job.receiver">
            <div class="supplier-contact job-contact" *ngFor="let rc of job.receiver?.receiver_contacts || []">
              <div class="delete-supplier-contact">
                <i class="bi bi-x" (click)="deleteRecieverContact(job.receiver, rc)"></i>
                <div class="supplier-contact-tooltip delete-contact-tooltip ">
                  Delete contact?
                </div>
              </div>
              <div class="supplier-contact-name">
                <input class="item-text-input sc-input"
                      type="text"
                      (keydown.enter)="inputEnter($event)"
                      (blur)="stopEditingReceiverContact($event, job.receiver, rc, 'name')"
                      placeholder="Name"
                      [(ngModel)]="rc.name"/>
              </div>
              <div class="supplier-contact-secondary-field jobs-page-bad-email">
                <input class="item-text-input sc-input"
                      type="text"
                      (keydown.enter)="inputEnter($event)"
                      (blur)="stopEditingReceiverContact($event, job.receiver, rc, 'email')"
                      placeholder="Email"
                      [(ngModel)]="rc.email"/>
                <div *ngIf="rc.invalid_email" class="jobs-page-bad-email-alert">
                  Invalid email
                </div>
              </div>
              <div class="supplier-contact-secondary-field">
                <input class="item-text-input sc-input"
                      type="text"
                      (keydown.enter)="inputEnter($event)"
                      (blur)="stopEditingReceiverContact($event, job.receiver, rc, 'phone')"
                      placeholder="Phone number"
                      [(ngModel)]="rc.phone"/>
              </div>
            </div>
            <div class="add-supplier-contact">
              <i class="bi bi-plus-lg" (click)="addReceiverContact($event, job.receiver)"
                  style="margin-left: 2px; max-height: 16px; font-size: 16px; position: relative; top: -4px;"></i>
            </div>
          </div>
        </div>
        <div class="small-jobs-width" style="position: relative; top: -1px; left: 8px;">
          <p-inputSwitch 
            [(ngModel)]="job.job.is_archived"
            name="jobs"
            (onChange)="doUpdateJob($event, job, 'is_archived')">
          </p-inputSwitch>
        </div>
        <div class="small-jobs-width" style="position: relative; top: -1px; left: 8px"
          *ngIf="showAllJobs" (click)="onUpdateJobVisible(job)">
          <i class="bi job-visible"
          [ngStyle]="{'color': job.job.user_tracking ? '#000': '#aaa'}"
          [ngClass]="{
            'bi-eye': job.job.user_tracking,
            'bi-eye-slash': !job.job.user_tracking,
          }"></i>
        </div>
      </div>
    </div>
  </div>
</div>


<materlog-create-update-job *ngIf="showNewJob"
  [fromGetTracking]="false"
  [fromJobsPage]="true"
  (close)="showNewJob = false"
  (jobBeenAdded)="jobBeenAdded()"
  >
</materlog-create-update-job>


<materlog-create-update-job *ngIf="showNewReceiver"
  [onlyReceiver]="true"
  [sidePanelAdd]="true"
  [fromGetTracking]="false"
  [fromJobsPage]="true"
  (receiverBeenAdded)="receiverBeenAdded($event)"
  (receiverContactBeenAdded)="receiverContactBeenAdded($event)"
  >
</materlog-create-update-job>