<div class="upload-container" [ngStyle]="{'max-width': maxWidth}" #uploadContainer>
    <div class="upload-container-header upload-text-main">
        Extract any order into structured data
    </div>
    <div class="upload-choice-block">
        <div class="upload-choice-block-header upload-text-main">
            1. Choose purpose
        </div>
        <div class="upload-choice-select">
            <div class="upload-choice-single" *ngFor="let purpose of uploadPurposes"
                (click)="onChosenPurpose(purpose)"
                [ngClass]="{
                    'upload-choice-single-chosen': purpose.selected
                }">
                {{purpose.name}}
            </div>
        </div>
    </div>
    <div class="upload-container-blocks custom-scrollbar-close">
        <div class="upload-container-block-major">
            <div class="upload-container-block-select upload-container-block-major-border">
                <!--<div class="upload-confirm-overlay" *ngIf="selectedId"></div>-->
                <div class="upload-block-header upload-text-main">
                    2. Select a document
                </div>
                <label class="upload-block-file" (click)="uploadFile($event)"
                    [ngClass]="{'upload-block-nomore-files': !canUploadMore}">
                    <i class="bi bi-file-earmark"></i>
                    Upload file(s)
                    <input type="file" #fileInput (change)="onFileSelected($event)" *ngIf="canUploadMore">
                </label>
                <div class="upload-container-subtext">
                    Max 4 files. Limit 20 pages. PDF and images (.png, .jpeg, and .jpg) accepted.
                </div>
                <div *ngFor="let doc of uploadDocs" class="upload-doc-single">
                    <div class="upload-doc-text">
                        {{doc.filename}}
                    </div>
                    <i class="bi bi-x-lg upload-delete-doc" (click)="deleteDoc(doc)" *ngIf="!extractionStarted"></i>
                </div>
                <div style="display: flex; height: 100%"></div>
                <div class="upload-container-begin"
                    (click)="beginExtraction()"
                    [ngStyle]="{'cursor': isDocDefault ? 'default': 'pointer'}"
                    [ngClass]="
                        uploadDocs.length ? 'upload-container-canstart': 'upload-container-nonstart'
                    ">
                    <div class="upload-text-main" *ngIf="!extractionStarted">
                        Begin extraction
                    </div>
                    <div class="upload-doc-spinner" *ngIf="extractionStarted"></div>
                </div>
            </div>
            <div class="upload-on-ready-container" *ngIf="ordersReady.length">
                <div class="upload-on-ready-container-header">
                    When extraction status below says ‘Ready for review’, click to review your data.
                </div>
                <div class="upload-on-ready-select custom-scrollbar-close">
                    <div *ngFor="let review of ordersReady" class="upload-single-order-ready"
                        (click)="onReviewChosen(review)"
                        [ngClass]="{
                            'upload-single-order-reviewable': review.status != -1,
                            'upload-selected-review': review.id == selectedId
                        }">
                        <div class="upload-single-pretext upload-single-pretext-extract"
                            [ngClass]="{'upload-single-pretext-hover': review.id != selectedId}">
                            Extraction
                        </div>
                        <div class="upload-single-pretext upload-single-pretext-dot"
                            [ngClass]="{'upload-single-pretext-hover': review.id != selectedId}">
                            ·
                        </div>
                        <div class="upload-single-pretext upload-single-pretext-date upload-date" >
                            {{formatDate(review.created_datetime)}}
                        </div>
                        <div class="upload-single-status-box"
                            [ngStyle]="{
                                'background-color' : review.status == 2 ? '#ffdf34' : '#D9D9D9'
                            }">
                            <i class="bi" [ngClass]="classForStatus(review.status)"></i>
                            {{textForStatus(review.status)}}
                        </div>
                        <div style="display: flex; width: 100%"></div>
                        <i *ngIf="review.status == 2" class="bi bi-x-lg upload-delete-doc" (click)="deleteReviewReady($event, review)"></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="upload-full-confirm-container">
            <div class="upload-confirm-overlay" *ngIf="!selectedId"></div>
            <div class="upload-container-block-major upload-container-confirm upload-container-data">
                <div class="upload-block-header upload-text-main upload-container-maintext">
                    3. Confirm extracted data
                </div>
                <div class="upload-block-order-item">
                    <div *ngIf="isOrderChosen" class="upload-rounded-side upload-rounded-side-bottom-first upload-rounded-side-bottom-right"></div>
                    <div *ngIf="!isOrderChosen" class="upload-rounded-side-bottom-first upload-rounded-side"></div>
                    <div class="upload-block-confirm-order-box">
                        <div class="upload-block-confirm-order" (click)="setChooseOrderItem('order')"
                            [ngClass]="[
                                isOrderChosen ? 'upload-block-confirm-chosen': 'upload-block-confirm-nonchosen',
                                selectedId ? '' : 'upload-block-nomore-files'
                            ]">
                            Order info
                        </div>
                    </div>
                    <div class="upload-rounded-side upload-rounded-side-bottom-middle"
                        [ngClass]="isOrderChosen ? 'upload-rounded-side-bottom-left': 'upload-rounded-side-bottom-right'"
                    ></div>
                    <div class="upload-block-confirm-order-box" >
                        <div class="upload-block-confirm-order" (click)="setChooseOrderItem('item')"
                            [ngClass]="[
                                !isOrderChosen ? 'upload-block-confirm-chosen': 'upload-block-confirm-nonchosen',
                                selectedId ? '' : 'upload-block-nomore-files'
                            ]">
                            Item info
                        </div>
                    </div>
                    <div *ngIf="!isOrderChosen" 
                        class="upload-rounded-side upload-rounded-side-bottom-left upload-rounded-side-bottom-middle"></div>
                    <div class="upload-order-item-round-off"></div>
                </div>
                <div class="upload-block-confirm-data">
    
                </div>
            </div>
            <div class="upload-confirm-container-bottom">
                <div class="upload-confirm-boxes upload-confirm-order-box">
                    <div class="upload-block-order-item upload-order-data-text">
                        {{orderOrItemText}}
                    </div>
                    <materlog-order-auto-upload *ngIf="this.isOrderChosen"
                        class="upload-confirm-order-item-datas custom-scrollbar-close"
                        [ngStyle]="{'min-height': blockMinHeight}"
                        [dropdownOptions]="dropdownOptions"
                        [allMailData]="allMailData"
                        [onlyProcessing]="!selectedId"
                        [msgData]="msgData"
                    ></materlog-order-auto-upload>
                    <materlog-item-auto-upload *ngIf="!this.isOrderChosen"
                        class="upload-confirm-order-item-datas custom-scrollbar-close"
                        [ngStyle]="{'min-height': blockMinHeight}"
                        [dropdownOptions]="dropdownOptions"
                        [allMailData]="allMailData"
                        [msgData]="msgData"
                    ></materlog-item-auto-upload>
                </div>
                <div class="upload-confirm-boxes upload-confirm-order-doc-box">
                    <div *ngIf="!selectedId" class="upload-block-order-item upload-order-data-text">
                        Order docs
                    </div>
                    <materlog-docs-auto-upload *ngIf="selectedId"
                        class="upload-order-doc-container custom-scrollbar-close"
                        [dropdownOptions]="dropdownOptions"
                        [allMailData]="allMailData"
                        [msgData]="msgData"
                        [selectedId]="selectedId"
                    ></materlog-docs-auto-upload>
                </div>
                <div class="upload-confirm-confirm-options upload-confirm-confirm-container">
                    <div class="upload-confirm-confirm-options upload-confirm-button upload-confirm-boxes upload-confirm-csv"
                        (click)="onConfirmOrder(false)"
                        [ngClass]="selectedId ? 'upload-can-submit' : ''"
                    >
                        <i class="bi bi-download"></i>
                        {{downloadCSVText}}
                    </div>
                    <div class="upload-confirm-confirm-options upload-confirm-button upload-confirm-add" (click)="onConfirmOrder()"
                        [ngClass]="selectedId ? 'upload-can-submit upload-container-canstart' : 'upload-container-nonstart'">
                        <i class="bi bi-plus-lg" *ngIf="!orderConfirmInProgress"></i>
                        {{addOrderText}}
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>