import { Component, Injector, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';

import { FormControl, FormGroup, Validators } from '@angular/forms';

import { ILoginResponse, ISingUp, IOptions } from './../../models/authentication.model';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { CommonConstants } from './../../../common/constants/common.constants';
import { IStoreApiItem } from 'src/app/common/models/store-api-item.model';
import { IApplicationState } from 'src/app/common/state/models/app.state.model';
import { AuthenticationActions } from '../../state/actions/authentication.actions';
import { selectSingUp, selectUserOptions } from '../../state/selectors/authentication.selector';
import { PermissionsService } from 'src/app/root/services/permissions.service';

@Component({
  selector: 'materlog-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.sass'],
})
export class SignUpComponent extends OnDestroyMixin() implements OnInit {
  permissionService: PermissionsService;
  emailExists: boolean = false;
  isSubmitted: boolean = false;
  isLoggedIn: boolean = false;
  isSubmittedCompany: boolean = false;
  isSubmittedRole: boolean = false;
  isSubmittedTeam: boolean = false;
  companyTypes: any;
  roleTypes: any;
  selectedCompanyType: any;
  selectedRoleType: any;
  errorMessage: string | undefined = '';

  signUpForm: FormGroup = new FormGroup({
    first_name: new FormControl(null, [Validators.required, Validators.minLength(2), Validators.maxLength(32)]),
    last_name: new FormControl(null, [Validators.required, Validators.minLength(2), Validators.maxLength(32)]),
    organization_name: new FormControl(null, [Validators.required, Validators.minLength(2), Validators.maxLength(32)]),
    email: new FormControl(null, [Validators.required, Validators.pattern(CommonConstants.emailRegex)]),
    phone: new FormControl(null, [Validators.maxLength(20)]),
    password: new FormControl(null, [Validators.required, Validators.pattern(CommonConstants.passwordRegex)]),
  });

  teamForm: FormGroup = new FormGroup({
    first_member: new FormControl(null, [Validators.required, Validators.minLength(2), Validators.maxLength(32)]),
    second_member: new FormControl(null, [Validators.required, Validators.minLength(2), Validators.maxLength(32)]),
    third_member: new FormControl(null, [Validators.required, Validators.minLength(2), Validators.maxLength(32)]),
    invite_link: new FormControl(null, [Validators.required, Validators.minLength(2)]),
  });

  constructor(
    public router: Router,
    private injector: Injector,
    private store: Store<IApplicationState>,
    private authenticationActions: AuthenticationActions,
  ) {
    super();
    this.permissionService = this.injector.get<PermissionsService>(PermissionsService);
  }

  ngOnInit(): void {
    if (this.permissionService.isLoggedIn()) {
      this.router.navigate(['']);
    }

    this.signUpForm.valueChanges.subscribe(() => {
      this.errorMessage = undefined;
      this.emailExists = false;
    });
  }

  onSubmit() {
    this.signUpForm.markAllAsTouched();
    this.isSubmitted = true;
    this.errorMessage = undefined;
    this.emailExists = false;

    const timezone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (this.signUpForm.value.phone === '') {
      this.signUpForm.controls.phone.setValue(null);
    }

    if (this.signUpForm.valid) {
      const objectToSend: ISingUp = { ...this.signUpForm.value, timezone };

      this.authenticationActions.requestSingUp({ ...objectToSend });
      this.store.pipe(
        select(selectSingUp),
        filter((singUp: IStoreApiItem<ILoginResponse>) => !singUp.isLoading),
        take(1)
      ).subscribe((authentication: IStoreApiItem<ILoginResponse>) => {
        if (authentication.isSuccess) {
          this.isLoggedIn = true;
          localStorage.setItem(CommonConstants.AUTH_TOKEN, authentication.data.access);
          setTimeout(() => {
            this.onRequestOptions();
          });
        } else {
          if (authentication.errors?.error?.email && authentication.errors?.error?.email[0] === 'This email cannot be used. Please contact the support team.') {
            this.emailExists = true;
          } else {
            this.errorMessage = 'Something went wrong. Please try again later. If this issue persists, contact support@getmeti.com.';
          }
        }
      });
    }
  }

  onRequestOptions() {
    this.authenticationActions.requestUserOptions();
    this.store.pipe(
      select(selectUserOptions),
      filter((login: IStoreApiItem<IOptions>) => !login.isLoading),
      take(1)
    ).subscribe((userOptions: IStoreApiItem<IOptions>) => {
      if (userOptions?.data) {
        this.companyTypes = this.sortKey(userOptions?.data?.organization_types, 'id', 'ctypes');
        this.roleTypes = this.sortKey(userOptions?.data?.user_roles, 'id', 'role');
      }
    });
  }

  sortKey(object: any, property: string, type: string) {
    let design = type == 'ctypes' ? 
      ['Procurement agency', 'Interior designer', 'General contractor', 'Remodeler', 'Other']:
      ['Procurement coordinator', 'Project manager', 'Business owner', 'Admin', 'Accountant'];
    let out: any = [];
    let comp_types = JSON.parse(JSON.stringify(object));
    design.forEach((name: any) => {
      let found = comp_types.find((item: any) => item.value == name);
      out.push({id: found.id, value: name});
    })
    return out;
  }

  onNavigateToSignIn() {
    this.router.navigate(['login']);
  }

  onSubmitCompanyType() {
    this.isSubmittedCompany = true;

    if (this.selectedCompanyType) {
      this.onAddCompany();
    }
  }

  onSubmitRoleType() {
    this.isSubmittedRole = true;
    this.onSkip();

    if (this.selectedRoleType) {
      this.onAddRole();
    }
  }

  onAddCompany() {
    this.authenticationActions.requestCompany({ types: this.selectedCompanyType });
  }

  onAddRole() {
    this.authenticationActions.requestRole({ role: this.selectedRoleType });
  }

  onSkipCompany() {
    this.isSubmittedCompany = true;
  }

  onSkipRole() {
    this.isSubmittedRole = true;
    this.onSkip();
  }

  onSkip() {
    this.router.navigate(['']).then(() => {});
  }

  onSubmitTeamMembers() {
    this.onSkip();
    // to do submit members
  }

  copyLink() {
    if (this.teamForm.controls.invite_link.value) {
      navigator.clipboard.writeText(this.teamForm.controls.invite_link.value);
    }
  }

  onNavigateHome() {
    if (this.permissionService.isLoggedIn()) {
      this.router.navigate(['']);
    } else {
      this.router.navigate(['login'])
    }
  }
}
