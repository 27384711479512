let nodeWidth = '330px';
let largeNodeWidth = '390px';

let node1Customizations: any = [
    {
        number: '1.',
        name: 'Choose how to share order updates with Meti',
        type: 'dropdown2',
        default: 1,
        slug: true,
        top: 68,
        options: [
            {
                name: 'Gmail extension', value: 0, color: '#828282',
                subtext: 'Install the extension for gmail users. The extension lets you automatically add, update and view order information directly from your inbox.',
                coming_soon: true,
            },
            {
                name: 'Forward emails', value: 1,
                subtext: 'Send emails to a unique email address. Anything received will be parsed and added to your account.',
            },

        ]
    },
    {
        number: '2.',
        name: 'Which email app do you use?',
        type: 'dropdown3',
        options: [
            {value: 0, name: 'Other', icon: 'bi-envelope'},
            {value: 1, name: 'Outlook', path: '/assets/img/outlook_logo.svg'},
            {value: 2, name: 'Gmail', path: '/assets/img/gmail_logo.svg'},
        ],
        org_data_value: 'new_order_email_app',
    }
];

let nodeForwardCustomizations: any = [
    {
        number: '1.',
        name: 'How will you be forwarding emails?',
        type: 'dropdown1',
        options: 'how_forward',
        org_data_value: 'order_update_how_forward',
    },
];

let flagIssuesCustomizations: any = [
    {
        number: '1.',
        name: 'Who should be notified of issues?',
        type: 'dropdown1',
        options: 'issue_notifiers',
        org_data_value: 'order_update_issues',
    },
    {
        number: '2.',
        name: 'How should they be notified?',
        main_texts: [
            {
                text: 'Each user can update this in'
            },
            {
                text: 'their preferences',
                underline: true,
                page: true,
                where: 'settings',
                subpage: 'Personal',
            }

        ]
    },
];

let firstWaitCustomization: any = [
    {
        number: '1.',
        name: 'Wait',
        type: 'dropdown1',
        org_data_value: 'order_update_wait_time',
        options: 'flag_issues',
    },
];


export let updateOrderDefaultNodes: any = [
    {
        id: 'node1',
        icon: 'bi-gear',
        icon_color: '#C2DBFF',
        subtext: 'Choose how to share order updates with Meti',
        text_color: '#616161',
        customizations: node1Customizations,
        node_width: largeNodeWidth,
        row: 1, style_left: 270,
    },
    {
        id: 'node2',
        icon: 'bi-envelope-arrow-down',
        icon_color: '#C2DBFF',
        subtext: 'Forward update',
        customizations: nodeForwardCustomizations,
        node_width: nodeWidth,
        row: 2, style_left: 300,
    },
    {
        id: 'node4',
        icon: 'meti',
        diff_icon: 'bi-flag',
        icon_color: '#C2DBFF',
        subtext: 'Flag issues',
        customizations: flagIssuesCustomizations,
        node_width: nodeWidth,
        row: 3, style_left: 520,
    },
    {
        id: 'node5',
        icon: 'bi-clock',
        icon_color: '#C2DBFF',
        subtext: 'Wait 2 days',
        customizations: firstWaitCustomization,
        toggle: 'order_update_send_follow_up_1',
        node_width: nodeWidth,
        row: 4, style_left: 520,
    },
    {
        id: 'node6',
        icon: 'meti',
        diff_icon: 'bi-flag',
        icon_color: '#C2DBFF',
        subtext: 'Flag issues - follow up #1',
        toggle: 'order_update_send_follow_up_1',
        node_width: nodeWidth,
        row: 5, style_left: 520,
    },
    {
        id: 'node3',
        icon: 'meti',
        subtext: 'Order updated + next check in date recalculated',
        node_width: largeNodeWidth,
        row: 6, style_left: 90,
    },
];

export let updateOrderDefaultConnections: any = [
    {from: 'node1', to: 'node2', arrow: 'arrow1', cut: undefined, space: true},
    {from: 'node2', to: 'node3', arrow: 'arrow2', cut: 23, space: false},
    {from: 'node2', to: 'node4', arrow: 'arrow3', cut: 23, space: false},
    {from: 'node4', to: 'node5', arrow: 'arrow4', cut: undefined, space: false},
    {from: 'node5', to: 'node6', arrow: 'arrow5', cut: undefined, space: false},
    {from: 'node5', to: 'node3', arrow: 'arrow8', cut: 23, space: false},
    {from: 'node6', to: 'node3', arrow: 'arrow8', cut: 23, space: false},
]

export let updateOrderDefaultSwitches: any = [
    { id: 'switch1', text: 'No Issues', from: 'node6', to: 'node3', above: 'node3', where: 23 },
    { id: 'switch2', text: 'Error', from: 'node2', to: 'node4', above: 'node4' },
]
