import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { EventEmitter, Output, ChangeDetectorRef, HostListener} from '@angular/core';
import { SelectedViewModel } from '../../models/selected-view.model';
import { Store } from '@ngrx/store';
import { ElementRef } from '@angular/core';
import { IApplicationState } from 'src/app/common/state/models/app.state.model';
import { DashboardActions } from '../../state/actions/dashboard.actions';
import { ActiveJobModel } from '../../models/active-job.model';
import { DashboardJobView } from '../../services/dashboard_job_view.service';
import { OrderService } from '../../services/order.service';
import { JobsService } from '../../services/job.service';
import { DashboardService } from '../../services/dashboard.service';
import { CommonConstants } from 'src/app/common/constants/common.constants';
import { createAllNotifications } from 'src/app/common/utils/general';


@Component({
  selector: 'notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css'],
})
export class NotificationsComponent implements OnInit {
  @Input() selectedView?: SelectedViewModel;
  @Input() dropdownOptions?: any;
  @Input() activeJobsList!: ActiveJobModel[];
  @Input() allMailData: any;
  @Input() sidePanelOpen: boolean = true;
  @Input() currentAlert: any = undefined;
  
  @ViewChild('noteOrder') noteOrder!: ElementRef;
  @ViewChild('noteSupplier') noteSupplier!: ElementRef;

  isLoading: boolean = false;
  skeletonItems: any[] = [];
  selectedId: any = 0;
  notifications: any = [];
  showAll: boolean = true;

  todayDate: any = undefined;

  notifTypeSupplier = 0;
  notifTypeSupplierCred = 1;
  notifTypeJobDone = 2;
  notifTypeInvalidCreds = 3;
  notifTypeItemStatus = 4;
  notifTypeMissingItems = 5;
  notifTypeNewOrder = 6;
  notifTypeNewSupplier = 7;

  currentItem: any = undefined;
  newItem: boolean = false;

  results: any = undefined;

  statusOptions: any = undefined;
  statusColorOptions: any = undefined;

  showSettings: boolean = false;

  noteOptions = [
    {name: 'Mark unread', value: 0,},
    {name: 'Delete', value: 1}
  ];

  constructor(
    private jobService: JobsService,
    private cdr: ChangeDetectorRef,
    private orderService: OrderService,
    private store: Store<IApplicationState>,
    private dashboardService: DashboardService,
    private djv: DashboardJobView,
    private dashboardActions: DashboardActions
  ) {
    (window as any)['note'] = this;
  }
  
  ngOnInit() {
    this.getTodayDateInEST();
    this.generateItems();
    this.pollGetNotifications();
  }

  getTodayDateInEST() {
    const formatter = new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      timeZone: 'America/New_York'
    });
  
    const parts: any = formatter.formatToParts(new Date());
    const year = parts.find((part: any) => part.type === 'year').value;
    const month = parts.find((part: any) => part.type === 'month').value;
    const day = parts.find((part: any) => part.type === 'day').value;
  
    this.todayDate =`${year}-${month}-${day}`;
  }

  generateItems() {
    for (let i = 0; i < 10; i++) {
      const randomWidth = Math.floor(Math.random() * (20 - 5 + 1)) + 5;
      this.skeletonItems.push({ width: `${randomWidth}rem` });
    }
  }

  itemClicked(item: any) {
    if (item.dropdown) return;
    item.seen = true;
    if (item.is_deleted) return;
    if (item?.id != this.currentItem?.id) {
      this.newItem = true;
      this.cdr.detectChanges();
      this.newItem = false;
      this.currentItem = item;
      setTimeout(() => {
        this.setScrollbar('.all-info-container');
      }, 200);
      this.setNotificationCnt();
    }
    this.dashboardService.updateAlert(item.id, {is_seen: true}).subscribe(() => {});
  }

  formatDate(item: any): string {
    if (item.date == 'Today') return item.date;
    let date: any = new Date(item.date);
    date = date.toISOString();
    let cur = date.split('T')[0];
    if (cur == this.todayDate) return 'Today';
    cur = cur.split('-');
    cur = cur.slice(-2).join('/');
    return cur;
  }

  backgroundForItem(item: any) {
    let colorId: any = undefined
    if (item.type == this.notifTypeJobDone) {
      colorId = this.itemStatusType('Delivered');
      colorId = this.itemStatusColor(colorId);
      return colorId || '#5fc64c';
    } else if (item.type == this.notifTypeNewOrder ||
              item.type == this.notifTypeNewSupplier) {
      colorId = this.itemStatusType('Ordered');
      colorId = this.itemStatusColor(colorId);
      return colorId || '#ed7840';
    }
    if (!item.statuses) {
      return 'white';
    }
    if (colorId = this.itemContainsStatus(item, 'Delivered')) {
      colorId = this.itemStatusColor(colorId);
    } else if (colorId = this.itemContainsStatus(item, 'Pending payment')) {
      colorId = this.itemStatusColor(colorId);
    } else if (colorId = this.itemContainsStatus(item, 'Shipped')) {
      colorId = this.itemStatusColor(colorId);
    } else if (colorId = this.itemContainsStatus(item, 'Tracking issues')) {
      colorId = this.itemStatusColor(colorId);
    } else if (colorId = this.itemContainsStatus(item, 'Ready to be scheduled')) {
      colorId = this.itemStatusColor(colorId);
    }
    return colorId ? colorId: 'white';
  }

  borderForItem(item: any) {
    let colorId: any = undefined
    if (item.type == this.notifTypeJobDone ||
      item.type == this.notifTypeNewOrder ||
      item.type == this.notifTypeNewSupplier) {  
        return '';
    }
    if (!item.statuses) {
      return '1px solid rgba(0, 0, 255, 0.5)';
    }
    if (colorId = this.itemContainsStatus(item, 'Delivered')) {
      return '';
    } else if (colorId = this.itemContainsStatus(item, 'Pending payment')) {
      return '';
    } else if (colorId = this.itemContainsStatus(item, 'Shipped')) {
      return '';
    } else if (colorId = this.itemContainsStatus(item, 'Tracking issues')) {
      return '';
    } else if (colorId = this.itemContainsStatus(item, 'Ready to be scheduled')) {
      return '';
    }
    return '1px solid rgba(0, 0, 255, 0.5)';
  }

  cardOpenHeight: number = 190;
  cardIsOpenChange(event: any) {
    setTimeout(() => {
      this.cardOpenHeight = this.noteOrder.nativeElement.clientHeight;
    }, 100);
  }
  getMaxHeight() {
    // note-display-text + note-supplier-text * 2 + single-card + supplier-creds
    return window.innerHeight - (60 * 3 + this.cardOpenHeight + 170 + 95);
  }

  setScrollbar(v: string) {
    let jdisplay = document.querySelector(v);
    if (jdisplay) {
      jdisplay.addEventListener('mouseenter', function() {
        (jdisplay as any).classList.add('custom-scrollbar');
        (jdisplay as any).classList.remove('custom-scrollbar-close');
      });
      jdisplay.addEventListener('mouseleave', function() {
        (jdisplay as any).classList.remove('custom-scrollbar');
        (jdisplay as any).classList.add('custom-scrollbar-close');
      });
    }
  }

  itemContainsStatus(item: any, type: string) {
    let foundId = this.itemStatusType(type);
    return item.statuses.find((s: any) => s == foundId);
  }

  itemStatusType(type: string) {
    let which = this.statusOptions.find((s: any) => s.value == type);
    return which ? which.id: undefined;
  }

  itemStatusColor(type: number) {
    let which = this.statusColorOptions.find((s: any) => s.id == type);
    return which ? which.value: '';
  }

  sortDates(obj1: any, obj2: any) {
    if (obj1.date === 'Today') return -1;
    if (obj2.date === 'Today') return 1;
  
    const date1: any = new Date(obj1.date);
    const date2: any = new Date(obj2.date);
    return date2 - date1;
  }

  createNotifications(results: any) {
    let output: any = createAllNotifications(results, this.dropdownOptions, this.allMailData);
    output.sort(this.sortDates);
    this.notifications = output;

    let found = false;
    if (this.currentAlert) {
      let foundNote = this.notifications?.find((n: any) => n.id == this.currentAlert);
      if (foundNote) {
        this.currentItem = foundNote;
        found = true;
      }
    }
    if (!found) {
      this.currentItem = this.notifications?.length ? this.notifications[0] : undefined;
    }
    setTimeout(() => {
      this.setScrollbar('.notifications-list-short');
    }, 200);

  }

  get dropdownRetrieved() {
    return this.dropdownOptions.document_types  &&
    this.dropdownOptions.job_id  &&
    this.dropdownOptions.status  &&
    this.dropdownOptions.status_colors  &&
    this.dropdownOptions.supplier_id  &&
    this.dropdownOptions.unit_of_measure &&
    this.allMailData?.curOrders &&
    this.allMailData?.supArray;
  }

  orderDeleted() {
    this.currentItem = undefined;
    this.getNotifications();
    this.dashboardActions.requestUncategorizedView(
      { include_items: CommonConstants.include_items.uncategorized });
  }

  setNotificationCnt() {
    let cnt = this.notifications.filter((n: any) => !n.seen).length;
    this.djv.do_set_notification_cnt(cnt);
    this.onSidePanelChange();
  }

  onSidePanelChange() {
    setTimeout(() => {
      this.djv.do_side_panel_notifs();
    }, 1000);
  }

  deleteAlert(alert: any) {
    if (alert.id == this.currentItem.id) {
      this.currentItem = undefined;
    }
    this.notifications = this.notifications.filter((n: any) => n.id != alert.id);
    if (this.notifications.length) {
      this.currentItem = this.notifications[0];
    }
    this.dashboardService.updateAlert(alert.id, {is_ignored: true}).subscribe(() => {});
    this.setNotificationCnt();
   }

  pollGetNotifications() {
    let outer = this;
    this.isLoading = true;
    var pollInterval = setInterval(function() {
      if (outer.dropdownRetrieved) {
        clearInterval(pollInterval);
        outer.getNotifications();
      }
    }, 20);
    
  }

  getNotifications() {
    this.dashboardService.getNotifications().subscribe((result: any) => {
      this.isLoading = false;
      this.results = result;
      this.createNotifications(result || []);
    })
  }

  get notificationBanner() {
    let defaultName = 'Notifications';
    if (this.isLoading) return defaultName
    return this.notifications?.length ? defaultName : 'No notifications!';
  }

  get cardViewWidth() {
    let base = '100vw - 300px - 50px';
    let extra = this.sidePanelOpen ? '- 300px' : '- 70px';
    return `calc(${base} ${extra})`
  }

  goToSettingsPage() {
    this.djv.do_home_page_clicked('personal');
  }

  get unreadNotifications() {
    let count = this.unreadNotificationsCount;
    return `Unread (${count})`;
  }

  get filteredNotes() {
    let notes = this.notifications.filter((n: any) => n.id == this.currentItem.id || !n.is_deleted)
    if (this.showAll) {
      return notes;
    } else {
      return notes.filter((n: any) => !n.seen);
    }
  }

  showNotifications(how: any) {
    this.showAll = how;
  }

  setAllAsSeen() {
    this.notifications.forEach((n: any) => {
      n.seen = true;
    });
    this.dashboardService.updateAlertAllSeen().subscribe();
  }

  get unreadNotificationsCount() {
    return this.notifications.filter((n: any) => !n.seen).length;
  }

  showItemDropdown(event: any, item: any) {
    if (!item.dropdown) {
      item.dropdown = true;
      setTimeout(() => {
        item.dropdown = true;
      }, 20);
    } else {
      setTimeout(() => {
        item.dropdown = false;
      }, 20);
    }

    this.notifications.forEach((n: any) => {
      if (n != item) {
        n.dropdown = undefined;
      }
    });
  }

  @HostListener('document:click', ['$event'])
  clickOutside(event: any) {
    this.notifications.forEach((n: any) => n.dropdown = undefined);
    this.showSettings = false;
  }

  onShowSettingsGear() {
    if (this.showSettings) {
      this.showSettings = false;
    } else {
      setTimeout(() => {
        this.showSettings = true;
      }, 0);
    }
  }

  onChooseNoteOption(event: any, item: any, which: any) {
    if (which.value == 0) {
      this.dashboardService.updateAlert(item.id, {is_seen: false}).subscribe(() => {});
      item.seen = false;
    } else {
      this.deleteAlert(item);
    }
    setTimeout(() => {
      item.dropdown = undefined;
    }, 10);
  }

  onInfoChange(event: any, item: any) {
      if (event) {
        let field = event.field;
        let value = event.type;
        if (value[field]) {
          item.is_deleted = true;
          item.seen = true;
          let payload = {id: item.id}
          this.dashboardService.deleteAlert(payload).subscribe(() => {});
          this.djv.do_side_panel_notifs();
        }
        this.onSidePanelChange();
      }
  }
}