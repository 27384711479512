let smallNodeWidth = '200px';
let nodeWidth = '330px';
let largeNodeWidth = '420px';

let lowGap = 15;
let gap = 22;
let highGap = 34;

let changeSupplierCheckinDay: any = [
    {
        number: '1.',
        name: 'When should Meti checkin?',
        main_texts: [
            {
                text: 'Head to the'
            },
            {
                text: 'Jobs page',
                underline: true,
                where: 'job',
                page: true,
            },
            {
                text: 'for job-by-job control of the checkin date.',
            }
        ]
    },
];

let isSupplierEmailOn: any = [
    {
        number: '1.',
        name: 'How Meti should check in with your Suppliers?',
        main_texts: [
            {
                text: 'Head to the'
            },
            {
                text: 'Supplier page',
                underline: true,
                where: 'supplier',
                page: true,
            },
            {
                text: 'for supplier-by-supplier control.',
            }
        ]
    },
    {
        number: '2.',
        name: 'When a new supplier is added, what should the default tracking method be?',
        type: 'dropdown1',
        org_data_value: 'supplier_default_tracking',
        options: 'tracking_choices',
    }
];

let flagIssueCustoms: any = [
    {
        number: '1.',
        name: 'Who should be notified of issues?',
        type: 'dropdown1',
        org_data_value: 'supplier_draft_flag_issue',
        options: 'issue_notifiers',
    },
    {
        number: '2.',
        name: 'How should they be notified?',
        main_texts: [
            {
                text: 'Each user can update this in'
            },
            {
                text: 'their preferences',
                underline: true,
                page: true,
                where: 'settings',
                subpage: 'Personal',
            },
        ]
    },
];

let copyWhichUsers: any = [
    {
        number: '1.',
        name: 'Which users should be copied?',
        type: 'dropdown1',
        org_data_value: 'supplier_draft_include_users',
        options: 'issue_notifiers',
    }
];

export let supplierDraftConnections: any = [
    {from: 'node1', to: 'node3',  cut: undefined, space: true},
    {from: 'node2', to: 'node3',  cut: undefined, space: true},
    {from: 'node3', to: 'node4',  cut: gap, space: false},
    {from: 'node3', to: 'node9',  cut: gap, space: false},
    {from: 'node4', to: 'node5',  cut: gap, space: false},
    {from: 'node4', to: 'node6',  cut: gap, space: false},
    {from: 'node6', to: 'node7',  cut: lowGap, space: false},
    {from: 'node6', to: 'node8',  cut: lowGap, space: false},
    {from: 'node6', to: 'node10',  cut: lowGap, space: false},
    {from: 'node6', to: 'node11',  cut: lowGap, space: false},
    {from: 'node11', to: 'node12',  cut: gap, space: false},
    {from: 'node10', to: 'node12',  cut: gap, space: false},
    {from: 'node12', to: 'node13',  cut: gap, space: false},

]

export let supplierDraftSwitches: any = [
    { id: 'switch1', text: 'Yes', from: 'node3', to: 'node4', above: 'node4', where: gap },
    { id: 'switch1', text: 'No', from: 'node3', to: 'node9', above: 'node9', where: gap },
    { id: 'switch1', text: 'Yes', from: 'node4', to: 'node6', above: 'node6', where: gap },
    { id: 'switch1', text: 'No', from: 'node4', to: 'node5', above: 'node5', where: gap },
    { id: 'switch1', text: 'Error', from: 'node6', to: 'node7', above: 'node7', where: highGap },
    { id: 'switch1', text: 'Clear', from: 'node6', to: 'node8', above: 'node8', where: highGap },
    { id: 'switch1', text: 'Unclear', from: 'node6', to: 'node10', above: 'node10', where: highGap },
    { id: 'switch1', text: 'Request', from: 'node6', to: 'node11', above: 'node11', where: highGap },

]

export let supplierDraftNodes: any = [
    {
        id: 'node1',
        icon: 'bi-calendar',
        icon_color: '#C2DBFF',
        subtext: 'Next supplier checkin date in is today',
        customizations: changeSupplierCheckinDay,
        node_width: '300px',
        style_left: 60, row: 1,
    },
    {
        id: 'node2',
        icon: 'bi-box-arrow-down-left',
        icon_color: '#C2DBFF',
        subtext: 'Can’t track order online',
        sidebar_main_text: 'Can’t tack order online - via task ‘Auto track order links - supplier’',
        node_width: '240px',
        style_left: 410, row: 1,
    },
    {
        id: 'node3',
        icon: 'bi-funnel',
        icon_color: '#C2DBFF',
        subtext: 'Is supplier email turned on?',
        customizations: isSupplierEmailOn,
        node_width: '240px',
        style_left: 250, row: 2,
    },
    {
        id: 'node4',
        icon: 'bi-funnel',
        icon_color: '#C2DBFF',
        subtext: 'Do we have supplier email?',
        node_width: '240px',
        style_left: 320, row: 3,
    },
    {
        id: 'node9',
        icon: 'meti',
        subtext: 'End',
        node_width: smallNodeWidth,
        style_left: 60, row: 3,
    },
    {
        id: 'node5',
        icon: 'bi-flag',
        icon_color: '#C2DBFF',
        subtext: 'Flag issue',
        customizations: flagIssueCustoms,
        node_width: '130px',
        style_left: 190, row: 4,
    },
    {
        id: 'node6',
        icon: 'meti',
        subtext: 'Email supplier',
        customizations: copyWhichUsers,
        node_width: '160px',
        style_left: 420, row: 4,
    },
    {
        id: 'node7',
        icon: 'bi-flag',
        icon_color: '#C2DBFF',
        subtext: 'Flag issue',
        customizations: flagIssueCustoms,
        node_width: '130px',
        style_left: 60, row: 5,
    },
    {
        id: 'node8',
        icon: 'meti',
        subtext: 'Parse the data and update order details in Meti ',
        node_width: '220px',
        style_left: 220, row: 5,
    },
    {
        id: 'node10',
        icon: 'meti',
        subtext: 'Request clarification',
        node_width: '180px',
        style_left: 450, row: 5,
    },
    {
        id: 'node11',
        icon: 'meti',
        subtext: 'Await requested information',
        node_width: '220px',
        style_left: 650, row: 5,
    },
    {
        id: 'node12',
        icon: 'bi-clock',
        icon_color: '#C2DBFF',
        subtext: 'Wait 2 days',
        node_width: '200px',
        toggle: 'supplier_draft_follow_up',
        row: 6, style_left: 550,
    },
    {
        id: 'node13',
        icon: 'meti',
        subtext: 'Send follow up request',
        toggle: 'supplier_draft_follow_up',
        node_width: '200px',
        row: 7, style_left: 550,
    },
];