import { Component } from '@angular/core';
import { INoRowsOverlayAngularComp } from 'ag-grid-angular';
import { INoRowsOverlayParams } from 'ag-grid-community';
import { DashboardJobView } from '../../services/dashboard_job_view.service';

@Component({
  selector: 'app-no-rows-overlay',
  templateUrl: "./custom-no-rows-overlay.component.html",
  styleUrls: ["./custom-no-rows-overlay.css"],
})
export class CustomNoRowsOverlay implements INoRowsOverlayAngularComp {
  public params!: INoRowsOverlayParams & { appliedFilters: () => boolean };
  slug: any = '';
  agInit(params: INoRowsOverlayParams & { appliedFilters: () => boolean }): void {
    let slug = (params as any).getOrgSlug();
    this.slug = slug ? `orders@${slug}.getmeti.com` : 'orders@getmeti.com';
    this.params = params;
  }

  constructor(
    private djv: DashboardJobView
  ) {

  }
  
  get slugHref() {
    return `mailto:${this.slug}`;
  }

  addOrderClicked() {
    this.djv.do_home_page_clicked('extract');
  }
}
