<div class="materlog-text-container">
    <input *ngIf="!vgrowth" #inputElement
      class="materlog-ship-input materlog-ship-input-default" type="text"
      [ngClass]="{
        'materlog-ship-input-active': isEditing,
        'materlog-ship-active': active,
        'materlog-ship-inactive': !active,
      }"
      [ngStyle]="{
        'width': getMaxWidth,
        'font-weight': !isEditing && enbold ? 'bold': 'inherit',
      }"
      [(ngModel)]="value" [placeholder]="placeholder"
      (focus)="onFocus($event)" (blur)="onBlur($event)"
      [maxlength]="maxInput"
      (keydown)="onKeydown($event)"/>
    <textarea *ngIf="vgrowth"
        class="materlog-ship-input materlog-ship-input-growth custom-scrollbar-close"
        [ngClass]="{
          'materlog-ship-textarea-active': isEditing,
          'materlog-ship-inactive': !active,
        }"
        [ngStyle]="{
          'width': getMaxWidth,
          'min-height': useHeight,
          'height': useHeight,
          'font-weight': !isEditing && enbold ? 'bold': 'inherit',
        }"
        [(ngModel)]="value" [placeholder]="placeholder"
        (focus)="onFocus($event)" (blur)="onBlur($event)"
        (keydown)="onKeydown($event)"
    >
    </textarea>
    <div class="materlog-text-error" *ngIf="showError">
      {{errorString}}
    </div>
</div>