import { ChangeDetectorRef, Component, OnInit, ViewChild, Input } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { ElementRef, HostListener } from '@angular/core';


@Component({
  selector: 'flow-node',
  templateUrl: './flow-node.component.html',
  styleUrls: [
    './flow-node.component.css',
]
})
export class MetiFlowNode implements OnInit {
    @Input() data: any = {};
    @Input() sidebar: boolean = false;
    @Input() selected: any = undefined;
    @Output() gotoPageClick: EventEmitter<any> = new EventEmitter();

    constructor(
    ) {
    }

    ngOnInit(): void {
    (window as any)['node'] = this;
    }

    get textColor() {
        if (this.sidebar) {
            return this.data.sidebar_text_color || '#000';
        } else {
            return this.data.text_color || '#000';
        }
    }

    get mainText() {
        if (this.sidebar) {
            return this.data.sidebar_main_text || this.data.subtext;
        } else {
            return this.data.subtext
        }
    }

    get preText() {
        if (this.sidebar) {
            return this.data.sidebar_pre_text;
        } else {
            return this.data.pre_text
        }
    }

    get postText() {
        if (this.sidebar) {
            return this.data.sidebar_post_text;
        } else {
            return this.data.post_text
        }
    }

    goToPage(where: any) {
        this.gotoPageClick.emit(where)
    }
}

