<div class="materlog-status-view">
    <div class="materlog-status-single" *ngFor="let opt of curStatuses">
      <div class="materlog-status-color-blk"
      [ngStyle]="{'background-color': getStatusColor(opt)}"
      >
      </div>
      <materlog-dropdown-component
        class="materlog-field-type"
        [options]="options"
        [selected]="opt.id"
        [enbold]="enbold"
        [valueField]="valueField"
        [maxWidth]="maxWidth"
        [inputText]="'Search...'"
        (onChange)="onDropdownChange($event, opt)"
        [active]="active"
      >
      </materlog-dropdown-component>
    </div>
</div>