import { ChangeDetectorRef, Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Input, Output, EventEmitter } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {debounceTime, distinctUntilChanged, filter, Subject, take, takeUntil, tap} from 'rxjs';

import { TableConstants } from 'src/app/common/constants/table.constants';
import { CommonConstants } from 'src/app/common/constants/common.constants';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { IStoreApiList } from 'src/app/common/models/store-api-list.model';
import { IApplicationState } from 'src/app/common/state/models/app.state.model';
import { SupplierCredentialsActions } from '../../state/actions/suppliers-credentials.actions';
import {  selectSupplierCredentialsOptions,selectSupplierCredentialsPage } from '../../state/selectors/suppliers-credentials-page.selector';
import { IOptions } from 'src/app/authentication/models/authentication.model';
import { IStoreApiItem } from 'src/app/common/models/store-api-item.model';
import {switchMap} from "rxjs/operators";
import {selectSuppliers} from "../../../dashboard/state/selectors/dashboard.selector";
import {ISuppliers} from "../../../dashboard/models/dashboard.model";
import {DashboardActions} from "../../../dashboard/state/actions/dashboard.actions";
import { ConnectedOverlayScrollHandler } from 'primeng/dom';
import { SupplierCredentialsService } from '../../services/suppliers.credentials.service';
import { deepCopy } from 'src/app/common/utils/general';
import { selectUserProfile } from 'src/app/user-settings/state/selectors/user.selector';
import { SuppliersService } from '../../services/suppliers.service';
import { SupplierService } from 'src/app/dashboard/services/supplier.service';
import { createUrlTreeFromSnapshot } from '@angular/router';
import { openStatuses } from 'src/app/common/utils/general';

@Component({
  selector: 'materlog-suppliers-credentials-page',
  templateUrl: './suppliers-credentials-page-new.component.html',
  styleUrls: ['./suppliers-credentials-page-new.component.css']
})
export class SupplierCredentialsPageComponent extends OnDestroyMixin() implements OnInit {
  @Input() notificationsView: boolean = false;
  @Input() panelOpen: boolean = true;
  @Input() supplierId: any = undefined;

  @Input() dashView: boolean = false;
  @Input() allMailData: any;
  @Input() maxWidth: string = '';
  @Input() supplierNeedsEmail: boolean = false;

  @Output() supplierChange = new EventEmitter<any>();
  @Output() onChange = new EventEmitter<any>();

  isLoading = true;
  org_slug: any = undefined;
  skeletonItems: any[] = [];
  allSuppliers: any = [];
  filteredSuppliers: any = [];
  @ViewChild('credentialContainer') credentialContainer!: ElementRef;
  searchText: any = '';
  hiddenPassword = '********';
  defaultCredChoices: any = undefined;
  defaultShareChoices: any = undefined;
  ogDefaultCredChoices: any = undefined;
  allMailDataObtained: boolean = false;

  credentials: any = undefined;
  searchTerm: any = '';
  searchUpdate = new Subject<string>();
  normalized_credentials: any[] = [];
  clean_normalized_credentials: any[] = [];
  nextPage?: number | null;
  columnsSupplierCredentials = [
    'SUPPLIER', 'BASICS', 'CONTACTS', 'ACCOUNT #', 'TRACKING',
  ];
  allSupplierCheckbox: boolean = false;
  temporarySupplierList: any = undefined;
  customColumnsLayout = [
    'index',
    'supplier'
  ];
  readonlyColumnsLayout = [
    'updated_datetime',
    'created_datetime'
  ];
  dropdownOptions: any = undefined;
  private resultsPerPage: number = CommonConstants.resultsPerPage;

  constructor(
    private dashboardActions: DashboardActions,
    private store: Store<IApplicationState>,
    private supplierCredentialsActions: SupplierCredentialsActions,
    private supplierCredentialsService: SupplierCredentialsService,
    private supplierService: SupplierService,
    private cdr: ChangeDetectorRef,
  ) {
    super();
    (window as any)['supp'] = this;
  }

  ngOnInit(): void {
    this.generateItems();
    // this.getSlug();
    this.onRequestSupplierCredentialsPage();
  }

  ngOnChanges(changes: any) {
    if (changes.allMailData && !this.allMailDataObtained && this.isAllMailDataObtained) {
      if (this.filterMySuppliers && !this.notificationsView) {
        this.allMailDataObtained = true;
        this.filteredSuppliers = this.allSuppliers;
        this.doFilterMySuppliers();
      }
    }
  }

  get isAllMailDataObtained() {
    return this.allMailData?.curOrders && this.allMailData?.activeJobsList;
  }

  get allDataRetrieved() {
    return this.dropdownOptions && this.credentials
  }

  generateItems() {
    for (let i = 0; i < 12; i++) {
      const randomWidth = Math.floor(Math.random() * 50) + 25;
      this.skeletonItems.push({ width: `${randomWidth}rem` });
    }
  }

  ngAfterViewInit() {
    this.setScrollBar('.size-change');
    this.setScrollBar('.credentials-info');
  }

  setMaterLogTracking(supplier: any, is_error: boolean = false) {
    /*
  
      class TrackOrderOnlineChoices(models.IntegerChoices):
          YES_NO_LOGIN_REQUIRED = 0, "Yes, No login Req'd"
          YES_LOGIN_REQUIRED = 1, "Yes, Login req'd"
          UNSURE = 2, "Unsure, Log in on site, not sure if tracking available"
          NO = 3, "No"
          NO_TRACKING_FEATURE = 4, "No (account available but not tracking feature)"
          NOT_SELECTED = 5, "Not Selected"
          UNTRACKABLE = 6, "Untrackable"

      class CredentialTrackChoices(models.IntegerChoices): 
          NO = 0, "Tracking off"
          YES = 1, "Default tracking"
          YES_ONLINE_ONLY = 2, "Online only"
          EMAIL_ONLY = 3, "Email only"

    */
    let credential = supplier.credentials;
    let contacts = supplier.contacts;
    let track = credential.track_supplier;
    let supp_track = supplier.track_order_online;
    let isOnline = ['Wayfair', 'Amazon'].includes(supplier.name);
    if (track == 1 || track == 2 || track == 3) { 
      if (supp_track == 6) {
        return is_error ? false: 'Cannot track';
      }
      if (track != 3 && supp_track == 0) {
        return is_error ? false: 'Via supplier website';
      }
      if ((track == 1 || track == 2) && supp_track == 1 && isOnline) {
        if (credential.username && credential.password && !credential.invalid_creds) {
          return is_error ? false: 'Via supplier website ';
        }
      }
      if (track == 2) {
        if (supp_track == 2 || supp_track == 5) {
          return is_error ? false: 'Pending';
        }        
        return is_error ? false: 'Not tracked';
      }

      if (supplier.vendor_not_responsive) {
        return is_error ? false:  "Can't track - supplier unresponsive to email";
      } else if (credential.email || supplier.email || contacts.find((c: any) => c.email)) {
        return is_error ? false:  'Via email';
      } else {
        return is_error ? true: 'Email needed';
      }

    }
    return is_error ? false: 'Not tracked';
  }

  setScrollBar(v: string) {
    setTimeout(() => {
      let jdisplays = this.credentialContainer.nativeElement.querySelectorAll(v);
      if (jdisplays) {
        jdisplays.forEach((jdisplay: any) => {
          jdisplay.addEventListener('mouseenter', function() {
            (jdisplay as any).classList.add('custom-scrollbar');
            (jdisplay as any).classList.remove('custom-scrollbar-close');
          });
          jdisplay.addEventListener('mouseleave', function() {
            (jdisplay as any).classList.remove('custom-scrollbar');
            (jdisplay as any).classList.add('custom-scrollbar-close');
          });
        })
      }
    }, 500);
  }

  createNewSupplier(supplier: any, contacts: any, credentials: any) {
    let supplierId = supplier.id;
    let screds = credentials.find((c: any) => c.supplier == supplierId);
    let cur: any = {...supplier};
    cur.checkbox = false;
    cur.focus_password = false;
    cur.credentials = screds ? {...screds}: {...this.blank_cred};
    cur.credentials.supplier = supplier.id;
    cur.credentials.actualPassword = cur.credentials.password;
    cur.credentials.password = cur.credentials.password ? this.hiddenPassword : '';
    cur.credentials.organization = this.current_org;
    cur.credentials.website = cur.credentials.website != null ? cur.credentials.website : supplier.website;
    cur.credentials.email = cur.credentials.email != null ? cur.credentials.email : supplier.email;
    cur.credentials.phone = cur.credentials.phone != null ? cur.credentials.phone : supplier.phone;    
    cur.tracking_hostname = this.makeUrl(supplier.webtrack_link);
    cur.addingContact = false;

    let sconts = contacts.filter((c: any) => c.supplier == supplierId);
    sconts = sconts.map((sc: any) => ({...sc}));
    let seenEmails = new Set();
    let uniqueContacts = sconts.filter((contact: any) => {
      const duplicate = seenEmails.has(contact.email);
      seenEmails.add(contact.email);
      return !duplicate;
    });
    cur.contacts = uniqueContacts.map((sc: any) => ({ ...sc }));
    return cur;
  }

  emailMismatch(supplier: any, contact: any) {
    let sc_email = supplier.credentials.email;
    if (!sc_email || !contact.email) return false;
    return sc_email.split('@')[1] != contact.email.split('@')[1];

  }

  sortSuppliers(values: any) {
    values.sort((a: any, b: any) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    });
  }

  blank_cred: any = undefined;
  current_org: any = undefined;
  createCredentialTable(results: any) {
    this.dropdownOptions = {...results.options};
    let allCredChoices = [...this.dropdownOptions.credChoices];
    this.ogDefaultCredChoices = {id: 4, value: '--'};
    allCredChoices.unshift(this.ogDefaultCredChoices);
    this.dropdownOptions.allCredChoices = allCredChoices;
    this.dropdownOptions.allShareChoices = [
      this.ogDefaultCredChoices,
      {id: 0, value: 'Yes'},
      {id: 1, value: 'No'}
    ]
    this.defaultCredChoices = this.ogDefaultCredChoices;
    this.defaultShareChoices = this.ogDefaultCredChoices;

    let suppliers = results.suppliers;
    let credentials = results.credentials || [];
    let contacts = results.contacts || [];
    this.blank_cred = results.blank;
    this.current_org = results.organization;
    let values: any = [];
    suppliers.forEach((s: any) => {
      let cur = this.createNewSupplier(s, contacts, credentials);
      values.push(cur);
    });
    this.sortSuppliers(values);
    this.setScrollBar('.single-supplier-container');
    this.setScrollBar('.supplier-contact-container');
    this.allSuppliers = values;
    this.filteredSuppliers = this.allSuppliers;
    if (this.filterMySuppliers && !this.notificationsView && this.allMailData) {
      this.doFilterMySuppliers();
    }
  }

  onRequestSupplierCredentialsPage() {
    let payload = this.notificationsView ? {supplier_id: this.supplierId}: undefined;
    this.supplierCredentialsActions.requestSupplierCredentialsPage(payload);
    this.isLoading = true;
    this.store
      .pipe(
        select(selectSupplierCredentialsPage),
        filter((supplierCredentialsPage: IStoreApiList<any>) => !supplierCredentialsPage.isLoading),
        takeUntil(this.destroy)
      )
      .subscribe((supplierCredentialsPage: IStoreApiList<any>) => {
        this.isLoading = false;
        this.credentials = supplierCredentialsPage;
        if (supplierCredentialsPage?.data) {
          this.createCredentialTable(supplierCredentialsPage.data);
        }
      });
  }

  private getSlug(): void {
    this.store
      .pipe(
        select(selectUserProfile),
      )
      .subscribe((userProfile: any) => {
        if (userProfile?.data?.all_users && userProfile.data.all_users.length) {
          this.org_slug = userProfile.data.org_slug || 'orders';
        }
      });
  }

  showCredTooltip: boolean = false;
  allShareCreds: boolean = false;
  allSuppliersClicked() {
    this.filteredSuppliers.forEach((s: any) => s.checkbox = this.allSupplierCheckbox);
    this.showCredTooltip = this.allSupplierCheckbox;
    if (!this.showCredTooltip) {
      this.closeTooltip();
    }
  }

  singleSupplierClicked(supplier: any) {
    this.showCredTooltip = this.filteredSuppliers.find((s: any) => s.checkbox) ? true: false;
    if (!this.showCredTooltip) {
      this.closeTooltip();
    }
  }

  closeTooltip() {
    this.showCredTooltip = false;
    this.allSupplierCheckbox = false;
    this.filteredSuppliers.forEach((s: any) => s.checkbox = false);
    this.defaultCredChoices = this.ogDefaultCredChoices;
    this.defaultShareChoices = this.ogDefaultCredChoices;
    this.allShareCreds = false;
  }


  applyAllSelect() {
    let values: any = [];
    let doApply = this.defaultCredChoices.value != '--' || this.defaultShareChoices.value != '--';
    this.filteredSuppliers.forEach((s: any) => {
      if (s.checkbox) {
        if (this.defaultCredChoices.value != '--') {
          s.credentials.track_supplier = this.defaultCredChoices.id;
        } 
        if (this.defaultShareChoices.value != '--') {
          s.credentials.no_share_creds = this.defaultShareChoices.id == 0 ? true: false;
        }
        values.push(s.credentials);
      }
    });
    this.closeTooltip();
    this.defaultCredChoices = this.ogDefaultCredChoices;
    this.defaultShareChoices = this.ogDefaultCredChoices;
    if (doApply) {
      this.updateMassCredentials(values);
    }
  }

  allShareCredsClicked(event: any) {
    this.defaultShareChoices = event.event.value;
  }

  allTrackCreds(event: any) {
    this.defaultCredChoices = event.event.value;
  }

  updateMassCredentials(inputs: any) {
    let values: any = [];
    inputs.forEach((sc: any) => {
      let cur: any = {};
      cur.id = sc.id ? sc.id: undefined;
      cur.supplier = sc.supplier;
      cur.organization = sc.organization;
      cur.track_supplier = sc.track_supplier;
      cur.no_share_creds = sc.no_share_creds;
      values.push(cur);
    });
    this.supplierCredentialsService.updateSupplierCredentialsMass({values: values}).subscribe((result: any) => {
      inputs.forEach((i: any) => {
        if (!i.id) {
          let curSup = this.allSuppliers.find((s: any) => s.id == i.supplier);
          let found = result.find((r: any) => r.supplier == i.supplier);
          if (found) {
            curSup.credentials.id = found.id;
          }
        }
      })
    })
  }

  onSearchChange(with_filter: boolean = true) {
    let search = this.searchText.toLowerCase();
    if (!search) {
      this.filteredSuppliers = this.allSuppliers;
      if (with_filter && this.filterMySuppliers) {
        this.doFilterMySuppliers();
      }
      return;
    }

    let cur: any = [];
    this.allSuppliers.forEach((s: any) => {
      let text = '';
      text += s.name? s.name: '';
      text += s.website ? s.website : '';
      s.contacts.forEach((c: any) => {
        text += c.email ? c.email : '';
        text += c.first_name ? c.first_name: '';
        text += c.last_name ? c.last_name: '';
        text += c.location ? c.location: '';
        text += c.phone ? c.phone: '';
      });
      let cred = s.credentials;
      text += cred.account ? cred.account: '';
      text += cred.username ? cred.username: '';
      text += cred.value ? cred.value: '';
      text = text.toLowerCase();
      if (text.includes(search)) cur.push(s);
    });

    this.filteredSuppliers = cur;
    if (with_filter && this.filterMySuppliers) {
      this.doFilterMySuppliers();
    }

  }

  addingSupplierContact: boolean = false;
  addingSupplier: boolean = false;
  suppierContactSupplier: any = undefined;
  addSupplierContact(event: any, supplier: any) {
    this.suppierContactSupplier = supplier.id;
    this.addingSupplierContact = true;
  }

  addSupplier() {
    this.addingSupplier = true;
  }

  closeSupplierModal() {
    this.addingSupplierContact = false;
    this.addingSupplier = false;
  }

  supplierContactFormChangeEvent(event: any) {
    this.addingSupplierContact = false;
    this.suppierContactSupplier = undefined;
    if (event.close) return;
    let curSup = this.allSuppliers.find((s: any) => s.id == event.supplier);
    if (curSup) {
      curSup.contacts.push({...event});
    }
  }

  supplierFormChangeEvent(event: any) {
    this.addingSupplier = false;
    if (event.close) return;
    let sid = event.id;
    if (this.allSuppliers.find((s: any) => s.id == sid)) return;
    let newSup = this.createNewSupplier(event, [], []);
    this.allSuppliers.push(newSup);
    this.sortSuppliers(this.allSuppliers);
    this.setScrollBar('.single-supplier-container');
    this.setScrollBar('.supplier-contact-container');
    this.onSearchChange();
  }

  startEditingSupplierContact(event: any, supplier: any, contact: any, field: string) {
  }

  deleteSupplierContact(supplier: any, contact: any) {
    supplier.contacts = supplier.contacts.filter((c: any) => c.id != contact.id);
    this.supplierService.deleteSupplierContact({id: contact.id}).subscribe(() => {});
  }

  setErrorOnValue(value: any, errString: any) {
    value.error = errString;
    setTimeout(() => {
      value.error = undefined;
    }, 1300);
  }

  stopEditingSupplierContact(event: any, supplier: any, contact: any, field: string) {
    let data = {...contact};
    let cid = data.id;
    delete data.id;
    let name = data.name || '';
    let [first, ...rest] = name.trim().split(' ');
    let last = rest.join(' ');
    delete data.name;
    data.first_name = first;
    data.last_name = last;
    let payload = {payload: data, id: cid};
    if (!data.first_name || !data.email) {
      this.setErrorOnValue(contact, 'Name and Email cannot be blank')
      return;
    }
    if (!this.isValidEmail(data.email)) {
      this.setErrorOnValue(contact, 'Invalid email');
      return;
    }
    this.supplierService.editSupplierContact(payload).subscribe((result: any) => {
    })
  }

  isValidEmail(email: string): boolean {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  startEditingCredential(event: any, supplier: any, field: string) {
    if (field == 'password') {
      supplier.credentials.password = supplier.credentials.actualPassword;
    }
  }

  stopEditingCredential(event: any, supplier: any, field: string) {
    this.onChange.emit({type: supplier.credentials, field: field});
    if (field == 'password') {
      supplier.credentials.actualPassword = supplier.credentials.password;
      supplier.credentials.password = supplier.credentials.password ? this.hiddenPassword: '';
    }
    if (field == 'email' && supplier.credentials.email) {
      if (!this.isValidEmail(supplier.credentials.email)) {
        supplier.credentials.email = '';
        this.setErrorOnValue(supplier.credentials, 'Invalid email');
        return;
      }
    }
    this.updateSupplierCredential(supplier);
  }

  updateSupplierCredential(supplier: any) {
    let payload = {...supplier.credentials};
    payload.password = payload.actualPassword;
    delete payload.actualPassword;
    let sc_id = payload.id;
    delete payload.id;
    if (sc_id) {
      let data = {payload: payload, id: sc_id};
      this.supplierCredentialsService.updateSupplierCredentials(data).subscribe((r: any) => {
      })
    } else {
      this.supplierCredentialsService.addSupplierCredentials({payload: payload}).subscribe((r: any) => {
        supplier.credentials.id = r.id;
      })
    }
  }

  inputEnter(event: any) {
    event.target.blur();
  }

  shareCredsChange(event: any, supplier: any) {
    this.updateSupplierCredential(supplier);
  }

  onUpdateDropdown(event: any, supplier: any) {
    supplier.credentials.track_supplier = event.event.value.id;
    this.updateSupplierCredential(supplier);
  }

  findCredChoice(supplier: any) {
    return this.dropdownOptions.credChoices.find((c: any) => c.id == supplier.credentials.track_supplier);
  }

  makeUrl(link: any) {
    try {
      const url = new URL(link);
      return url.hostname;
    } catch(error) {
      return '';
    }
  }

  colorForTrackSupplier(supplier: any) {
    switch (supplier.credentials?.track_supplier) {
      case 0:
        return '#FF9B05';
      case 1:
        return '#17E1D2';
      case 2:
        return '#5FC64C';
      default:
        return '#17E1D2';
    }
  }

  valueForTrackSupplier(supplier: any) {
    let found = this.dropdownOptions.credChoices.find((c: any) => c.id == supplier.credentials?.track_supplier);
    return found ? found.value: 'Default tracking';
  }

  getSupplierIcon(supplier: any, style: boolean, val: any, with_color: boolean = true) {
    let base = val != null ? 1.0 : .8;
    let unsure: any = {'font-size': `${base + .4}rem`};
    let yes: any = {'font-size': `${base}rem`};
    let no: any =  {'font-size': `${base}rem`};
    if (with_color) {
      unsure.color = '#FF9B05';
      yes.color = '#75D649';
      no.color = '#00A3FF';
    }
    let unlock = { "bi-globe": true } as any;
    let lock = { "bi-lock": true } as any;
    let envelope = { "bi-envelope": true } as any;
    let question = { "bi-question": true } as any;
    unlock.bi = true;
    lock.bi = true;
    envelope.bi = true;
    question.bi = true;
    let sw = val != null ? val : supplier.track_order_online; 
    let isOnline = ['Wayfair', 'Amazon'].includes(supplier.name);
    if (isOnline && supplier.track_order_online == 1) {
      return style ? yes: lock;
    }
    switch (sw) {
      case 0:
        return style ? yes: unlock;
      // case 1:
      //   return style ? yes: lock;
      case 1:
      case 3:
      case 4:
        return style ? no: envelope;
      default:
        return style ? unsure: question;
    }
  }

  getSupplierTrackingString(supplier: any) {
    let isOnline = ['Wayfair', 'Amazon'].includes(supplier.name);
    if (isOnline && supplier.track_order_online == 1) {
      return 'Online - login required';
    }
    switch (supplier.track_order_online) {
      case 0:
        return 'Online - no login required';
      // case 1:
      //  return 'Online - login required';
      case 1:
      case 3:
      case 4:
        return 'Via email';
      default:
        return 'Pending';

    }
  }

  columnWidthForName(name: string) {
    switch (name) {
      case 'NOT SHARED':
        return 'share-creds-width';
      case 'BASICS':
      case 'CONTACTS':
      case 'TRACKING':
      case 'SUPPLIER':
        return 'long-col-width-supplier';
      default:
        return 'default-col-width-supplier';
    }
  }

  getDynamicStyles() {
    if (this.notificationsView && this.panelOpen) {
      const width = `calc(100vw - 550px)`;
      return { 'max-width': width };
    } else {
      return { 'max-width': '' };
    }
  }

  doFilterMySuppliers() {
    if (!this.allMailData?.curOrders || !this.allMailData?.activeJobsList) {
      return this.filteredSuppliers;
    }
    let statuses = openStatuses();
    this.filteredSuppliers = this.filteredSuppliers.filter((s: any) => {
      return this.allMailData.curOrders.find((o: any) => {
        let has_status = o.items?.find((i: any) => statuses.includes(i.status))
        return o.supplier_id == s.id && has_status && (
          this.allMailData.activeJobsList.find((j: any) => j.id == o.job_id) || !o.job_id
        );
      })
    })
  }

  doFilterActiveSuppliers(which: boolean) {
    this.filterActiveSuppliers(which);
  }

  filterMySuppliers: boolean = true;
  filterActiveSuppliers(which: any) {
    if (which == this.filterMySuppliers) return;
    this.filterMySuppliers = which;
    this.onSearchChange(false);
    if (this.filterMySuppliers) {
      this.doFilterMySuppliers();
    }
  }

  deleteSupplier(supplier: any) {
    this.allSuppliers = this.allSuppliers.filter((s: any) => s.id != supplier.id);
    this.filteredSuppliers = this.filteredSuppliers.filter((s: any) => s.id != supplier.id);
    let payload = {supplier_id: supplier.id};
    this.supplierService.deleteSupplier(payload).subscribe(() => {});
  }

  showDeleteSupplierModal: boolean = false;
  supplierToDelete: any = undefined;
  onClickDeleteSupplier(supplier: any) {
    this.showDeleteSupplierModal = true;
    this.supplierToDelete = supplier;
  }

  onConfirmDeleteSupplier() {
    this.deleteSupplier(this.supplierToDelete);
    this.supplierToDelete = undefined;
    this.showDeleteSupplierModal = false;
  }

  onHideDeleteSupplier() {
    this.showDeleteSupplierModal = false;
    this.supplierToDelete = undefined;
  }

  get theMaxWidth() {
    return this.maxWidth ? this.maxWidth : 'calc(100vw - 330px)';
  }
}
