<div class="side-panel-container" [ngClass]="isPanelOpen ? '' : 'side-panel-close-width' " #sidePanelContainer>
  <div [ngClass]="isPanelOpen ? 'logo-container-div-open' : 'logo-container-div-close' ">
    <img *ngIf="isPanelOpen" class="logo-container"
        src="/assets/img/meti_logo.svg" alt="logo" (click)="homePageClicked()"/>
    <img *ngIf="!isPanelOpen" class="logo-container"
        src="/assets/img/meti_logo_small.svg" alt="logo" (click)="homePageClicked()"/>
  </div>

  <div class="organization-container" [ngClass]="isPanelOpen ? '': 'organization-container-onopen'">
    <div class="organization-container-open" *ngIf="isPanelOpen">
      <div class="organization-container-name">
        {{orgName}}
      </div>
      <i class="bi bi-gear organization-settings-icon" style="position: relative;" (click)="onOrgGearClick()">
        <i *ngIf="canShowAlert('Settings')" class="bi bi-dot sp-alert-dot settings-alert-dot"></i>
      </i>
    </div>
    <div class="organization-container-closed" (click)="homePageClicked()" style="cursor: pointer;"
        [ngClass]="orgLogo? 'org-closed-logo' : 'org-closed-no-logo' "
        *ngIf="!isPanelOpen">
      <img *ngIf="orgLogo" [src]="orgLogo">
    </div>
  </div>
  <div class="notifications-container" [ngClass]="isPanelOpen ? '': 'notifications-container-line'">
    <div class="notification-box-outer" *ngFor="let which of notificationTypes" >
      <div class="notification-box" (click)="onNotificationClick(which)"
        [ngClass]="{
            'notification-value-active': highlightSide(which.name),
            'notification-box-open': isPanelOpen,
            'notification-box-closed': !isPanelOpen
        }">
        <i [ngClass]="[which.class, isPanelOpen ? '' : 'notification-icon-closed']"
        class="notification-icon notification-icon-width"></i>
        <div class="notifications-text" *ngIf="isPanelOpen">
          {{which.name}}
        </div>
        <i *ngIf="canShowAlert(which.name)" class="bi bi-dot sp-alert-dot"
            [ngClass]="{
              'alert-dot-open': isPanelOpen,
              'alert-dot-closed': !isPanelOpen
            }"
        ></i>
      </div>
      <div *ngIf="canShowAllJobs(which)" class="sp-view-list-container custom-scrollbar-close">
        <view-tab-list
          [selectedView]="selectedView"
          [isLoading]="isLoading"
          [isLoadingViews]="isLoadingViews"
          [viewTabPaddingLeft]="'9px'"
          [viewTabMarginLeft]="'18px'"
        ></view-tab-list>
      </div>

    </div>
  </div>
  <div class="jobs-container" [ngStyle]="{'margin': isPanelOpen ? '0px 25px 0px 12px' : ''}">
    <div class="jobs-container-top" [ngClass]="isPanelOpen ? 'jobs-container-open': 'jobs-container-close'">
      <div class="organization-container-name" *ngIf="isPanelOpen">
        Jobs
      </div>
      <div class="job-icon-display">
        <i *ngIf="isPanelOpen" class="bi bi-search job-icon-width job-icon-search"
            (click)="jobSearchIconClicked()"></i>
        <i class="bi bi-plus-lg jobs-add-icon" (click)="onJobPlusClick()"
        [ngStyle]="{'margin': isPanelOpen ? '' : '12px 0px'}"
        ></i>
      </div>

    </div>
    <ng-container *ngIf="jobsDropdownVisible">
      <div class="new-option-dropdown jobs-option-dropdown"
        [ngClass]="isPanelOpen ? 'jobs-option-dropdown-open' : 'jobs-option-dropdown-closed'">
        <div class="new-option" (click)="jobsOptionClicked(which)" *ngFor="let which of jobsPages">
          <i [ngClass]="[which.class]" class="sp-co-settings-icon"></i>
          {{which.name}}
        </div>
      </div>
    </ng-container>
    <active-job-list
      [activeJobs]="activeJobs"
      [activePage]="activePageType"
      [hasUncat]="hasUncat"
      [selectedView]="selectedView"
      [isLoading]="isLoading"
      [isLoadingViews]="isLoadingViews"
      [isPanelOpen]="isPanelOpen"
      [searchClicked]="jobSearchClicked"
      (selectedJob)="selectJob($event)"
      (searchClickClose)="onJobSearchClose()"
    ></active-job-list>
  </div>
  <div class="automations-text-container" *ngIf="isPanelOpen">
    Tools & automations
  </div>
  <div class="notifications-container"
      [ngClass]="isPanelOpen ? '': 'notifications-container-before automations-container'">
    <div class="notification-box"
        *ngFor="let which of automationTypes" (click)="onNotificationClick(which)"
        [ngClass]="{
          'notification-value-active': highlightSide(which.name),
          'notification-box-open': isPanelOpen,
          'notification-box-closed': !isPanelOpen
        }"
        >
      <i [ngClass]="[which.class, isPanelOpen ? '' : 'notification-icon-closed']"
        class="notification-icon notification-icon-width"></i>
      <div class="notifications-text" *ngIf="isPanelOpen">
        {{which.name}}
      </div>
      <i *ngIf="canShowAlert(which.name)" class="bi bi-dot sp-alert-dot"
        [ngClass]="{
          'alert-dot-open': isPanelOpen,
          'alert-dot-closed': !isPanelOpen
        }"
      ></i>
    </div>
  </div>
  <div style="display: flex; height: 100%"></div>
  <div class="bottom-side-panel" [ngClass]="isPanelOpen ? 'bottom-side-panel-open': 'bottom-side-panel-close'">
    <ng-container *ngIf="newOptionsVisible">
      <div class="new-option-dropdown new-option-dropdown-plus">
        <div class="new-option" (click)="createNewThing(which)"
        *ngFor="let which of newOptionTypes">
          <span> {{ which }} </span>
        </div>
      </div>
    </ng-container>
    <div class="notification-box toggle-new-data"
        (click)="setNewOptionsVisible()"
        [ngClass]="{
          'new-toggle-box-open notification-box-open': isPanelOpen,
          'new-toggle-box-closed notification-box-closed': !isPanelOpen
        }">
      <i [ngClass]="isPanelOpen ? '' : 'notification-icon-closed'"
        class="bi bi-plus-lg jobs-add-icon notification-icon notification-icon-width">
      </i>
      <div class="notifications-text" *ngIf="isPanelOpen">
        New
      </div>
    </div>
    <div class="toggle-side-container">
      <div class="toggle-side-panel" (click)="toggleSidePanel()">
        <i class="bi toggle-side-panel-icon" [ngClass]="isPanelOpen ? 'bi-chevron-left' : 'bi-chevron-right'"></i>
      </div>
    </div>
  </div>
</div>


<materlog-templates *ngIf="showTemplates"
  [selectedView]="selectedView"
  [show]="showTemplates"
  (close)="showTemplates = false">
</materlog-templates>

<materlog-create-update-job *ngIf="showNewJob"
  [fromGetTracking]="false"
  (close)="showNewJob = false">
</materlog-create-update-job>

<materlog-create-update-job *ngIf="showNewReceiver"
  [onlyReceiver]="true"
  [sidePanelAdd]="true"
  [fromGetTracking]="false"
  (close)="showNewReceiver = false">
</materlog-create-update-job>

<materlog-add-edit-po *ngIf="showNewOrder"
  (close)="showNewOrder = false"
  (gotoNotif)="gotoNotifications()">
</materlog-add-edit-po>

<materlog-add-item *ngIf="showNewItem"
  [allMailData]="allMailData"
  (close)="showNewItem = false">
</materlog-add-item>

<p-dialog *ngIf="addSupplierModal"
  [(visible)]="addSupplierModal"
  [style]="{ marginTop: '250px', width: '500px' }"
  [closeOnEscape]="false"
  [modal]="true"
  [resizable]="false"
  [draggable]="false"
  [position]="'top'"
  [closable]="false"
  [maskStyleClass]="'dialog-mask'"
>
  <ng-template pTemplate="header">
    <div class="dialog__header">
      <span>Add supplier</span>
    </div>
    <button class="dialog-close" type="button" (click)="addSupplierModal = false;"><i class="bi bi-x-lg"></i></button>
  </ng-template>
  <materlog-add-supplier-modal
    [resetSupplier]="addSupplierModal"
    [sidePanelAdd]="true"
    (supplierFormChange)="supplierFormChangeEvent($event)"
  ></materlog-add-supplier-modal>
</p-dialog>