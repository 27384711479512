import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { EventEmitter, Output, ChangeDetectorRef} from '@angular/core';
import { Router } from '@angular/router';
import { SelectedViewModel } from '../../models/selected-view.model';
import { select, Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';
import { DashboardRoutes } from '../../enums/dashboard-routes.enum';
import { ElementRef, HostListener } from '@angular/core';
import { MailBoxCommunicationService } from '../../services/mailbox_comm.service';
import { selectComments, selectViews} from '../../state/selectors/dashboard.selector';
import { selectActiveJobs } from '../../state/selectors/dashboard.selector';
import { IStoreApiItem } from 'src/app/common/models/store-api-item.model';
import { IApplicationState } from 'src/app/common/state/models/app.state.model';
import { CommentsService } from '../../services/comment.service';
import { DashboardActions } from '../../state/actions/dashboard.actions';
import { MentionModule } from 'angular-mentions';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { deepCopy } from 'src/app/common/utils/general';
import { ActiveJobModel } from '../../models/active-job.model';
import { UserActions } from 'src/app/user-settings/state/actions/user.actions';
import { DashboardJobView } from '../../services/dashboard_job_view.service';
import { PermissionsService } from 'src/app/root/services/permissions.service';
import { environment } from 'src/environments/environment';
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { UserService } from 'src/app/user-settings/services/user.service';
import { DashboardService } from '../../services/dashboard.service';
// import { OrderUploadService } from '../../services/order-upload.service';


@Component({
  selector: 'side-panel',
  templateUrl: './side-panel.component.html',
  styleUrls: ['./side-panel.component.css'],
})
export class SidePanelComponent implements OnInit {

  private baseUrl: string = environment.ENV.BASE_URL;
  tawk_id: string = environment.ENV.TAWK_ID;

  newOptionsVisible: boolean = false;

  orgName: string = '';
  hasUncat: boolean = false;
  orgLogo: any = '';

  isPanelOpen: boolean = true;
  
  jobsDropdownVisible: boolean = false;
  jobSearchClicked: boolean = false;

  workflowsConfirmed: boolean = true;
  supplierAlert: boolean = false;
  orderAlert: boolean = false;

  jobsPages = [
    {name: 'New job', class: 'bi bi-plus-lg'},
    {name: 'All jobs', class: 'bi bi-layers'},
    
  ]

  notificationTypes = [
    {name: 'Home', class: 'bi bi-house-door'},
    {name: 'Notifications', class: 'bi bi-bell'},
    {name: 'Orders', class: 'bi bi-box-seam'},
    {name: 'Suppliers', class: 'bi bi-people'},
    /* {name: 'Activity', class: 'bi bi-activity'}, */
  ];

  automationTypes = [
    {name: 'Extract', class: 'bi bi-box-arrow-right'},
    {name: 'Workflows', class: 'bi bi-lightning-charge'},
    /* {name: 'Inboxes', class: 'bi bi-envelope'}, */
    /* {name: 'Analytics', class: 'bi bi-clipboard-data'}, */
  ]

  activePageType: any = 'Home';
  profile: any = {};

  mailBoxVisible: boolean = false;

  showTemplates: boolean = false;
  showNewJob: boolean = false;
  showNewOrder: boolean = false;
  showNewItem: boolean = false;
  showNewReceiver: boolean = false;
  addSupplierModal: boolean = false;

  @ViewChild('newOptionsButton') newOptionsButton!: ElementRef;
  @ViewChild('sidePanelContainer') sidePanelContainer!: ElementRef;

  @Input() selectedViewStyle: number = -1;
  @Input() selectedView?: SelectedViewModel;
  @Input() isLoading: any;
  @Input() isLoadingViews: any;
  @Input() isLoadingOverlay: any;
  @Input() activeJobs: any = [];
  @Input() panelHeight!: 750;
  @Input() allMailData: any;
  @Input() dropdownOptions: any;
  @Output() selectedJob: EventEmitter<any> = new EventEmitter();
  @Output() sidePanelToggled: EventEmitter<any> = new EventEmitter();
  @Output() profileValuesSet: EventEmitter<any> = new EventEmitter();

  commentCnt: number = 0;
  confirmationCnt: number = 0;
  draftCnt: number = 0;
  notificationCnt: number = 0;
  notifReceived: boolean = false;

  initDataCalled: boolean = false;

  ngOnInit() {
    this.onRequestUserProfile();
  }


  onPageReload() {
    let reloadPage = localStorage.getItem('activePage');
    if (reloadPage) {
      switch (reloadPage) {
        case 'Settings':
          this.metaSettingsClicked();
          break;
        case 'Items':
          this.resetItems();
          break;
        case 'Jobs':
          this.jobsClicked();
          break;
        case 'Suppliers':
          this.suppliersClicked();
          break;
        case 'Inboxes':
          this.pullsClicked();
          break;
        case 'Notifications':
          this.notificationsClicked();
          break;
        case 'Workflows':
          this.workflowsClicked();
          break;
        case 'Home':
          this.homePageClicked();
          break;
        case 'Extract':
          this.extractClicked();
          break;
        default:
          this.resetItems();
          break;
      }
    }
  }

  ngOnChanges(changes: any) {
  }

  ngAfterViewInit() {
    this.onPageReload();
    this.retrieveNotificationsWait();
  }

  constructor(
    public router: Router,
    private dashboardActions: DashboardActions,
    private store: Store<IApplicationState>,
    private cdr: ChangeDetectorRef,
    private userActions: UserActions,
    private djv: DashboardJobView,
    private mailBoxComm: MailBoxCommunicationService,
    private permissionService: PermissionsService,
    private httpClient: HttpClient,
    private userService: UserService,
    private dashboardService: DashboardService
    // private orderUploadService: OrderUploadService,
  ) {
    (window as any)['sp'] = this;

    this.djv.notificationCount$.subscribe((data: any) => {
      this.confirmationCnt = data;
    });
    this.djv.viewClick$.subscribe(() => {
      this.setItemPageActive();
    });
    this.djv.hasNoUncat$.subscribe((data: any) => {
      this.hasUncat = data;
    });
    this.djv.setNewJob$.subscribe((data: any) => {
      if (this.activeJobs?.length) {
        let jid = this.activeJobs[0]?.id;
        if (jid) {
          this.selectJob(jid);
        }
      }
    });
    this.djv.homePageClickObs$.subscribe((data: any) => {
      if (data == 'job') {
        this.jobsClicked();
      } else if (data == 'supplier') {
        this.suppliersClicked();
      } else if (data == 'order') {
        this.setSelectJob()
      } else if (data == 'alerts') {
        this.notificationsClicked();
      } else if (data == 'extract') {
        this.extractClicked();
      } else if (data == 'workflows') {
        this.workflowsClicked();
      } else if (data == 'settings') {
        this.metaSettingsClicked();
      }
    });
    this.djv.statusClicked$.subscribe((data: any) => {
      this.setStatusClick(data);
    });
    this.djv.sidePanelNotifGet$.subscribe(() => {
      this.retrieveNotificationsWait();
    });

  }

  get dataReceived() {
    return this.profile.user_id && this.notifReceived;
  }

  createNewThing(event: any) {
    if (this.isAnyLoading) return;
    this.newOptionsVisible = false;
    if (event == 'Item') {
      this.showNewItem = true;
    } else if (event == 'Order') {
      this.showNewOrder = true;
    } else if (event == 'Job') {
      this.showNewJob = true;
    } else if (event == 'View') {
      this.djv.make_new_view();
    } else if (event == 'Receiver') {
      this.showNewReceiver = true;
    } else if (event == 'Supplier') {
      this.addSupplierModal = true;
    }
  }

  @HostListener('document:click', ['$event'])
  clickOutside(event: any) {
    this.newOptionsVisible = false;
    this.jobsDropdownVisible = false;
  }

  setNewOptionsVisible() {
    if (this.isAnyLoading) return;
    if (this.newOptionsVisible) {
      this.newOptionsVisible = false;
    } else {
      setTimeout(() => {
        this.newOptionsVisible = true;
      }, 0);
    }
  }

  isButtonInactive() {
    return this.activePageType == 'Notifications' ||  this.activePageType == 'Inboxes';
  }

  alwaysClickable(type: any) {
    return ['Notifications', 'Inboxes'].includes(type);

  }

  unsetPulls() {
    if (this.activePageType == 'Inboxes') this.activePageType = undefined;
  }

  unsetNotifications() {
    if (this.activePageType == 'Notifications') this.activePageType = undefined;
  }

  resetItems() {
    this.activePageType = 'Items';
    this.setActivePageType();
    this.setScrollBar('.sp-view-list-container');
    this.djv.side_panel_click({'page': 'items'});
  }

  onNotificationClick(notifType: any) {
    if (this.isAnyLoading) return;
    let which = notifType.name;
    this.activePageType = which == this.activePageType ? undefined : which;
    if (which == 'Home') {
      this.homePageClicked();
    } else if (which == 'Notifications') {
      this.notificationsClicked();
    } else if (which == 'Orders') {
      this.setSelectJob();
    } else if (which == 'Suppliers') {
      this.suppliersClicked();
    } else if (which == 'Activity') {

    } else if (which == 'Workflows') {
      this.workflowsClicked();
    } else if (which == 'Inboxes') {
      this.pullsClicked();
    } else if (which == 'Extract') {
      this.extractClicked();
    }
  }

  notificationsClicked() {
    this.activePageType = 'Notifications';
    this.setActivePageType();
    this.djv.side_panel_click({'page': 'notifications'});
    this.isPanelOpen = false;
    this.sidePanelToggled.emit(this.isPanelOpen);
  }

  workflowsClicked() {
    this.activePageType = 'Workflows';
    this.setActivePageType();
    this.djv.side_panel_click({'page': 'workflows'});
  }

  extractClicked() {
    this.activePageType = 'Extract';
    this.setActivePageType();
    this.djv.side_panel_click({'page': 'extract'});
  }

  pullsClicked() {
    this.activePageType = 'Inboxes';
    this.setActivePageType();
    this.djv.side_panel_click({'page': 'confirmations'});
  }

  setActivePageType() {
    localStorage.setItem('activePage', this.activePageType);
  }

  emailsClicked() {
    if (this.isButtonInactive()) return;
    this.unsetPulls();
    this.unsetNotifications();
    this.onNotificationClick('Emails');
  }

  suppliersClicked() {
    this.activePageType = 'Suppliers';
    this.setActivePageType();
    this.unsetPulls();
    this.unsetNotifications();
    this.djv.side_panel_click({'page': 'suppliers'});
  }

  homePageClicked() {
    if (this.isAnyLoading) return;
    this.activePageType = 'Home';
    this.setActivePageType();
    this.unsetPulls();
    this.unsetNotifications();
    this.djv.side_panel_click({'page': 'home'});
  }
  
  jobsClicked() {
    this.activePageType = 'Jobs';
    this.setActivePageType();
    this.unsetPulls();
    this.unsetNotifications();
    this.djv.side_panel_click({'page': 'jobs'});
  }

  metaSettingsClicked() {
    this.activePageType = 'Settings';
    this.isPanelOpen = false;
    this.sidePanelToggled.emit(this.isPanelOpen);
    this.setActivePageType();
    this.unsetPulls();
    this.unsetNotifications();
    this.djv.side_panel_click({'page': 'meta-settings'});
  }
  

  setItemPageActive() {
    this.activePageType = 'Items';
    this.setActivePageType();
    this.unsetPulls();
    this.unsetNotifications();
    this.djv.side_panel_click({'page': 'items'});
  }

  templatesClicked() {
    this.showTemplates = true;
  }

  highlightSide(which: any) {
    return this.activePageType == which ||
            this.isOnAllJobs(which);
  }

  isOnAllJobs(which: any) {
    return this.activePageType == 'Items' &&
            which == 'Orders' &&
            !this.isPanelOpen &&
            this.selectedJobId == 'all';
  }

  selectJob(id: string): void {
    this.djv.side_panel_click({'page': 'items'});
    this.activePageType = 'Items';
    this.setActivePageType();
    this.unsetPulls();
    this.unsetNotifications();
    this.selectedJob.emit(id);
  }

  setSelectJob() {
    let id = 'all';
    if (id == this.selectedJobId && this.activePageType == 'Items') {
      return;
    }
    localStorage.setItem('selectedJob', JSON.stringify(id));
    this.selectJob(id);
    this.setScrollBar('.sp-view-list-container');
  }

  setStatusClick(data: any) {
    let id = 'all';
    localStorage.setItem('selectedJob', JSON.stringify(id));
    this.djv.side_panel_click({'page': 'items'});
    this.activePageType = 'Items';
    this.setActivePageType();
    this.unsetPulls();
    this.unsetNotifications();
  }

  supplierFormChangeEvent(event: any) {
    setTimeout(() => {
      this.dashboardActions.requestSuppliers();
    }, 100);
    this.addSupplierModal = false;
  }

  loadNotificationMetaData(): Observable<any> {
    return this.userService.loadNotificationMetaData();
  }

  gotoNotifications() {
    this.showNewOrder = false;
    this.onNotificationClick('Notifications');
  }

  onOrgGearClick() {
    if (this.isAnyLoading) return;
    this.metaSettingsClicked()
  }

  onJobPlusClick() {
    if (this.jobsDropdownVisible) {
      this.jobsDropdownVisible = false;
    } else {
      setTimeout(() => {
        this.jobsDropdownVisible = true;
      }, 0);
    }
  }

  jobsOptionClicked(which: any) {
    if (this.isAnyLoading) return;
    this.jobsDropdownVisible = false;
    if (which.name == 'All jobs') {
      this.jobsClicked();
    } else if (which.name == 'New job') {
      this.showNewJob = true;
    }
  }

  onRequestUserProfile() {
    this.userService.loadSidePanelData().subscribe((result: any) => {
      this.profile.name = result?.first_name + ' ' + result?.last_name;
      this.profile.email = result?.user_email;
      this.profile.user_id = result?.user_id;
      this.profile.first_name = result?.first_name;
      this.profile.slug = result?.slug;
      this.profileValuesSet.emit({...result});
      this.orgName = result.org_name;
      this.hasUncat = result.uncategorized;
      this.activeJobs = result.jobs;
      this.orgLogo = result.logo;
      localStorage.setItem('profile', JSON.stringify(this.profile));
      (window as any).Intercom("boot", {
        api_base: "https://api-iam.intercom.io",
        app_id: "av6fx1t3",
        user_id: this.profile.user_id,
        name: this.profile.name,
        email: this.profile.email,
        created_at: new Date(),
      });
    });
  }

  get selectedJobId(): string | null {
    const savedJobId = localStorage.getItem('selectedJob') as any;
    return !!savedJobId ? JSON.parse(savedJobId) : null;
  }

  canShowAllJobs(which: any) {
    return this.isPanelOpen &&
            this.selectedJobId === 'all' &&
            which.name == 'Orders' &&
            this.activePageType == 'Items';
  }

  setScrollBar(v: string) {
    let self = this;
    setTimeout(() => {
      let jdisplays = this.sidePanelContainer.nativeElement.querySelectorAll(v);
      if (jdisplays) {
        jdisplays.forEach((jdisplay: any) => {
          jdisplay.addEventListener('mouseenter', function() {
            if (!self.isPanelOpen) return;
            (jdisplay as any).classList.add('custom-scrollbar');
            (jdisplay as any).classList.remove('custom-scrollbar-close');
            setTimeout(() => {
              (jdisplay as any).classList.remove('custom-scrollbar');
              (jdisplay as any).classList.add('custom-scrollbar-close');
            }, 1500)
          });
          jdisplay.addEventListener('wheel', function() {
            if (!self.isPanelOpen) return;
            (jdisplay as any).classList.add('custom-scrollbar');
            (jdisplay as any).classList.remove('custom-scrollbar-close');
            setTimeout(() => {
              (jdisplay as any).classList.remove('custom-scrollbar');
              (jdisplay as any).classList.add('custom-scrollbar-close');
            }, 1500)
          });
          jdisplay.addEventListener('mouseleave', function() {
            if (!self.isPanelOpen) return;
            (jdisplay as any).classList.remove('custom-scrollbar');
            (jdisplay as any).classList.add('custom-scrollbar-close');
          });
        })
      }
    }, 500);
  }

  jobSearchIconClicked() {
    if (this.isAnyLoading) return;
    this.jobSearchClicked = !this.jobSearchClicked;
  }

  onJobSearchClose() {
    setTimeout(() => {
      this.jobSearchClicked = false;
    }, 300);
  }


  toggleSidePanel() {
    this.isPanelOpen = !this.isPanelOpen;
    this.sidePanelToggled.emit(this.isPanelOpen);
  }

  get dropdownRetrieved() {
    return this.dropdownOptions.alert_types
  }

  retrieveNotificationsWait() {
    let outer = this;
    var pollInterval = setInterval(function() {
      if (outer.dropdownRetrieved) {
        clearInterval(pollInterval);
        outer.retrieveNotifications();
      }
    }, 20);
  }

  get anyAlert() {
    return this.supplierAlert || this.orderAlert
  }

  canShowAlert(which: any) {
    if (which == 'Notifications') {
      return this.anyAlert;
    } else if (which == 'Suppliers') {
      return this.supplierAlert;
    } else if (which == 'Workflows') {
      // return !this.workflowsConfirmed
    } else if (which == 'Settings') {
      return !this.workflowsConfirmed
    }
    return false;
  }

  retrieveNotifications() {
    setTimeout(() => {
      this.dashboardService.getNotificationsSidePanel().subscribe((result: any) => {
        let order_type = this.dropdownOptions?.alert_types?.find((a: any) => a.value == 'Order')?.id;
        let supplier_type = this.dropdownOptions?.alert_types?.find((a: any) => a.value == 'Supplier')?.id;
        let order_alerts = [
            1, // MISSING_SUPPLIER = 1, 'Missing supplier'
            3, // ITEM_ORDERED_PO = 3, 'Confirmation number needed'
            5, // INVALID_TRACKING = 5, 'Invalid tracking info'
            6, // MISSING_TRACKING = 6, 'Missing tracking info'  # supplier tracking
            10, // TRACKING_WRONG = 10, 'Confirm order details for tracking'
        ];
        let supplier_alerts = [
          2 // NO_EMAIL = 2, 'No email'
        ]
        this.workflowsConfirmed = result.confirmed;

        let tmpOrderAlert = false;
        let tmpSupplierAlert = false;
        result?.data?.forEach((alert: any) => {
          if (alert.alert_type == order_type && order_alerts.includes(alert.order_alert)) {
            tmpOrderAlert = true;
          }
          else if (alert.alert_type == supplier_type && supplier_alerts.includes(alert.supplier_alert)) {
            tmpSupplierAlert = true;
            
          }
        })
        this.orderAlert = tmpOrderAlert;
        this.supplierAlert = tmpSupplierAlert;
      })
    }, 100);

  }

  get newOptionTypes() {
    if (this.activePageType == 'Items') {
      return ['View', 'Order', 'Item']
    } else {
      return ['Order', 'Item']
    }
  }

  get isAnyLoading() {
    return this.isLoading || this.isLoadingViews;
  }

}