<div class="workflow-data-entry" style="padding-right: 16px; gap: 24px; margin-bottom: 12px">
    <div class="workflow-box-row">
        <div class="workflow-leftmost-small wf-data-entry-margin wf-counter-circle">
            1
        </div>
        <div class="workflow-box-col">
            <div class="org-ai-option-text workflow-active-text" style="margin-bottom: 16px;">
                How will you share order updates with Meti?
            </div>
            <div class="wf-choose-small-text">
                For data completeness, Meti strongly suggests you share for processing any updates suppliers send to you.
            </div>
            <div class="workflow-box-row wf-box-row-choosehow" >
                <div *ngFor="let how of chooseHow" class="wf-choose-how-one"
                    (click)="setChooseHowActive(how)"
                    [ngClass]="{
                        'workflow-close-box-not-done2': how.active && !box.complete,
                        'workflow-close-box-done2': how.active && box.complete,
                        'wf-choose-how-inactive': !how.active,
                    }"
                    [ngStyle]="{'cursor': how.soon ? 'default': 'pointer'}"
                >
                    <div class="workflow-box-row" style="align-items: center;">
                        <div class="wf-how-main-text"
                            [ngStyle]="{'color': how.soon ? '#828282': '#000'}">
                            {{how.name}}
                        </div>
                        <div *ngIf="how.soon" class="wf-coming-soon">
                            Coming soon!
                        </div>
                    </div>
                    <div class="wf-how-sub-text">
                        {{how.text}}
                    </div>
                </div>
            </div>
            <div *ngIf="forwardChosen" class="workflow-box-col">
                <div class="org-ai-option-text workflow-active-text" style="margin-bottom: 12px; margin-top: 24px">
                    Meti Forwarding Email
                </div>
                <div class="wf-choose-small-text">
                    CC this email address on any POs you issue to pull them into the system.
                </div>
                <div class="workflow-box-row" style="align-items: center; gap: 4px; margin-top: 12px">
                    <div class="wf-choose-small-text">
                        orders@
                    </div>
                    <input class="wf-slug-box"
                        [ngClass]="{
                            'workflow-close-box-not-done': !box.complete,
                            'workflow-close-box-done': box.complete,
                        }"
                        [ngStyle]="{'max-width': slugWidth}"
                        [(ngModel)]="slug" 
                        [placeholder]="'SLUG'"
                        (keydown.enter)="onSlugKeyDown($event)"
                        (blur)="onSlugBlur($event)"
                    />
                    <div class="wf-choose-small-text">
                        .getmeti.com
                    </div>
                    <i *ngIf="!copying" class="bi bi-copy wf-copy-slug" (click)="sendToClipboard()"></i>
                    <i *ngIf="copying" class="bi bi-check2 wf-copy-slug"></i>
                </div>
                <div *ngIf="slugError && showSlugError" class="wf-choose-small-text wf-slug-error">
                    {{slugError}}
                </div>
                <div *ngIf="showSlugSuccess" class="wf-choose-small-text wf-slug-error" style="color: #53AE61">
                    Successfully changed your slug!
                </div>
                <div class="wf-choose-xsmall-text" style="margin-top: 24px">
                    If you selected “gmail extension” or “forward emails” as the method for getting new orders into Meti (the previous set up step), you will have to use the same method for sharing updates. 
                </div>
                <div class="wf-choose-xsmall-text" style="margin-top: 16px">
                    No matter the update method you select, you can always manually add or change order details and upload documents in the app.
                </div>
            </div>
        </div>
    </div>
    <div class="workflow-box-row">
        <div class="workflow-leftmost-small wf-data-entry-margin wf-counter-circle">
            2
        </div>
        <div class="workflow-box-col">
            <div class="org-ai-option-text workflow-active-text" style="margin-bottom: 16px;">
                How should Meti check in on your orders?
            </div>
            <div class="wf-choose-small-text" style="margin-bottom: 12px;">
                You can customize how Meti checks in on your orders.
            </div>
            <div class="workflow-box-row wf-box-row-choosehow" >
                <div *ngFor="let how of customWhere" class="wf-choose-how-one wf-choose-how-inactive"
                    style="cursor: pointer; min-height: 140px; max-height: 140px; min-width: 300px; max-width: 300px;"
                    (click)="goToPage(how)"
                >
                    <div class="workflow-box-row" style="align-items: center; gap: 8px;">
                        <div class="wf-custom-where-layers">
                            <i class="bi" [ngClass]="[how.class]"></i>
                        </div>
                        <div class="wf-how-main-text">
                            {{how.name}}
                        </div>
                        <i class="bi bi-chevron-right wf-layers-chevron"></i>
                    </div>
                    <div class="wf-how-sub-text">
                        {{how.text}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>