import { ChangeDetectorRef, Component, OnInit, ViewChild, Input } from '@angular/core';
import { ElementRef, HostListener } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { DashboardService } from 'src/app/dashboard/services/dashboard.service';


@Component({
  selector: 'materlog-workflows-slug',
  templateUrl: './workflow-slug.component.html',
  styleUrls: [
    '../../workflows/workflows.component.css',
  ]
})
export class MaterlogWorkflowsSlug implements OnInit {
    @Input() orgData: any = {};
    @Input() gmailText: boolean = true;
    @Input() graphView: boolean = false;

    slug: any = 'SLUG';
    slugErrorTextNeed = 'Slugs need to be alphanumeric';
    slugErrorUnsuccess = 'URL unavailable. Please try another.';
    slugError = '';
    showSlugSuccess: boolean = false;
    showSlugError: boolean = false;

    profile: any = {};
    orgSlugs: any = [];

    ngOnChanges(changes: any) {

    }

    ngOnInit(): void {
        this.getOrgSlugs();
        (window as any)['wf_choose'] = this;
        this.resetSlug();
    }

    resetSlug() {
      this.profile = JSON.parse(localStorage.getItem('profile') as any);
      this.slug = this.profile.slug || 'SLUG';
    }

    get slugWidth() {
        return `${this.slug.length + 5}ch`;
    }

    constructor(
        private dashboardService: DashboardService
    ) {}


    onSlugBlur(event: any) {
      if (!this.ensureSlugValidity()) {
        this.showSlugError = true;
        setTimeout(() => {
          this.showSlugError = false;
          this.slugError = '';
          this.resetSlug();
        }, 1500);
        return;
      }
      if (this.slug == this.profile.slug) return;
      let data = {slug: this.slug}
      this.dashboardService.writeOrgSlug(data).subscribe((result: any) => {
        if (!result.success) {
          this.showSlugError = true;
          this.slugError = this.slugErrorUnsuccess;
          setTimeout(() => {
            this.showSlugError = false;
            this.slugError = '';
          }, 1500);
        } else {
            let profile = JSON.parse(localStorage.getItem('profile') as any);
            profile.slug = this.slug;
            localStorage.setItem('profile', JSON.stringify(profile));
            this.showSlugSuccess = true;
            setTimeout(() => {
              this.showSlugSuccess = false;
            }, 1500);
        }
      })
    }

    ensureSlugValidity() {
      if (!this.slug) {
        this.slugError = this.slugErrorUnsuccess;
        return false;
      };
      if (this.orgSlugs.find((o: any) => o == this.slug.toLowerCase())) {
        this.slugError = this.slugErrorUnsuccess;
        return false;
      };
      if (!/^[a-zA-Z0-9]+$/.test(this.slug)) {
        this.slugError = this.slugErrorUnsuccess;
        return false;
      };
      return true;
    }

    onSlugKeyDown(event: any) {
      event.target.blur();
    }

    copying = false;
    sendToClipboard() {
      this.copying = true;
      let what = `orders@${this.slug}.getmeti.com`;
      if (navigator.clipboard && window.isSecureContext) {
        navigator.clipboard.writeText(what).then(() => {});
        setTimeout(() => {
          this.copying = false;
        }, 2000);
      }
    }

    getOrgSlugs() {
      this.dashboardService.getOrgSlugs().subscribe((result: any) => {
        let data = result?.data;
        let slugs = data
          ?.filter((r: any) => r.organization != this.orgData.id)
          ?.map((r: any) => (r.slug.toLowerCase()))
        this.orgSlugs = slugs;
      })
    }
}
