import { ChangeDetectorRef, Component, OnInit, ViewChild, Input } from '@angular/core';
import { ElementRef, HostListener } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { DashboardService } from 'src/app/dashboard/services/dashboard.service';
import { DashboardJobView } from 'src/app/dashboard/services/dashboard_job_view.service';


@Component({
  selector: 'materlog-workflows-choose',
  templateUrl: './workflow-choose.component.html',
  styleUrls: [
    '../../workflows/workflows.component.css',
  ]
})
export class MaterlogWorkflowsChoose implements OnInit {
    @Input() dropdownOptions: any;
    @Input() allMailData: any;
    @Input() orgData: any = {};
    @Input() box: any = {};
    @Input() chosenPic: string = '';
    @Output() applyUpdate = new EventEmitter<any>();

    slug: any = 'SLUG';
    slugErrorTextNeed = 'Slugs need to be alphanumeric';
    slugErrorUnsuccess = 'URL unavailable. Please try another.';
    slugError = '';
    showSlugSuccess: boolean = false;
    showSlugError: boolean = false;
    orgSlugs: any = [];
    profile: any = undefined;

    chooseHow = [
      {
        name: 'Gmail extension',
        soon: true,
        text: 'Install the extension for gmail users. The extension lets you automatically add, update and view order information directly from your inbox.',
        active: false,
      },
      {
        name: 'Forward emails',
        soon: false,
        text: 'Send emails to a unique email address. Anything received will be parsed and added to your account.',
        active: true,
      },
    ]

    customWhere = [
      {
        name: 'Customize your Jobs',
        page: 'job',
        class: 'bi-layers',
        text: 'Set the day of the week to check in, who to copy, and more.',
      },
      {
        name: 'Customize your Suppliers',
        page: 'supplier',
        class: 'bi-people',
        text: 'Set the default check in method.',
      },
    ]

    ngOnChanges(changes: any) {

    }

    ngOnInit(): void {
        this.getOrgSlugs();
        (window as any)['wf_choose'] = this;
        this.resetSlug();
    }

    resetSlug() {
      this.profile = JSON.parse(localStorage.getItem('profile') as any);
      this.slug = this.profile.slug || 'SLUG';
    }

    get slugWidth() {
        return `${this.slug.length + 5}ch`;
    }

    constructor(
      private djv: DashboardJobView,
      private dashboardService: DashboardService,
    ) {}

    setChooseHowActive(how: any) {
      if (how.soon) return;
      this.chooseHow.forEach((p: any) => {
        if (p == how) {
          p.active = true;
        } else {
          p.active = false;
        }
      })
    }

    get forwardChosen() {
      let found = this.chooseHow.find((ch: any) => ch.active);
      if (!found) return false;
      return found.name == 'Forward emails';
    }

    onSlugBlur(event: any) {
      if (!this.ensureSlugValidity()) {
        this.showSlugError = true;
        setTimeout(() => {
          this.showSlugError = false;
          this.slugError = '';
          this.resetSlug();
        }, 1500);
        return;
      }
      if (this.slug == this.profile.slug) return;
      let data = {slug: this.slug}
      this.dashboardService.writeOrgSlug(data).subscribe((result: any) => {
        if (!result.success) {
          this.showSlugError = true;
          this.slugError = this.slugErrorUnsuccess;
          setTimeout(() => {
            this.showSlugError = false;
            this.slugError = '';
          }, 1500);
        } else {
            let profile = JSON.parse(localStorage.getItem('profile') as any);
            profile.slug = this.slug;
            localStorage.setItem('profile', JSON.stringify(profile));
            this.showSlugSuccess = true;
            setTimeout(() => {
              this.showSlugSuccess = false;
            }, 1500);
        }
      })
    }

    ensureSlugValidity() {
      if (!this.slug) {
        this.slugError = this.slugErrorUnsuccess;
        return false;
      };
      if (this.orgSlugs.find((o: any) => o == this.slug.toLowerCase())) {
        this.slugError = this.slugErrorUnsuccess;
        return false;
      };
      if (!/^[a-zA-Z0-9]+$/.test(this.slug)) {
        this.slugError = this.slugErrorUnsuccess;
        return false;
      };
      return true;
    }

    onSlugKeyDown(event: any) {
      event.target.blur();
    }

    goToPage(where: any) {
        this.djv.do_home_page_clicked(where.page);
    }

    copying = false;
    sendToClipboard() {
      this.copying = true;
      let what = `orders@${this.slug}.getmeti.com`;
      if (navigator.clipboard && window.isSecureContext) {
        navigator.clipboard.writeText(what).then(() => {});
        setTimeout(() => {
          this.copying = false;
        }, 2000);
      }
    }

    getOrgSlugs() {
      this.dashboardService.getOrgSlugs().subscribe((result: any) => {
        let data = result?.data;
        let slugs = data
          ?.filter((r: any) => r.organization != this.orgData.id)
          ?.map((r: any) => (r.slug.toLowerCase()))
        this.orgSlugs = slugs;
      })
    }
}
