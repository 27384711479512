<materlog-loader class="loader" *ngIf="isLoading"></materlog-loader>
<materlog-loader class="loader-center" *ngIf="isLoadingOverlay && !isLoading"></materlog-loader>
<p-table
  *ngIf="!isLoading"
  [columns]="columns"
  [value]="jobs"
  styleClass="editable-table"
  [resizableColumns]="true"
  [reorderableColumns]="true"
  [columnResizeMode]="'expand'"
  [scrollable]="true"
  scrollHeight="flex"
  [contextMenu]="cm"
  [(contextMenuSelection)]="selectedRow"
  (onColReorder)="onColumnReorder($event)"
  (onColResize)="onColResize($event)"
  [virtualScroll]="true"
  [virtualScrollItemSize]="32"
  [rowTrackBy]="trackByRows"
  dataKey="job_id"
  [lazy]="true"
  [rows]="1000"
  (onLazyLoad)="loadJobsLazy($event)"
>
  <ng-template pTemplate="loadingbody" let-columns="columns">
    <tr style="display: flex; height: 32px">
      <td
        [hidden]="!col.visible"
        *ngFor="let col of columns"
        [ngStyle]="{
          width: col.width + 'px',
          padding: '8px'
        }"
      >
        <p-skeleton></p-skeleton>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="header" let-columns>
    <tr style="display: flex; height: 32px">
      <th
        *ngFor="let col of columns"
        [hidden]="!col.visible"
        pResizableColumn
        pReorderableColumn
        [ngStyle]="{
          width: col.width + 'px',
          textOverflow: 'ellipsis'
        }"
        [ngClass]="{ sticky: col.field === 'index' }"
      >
        <div class="row-border-right">{{ col.header ? col.header : '&nbsp;' }}</div>
      </th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-data let-columns="columns" let-index="rowIndex">
    <tr [pContextMenuRow]="data" style="height: 32px; display: flex">
      <td
        *ngFor="let col of columns"
        [hidden]="!col.visible"
        pEditableColumn
        [ngStyle]="{
          height: '32px',
          width: col.width + 'px',
          textOverflow: 'ellipsis',
          color: col.field === 'index' ? '#9a9a9a' : 'inherit'
        }"
        [ngClass]="{
          sticky: col.field === 'index',
          historyBackgroundColor: data.fields_for_history_visible?.includes(col.field)
        }"
      >
        <span *ngIf="!customColumnsLayout?.includes(col.field)">
          <div *ngIf="!col.editable" class="cell-output">
            {{ data[col.field] }}
          </div>

          <p-cellEditor *ngIf="col.editable && !col.type">
            <ng-template pTemplate="input">
              <input
                pInputText
                blockLateralArrowNavigation
                nullValue
                type="text"
                [(ngModel)]="data[col.field]"
                (keydown.enter)="onChange(col.field, data[col.field], data['id'], data['order_id'])"
                (change)="onChange(col.field, data[col.field], data['id'], data['order_id'])"
              />
            </ng-template>
            <ng-template pTemplate="output">
              {{ data[col.field] }}
            </ng-template>
          </p-cellEditor>

          <p-cellEditor *ngIf="col.editable && col.type === 'dropdown'">
            <ng-template pTemplate="input">
              <p-dropdown
                blockLateralArrowNavigation
                panelStyleClass="custom-dropdown-panel"
                autoWidth="false"
                appendTo="body"
                [showClear]="true"
                [options]="dropdownOptions[col.field]"
                [(ngModel)]="data[col.field]"
                name="dropdown"
                optionValue="id"
                optionLabel="value"
                [filter]="true"
                [placeholder]="col.header"
                (onChange)="onChange(col.field, data[col.field], data['id'], data['order_id'])"
                (onClear)="
                  onChange(
                    col.field,
                    col.field === 'shipping_state' || col.field === 'shipping_country' || col.field === 'job_id'
                      ? null
                      : 0,
                    data['id'],
                    data['order_id']
                  )
                "
              >
              </p-dropdown>
            </ng-template>
            <ng-template pTemplate="output">
              <ng-container *ngFor="let dropdownItem of dropdownOptions[col.field]">
                <ng-container *ngIf="dropdownItem.id === data[col.field]">
                  <span *ngIf="col.field !== 'is_reimbursable'">
                    {{ dropdownItem.value === 'Not selected' || dropdownItem.value === 'No' ? '' : dropdownItem.value }}
                  </span>
                  <span *ngIf="col.field === 'is_reimbursable'">{{
                    dropdownItem.value === 'Not selected' ? '' : dropdownItem.value
                  }}</span>
                </ng-container>
              </ng-container>
            </ng-template>
          </p-cellEditor>

          <p-cellEditor *ngIf="col.editable && col.type === 'date'">
            <ng-template pTemplate="input">
              <p-calendar
                appendTo="body"
                placeholder="MM-DD-YYYY"
                dateFormat="mm/dd/yy"
                dataType="date"
                [showClear]="true"
                [readonlyInput]="true"
                [(ngModel)]="data[col.field]"
                (onSelect)="onChangeDate(col.field, data[col.field], data['id'])"
                (onClear)="onChangeDate(col.field, data[col.field], data['id'])"
              >
              </p-calendar>
            </ng-template>
            <ng-template pTemplate="output">
              {{ data[col.field] | date : 'MM/dd/yyyy' }}
            </ng-template>
          </p-cellEditor>
        </span>

        <!--Custom columns layout-->
        <div *ngIf="col.field === 'index'" class="cell-output">
          <span [ngStyle]="{ borderLeft: '4px solid' + data['status_color'] }" class="cell-border-left">
            <span class="cell-border-left-text"> {{ index + 1 }} </span>
          </span>
        </div>

        <div *ngIf="col.field === 'next_supplier_follow_up_date'" class="cell-output">
          <span
            [ngClass]="{ 'italic-text': !CommonConstants.dateRegex.test(data[col.field]) }"
            class="cell-border-left-text"
          >
            {{ data[col.field] }}
          </span>
        </div>

        <p-cellEditor *ngIf="col.field === 'status'">
          <ng-template pTemplate="input">
            <p-dropdown
              blockLateralArrowNavigation
              panelStyleClass="custom-dropdown-panel"
              autoWidth="false"
              appendTo="body"
              [showClear]="true"
              [options]="dropdownOptions[col.field]"
              [(ngModel)]="data[col.field]"
              name="dropdown"
              optionValue="id"
              optionLabel="value"
              [filter]="true"
              [placeholder]="col.header"
              (onChange)="onChange(col.field, data[col.field], data['id'])"
              (onClear)="onChange(col.field, 1, data['id'])"
            >
            </p-dropdown>
          </ng-template>
          <ng-template pTemplate="output">
            <ng-container *ngFor="let dropdownItem of dropdownOptions[col.field]">
              <span *ngIf="dropdownItem.id === data[col.field]">
                <span class="custom-color-cell" [ngStyle]="{ backgroundColor: data['status_color'] }">
                  {{ dropdownItem.value === 'Not selected' ? '' : dropdownItem.value }}</span
                >
              </span>
            </ng-container>
          </ng-template>
        </p-cellEditor>

        <div *ngIf="col.field === 'po_document_number'" class="orders-row cell-output">
          <span class="orders-items">
            <div *ngIf="data[col.field]?.number" class="number">
              <span class="orders-item--button" (click)="openOrderModal(data[col.field]?.id, true, data.id)">
                {{ data[col.field]?.number }}
              </span>
              <i class="close-icon bi bi-x-lg" (click)="deleteOrder(data[col.field]?.id, data)"></i>
            </div>
          </span>
          <span
            *ngIf="!data[col.field]?.number"
            class="orders-item orders-item--button-plus"
            (click)="openOrderModal(null, true, data.id)"
          >
            +
          </span>
        </div>

        <ng-container *ngIf="col.field === 'po_document_date'">
          <div class="cell-output">
            <span
              *ngIf="data[col.field]"
              style="cursor: pointer"
              (click)="openOrderModal(data['po_document_number']?.id, true, data.id)"
            >
              {{ data[col.field] }}
            </span>
          </div>
        </ng-container>

        <div *ngIf="col.field === 'supplier_documents_numbers'" class="orders-row cell-output">
          <span class="orders-items">
            <div *ngFor="let item of data[col.field]" class="number">
              <span class="orders-item--button" (click)="openOrderModal(item.id, false, data.id)">
                {{ item.number }}
              </span>
              <i class="close-icon bi bi-x-lg" (click)="deleteOrder(item.id, data)"></i>
            </div>
          </span>
          <span class="orders-item orders-item--button-plus" (click)="openOrderModal(null, false, data.id)"> + </span>
        </div>

        <p-cellEditor *ngIf="col.field === 'supplier_id'">
          <ng-template pTemplate="input">
            <p-autoComplete
              blockLateralArrowNavigation
              autoWidth="false"
              styleClass="custom-autocomplete-panel"
              appendTo="body"
              [showClear]="true"
              [dropdown]="true"
              [suggestions]="checkSupplierSuggestions(data['id']) ? temporarySupplierList : dropdownOptions[col.field]"
              (completeMethod)="filterSupplier($event, data)"
              (onDropdownClick)="getSuppliers(data)"
              name="name"
              field="name"
              [ngModel]="data[col.field]"
              (ngModelChange)="setTemporarySupplierValue($event)"
              [placeholder]="col.header"
              (onSelect)="onChange(col.field, temporarySupplierValue, data['id'], data['order_id'])"
              (onClear)="onChange(col.field, null, data['id'], data['order_id'])"
            >
              <ng-template pTemplate="footer">
                <button
                  pButton
                  type="button"
                  label="+ Add new supplier"
                  class="p-button-text p-button-plain"
                  (click)="onOpenModalAddSupplier(data)"
                ></button>
              </ng-template>
            </p-autoComplete>
          </ng-template>
          <ng-template pTemplate="output">
            <ng-container *ngFor="let dropdownItem of dropdownOptions[col.field]">
              <span *ngIf="dropdownItem?.id === data[col.field]?.id">
                {{ dropdownItem.name }}
              </span>
            </ng-container>
          </ng-template>
        </p-cellEditor>

        <p-cellEditor *ngIf="col.field === 'supplier_contact_id'">
          <ng-template pTemplate="input">
            <p-dropdown
              blockLateralArrowNavigation
              panelStyleClass="custom-dropdown-panel"
              autoWidth="false"
              appendTo="body"
              [showClear]="true"
              [options]="getSupplierContactsOptions(data['supplier_id']?.id)"
              [(ngModel)]="data[col.field]"
              ngDefaultControl
              optionValue="id"
              optionLabel="name"
              [filter]="true"
              [placeholder]="col.header"
              (onChange)="onChange(col.field, data[col.field], data['id'], data['order_id'])"
            >
              <ng-template pTemplate="footer">
                <button
                  *ngIf="data['supplier_id']?.id"
                  pButton
                  type="button"
                  label="+ Add new supplier contact"
                  class="p-button-text p-button-plain"
                  (click)="onOpenModalAddSupplierContact(data)"
                ></button>
              </ng-template>
            </p-dropdown>
          </ng-template>
          <ng-template pTemplate="output">
            <ng-container *ngFor="let dropdownItem of getSupplierContactsOptions(data['supplier_id']?.id)">
              <span *ngIf="dropdownItem.id === data[col.field]">
                {{ dropdownItem.name }}
              </span>
            </ng-container>
          </ng-template>
        </p-cellEditor>

        <div
          *ngIf="col.field === 'po_document_attachments' || col.field === 'supplier_documents_attachments'"
          class="orders-row cell-output"
        >
          <span class="orders-items">
            <div *ngIf="data[col.field]?.length" class="d-flex gap-1">
              <div
                style="cursor: pointer"
                *ngFor="let attachment of data[col.field]"
                [ngClass]="attachment.attachment_preview ? 'orders-item' : 'orders-item orders-item--no-border'"
              >
                <!-- if it's an image open the p-image , if not call openDocumentModal -->
                <ng-container *ngIf="!checkFileType(attachment.filename)">
                  <p-image
                    class="cell-image"
                    src="{{
                      attachment.attachment_preview ? attachment.attachment_preview : 'assets/img/default-doc-img.png'
                    }}"
                    alt="{{ attachment.filename }}"
                    width="20"
                    (click)="openDocumentModal(attachment.attachment, attachment.filename)"
                  >
                    <ng-template pTemplate="indicator">
                      <fa-icon class="icon" [icon]="faEye"></fa-icon>
                    </ng-template>
                  </p-image>
                </ng-container>
                <ng-container *ngIf="checkFileType(attachment.filename)">
                  <p-image
                    appendTo="body"
                    class="cell-image"
                    src="{{ attachment.attachment ? attachment.attachment : 'assets/img/default-doc-img.png' }}"
                    alt="{{ attachment.filename }}"
                    [preview]="true"
                    width="20"
                  >
                    <ng-template pTemplate="indicator">
                      <fa-icon class="icon" [icon]="faEye"></fa-icon>
                    </ng-template>
                  </p-image>
                </ng-container>
                <i
                  class="close-icon bi bi-x-lg"
                  (click)="deleteFile($event, 'attachment_id_to_delete', data['id'], attachment.id, col.field)"
                ></i>
              </div>
            </div>
          </span>
        </div>

        <div *ngIf="col.field === 'images'" class="orders-row cell-output">
          <span class="orders-items">
            <div *ngFor="let image of data[col.field]" class="orders-item-image">
              <p-image
                appendTo="body"
                class="cell-image"
                src="{{ image.image_thumbnail ? image.image_thumbnail : image.image }}"
                alt="{{ image.name }}"
                [preview]="true"
                width="20"
              >
                <ng-template pTemplate="indicator">
                  <fa-icon class="icon" [icon]="faEye"></fa-icon>
                </ng-template>
              </p-image>
              <i
                class="close-icon bi bi-x-lg"
                (click)="deleteFile($event, 'image_id_to_delete', data['id'], image.image_id, col.field)"
              ></i>
            </div>
          </span>
          <span class="orders-item orders-item--button-plus" (click)="fileInput.click()">
            <input
              multiple
              #fileInput
              type="file"
              hidden
              accept=".png, .jpeg, .jpg"
              (change)="addImages(col.field, $any($event.target).files, data['id'])"
            />
            +
          </span>
        </div>

        <p-cellEditor *ngIf="col.field === 'room_id'">
          <ng-template pTemplate="input">
            <p-dropdown
              blockLateralArrowNavigation
              panelStyleClass="custom-dropdown-panel"
              autoWidth="false"
              appendTo="body"
              [showClear]="true"
              [filter]="true"
              [options]="dropdownOptions[col.field]"
              [(ngModel)]="data[col.field]"
              name="dropdown"
              optionValue="id"
              optionLabel="value"
              [placeholder]="col.header"
              (onChange)="onChange('room_id', data[col.field], data['id'])"
              (onClear)="onChange('room_id', null, data['id'])"
            >
              <ng-template pTemplate="footer">
                <button
                  pButton
                  type="button"
                  label="+ Add new room"
                  class="p-button-text p-button-plain"
                  (click)="onOpenAddRoomModal(data['id'])"
                ></button>
              </ng-template>
            </p-dropdown>
          </ng-template>
          <ng-template pTemplate="output">
            <ng-container *ngFor="let dropdownItem of dropdownOptions[col.field]">
              <span *ngIf="dropdownItem.id === data[col.field]">
                {{ dropdownItem.value }}
              </span>
            </ng-container>
          </ng-template>
        </p-cellEditor>

        <p-cellEditor *ngIf="col.field === 'total_cost'">
          <ng-template pTemplate="input">
            <input
              pInputText
              blockLateralArrowNavigation
              nullValue
              type="number"
              [(ngModel)]="data[col.field]"
              (keydown.enter)="onChange(col.field, data[col.field], data['id'], data['order_id'])"
              (change)="onChange(col.field, data[col.field], data['id'], data['order_id'])"
            />
          </ng-template>
          <ng-template pTemplate="output">
            <i *ngIf="data[col.field]" class="input_with_icon_placeholder bi bi-currency-dollar"></i>
            {{ data[col.field] | number : '1.2' }}
          </ng-template>
        </p-cellEditor>

        <p-cellEditor
          *ngIf="col.field === 'link_to_material' || col.field === 'supplier_website' || col.field === 'tracking_info'"
        >
          <ng-template pTemplate="input">
            <input
              pInputText
              blockLateralArrowNavigation
              nullValue
              type="text"
              [(ngModel)]="data[col.field]"
              (keydown.enter)="onChange(col.field, data[col.field], data['id'], data['order_id'])"
              (change)="onChange(col.field, data[col.field], data['id'], data['order_id'])"
            />
          </ng-template>
          <ng-template pTemplate="output">
            <a
              *ngIf="data[col.field]"
              class="link link--no-underline"
              href="//{{ data[col.field].includes('://') ? data[col.field].split('://')[1] : data[col.field] }}"
              target="_blank"
            >
              {{ data[col.field] }}
            </a>
          </ng-template>
        </p-cellEditor>
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="emptymessage" let-columns>
    <tr>
      <td [attr.colspan]="columns.length">
        <div class="empty-table">
          <div class="empty-table-box" *ngIf="!appliedFilters">
            <img src="assets/img/empty-table-loop.svg" alt="Magnifying glass empty table" />
            <b class="empty-table-box-bold">Start adding orders</b>
            <p class="empty-table-box-text">Click "+ Add order"</p>
            <p class="empty-table-box-text">or</p>
            <p class="empty-table-box-text">
              Forward POs or order confirmations to <a href="mailto:orders@getmeti.com">orders@getmeti.com</a>
            </p>
          </div>
          <div class="empty-table-box" *ngIf="appliedFilters">
            <img src="assets/img/empty-table-loop.svg" alt="Magnifying glass empty table" />
            <b class="empty-table-box-bold">No results</b>
            <p class="empty-table-box-text">We couldn't retrieve any records based on your chosen filters.</p>
          </div>
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>

<add-edit-doc
  [data]="selectedOrder"
  [docTypes]="orderOptions"
  [showDialog]="showOrderDialog"
  [isPO]="isPo"
  [itemId]="selectedRowId"
  (closeDialog)="closeOrderModal($event)"
  (addUpdateOrder)="updatedOrderModal($event)"
></add-edit-doc>

<p-dialog
  [(visible)]="showDialog"
  [style]="{ marginTop: '250px', width: '450px' }"
  [closeOnEscape]="false"
  [modal]="true"
  [resizable]="false"
  [draggable]="false"
  [position]="'top'"
  [closable]="false"
  [maskStyleClass]="'dialog-mask'"
  appendTo="body"
>
  <ng-template pTemplate="header">
    <div class="dialog__header">
      <span>Delete item</span>
    </div>
    <button class="dialog-close" type="button" (click)="closeDialog()"><i class="bi bi-x-lg"></i></button>
  </ng-template>
  <ng-template pTemplate="content">
    <div class="dialog__content">
      <span class="dialog__content-info">
        Are you sure you want to delete this item? Deleting the item means this record will no longer show up for anyone
        at your company and will no longer be tracked. This cannot be undone.
      </span>

      <div class="dialog__content-radio" *ngFor="let reason of dropdownOptions?.reason_to_not_display?.slice(1)">
        <p-radioButton [value]="reason.id" [label]="reason.value" [(ngModel)]="reasonToDelete"></p-radioButton>
      </div>

      <textarea
        *ngIf="reasonToDelete == 3"
        class="dialog__content-input dialog__content-input-text"
        pInputTextarea
        [(ngModel)]="reasonToDeleteText"
        placeholder="Please tell us why ..."
        maxlength="250"
        minlength="1"
      ></textarea>
      <span class="textfield__error-message" *ngIf="isSubmitted && isDeleteError">Please provide a reason</span>
    </div>
  </ng-template>

  <ng-template pTemplate="footer">
    <div class="dialog-supplier__content-action">
      <button class="dialog-supplier__content-action-cancel" type="button" (click)="closeDialog()">Cancel</button>
      <button class="dialog-supplier__content-action-button" type="submit" (click)="deleteItem(deleteRowId)">
        Delete
      </button>
    </div>
  </ng-template>
</p-dialog>

<p-dialog
  [(visible)]="addRoomModal"
  [modal]="true"
  [resizable]="false"
  [draggable]="false"
  [closable]="false"
  [closeOnEscape]="false"
  [position]="'top'"
  [style]="{ marginTop: '150px', width: '588px' }"
  [maskStyleClass]="'dialog-mask'"
  appendTo="body"
>
  <ng-template pTemplate="header">
    <div class="dialog__header">
      <span> Add new room </span>
    </div>
    <button class="dialog-close" type="button" (click)="onCloseAddRoomModal()">
      <i class="bi bi-x-lg"></i>
    </button>
  </ng-template>
  <ng-template pTemplate="content">
    <input
      pInputText
      nullValue
      type="text"
      name="addRoomName"
      class="form-controls dialog__content-input-text"
      placeholder="Name"
      [(ngModel)]="roomName"
      (keyup.enter)="onSaveRoomName(selectedRowId)"
    />
    <div class="textfield__error-message" *ngIf="isAddRoomNameSubmitted && !roomName?.trim()">Add a room name</div>
  </ng-template>
  <ng-template pTemplate="footer">
    <button
      pButton
      type="submit"
      label="Save"
      class="button-primary p-button-raised mt-4"
      (click)="onSaveRoomName(selectedRowId)"
    ></button
  ></ng-template>
</p-dialog>

<p-dialog
  [(visible)]="addSupplierModal"
  [style]="{ marginTop: '250px', width: '500px' }"
  [closeOnEscape]="false"
  [modal]="true"
  [resizable]="false"
  [draggable]="false"
  [position]="'top'"
  [closable]="false"
  [maskStyleClass]="'dialog-mask'"
>
  <ng-template pTemplate="header">
    <div class="dialog__header">
      <span>Add supplier</span>
    </div>
    <button class="dialog-close" type="button" (click)="closeSupplier()"><i class="bi bi-x-lg"></i></button>
  </ng-template>
  <materlog-add-supplier-modal
    [resetSupplier]="addSupplierModal"
    (supplierFormChange)="supplierFormChangeEvent($event)"
  ></materlog-add-supplier-modal>
</p-dialog>

<p-dialog
  [(visible)]="addSupplierContactModal"
  [style]="{ marginTop: '150px', width: '540px' }"
  [closeOnEscape]="false"
  [modal]="true"
  [resizable]="false"
  [draggable]="false"
  [position]="'top'"
  [closable]="false"
  [maskStyleClass]="'dialog-mask'"
>
  <ng-template pTemplate="header">
    <div class="dialog__header">
      <span>Add supplier contact</span>
    </div>
    <button class="dialog-close" type="button" (click)="closeSupplierContact()"><i class="bi bi-x-lg"></i></button>
  </ng-template>
  <materlog-add-supplier-contact-modal
    [resetContact]="addSupplierContactModal"
    [contactRoles]="dropdownOptions['supplier_contact_role']"
    [supplierList]="dropdownOptions['supplier_id']"
    [supplierId]="selectedSupplier"
    (supplierContactFormChange)="supplierContactFormChangeEvent($event)"
  ></materlog-add-supplier-contact-modal>
</p-dialog>

<p-dialog
  [(visible)]="documentModal"
  [style]="{ marginTop: '50px', width: '90vw', height: '90vh' }"
  [closeOnEscape]="true"
  [modal]="true"
  [resizable]="false"
  [draggable]="false"
  [position]="'top'"
  [closable]="false"
  [maskStyleClass]="'dialog-mask'"
>
  <ng-template pTemplate="header">
    <div class="dialog__header">
      <span></span>
    </div>
    <button class="dialog-close" type="button" (click)="closeDocumentModal()"><i class="bi bi-x-lg"></i></button>
  </ng-template>
  <ng-template pTemplate="content">
    <iframe [src]="selectedAttachment | sanitize" allow="fullscreen" width="100%" height="98%"></iframe>
  </ng-template>
</p-dialog>

<p-contextMenu #cm [model]="menu"></p-contextMenu>
