import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { EventEmitter, Output, ChangeDetectorRef} from '@angular/core';
import { ElementRef, HostListener } from '@angular/core';
import { OrderService } from '../../services/order.service';
import { JobsService } from '../../services/job.service';
import { DashboardActions } from '../../state/actions/dashboard.actions';
import { generateRandomString } from 'src/app/common/utils/general';
import { setScrollBar } from 'src/app/common/utils/general';

@Component({
  selector: 'materlog-docs-auto-upload',
  templateUrl: './order-docs-auto-upload.component.html',
  styleUrls: ['./order-docs-auto-upload.component.css'],
})
export class OrderDocsAutoUploadComponent implements OnInit {
    @Input() allMailData: any;
    @Input() dropdownOptions?: any;
    @Input() msgData: any = undefined;
    @Input() selectedId: any = undefined;

    @ViewChild('materlogScroll') materlogScroll!: ElementRef;
    @ViewChild('materlogDocsContainer') materlogDocsContainer!: ElementRef;

    docs: any = [];

    ngOnInit() {
        this.docs = this.msgData?.order?.documents || [];
    }
    
    ngOnChanges(changes: any) {
        if (changes.selectedId) {
            this.docs = this.msgData?.order?.documents || [];
        }
    }
  
    ngAfterViewInit() {
        setScrollBar(this.materlogDocsContainer, '.materlog-doc-scrollable');
    }

    constructor(
        private orderService: OrderService,
        private cdr: ChangeDetectorRef,
        private jobsService: JobsService,
        private dashboardActions: DashboardActions
    ) {
        (window as any)['docsup'] = this;
    }

    docNameForNumber(doc: any) {
        let dt = doc.document_type;
        let opts = this.dropdownOptions?.document_types;
        if (!opts) return '';
        let found = opts.find((o: any) => o.id == dt);
        return found ? found.value: '';
    }
    
    downloadDoc(att: any) {
        const attachmentUrl = att.aws_url || att.attachment;
        if (attachmentUrl) {
          window.open(attachmentUrl, '_blank');
        }
    }
    
    onDropdownChange(event: any, doc: any) {
        doc.document_type = event.id;
        this.onBlurDocConfirm(doc);
    }
    
    deleteDoc(doc: any) {
        this.orderService.deleteOrderDocumentExtract(doc.id).subscribe(() => {});
        this.docs = this.docs.filter((d: any ) => d != doc);
        let docs = this.msgData.order.documents;
        docs = docs.filter((d: any) => d.id != doc.id);
        this.msgData.order.documents = docs;
    }
    
    
    deleteAtt(doc: any, att: any) {
        this.orderService.deleteOrderAttachmentExtract(att.id).subscribe(() => {});
        let docFound = this.msgData?.order?.documents?.find((d: any) => d.id == doc.id);
        if (docFound?.attachments) {
          docFound.attachments = docFound.attachments.filter((a: any) => a.id != att.id);
        }
        this.cdr.detectChanges();
    }  
    
    onFocus(att: any, is_doc: boolean) {
        this.docs.forEach((d: any) => {
            d.attachments?.forEach((a: any) => {
            a.editingFilename = a.editingNumber = false;
            });
            d.editingFilename = d.editingNumber = false;
        });
        if (is_doc) {
            att.editingNumber = true;
        } else {
            att.editingFilename = true;
        }
        this.cdr.detectChanges();
        setTimeout(() => {
            if (this.materlogScroll?.nativeElement) {
            let el = this.materlogScroll.nativeElement.querySelector('.materlog-ship-input-active');
            if (el) el.focus();
            this.cdr.detectChanges();
            }
        }, 150);
    }
    
    onEnter(event: any) {
        event.target.blur();
    }
    
    onBlurDocConfirm(doc: any) {
        let bad_number = 'Document #';
        let data = {
            number: doc.number || bad_number,
            document_type: doc.document_type,
        };
        let searchId = doc.id;
        let payload = {
            data: data,
            id: searchId,
            new: doc.new,
            order_id: this.msgData.order.id
        };
        delete doc.new;
        this.orderService.updateOrderDocumentExtract(payload).subscribe((result: any) => {
            if (result?.id) {
                let found = this.msgData?.order?.documents.find((d: any) => d.id == searchId);
                if (!found) {
                    this.msgData.order.documents.push(doc);
                } else {
                    found.number = data.number == bad_number ? '': data.number;
                    found.document_type = data.document_type;
                    found.id = result.id;
                }
                doc.id = result.id;
            };
        });
    }
    
    onBlurAttConfirm(doc: any, att: any) {
        let payload = {
            filename: att.filename,
            id: att.id,
        };
        this.orderService.updateOrderAttachmentExtract(payload).subscribe((result: any) => {
            let found = this.msgData?.order?.documents.find((d: any) => d.id == doc.id);
            if (found) {
                let attFound = found.attachments.find((a: any) => a.id == att.id);
                if (attFound) {
                    attFound.filename = att.filename;
                }
            }
        });
    }

    onBlur(doc: any, att: any, is_doc: boolean) {
        if (is_doc) {
            this.onBlurDocConfirm(doc);
        } else {
            this.onBlurAttConfirm(doc, att);
        }
        this.stopEditingDocs();
    }
    
    stopEditingDocs() {
        this.docs.forEach((d: any) => {
            d.attachments?.forEach((a: any) => {
                a.editingFilename = a.editingNumber = false;
            });
            d.editingFilename = d.editingNumber = false;
        })
    }
    
    docHasId(doc: any) {
        return doc.attachments?.find((a: any) => !a.delete);
    }
    
    attForDoc(doc: any) {
        return doc.attachments?.filter((a: any) => !a.delete);
    }
    
    addNewDoc() {
        if (this.docs.find((d: any) => !d.number)) {
          return;
        }
        let cur = {
            attachments: [],
            document_type: 0,
            id: generateRandomString(),
            number: '',
            new: true,
            order_document_date: null,
        }
        if (!this.msgData?.order?.documents) {
            this.msgData.order.documents = [];
        }
        this.msgData.order.documents.push(cur);
        if (this.materlogScroll?.nativeElement) {
            setTimeout(() => {
                let element = this.materlogScroll.nativeElement;
                element.scrollTop = element.scrollHeight;
        
                let inputs = element.querySelectorAll('.materlog-ship-input');
                if (inputs) {
                    inputs[inputs.length - 1].focus();
                }
                this.cdr.detectChanges();
            }, 100);
        }
        this.cdr.detectChanges();
      }
    
    uploadFile(event: any) {
        
    }
    
    onFileSelected(event: any, doc: any) {
        const input = event.target as HTMLInputElement;
        const formData = new FormData();
        if (input.files) {
          for (let i = 0; i < input.files.length; i++) {
            let cur = input.files[i];
            // does this already exist?
            if (doc?.attachments?.find((a: any) => a.filename == cur.name)) {
              continue;
            }
            let rstring = generateRandomString();
            let location = 'order_documents/' + rstring;
    
            formData.append('file', cur, cur.name);
            formData.append('path', location);
            let att = {
              filename: cur.name, id: rstring,
              attachment: location, needs_upload: true,
            };
            let curDoc = this.docForId(doc.id);
            curDoc = this.msgData?.order?.documents?.find((od: any) => od.id == doc.id);
            if (curDoc) {
              formData.append('order_doc_id', curDoc.id);
            }
            if (!curDoc) return;
            if (!curDoc.attachments) {
              curDoc.attachments = [];
            }
            
            curDoc.attachments.push(att);
    
            this.docs = [...this.docs];
            this.cdr.detectChanges();
            this.orderService.uploadDocumentExtract(formData).subscribe((result: any) => {
              if (!result.success && curDoc) {
                curDoc.attachments = curDoc.attachments?.filter((a: any) => a.id != rstring) || [];
              } else {
                let found = curDoc.attachments?.find((i: any) => i.id == rstring);
                if (found) {
                  found.aws_url = result.aws_url;
                }
              }
              let found = curDoc.attachments.find((a: any) => a.id == rstring);
              if (found) {
                found.id = result.id;
                found.filename = result.filename;
                found.attachment = result.attachment;
              }
              this.docs = [...this.docs];
              this.cdr.detectChanges();
            })
          }
        }
        event.target.value = '';
      }
    
    
    docForId(doc_id: any) {
        return this.msgData?.order?.documents?.find((od: any) => od.id == doc_id);
    }
}