import { ChangeDetectorRef, Input, Output, EventEmitter} from '@angular/core';
import { ViewChildren, QueryList } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HostListener } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Component, ViewChild, ElementRef, Renderer2, AfterViewInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { DashboardActions } from 'src/app/dashboard/state/actions/dashboard.actions';
import { PayloadService } from "../../../services/payload.service";
import { HttpClient } from "@angular/common/http";
import { JobTableService } from "../../../services/job-table.service";
import { OptionsService } from 'src/app/dashboard/services/options.service';
import tippy from 'tippy.js';
import { DashboardRoutes } from 'src/app/dashboard/enums/dashboard-routes.enum';
import { FormsModule } from '@angular/forms';
import { ListboxModule } from 'primeng/listbox';
import { DialogModule } from 'primeng/dialog';
import { AddRoomModalComponent } from '../add-room-dialog/add-room-modal.component';
import { AddSupplierContactModalComponent } from '../../add-supplier-contact-modal/add-supplier-contact-modal.component';
import { AddSupplierModalComponent } from '../../add-supplier-modal/add-supplier-modal.component';
import { DashboardService } from 'src/app/dashboard/services/dashboard.service';


@Component({
  selector: 'shiplink-cell-renderer',
  templateUrl: './shiplink.component.html',
  styleUrls: ['./shiplink.component.css',
  ],
  imports: [CommonModule, FormsModule, ListboxModule, DialogModule],
  standalone: true
})

export class ShipLinkCellRenderer implements ICellRendererAngularComp, AfterViewInit {
  @ViewChildren('inputField') inputFields!: QueryList<ElementRef>;
  @ViewChild('container') container!: ElementRef;
  @Input() isEditable: boolean = true;
  @Input() maxWidth!: number;
  @Input() cardView: boolean = false;
  @Input() linkData: any;
  @Output() onLinkChange = new EventEmitter<any>();

  params: any = undefined;
  links: any = [];
  curElement: undefined;

  constructor(
    private httpClient: HttpClient,
    private dashboardActions: DashboardActions,
    private jobTableService: JobTableService,
    private payloadService: PayloadService,
    private dashboardService: DashboardService,
    readonly optionsService: OptionsService,
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2,
    public router: Router,
  ) {
    (window as any)['ship'] = this;
  }
  ngOnChanges(changes: any) {
    if (this.cardView && changes.linkData.currentValue) {
      this.setLinks(changes.linkData.currentValue);
    }
  }

  ngOnInit() {
    if (this.cardView) {
      this.setLinks(this.linkData);
    }
  }

  ngAfterViewInit() {
  }


  setLinks(values: any) {
    if (!Array.isArray(values)) {
      this.links = [];
      return;
    }
    this.links = values
        .filter((v: any) => v && v.trim())
        .map((v: any) => (
      {
        value: this.makeURLFull(v),
        placeholder: this.makeURL(this.makeURLFull(v)),
        editing: false,
        isurl: this.isURL(v),
        original: v,
      }));
  }

  setCell(params: ICellRendererParams) {
    this.params = params;
    if (!params.value) return;
    this.setLinks(params.value);
  }

  agInit(params: ICellRendererParams) {
    this.isEditable = (params as any).isEditable;
    this.setCell(params);
  }

  refresh(params: ICellRendererParams<any, number>): boolean {
    this.setCell(params);
    return true;
  }

  onShipFocus(event: any, link: any): void {
    link.editing = true;
    this.curElement = event.target;
  }

  onShipClick(event: any, link: any) {
    event.stopPropagation();
  }

  onShipBlur(event: any, link: any, fromClick: boolean = false): void {
    setTimeout(() => {
      event.target.scrollLeft = 0;
    }, 200);
    link.isurl = this.isURL(link.value);
    if (link.isurl) {
      if (fromClick) {
        event.style.display = 'none';
      } else {
        event.target.style.display = 'none';
      }
    }

    if (!link.value) {
      this.links = this.links.filter((l: any) => l.value);
      link.editing = false;
      this.writeLinks(link.original, true);
      return;
    }
    link.value = this.makeURLFull(link.value);
    link.original = link.value;
    link.placeholder = this.makeURL(link.value);
    link.editing = false;
    this.writeLinks(link.value, false);
  }

  writeLinks(target: string, doDelete: boolean = false) {
    let values = this.links.map((l: any) => (l.value));
    if (this.cardView) {
      let payload = {
        values: values,
        target: target,
        delete: doDelete,
      }
      this.onLinkChange.emit(payload);
      return;
    }
    let payload = { [this.params.colDef.field]: values, id: this.params.data.id };
    this.params.data[this.params.colDef.field] = values;
    this.params.api.applyTransaction({ update: [this.params.data] });

    this.dashboardService.updateViewCell({payload: payload}).subscribe(() => {});
  }

  async getClipboard() {
    try {
        return await navigator.clipboard.readText();
    } catch (err) {
        return undefined;
    }
}

  shipKeydown(event: any, link: any) {
    if (event.key === "ArrowUp" || event.key === "ArrowDown" || event.key === "ArrowLeft" || event.key === "ArrowRight") {
      event.stopPropagation();
      return;
    }
    if (event.key == 'Enter') {
      event.target.blur();
    }
    if (event.code == 'KeyV') {
      event.stopPropagation();
    }
    if (event.code == 'KeyC') {
      event.stopPropagation();
      navigator.clipboard.writeText(link.value).then(() => {});
    }
  }

  placeHolderLength(link: any, buffer: number) {
    if (this.cardView) {
      return `${this.maxWidth - 10}px`;
    }
    let length = link.placeholder.length < 10 ? 10: link.placeholder.length;
    return `${Math.min(30, length + buffer)}ch`;
  }

  hasSpaces(value: string) {
    return value.includes(' ');
  }

  editLink(event: any, link: any, index: number) {
    (window as any)['ship'] = this;
    link.editing = true;
    event.stopPropagation();
    const parentElement = (event.target as HTMLElement).closest('.ship-link');
    const inputElement = parentElement?.querySelector('.ship-input') as HTMLInputElement;
    setTimeout(() => {
      inputElement.focus();
      inputElement.setSelectionRange(0, 0);
      inputElement.scrollLeft = 0;
    }, 50);
  }

  removeLink(event: any, link: any) {
    event.stopPropagation();
    this.links = this.links.filter((l: any) => l.value != link.value);
    this.writeLinks(link.value, true);
  }

  addLink(event: any) {
    this.links = [{ value: '', placeholder: '', editing: true}].concat(this.links);
    setTimeout(() => {
      let first = this.container.nativeElement.querySelector('.ship-input');
      first.focus();
    }, 50);
    event.stopPropagation();
  }

  makeURL(v: any) {
    try {
      const url = new URL(v);
      return `${url.hostname}`;
    } catch(error) {
      return v;
    }
  }

  makeURLFull(v: any): string {
    try {
      let url = new URL(v);
      return url.href;
    } catch (error) {
      return v;
    }
  }

  isURL(v: any) {
    try {
      let url = new URL(v);
      return true;
    } catch (error) {
      return false;
    }
  }
  
}