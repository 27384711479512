import { DatePipe } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';

import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { filter, take, takeUntil } from 'rxjs/operators';

import { MenuItem, MessageService } from 'primeng/api';

import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { IApplicationState } from 'src/app/common/state/models/app.state.model';
import { DashboardActions } from '../../state/actions/dashboard.actions';
import { selectActiveOrders, selectAddRoom, selectItems, selectOptions, selectOrders } from '../../state/selectors/dashboard.selector';
import { IStoreApiItem } from './../../../common/models/store-api-item.model';
import { IOptions } from 'src/app/authentication/models/authentication.model';
import { IActiveOrderList, INewItem, IOrders } from '../../models/dashboard.model';
import { CommonConstants } from 'src/app/common/constants/common.constants';
import { DashboardRoutes } from '../../enums/dashboard-routes.enum';
import { PayloadService } from '../../services/payload.service';
import { JobsService } from '../../services/job.service';
import { OrderService } from '../../services/order.service';
import { doTextColumnCompareNullLast } from '../job-table-v2/job-table-v2.utils';

@Component({
  selector: 'materlog-add-item',
  templateUrl: './add-item.component.html',
  styleUrls: ['./add-item.component.sass', './add-item.component.css'],
  providers: [DatePipe]
})
export class AddItemComponent extends OnDestroyMixin() implements OnInit {
  @Input() job: any;
  @Input() supplier: any;
  @Input() fromOrder: boolean = false;
  @Input() curOrderId: any;
  @Input() allMailData: any;

  @Output() close = new EventEmitter<void>();
  @Output() addedItems = new EventEmitter<any>();
  
  jobsArray: any = undefined;
  showDialog: boolean = false;
  isSubmitted: boolean = false;
  wrongQuantity: boolean = false;
  isLoading: boolean = false;
  edit: boolean = false;
  save: boolean = false;
  isDeleteSubmited: boolean = false;
  showDeleteDialog: boolean = false;
  @Input() itemList: any = [];
  orderList: any;
  orderJobList: any;
  poList: any;
  categoryList: any;
  roomList: any;
  floorList: any;
  statusList: any;
  deliveryList: any;
  po: any;
  order: any;
  activeOrderList: any;
  maxDateCompletion: any;
  minDateShip: any;
  maxDateShip: any;
  minDateDelivery: any;
  reasonToDelete: any;
  reasonToDeleteText: any;
  reason_to_not_display: any;
  deleteId: any;
  comSelection: any;
  addRoomModal: boolean = false;
  isAddRoomNameSubmitted: boolean = false;
  newRoomName: any = undefined;
  roomName: string = '';

  DashboardRoutes = DashboardRoutes;

  faTriangle = faExclamationTriangle;
  faXMark = faXmark;

  @Input() addItemForm: any = {
    id: null,
    order_id: null,
    name: null,
    description: null,
    images: [{ image_id: null, image: '', image_name: '' }],
    quantity: null,
    sku: null,
    link_to_material: null,
    category: 0,
    room_id: null,
    floor: 0,
    status: 1,
    delivery_method: 0,
    tracking_info: null,
    item_com_selection: 0,
    in_stock_date: null,
    ship_date: null,
    delivery_date: null,
    notes: null
  };

  items: MenuItem[] = [];

  constructor(
    public router: Router,
    private store: Store<IApplicationState>,
    private dashboardActions: DashboardActions,
    private messageService: MessageService,
    private datePipe: DatePipe,
    private payloadService: PayloadService,
    private jobsService: JobsService,
    private orderService: OrderService,
  ) {
    super();
    (window as any)['item'] = this;
  }

  ngOnInit(): void {
    this.showDialog = true;
    this.onRequestOptions();
    this.onRequestActiveOrders();
  }


  onSetBreadcrumb(poNumber: any, orderNumber: any) {
    let order;
    if (orderNumber) {
      order = orderNumber?.number;
    }
    let po;
    if (poNumber) {
      po = poNumber?.number;
    }
    this.items = [
      { label: `${po ? 'PO ' + po : ''} ${po && order ? '|' : ''} ${order ? ' Order ' + order : ''}`, command: () => this.navigateOrder() },
      { label: 'Add items' }
    ]
    if (!orderNumber && !poNumber)
      this.items = []
  }

  updateRoomName() {
    while (!this.roomList && !this.jobsArray) {}
    this.roomList.forEach((r: any) => {
      if (!r.job) return;
      let found = this.jobsArray.find((j: any) => r.job == j.id);
      if (found) {
        r.value = `${r.value} (${found.value})`;
      }
    })
  }

  onRequestOptions() {
    this.jobsService.getJobList({payload: {'small': true}}).subscribe((jobs: any) => {
      if (jobs) {
        let curJobs = jobs.reduce((map:any, item:any) => {
          map.set(item.id, item.name);
          return map;
        }, new Map());
        let jobDict = Array.from(curJobs as Iterable<[any, any]>).map(([id, name]: [any, any]) => ({
          id: id, value: name
        })) as any;
        jobDict.sort((a: any, b: any) => a.value.toLowerCase().localeCompare(b.value.toLowerCase()));
        this.jobsArray = jobDict as any;
        this.updateRoomName();
      }
    });
    this.store
      .pipe(
        select(selectOptions),
        filter((options: IStoreApiItem<IOptions>) => !options.isLoading),
        takeUntil(this.destroy)
      )
      .subscribe((options: IStoreApiItem<IOptions>) => {
        this.isLoading = false;
        this.categoryList = options?.data?.categories;
        let roomList: any = options?.data?.rooms;
        roomList = roomList.map((r: any) => ({...r}));
        this.roomList = [...roomList];
        this.deliveryList = options?.data?.delivery_method;
        this.floorList = options?.data?.floors;
        this.statusList = options?.data?.status;
        this.reason_to_not_display = options?.data?.reason_to_not_display;
        this.comSelection = options?.data.item_com_selection;
      });
  }

  onRequestItems() {
    let orderId = this.addItemForm.order_id || this.curOrderId;

    if (orderId) {
      this.dashboardActions.requestOrders(orderId);

      this.store
        .pipe(
          select(selectOrders),
          filter((orders: IStoreApiItem<IOrders>) => !orders.isLoading),
          take(1)
        )
        .subscribe((orders: IStoreApiItem<IOrders>) => {
          if (this.po && this.order) {
            const orderBreadcrumb = orders?.data?.supplier_documents?.find((document: any) => document.id == this.order);
            this.onSetBreadcrumb(orders?.data?.po_document, orderBreadcrumb);
          }
          else if (this.order) {
            const orderBreadcrumb = orders?.data?.supplier_documents?.find((document: any) => document.id == this.order);
            this.onSetBreadcrumb(null, orderBreadcrumb);
          } else if (this.po) {
            this.onSetBreadcrumb(orders?.data?.po_document, null);
          } else {
            this.onSetBreadcrumb(null, null);
          }
          this.itemList = orders?.data?.items;
          localStorage.removeItem('temporarySupplier');
          localStorage.removeItem('temporaryPo');
        });
    } else {
      this.itemList = []
    }
  }

  sortPoList(a: any, b: any) {
    return doTextColumnCompareNullLast(a.number, b.number, false);
  }

  onRequestActiveOrders() {
    this.dashboardActions.requestActiveOrders();
    this.store
      .pipe(
        select(selectActiveOrders),
        filter((activeOrders: IStoreApiItem<IActiveOrderList>) => !activeOrders.isLoading),
        take(1)
      )
      .subscribe((activeOrders: any) => {
        let poList = [...activeOrders?.data?.po_documents];
        if (this.allMailData) {
          poList = poList.map((p: any) => ({...p}));
          poList.forEach((p: any) => {
            let found = this.allMailData.curOrders.find((o: any) => o.id == p.order_id);
            if (found) {
              found = this.allMailData.supArray.find((s: any) => s.id == found.supplier_id);
              if (found) {
                p.number = `${p.number} (${found.value})`;
                if (p.number.length > 60) {
                  p.number = p.number.slice(0, 60) + '...'; // blah
                }
              }
            }
          })
        }
        this.poList = poList.sort(this.sortPoList);
        let orderList = [...activeOrders?.data?.supplier_documents];
        if (this.allMailData) {
          orderList = orderList.map((p: any) => ({...p}));
          orderList.forEach((p: any) => {
            let forder = this.allMailData.curOrders.find((o: any) => o.id == p.order_id);
            if (forder) {
              let found = this.allMailData.supArray.find((s: any) => s.id == forder.supplier_id);
              if (found) {
                p.number = `${forder.value} (${found.value})`;
                if (p.number.length > 60) {
                  p.number = p.number.slice(0, 60) + '...'; // blah
                }
              }
            }
          })
        }
        this.orderList = orderList.sort(this.sortPoList);
        this.orderJobList = activeOrders?.data?.order_job;

        if (localStorage.getItem('temporaryPo') !== null) {
          this.po = localStorage.getItem('temporaryPo');
        }

        if (localStorage.getItem('temporarySupplier') !== null) {
          this.order = localStorage.getItem('temporarySupplier');
        }

        if (this.order)
          this.onSelectOrder(this.order);
        if (this.po)
          this.onSelectPo(this.po)

      });
  }

  onSelectPo(event: any) {
    if (event !== null) {
      this.poList?.forEach((elem: any) => {
        if (event === elem.id) {
          this.addItemForm.order_id = elem.order_id;
        }
      });
      this.orderList = this.orderList?.filter((elem: any) => elem.order_id === this.addItemForm.order_id);
      setTimeout(() => {
        this.onRequestItems();
      }, 400);
    } else {
      this.addItemForm.order_id = null;
      this.onRequestActiveOrders();

      if (!this.order) { this.itemList = null; }
    }
  }

  onClearPo() {
    this.po = null;
    this.onRequestActiveOrders();

    if (!this.order) { this.itemList = null; this.onSetBreadcrumb(null, null) }
  }

  onSelectOrder(event: any) {
    if (event !== null) {
      this.orderList?.forEach((elem: any) => {
        if (event === elem.id) {
          this.addItemForm.order_id = elem.order_id;
        }
      });
      this.poList = this.poList?.filter((elem: any) => elem.order_id === this.addItemForm.order_id);
      setTimeout(() => {
        this.onRequestItems();
      }, 400);
    } else {
      this.addItemForm.order_id = null;
      this.onRequestActiveOrders();

      if (!this.po) { this.itemList = null; }
    }
  }

  onClearOrder() {
    this.order = null;
    this.onRequestActiveOrders();

    if (!this.po) {
      this.itemList = null;
      this.onSetBreadcrumb(null, null);
    }
  }

  closeDialog() {
    this.edit = false;
    this.itemList = [];
    this.showDialog = false;
    localStorage.removeItem('temporarySupplier');
    localStorage.removeItem('temporaryPo');
    this.close.emit();
  }

  closeDeleteDialog() {
    this.showDeleteDialog = false;
    this.reasonToDelete = null;
    this.reasonToDeleteText = null;
  }

  onSave() {
    this.save = true;
    this.addItem();
    this.addedItems.emit(true);
    if (!this.fromOrder) this.closeDialog();
  }

  //Handles files browse
  fileBrowseHandler(files: File[]) {
    for (const item of files) {
      this.convertBase64(item);
    }
  }

  deleteFile(fileIndex: number) {

    if (this.addItemForm.images?.length >= 1) { this.addItemForm.images.splice(fileIndex, 1); }
    else {
      this.addItemForm.images = [{ image_id: null, image: '', image_name: '' }];
    }
  }

  checkFileType(file: any): boolean {
    const prepareFile: any = file.name.split('.');
    const fileType = prepareFile?.findLast((el: any) => el.length > 1);
    const types = ['png', 'jpeg', 'jpg', 'jfif', 'pjpeg', 'pjp', 'svg', 'webp', 'bmp'];

    if (!types.includes(fileType)) { return false; }

    return true;
  }

  convertBase64(file: any) {
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => {
      const data = String(reader.result).split('base64,');

      if (data[1] && this.checkFileType(file)) {
        if (this.addItemForm.images[0]?.image_name.length < 1) {
          this.addItemForm.images[0].image_id = null;
          this.addItemForm.images[0].image = data[1];
          this.addItemForm.images[0].image_name = file.name;
        } else {
          this.addItemForm.images.push({
            image_id: null,
            image: data[1],
            image_name: file.name,
          });
        }
      } else {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Please provide a valid file.',
          key: 'error',
        });
      }
    };
  }

  addItem() {
    this.isSubmitted = true;
    this.wrongQuantity = typeof this.addItemForm.quantity != 'number' && this.addItemForm.quantity != null;
    let curRoomId = this.addItemForm.room_id;
    let found = this.roomList.find((r: any) => (r.id == r.value) && (curRoomId == r.id));
    if (found) this.addItemForm.room_id = null;

    if (!this.isItemName && this.isItemOrderId && !this.isLoading) {
      this.addItemForm.in_stock_date = this.addItemForm.in_stock_date ? this.datePipe.transform(this.addItemForm.in_stock_date, 'yyyy-MM-dd') : null;
      this.addItemForm.ship_date = this.addItemForm.ship_date ? this.datePipe.transform(this.addItemForm.ship_date, 'yyyy-MM-dd') : null;
      this.addItemForm.delivery_date = this.addItemForm.delivery_date ? this.datePipe.transform(this.addItemForm.delivery_date, 'yyyy-MM-dd') : null;
      if (this.addItemForm.id === null) {
        if (this.addItemForm?.images != null) {
          this.addItemForm.images = this.addItemForm.images[0].image_name?.length >= 1 ? this.addItemForm.images : null;
        }
        // this.dashboardActions.requestAddItem({ ...this.addItemForm });
        this.orderService.addItem({payload: { ...this.addItemForm }}).subscribe((item: any) => {
          if (found) {
            this.addRoomStore(this.newRoomName, item.id);
          }
          this.storeCall();
        })

      } else if (this.addItemForm.id !== null) {
        this.addItemForm.images?.forEach((elem: any) => {
          if (elem.image_id !== null) {
            elem.image = '';
          }
        });

        this.dashboardActions.requestUpdateItem({ ...this.addItemForm });
        if (found) {
          this.addRoomStore(this.newRoomName, this.addItemForm.id);
        }
        this.storeCall();
      }
    } else {
      this.save = false;
    }
  }


  storeCall() {
    this.resetForm();
    this.updateListOfItems();
    this.onRequestItems();
  }

  resetForm() {
    this.isSubmitted = false;
    this.wrongQuantity = false;
    this.edit = false;
    this.addItemForm = {
      id: null,
      order_id: this.addItemForm.order_id,
      name: null,
      description: null,
      images: [{ image_id: null, image: '', image_name: '' }],
      quantity: null,
      sku: null,
      link_to_material: null,
      category: 0,
      room_id: null,
      // floor: 0,
      status: 1,
      delivery_method: 0,
      tracking_info: null,
      item_com_selection: 0,
      in_stock_date: null,
      ship_date: null,
      delivery_date: null,
      notes: null
    };
    localStorage.removeItem('temporarySupplier');
    localStorage.removeItem('temporaryPo');
  }

  checkDates() {
    this.checkMaxDateCompletion();
    this.checkMaxDateShip();
    this.checkMinDateDelivery();
    this.checkMinDateShip();
  }

  checkMinDateDelivery() {
    if (this.addItemForm.ship_date !== null && this.addItemForm.ship_date >= this.addItemForm.in_stock_date) {
      this.minDateDelivery = new Date(this.addItemForm.ship_date);
    } else if (this.addItemForm.in_stock_date !== null) {
      this.minDateDelivery = new Date(this.addItemForm.in_stock_date);
    } else { this.minDateDelivery = new Date(0); }
  }

  checkMaxDateCompletion() {
    if (this.addItemForm.ship_date !== null) {
      this.maxDateCompletion = new Date(this.addItemForm.ship_date);
    } else if (this.addItemForm.delivery_date !== null) {
      this.maxDateCompletion = new Date(this.addItemForm.delivery_date);
    } else { this.maxDateCompletion = new Date('2500'); }

  }

  checkMinDateShip() {
    this.minDateShip = this.addItemForm.in_stock_date ? new Date(this.addItemForm.in_stock_date) : new Date(0);
  }

  checkMaxDateShip() {
    this.maxDateShip = this.addItemForm.delivery_date ? new Date(this.addItemForm.delivery_date) : new Date('2500');
  }

  selectItem(item: any) {
    this.edit = true;
    this.addItemForm = {
      id: item.id,
      order_id: item.order_id,
      name: item.name,
      description: item.description,
      images: item.images.length > 0 ? item.images?.map((elem: any) => {
        return { image: '', image_id: elem.image_id, image_name: elem.image_name };
      }) : null,
      quantity: item.quantity,
      sku: item.sku,
      link_to_material: item.link_to_material,
      category: item.category,
      room_id: item.room_id,
      floor: item.floor,
      status: item.status,
      delivery_method: item.delivery_method,
      tracking_info: item.tracking_info,
      item_com_selection: item.item_com_selection,
      in_stock_date: item.in_stock_date !== null ? new Date(item.in_stock_date) : null,
      ship_date: item.ship_date !== null ? new Date(item.ship_date) : null,
      delivery_date: item.delivery_date !== null ? new Date(item.delivery_date) : null,
      notes: item.notes
    };
  }

  deleteItem(itemId: any) {
    this.isDeleteSubmited = true;

    if (this.reasonToDelete == 3 && this.reasonToDeleteText?.trim()?.length >= 1) {
      const payload = {
        id: itemId,
        reason_to_not_display: this.reasonToDelete,
        reason_to_not_display_text: this.reasonToDeleteText,
      };

      this.confirmDelete(payload);
      this.showDeleteDialog = false;
    } else if (this.reasonToDelete != 0 && this.reasonToDelete !== 3 && this.reasonToDelete) {
      const payload = {
        id: itemId,
        reason_to_not_display: this.reasonToDelete,
      };

      this.confirmDelete(payload);
      this.showDeleteDialog = false;
    }
  }

  confirmDelete(payload: any) {
    this.isLoading = true;
    this.dashboardActions.requestDeleteItemReason(payload);
    this.store
      .pipe(
        select(selectItems),
        filter((items: IStoreApiItem<INewItem>) => !items.isLoading),
        takeUntil(this.destroy)
      )
      .subscribe((item: any) => {
        this.isLoading = false;

        if (item?.items?.isSuccess) {
          this.closeDeleteDialog();
        }

        if (item?.items?.errors) {
          this.messageService.clear();
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Something went wrong. Please try again later. If this issue persists, contact support@getmeti.com.',
            key: 'error',
          });
        }
      });

    setTimeout(() => this.onRequestItems(), 500);
  }

  updateListOfItems() {
    this.dashboardActions.requestSuppliers();
    this.dashboardActions.requestViews({ ...this.payloadService.setViewsPayload() });
  }

  openDeleteDialog(itemId: any) {
    this.showDeleteDialog = true;
    this.deleteId = itemId;
  }

  get isItemName() {
    if (this.addItemForm.name) {
      return this.addItemForm.name?.length < 1;
    } else { return true; }
  }

  get isItemOrderId() {
    this.addItemForm.order_id = this.addItemForm.order_id || this.curOrderId;
    return this.addItemForm.order_id;
  }

  onOpenAddRoomModal() {
    this.addRoomModal = true;
    this.isAddRoomNameSubmitted = false;
    this.roomName = '';
  }


  maybeAddRoom(room_name: any, item_id: string) {
    if (this.newRoomName && this.newRoomName == room_name) {
      this.addRoomStore(this.newRoomName, item_id);
    }
  }

  onCloseAddRoomModal() {
    this.addRoomModal = false;
  }

  onSaveRoomName() {
    this.isAddRoomNameSubmitted = true;

    if (this.roomName.trim()?.length >= 1) {
      this.addRoomModal = false;
      let value = this.roomName
      let newRoom: any = {};
      if (this.addItemForm.order_id) {
        let found = this.orderJobList.find((o: any) => o.id == this.addItemForm.order_id);
        if (found) {
          newRoom.job = found.job_id;
          found = this.jobsArray.find((j: any) => found.job_id == j.id);
          if (found) {
            value = `${value} (${found.value})`;
          }
        }
      }
      newRoom.value = value;
      newRoom.id = value;
      this.addItemForm.room_id = value;
      this.roomList.push({...newRoom});
      this.newRoomName = this.roomName;
    }
  }

  addRoomStore(name: string, item_id: string) {
    this.isLoading = true;
    this.dashboardActions.requestAddRoom({ name: name, item: item_id });
    this.store
      .pipe(
        select(selectAddRoom),
        filter((room: IStoreApiItem<any>) => !room.isLoading),
        take(1)
      )
      .subscribe((room: any) => {
        if (room?.isSuccess) {
          this.dashboardActions.requestOptions();
          this.addItemForm.room_id = room?.data?.id;
        }

        if (room?.errors) {
          this.onSetCustomErrorMessage(room);
          this.isLoading = false;
        }
      });
  }

  onSetCustomErrorMessage(data: any) {
    let errorMessage: string;

    switch (Object.keys(data.errors.error)[0]) {
      case CommonConstants.errorMessages.name:
        errorMessage = data.errors.error[CommonConstants.errorMessages.name][0];
        break;
      default:
        errorMessage = CommonConstants.defaultError;
    }

    this.messageService.clear();
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: errorMessage,
      key: 'error',
    });
  }

  get isDeleteError() {
    if (this.reasonToDelete === 3 && (this.reasonToDeleteText?.trim()?.length <= 1 || !this.reasonToDeleteText)) {
      return true;
    } else if (!this.reasonToDelete) {
      return true;
    } else { return false; }
  }

  navigateOrder() {
    this.router.navigate([{ outlets: { addItem: null } }], { skipLocationChange: true }).then(() => {
      this.router.navigate([{ outlets: { addOrder: DashboardRoutes.AddOrder } }], { skipLocationChange: true })
      localStorage.setItem('itemBreadcrumb', this.addItemForm.order_id);
    })
  }
}
