import { ChangeDetectorRef, Component, OnInit, ViewChild, Input } from '@angular/core';
import { ElementRef, HostListener } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'materlog-workflows-start',
  templateUrl: './workflow-start.component.html',
  styleUrls: [
    '../../workflows/workflows.component.css',
  ]
})
export class MaterlogWorkflowsStart implements OnInit {
    @Input() dropdownOptions: any;
    @Input() allMailData: any;
    @Input() orgData: any = {};
    @Input() box: any = {};
    @Input() chosenPic: string = '';
    @Output() applyUpdate = new EventEmitter<any>();
    ngOnChanges(changes: any) {

    }

    ngOnInit(): void {
        (window as any)['wf_start'] = this;
      }
}
