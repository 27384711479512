<div class="workflow-box-col" style="padding-right: 32px;">
    <div class="org-ai-below workflow-data-entry"
        *ngFor="let option of setting.options; let isFirst = first; let isLast = last" 
    >
        <div class="org-ai-main-options">
            <div class="workflow-box-row">
                <i *ngIf="box.complete"
                    class="bi bi-check-circle-fill workflow-leftmost-small workflow-check wf-data-entry-margin"
                    [ngClass]="box.complete ? 'workflow-check-done' : 'workflow-check-not-done'"
                ></i>
                <div *ngIf="!box.complete" class="workflow-leftmost-small wf-data-entry-margin"></div>
                <div class="workflow-ai-fulltext">
                    <div class="org-ai-option-text" [ngClass]="{'workflow-active-text': option.is_active}">
                        {{option.name}}
                    </div>
                    <div *ngIf="option.subtext" class="workflow-org-ai-option-subtext">
                        {{option.subtext}}
                    </div>
                </div>
            </div>
            <div class="" (click)="toggleOption(setting.name, option.name)">
                <i *ngIf="!toggleForName(setting.name, option.name)"
                        [ngClass]="{'org-ai-is-toggleable': isToggleable(setting.name)}"
                        class="bi bi-toggle-off wf-ai-toggle org-ai-toggle-off"></i>
                <i *ngIf="toggleForName(setting.name, option.name)" 
                        [ngClass]="{'org-ai-is-toggleable': isToggleable(setting.name)}"
                        class="bi bi-toggle-on wf-ai-toggle org-ai-toggle-on"></i>
            </div>
        </div>
        <div *ngIf="toggleForName(setting.name, option.name) && option.subvalues"
            class="org-ai-main-options wf-ai-subvalues">
            <div class="org-ai-subtext">
                {{option.subvalues.name}}
            </div>
            <div class="org-ai-sub-dropdown" (click)="onToggleSubvalues(option.subvalues)">
                {{optionValueForOption(setting.name, option.subvalues.name, option.subvalues.options)}}
                <i class="bi bi-chevron-down ai-employee-chevron"></i>
            </div>
            <div class="ai-subvalue-option-dropdown" *ngIf="option.subvalues.is_open">
                <div class="ai-subvalue-option" (click)="onSetSubvalue(setting.name, option.subvalues, value)"
                    *ngFor="let value of option.subvalues.options">
                <span> {{ value.value }} </span>
                </div>
            </div>
        </div>
    </div>
    <div class="workflow-box-row" style="margin-left: 64px; gap: 12px; padding-top: 16px">
        <div class="org-ai-option-text workflow-active-text">
            Looking to automate something else?
        </div>
        <div class="wf-let-us-know" (click)="letUsKnowClick()">
            Let us know!
        </div>
    </div>
</div>
