

export function deepCopy(value: any) {
  return JSON.parse(JSON.stringify(value));
}

export function deepArrayClone(value: any) {
  // @ts-ignore
  return  value.map(a => {return {...a}})
}

export function emptySort(){
  return [];
}

export function isEmptySort(x: any){
  if (!x || x.length > 1)  return false;
  return x[0].key == 'created_datetime'
         && x[0].direction == true
         && x[0].position == 0
}

export function generateRandomString() {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < 15; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function createAllNotifications(results: any, dropdownOptions: any, allMailData: any) {
  let output: any = [];
  let job_type = dropdownOptions?.alert_types?.find((a: any) => a.value == 'Job')?.id;
  let order_type = dropdownOptions?.alert_types?.find((a: any) => a.value == 'Order')?.id;
  let supplier_type = dropdownOptions?.alert_types?.find((a: any) => a.value == 'Supplier')?.id;
  let receiver_type = dropdownOptions?.alert_types?.find((a: any) => a.value == 'Receiver')?.id;

  results.forEach((result: any) => {
    if (result.alert_type == job_type || result.alert_type == receiver_type) {
      let which = undefined;
      if (result.alert_type == job_type) {
        which = dropdownOptions.job_alerts.find((a: any) => result.job_alert == a.id)?.id;
      } else {
        which = dropdownOptions.receiver_alerts.find((a: any) => result.receiver_alert == a.id)?.id;
      }
      if (!which) return;
  
      let job_id = result.job;
      let job = allMailData?.jobsArray?.find((j: any) => j.id == job_id);
      if (!job) {
        return
      };
      let cur: any = {
          id: result.id,
          seen: result.is_seen,
          date: result.created_datetime,
          job_id: job.id,
          job_name: job.value,
          type: 'Job',
      }
      if (result.alert_type == job_type) {
        if (which == 1) { // no address
          cur.text = `${job.value} needs a job address`;
          cur.address_error = true;
          cur.emoji_icon = '📍';
        } else if (which == 2) { // no receiver
          // cur.text = `No receiver: ${job.value}`;
        } else if (which == 4) { // job complete
          cur.text = `${job.value} is now fully delivered!`;
          cur.emoji_icon = '🎉';
        } else if (which == 5) { // new job
          cur.text = `Your new job ${job.value} is now live!`;
          cur.emoji_icon = '➕';
        }
      } else {
        let rname = result.receiver_name;
        if (which == 1) { // no address
          cur.text = `Receivers ${rname} is missing address info`;
          cur.receiver_error_address = true;
          cur.emoji_icon = '📇';
        } else if (which == 2) { // no email
          cur.text = `Receivers ${rname} is missing email info`;
          cur.receiver_error_email = true;
          cur.emoji_icon = '📇';
        } else if (which == 3) { // invalid email
          // cur.text = `Invalid receiver email: ${rname}`;
        } else if (which == 4) { // receiver contact info
          cur.text = `A ${rname} contact is missing info`;
          cur.receiver_contact_error = true;
          cur.emoji_icon = '📇';
        }
      }
  
      if (cur.text) {
        output.push(cur);
      }
    } else if (result.alert_type == order_type) {
      let which = dropdownOptions.order_alerts.find((a: any) => result.order_alert == a.id)?.id;
      if (!which) {
        return;
      }
      let order_id = result.order;
      let order = allMailData?.curOrders?.find((o: any) => o.id == order_id);
      if (!order) {
        return
      };
      let cur: any = {
          id: result.id,
          seen: result.is_seen,
          date: result.created_datetime,
          order_id: order.id,
          order_name: order.value,
          job_id: order.job_id,
          job_name: order.job_name,
          supplier_id: order.supplier_id,
          supplier_name: order.supplier_name,
          type: 'Order',
      }
      if (which == 1) { // missing supplier
        cur.text = `${order.value} is missing a supplier`;
        cur.emoji_icon = '⚠️';
        cur.sub_text = 'Orders require suppliers to be tracked.';
        cur.order_error_supplier = true;
      } else if (which == 2) { // no items
        cur.text = `${order.value} is missing items`;
        cur.sub_text = 'You can delete this order by hovering over the note and clicking the trash icon.';
        cur.emoji_icon = '⚠️';
      } else if (which == 3) { // item order but only po
        cur.text = `${order.value} is missing an order number`;
        cur.emoji_icon = '⚠️';
        cur.order_error_orderdoc = true;
        cur.sub_text = 'This order’s status has been updated, however it is missing a supplier provided number (like a confirmation or invoice number). The absence of this number may prevent or slow tracking.';
      } else if (which == 4) { // order expiring
        cur.text = `Tracking on ${order.value} is expiring`;
        cur.emoji_icon = '⏱️';
        cur.sub_text = 'If we haven’t heard back from a supplier, we will eventually expire the follow ups.'
      } else if (which == 5 || which == 10) { // invalid tracking, tracking wrong
        cur.text = `Please review and confirm tracking details for ${order.value}`;
        cur.sub_text = 'We tried to track this order but our details are incorrect';
        cur.order_error_tracking = true;
        cur.emoji_icon = '⚠️';
      } else if (which == 6) { // missing tracking
        cur.text = `Missing tracking: ${order.value}`;
        cur.order_error_tracking = true;
        cur.emoji_icon = '🚚'
        cur.sub_text = 'Someone (either you or the supplier) let us know that this order has shipped, but we didn’t get tracking information. If we can, we’ll reach out to the supplier for this. But you can add it here if you have it handy.';
      } else if (which == 7) { // ready to be scheduled
        // cur.text = `Ready to be scheduled: ${order.value}`;
      } else if (which == 8) { // new comment
        let job_order = [order.job_name, order.value].join(' ');
        let prelude = result?.extra_data?.comment_name
                    ? `${result.extra_data.comment_name} left you a comment on`
                    : 'A comment was left for you on';
        cur.text = `${prelude} ${job_order}`;
        cur.emoji_icon = '💬';
      } else if (which == 9) { // new order
        // cur.text = `Order created: ${order.value}`;
      }
      if (cur.text) {
        output.push(cur);
      }
    } else if (result.alert_type == supplier_type) {
      let which = dropdownOptions.supplier_alerts.find((a: any) => result.supplier_alert == a.id)?.id;
      if (!which) {
        return;
      }
      let supplier = allMailData.supArray.find((s: any) => s.id == result.supplier);
      if (!supplier) {
        return;
      }
      let order = allMailData?.curOrders?.find((o: any) => o.supplier_id == result.supplier);
      let cur: any = {
        id: result.id,
        seen: result.is_seen,
        date: result.created_datetime,
        supplier_id: supplier.id,
        supplier_name: supplier.value,
        order_id: order?.id,
        order_name: order?.value,
        type: 'Supplier',
    }
      if (which == 1) { // new supplier
        cur.text = `You've added ${supplier.value} as a supplier`;
        cur.emoji_icon = '➕';
      } else if (which == 2) { // no email
        cur.text = `${supplier.value} is missing info`;
        cur.supplier_error_email = true;
        cur.emoji_icon = '📇';
      } else if (which == 3) { // invalid email
        // cur.text = `Invalid supplier email: ${supplier.value}`;
      } else if (which == 4 || which == 5) { // no creds
        // cur.text = `Please review and confirm ${supplier.value} credentials`;
        // cur.emoji_icon = '⚠️';
      } else if (which == 6) { // account missing
        // cur.text = `Supplier account number missing: ${supplier.value}`;
      }
      if (cur.text) {
        output.push(cur);
      }
    } else if (result.alert_type == receiver_type) {
      let which = dropdownOptions.receiver_alerts.find((a: any) => result.receiver_alert == a.id)?.id;
      if (!which) return;
      if (which == 1) { // no address
  
      } else if (which == 2) { // no email
        
      } else if (which == 3) { // invalid email
  
      }
    } 
  })
  return output;
}


export function emojiForAlert(text: any) {
  switch (text) {
      case 'Missing supplier':
          return '🤔';
      case 'Confirmation number needed':
          return '☝️'
      case 'Monitoring expired':
          return '⏳';
      case 'Ready to be scheduled':
          return '🗓️';
      case 'New comment':
          return '💬';
      case 'Missing email':
          return '📭';
      default:
          return '';
  }
}


export function setScrollBar(el: any, v: string) {
  setTimeout(() => {
    let jdisplays = el.nativeElement.querySelectorAll(v);
    if (jdisplays) {
      jdisplays.forEach((jdisplay: any) => {
        jdisplay.addEventListener('mouseenter', function() {
          (jdisplay as any).classList.add('custom-scrollbar');
          (jdisplay as any).classList.remove('custom-scrollbar-close');
          setTimeout(() => {
            (jdisplay as any).classList.remove('custom-scrollbar');
            (jdisplay as any).classList.add('custom-scrollbar-close');
          }, 1500)
        });
        jdisplay.addEventListener('wheel', function() {
          (jdisplay as any).classList.add('custom-scrollbar');
          (jdisplay as any).classList.remove('custom-scrollbar-close');
          setTimeout(() => {
            (jdisplay as any).classList.remove('custom-scrollbar');
            (jdisplay as any).classList.add('custom-scrollbar-close');
          }, 1500)
        });
        jdisplay.addEventListener('mouseleave', function() {
          (jdisplay as any).classList.remove('custom-scrollbar');
          (jdisplay as any).classList.add('custom-scrollbar-close');
        });
      })
    }
  }, 500);
}


export function isPDF(bytes: Uint8Array): boolean {
  const magic = [0x25, 0x50, 0x44, 0x46, 0x2D];
  return checkMagicBytes(bytes, magic);
}

export function isJPEG(bytes: Uint8Array): boolean {
  const magics = [
    [0xFF, 0xD8, 0xFF, 0xE0],
    [0xFF, 0xD8, 0xFF, 0xEE],
    [0xFF, 0xD8, 0xFF, 0xE1],
    [0xFF, 0xD8, 0xFF, 0xE0]
  ];
  return magics.some(magic => checkMagicBytes(bytes, magic));
}

export function isPNG(bytes: Uint8Array): boolean {
  const magic = [0x89, 0x50, 0x4E, 0x47, 0x0D, 0x0A, 0x1A, 0x0A];
  return checkMagicBytes(bytes, magic);
}

export function checkMagicBytes(bytes: Uint8Array, magic: number[]): boolean {
  return magic.every((byte, index) => bytes[index] === byte);
}

export function createDate(date: any, add_time: boolean = true, og_format: boolean = false) {
  if (add_time) {
    date += 'T08:00:00Z';
  }
  const d = new Date(date);
  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  let fullYear = d.getFullYear().toString();
  let year = fullYear.slice(-2);

  if (month.length < 2) {
    month = '0' + month;
  }
  if (day.length < 2) {
    day = '0' + day;
  }
  if (og_format) {
    return [fullYear, month, day].join('-'); 
  }
  return [month, day, year].join('/');
}

export function isValidUUID(uuid: any) {
  const regex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/;
  return regex.test(uuid);
}


export function openStatuses() {
  return [
      1 , //INITIAL 
      26, //PO 
      27, //QUOTE 
      17, //PRE_ORDER 
      18, //SUBMITTALS 
      3 , //ORDERED 
      16, //IN_PRODUCTION 
      29, //IN_TRANSIT 
      4 , //IN_STOCK_OR_ARRIVED_AT_SUPPLIER 
      24, //PARTIALLY_SHIPPED 
      5 , //SHIPPED 
      15, //PARTIALLY_DELIVERED 
      21, //READY_FOR_SCHEDULED 
      7, // READY FOR PICK UP
      22, //DELIVERY_SCHEDULED 
      23, //TRACKING_ISSUES 
      14, //ON_HOLD 
      25, //AWAITING_FUNDS 
  ]
}