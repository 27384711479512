<div class="dialog">
  <div class="header">
    <span *ngIf="toggle[0]">Get Tracking!</span>
    <div *ngIf="toggle[1]">
      <fa-icon class="shareFa" [icon]="faOrder"></fa-icon>
      <span>Add your first order</span>
    </div>
    <div *ngIf="toggle[2]">
      <fa-icon class="trackFa" [icon]="faTrack"></fa-icon>
      <span>Start tracking your materials</span>
    </div>
    <button class="header-close" type="button" (click)="emitClose()"><i class="bi bi-x-lg"></i></button>
  </div>
  <!-- Default dialog view  -->
  <ng-container *ngIf="toggle[0]">
    <div class="dialog__container">
      <div class="dialog__container-item" (click)="clickAddJob()">
        <div class="dialog__container-item-icon job">
          <fa-icon class="job-icon" [icon]="faAdd"></fa-icon>
        </div>
        <h4 class="dialog__container-item-title">Add a job</h4>
        <p class="dialog__container-item-description">Add your first job details.</p>
      </div>
      <div class="dialog__container-item" (click)="clickPO()">
        <div class="dialog__container-item-icon forward">
          <fa-icon class="forward-icon" [icon]="faOrder"></fa-icon>
        </div>
        <h4 class="dialog__container-item-title">Forward a PO</h4>
        <p class="dialog__container-item-description">Easily share your first order.</p>
      </div>
      <div class="dialog__container-item" (click)="clickTrack()">
        <div class="dialog__container-item-icon track">
          <!-- <i class="bi bi-arrow-left-right"></i> -->
          <fa-icon class="track-icon" [icon]="faTrack"></fa-icon>
        </div>
        <h4 class="dialog__container-item-title">Track an item</h4>
        <p class="dialog__container-item-description">Start tracking your project materials.</p>
      </div>
    </div>
  </ng-container>
  <!-- Forward a PO dialog view  -->
  <ng-container *ngIf="toggle[1]">
    <div class="dialog__content">
      <div class="dialog__content-item">
        <fa-icon class="dialog__content-item-icon" [icon]="faFile"></fa-icon>
        <div class="dialog__content-item-text">
          <h4 class="dialog__content-item-text-big">1. Select a PO, invoice or order acknowledgement</h4>
        </div>
      </div>
      <div class="dialog__content-item">
        <fa-icon class="dialog__content-item-icon" [icon]="faEnvelope"></fa-icon>
        <div class="dialog__content-item-text">
          <h4 class="dialog__content-item-text-big">
            2. Forward to <a href="mailto:orders@getmeti.com">orders@getmeti.com</a>
          </h4>
          <p class="dialog__content-item-text-small">Send the email from the email address you signed up with.</p>
        </div>
      </div>
      <div class="dialog__content-item">
        <fa-icon class="dialog__content-item-icon" [icon]="faWand"></fa-icon>
        <div class="dialog__content-item-text">
          <h4 class="dialog__content-item-text-big">
            3. Your order details will appear on your Meti dashboard within a few hours.
          </h4>
          <p class="dialog__content-item-text-small">
            Want to be notified as soon as your documents have been loaded? Update your settings
            <a class="redirect" href="/user-settings">here</a>.
          </p>
        </div>
      </div>
      <div class="dialog__content-action">
        <button class="dialog__content-action-button" type="button" (click)="clickTrack()">
          I forwarded my first order doc!
        </button>
      </div>
    </div>
  </ng-container>
  <!-- Track an Item dialog view  -->
  <ng-container *ngIf="toggle[2]">
    <div class="dialog__content">
      <div class="dialog__content-item">
        <fa-icon class="dialog__content-item-icon" [icon]="faFile"></fa-icon>
        <div class="dialog__content-item-text">
          <h4 class="dialog__content-item-text-big">
            Once
            <a class="dialog__content-item-text-big-dashed" href="somewhere"
              >you’ve started forwarding order documents</a
            >
            (like POs, invoices and order confirmations), your purchased items will populate in the Meti app.
          </h4>
        </div>
      </div>
      <div class="dialog__content-item">
        <fa-icon class="dialog__content-item-icon" [icon]="faWand"></fa-icon>
        <div class="dialog__content-item-text">
          <h4 class="dialog__content-item-text-big">
            When you see your materials in the app, automatic tracking has begun!
          </h4>
          <p class="dialog__content-item-text-small">
            Questions? Or want to set up a walk through? Contact us
            <a class="redirect" href="mailto:orders@getmeti.com">here</a>.
          </p>
        </div>
      </div>
      <div class="dialog__content-action">
        <button class="dialog__content-action-finish" type="button" (click)="emitClose()">Finish</button>
      </div>
    </div>
  </ng-container>
</div>
