import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { DatePipe } from "@angular/common";
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { filter, take } from 'rxjs';
import { faBuilding, faEye } from '@fortawesome/free-solid-svg-icons';
import { TableConstants } from 'src/app/common/constants/table.constants';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { IApplicationState } from 'src/app/common/state/models/app.state.model';
import { SharedViewActions } from '../../state/actions/shared-view.actions';
import { selectSharedViewPage } from '../../state/selectors/shared-view.selector';
import { IStoreApiItem } from 'src/app/common/models/store-api-item.model';
import { CustomNoRowsOverlay } from "../../../dashboard/components/job-table-v2/custom-no-rows-overlay.component";
import {
  ColDef, ColumnApi,
  GridApi, GridReadyEvent,
  ValueFormatterParams
} from "ag-grid-community";
import { deepArrayClone, deepCopy } from "../../../common/utils/general";

import { DashboardActions } from "src/app/dashboard/state/actions/dashboard.actions";
import { DashboardService } from "src/app/dashboard/services/dashboard.service";
import { getTempColumns } from "src/app/dashboard/components/job-table-v2/job-table-v2.utils";
import { getSharedColumns, editableView } from "./shared-view-utils";
import { SharedCalendar } from "../shared-calendar/shared-calendar.component";
import { DropDownCellRenderer } from "src/app/dashboard/components/job-table-v2/dropdownv2/dropdownv2.component";
import { ShipLinkCellRenderer } from "src/app/dashboard/components/job-table-v2/ShipLinkCellRenderer/shiplink.component";
import { DependencyRenderer } from "src/app/dashboard/components/job-table-v2/dependency-renderer/dependency-renderer.component";

@Component({
  selector: 'materlog-shared-view',
  templateUrl: './shared-view.component.html',
  styleUrls: ['./shared-view.component.sass'],
  providers: [DatePipe],
})
export class SharedViewComponent extends OnDestroyMixin() implements OnInit {
  faEye = faEye;
  faBuilding = faBuilding;
  columns!: ColDef[];
  @Input() dropdownOptions: any;
  isNotValidLink: boolean = false;
  isLoading: boolean = true;
  selectedColumns: any = JSON.parse(JSON.stringify(TableConstants.jobItemsTableColumns));
  isSharedEditable: boolean = false;

  jobs: any = undefined;
  routeId: string = '';
  job_name: string = '';
  selectedView: any;
  columnTypes: any;
  organization_logo: string = '';
  gridApi!: GridApi;
  columnApi!: ColumnApi;
  rowHeight = TableConstants.row_small; //default height....
  searchTerm = '';
  sharedViewUrl = '';

  gridOptions: any = {
    alwaysMultiSort: true,
    isExternalFilterPresent: () => {
      // Now 'this' refers to the JobTableV2Component instance
      return !!this.searchTerm;
    },
    
    doesExternalFilterPass: (node: any) => {
      if (!this.searchTerm) return true;
      const item = node.data;
      for (const key in item) {
        let value = item[key];
        if (!this.searchTerm || this.applySearchTerm2(key, value, this.searchTerm)) {
          return true;
        }
      }
      return false;
    },
  }

  constructor(
    private router: Router,
    private sharedViewActions: SharedViewActions,
    private store: Store<IApplicationState>,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private datePipe: DatePipe,
    private dashboardActions: DashboardActions,
    private dashboardService: DashboardService,
  ) {
    super();
    this.routeId = route.snapshot.params.id;
    (window as any)['shared'] = this;
  }

  ngOnInit(): void {
    this.onRequestSharedViewPage();
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;

    this.setColDef();
    this.setColTypes();

    this.setFilterToAGgrid();

    let customCounter = 1;
    const height = this.rowHeight;
    if (this.gridApi) {
      this.gridApi.forEachNodeAfterFilterAndSort(function(node) {
        node.setRowHeight(height);
        (node as any).sticky = false;
        if (node.displayed && !node.group) {
          node.rowIndex = customCounter++;
        }
      });
      this.gridApi.refreshCells({ force: true });
      this.cdr.detectChanges();
    }
  }

  onRowDataUpdated(params: any) {
    if(this.selectedView.customization && this.selectedView.customization.image_size) {
      let result = this.selectedView.customization.image_size;
      if (result == "large") {
        this.rowHeight = TableConstants.row_large;
      } else if (result == "medium") {

        this.rowHeight = TableConstants.row_medium;
      } else {
        this.rowHeight = TableConstants.row_small;
      }
      if (this.rowHeight && this.gridApi) {
        this.gridApi?.setGetRowHeight(params => {
          return this.rowHeight;
        });
      }
      if (result) {
        this.gridApi?.resetRowHeights();
        this.cdr.markForCheck();
      }
    }
  }

  exportParams(): any {
    return {
      columnGroups: true,
      allColumns: false,
      columnKeys: this.columnApi.getAllDisplayedColumns()
        .filter(col =>
          !['index', 'images', 'po_document_attachments',
            'supplier_documents_attachments'].includes(col.getColId()))
        .map(col => col.getColId()),
      processRowGroupCallback: function(params: any) {
        if (params.node.group) {
          return null;
        }
        return params.node.data;
      },
      shouldRowBeSkipped: function(params: any) {
        return !params.node.data;
      },
      processCellCallback: this.processCellCallback.bind(this),

    };
  }

  processCellCallback(cell: any) {
    // Check if a valueFormatter is defined for the column
    let coldef = cell.column.getColDef();
    if (!cell.node || !cell.node.data) {
      return;
    }
    if (Array.isArray(cell.value)) {
      return cell.value.map((item: any) => item.number).join(', ');
    } else if (cell.value && cell.value.number) {
      return cell.value.number;
    } else if (cell && typeof cell.value === 'string') {
      return cell.value;
    }
    const formattedValue = (cell.column.getColDef() as any).valueFormatter({
      value: cell.value,
      data: cell.node.data,
      node: cell.node,
      colDef: cell.column.getColDef(),
      // Include other properties if your valueFormatter depends on them
    });
    return formattedValue;
  }
  
  printGrid() {
    this.gridOptions.api.setPagination(false);
    // this.gridOptions.domLayout = 'print';
    this.gridOptions.rowBuffer = 10000;
    this.gridApi.refreshCells();
    this.gridApi.setDomLayout('print');
    setTimeout(() => {
      window.print();
      this.gridApi.setDomLayout(undefined);
      this.gridOptions.rowBuffer = 30;
    }, 500);
  }

  exportGrid() {
    this.gridApi.exportDataAsCsv(this.exportParams());
  }

  public defaultColDef: ColDef = {
    suppressFiltersToolPanel: true,
    resizable: true,
    suppressMovable: true,
    floatingFilter: false,
    menuTabs: [],
    editable: false,
    valueFormatter: function(params: ValueFormatterParams) {
      if(params && params.value && params.value === 'Not selected'){
        return "";
      }
      return params.value;
    }
  };


  onRequestSharedViewPage() {
    this.sharedViewActions.requestSharedViewPage({
      id: this.routeId,
      pagination: {
        page_size: 20000,
        page: 1,
      },
    });

    this.store
      .pipe(
        select(selectSharedViewPage),
        filter((sharedViewPage: IStoreApiItem<any>) => !sharedViewPage.isLoading),
        take(1)
      )
      .subscribe((sharedViewPage: IStoreApiItem<any>) => {
        if (sharedViewPage.errors) {
          this.isNotValidLink = true;
          this.isLoading = false;
          return;
        }
        this.isNotValidLink = false;
        this.isLoading = false;

        this.job_name = sharedViewPage?.data?.job_name;
        this.organization_logo = sharedViewPage?.data?.organization_logo;
        this.selectedView = deepCopy(sharedViewPage?.data);
        this.selectedColumns = sharedViewPage?.data?.fields_selected_to_display
          ? JSON.parse(JSON.stringify(sharedViewPage.data.fields_selected_to_display))
          : JSON.parse(JSON.stringify(TableConstants.jobItemsTableColumns));
        this.selectedColumns = this.selectedColumns.filter((elem: any) => elem.visible);
        let results = sharedViewPage?.data?.results;
        this.dropdownOptions = deepCopy((sharedViewPage as any).data.options);
        this.isSharedEditable = this.dropdownOptions.editable;
        this.dropdownOptions.isSharedView = true;
        this.sharedViewUrl = window.location.href;
        let cur_jobs = undefined;
        if (sharedViewPage?.data?.sub_items.length) {
          let subitems = sharedViewPage.data.sub_items;
          let new_rows: any = [];
          subitems.forEach((sub: any) => {
            if (results.find((job: any) => job.id == sub.id)) return;
            let found = results.find((i: any) => i.id == sub.item_id);
            if (!found) return;
            let new_sub = deepCopy(sub);
            let found_status = this.dropdownOptions.status.find((s: any) => s.id == new_sub.status);
            if (found_status) new_sub.status = found_status.value;
            let new_row = {...deepCopy(found), ...new_sub};
            delete new_row.item_id;
            new_row.has_subitems.has = false;
            new_row.has_subitems.item_id = found.id;
            new_rows.push(new_row);
          });
          results = deepCopy(results);
          cur_jobs = results.concat(new_rows);
        } else {
          cur_jobs = deepCopy(results);
        }
        if (this.isSharedEditable) {
          this.createFastDropDownLookup();
          cur_jobs.forEach((j: any) => {
            if (j.status) {
              let stat = this.dropdownOptions.status.find((s: any) => s.value == j.status);
              if (stat) j.status = stat.id;
            }
          });
        }

        this.jobs = cur_jobs;
      });
  }

  createFastDropDownLookup() {
    let fields = ['status', 'status_colors'];
    (this.dropdownOptions as any)['faster'] = {} as any;
    let faster = (this.dropdownOptions as any).faster;
    Object.keys(this.dropdownOptions).forEach((key) => {
      if (fields.includes(key)) {
        const cur = (this.dropdownOptions as any)[key];
        let curdic = {};
        cur.forEach((dict: any) => {
            (curdic as any)[dict.id] = dict.value;
        });
        faster[key] = curdic;
      }
    });
  }

  onCellValueChanged(event: any) {
    let simple_updates = ['pallet_number', 'status', 'warehouse_id'];
    if (!event.colDef) return;
    let col_type = event.colDef.type as string;
    let col_field = event.colDef.field as string;
    let payload: any = {
      shared: true,
      sharedUrl: this.sharedViewUrl,
      id: event.data["id"],
    }
    if (col_type === "stringORdateColumn") {
      payload[`${event.colDef.field}`] = this.datePipe.transform(event.value, "yyyy-MM-dd");
      this.dashboardActions.requestUpdateViewCell(payload);
    } else if (simple_updates.includes(col_field)) {
      payload[`${col_field}`] = event.value;
      this.dashboardActions.requestUpdateViewCell(payload);
    }
  }

  redirectHome() {
    this.router.navigate(['']);
  }

  trackByRows(index: number, item: any): string {
    return index + item?.job_id;
  }

  protected readonly CustomNoRowsOverlay = CustomNoRowsOverlay;

  setColTypes() {

    this.columnTypes = {
      text: {
        type: 'text',
        cellDataType: 'text',
        comparator: (valueA: any, valueB: any, nodeA: any, nodeB: any, isDescending: boolean) => {
          return this.doTextColumnCompareNullLast(valueA,valueB,isDescending);
        },
      },
      dateColumn: {
        cellEditor: SharedCalendar,
        comparator: this.dateComparator,

      },
      date: {
        cellEditor: SharedCalendar,
        comparator: this.dateComparator,
      },
      dependencies: {
        cellDataType: 'object',
        cellRenderer: DependencyRenderer,
        sortable: false,
        cellRendererParams: {
          isEditable: false,
        },
      },
      stringORdateColumn: {
        cellEditor: SharedCalendar,
        comparator: this.dateComparator,
      },
      number: {
        type: 'numericColumn',
        filterType: 'number',
        cellDataType: 'number',
        valueFormatter: this.numberValueFormatter,
        valueGetter: this.numberValueGetter,
        comparator: (valueA: any, valueB: any, nodeA: any, nodeB: any, isDescending: boolean) => {
          return this.doColumnCompareNullLast(valueA,valueB,isDescending, '');
        },
      },
      money: {
        type: 'numericColumn',
        cellDataType: 'number',
        valueFormatter: this.moneyValueFormatter,
        valueGetter: this.numberValueGetter,
        comparator: (valueA: any, valueB: any, nodeA: any, nodeB: any, isDescending: boolean) => {
          return this.doColumnCompareNullLast(valueA,valueB,isDescending, '');
        },
      },
      object: {
        cellDataType: 'object',
        valueFormatter: (params: any) => {
          if (params && Array.isArray(params.value)) {
            return params.value.map((item: any) => item.number).join(', ');
          } else if (params && params.value && params.value.number) {
            return params.value.number;
          } else if (params && typeof params.value === 'string') {
            return params.value;
          }
          return '';
        },
        valueParser: (params: any) => {
          return '';
        },
      },
      link: {
        cellRenderer: (params: any) => {
          let href = params.value?.includes("://") ? params.value?.split("://")[1] : params.value;
          return params.value
            ? "<a  class=\"link link--no-underline\" href=\"//" + href + "\" target=\"_blank\" rel=\"noreferrer noopener\" >" + params.value + "</a> "
            : null;
        }
      },
      dropdown: {
        cellDataType: 'object',
        cellRenderer: DropDownCellRenderer,
        valueFormatter: this.dropdownValueGetter,
      },
      ship_link: {
        cellRenderer: ShipLinkCellRenderer,
        cellRendererParams: {
          isEditable: false,
        },
        sortable: true,
        cellDataType: 'object',
        type: 'object',
        valueFormatter: (params: any) => {
          if (params && Array.isArray(params.value)) {
            return params.value.join('');
          }
          return '';
        },
        comparator: (valueA: any, valueB: any, nodeA: any, nodeB: any, isDescending: boolean) => {
          return this.doTextColumnCompareNullLast(valueA.join(''),valueB.join(''),isDescending);
        },
        filter: true
      },
    };
  }

  dropdownValueGetter = (params: ValueFormatterParams) => {
    const field: any = params.colDef.field;
    return params.value;
  };

  setColDef() {
    let widthmap = new Map();
    let pinmap = new Map();
    let indexmap = new Map();
    //lets look up saved column definitions
    if (this.selectedView?.fields_selected_to_display) {
      let fields_save = deepCopy(this.selectedView?.fields_selected_to_display);
      for (let i = 0; i < fields_save.length; i++) {
        let column = deepCopy(fields_save[i]);
        if (column.field && column.field != "index") {

          widthmap.set(column.field, column.width);
          pinmap.set(column.field, column.pinned);
        }
        //grab ordering info
        if (!column.hasOwnProperty("order")) {
          indexmap.set(column.field, column.length + i + 100); //push off
        } else {
          indexmap.set(column.field, column.order);
        }
      }
    }else{
      let fields_save = deepCopy(TableConstants.jobItemsTableColumns);
      for (let i = 0; i < fields_save.length; i++) {
        let column = deepCopy(fields_save[i]);
        if (column.field && column.field != "index") {
          widthmap.set(column.field, column.width);
          pinmap.set(column.field, column.pinned);
        }
        //grab ordering info
        if (!column.hasOwnProperty("order")) {
          indexmap.set(column.field, column.length + i + 100); //push off
        } else {
          indexmap.set(column.field, column.order);
        }
      }
    }

    //setup the main table view, with default column info plus rendering info for aggrid
    let tableinfoMap = new Map();
    for (let i = 0; i < TableConstants.jobItemsTableColumns.length; i++) {
      let column = TableConstants.jobItemsTableColumns[i];
      tableinfoMap.set(column.field,column);
    }

    let tempcolumns = getTempColumns.bind(this)(tableinfoMap, widthmap, pinmap);
    tempcolumns = deepArrayClone(tempcolumns?.sort((a: any, b: any) => indexmap.get(a.field) - indexmap.get(b.field)));
    const allowedKeys = ['field', 'headerName', 'order', 'pinned', 'width', 'enableRowGroup', 'type'];
    tempcolumns.forEach((c: any) => {
      Object.keys(c).forEach((k: any) => {
        if (!allowedKeys.includes(k)) delete c[k];
      })      
      c.editable = this.isEditable.bind(this);
      if (c.type == 'dropdown') c.type = 'text';
    });

    let sharedColChanges = getSharedColumns.bind(this)();
    sharedColChanges.forEach((c: any) => {
      let curcol = tempcolumns.find((tc: any) => tc.field == c.field);
      if (!curcol) {
        return;
      }

      Object.keys(c).forEach((k: any) => {
        if (k != 'field') {
          curcol[k] = c[k];
        }
      });
    });

    if (this.isSharedEditable) {
      let shareEdits = editableView.bind(this)();
      shareEdits.forEach((c: any) => {
        let curcol = tempcolumns.find((tc: any) => tc.field == c.field);
        if (!curcol) {
          return;
        }
        if (c.field == 'status') {
          delete curcol.cellRenderer;
        }
        Object.keys(c).forEach((k: any) => {
          if (k != 'field') {
            curcol[k] = c[k];
          }
        });
      });
    }

    this.columns = tempcolumns;
    this.columns.forEach((item:any)=>{
      if(indexmap.has(item.field)){
        item.order = indexmap.get(item.field);
      }
    });

    this.cdr.markForCheck();
  }

  sortChanged(event:any){
  }

  onImagesUpdated(event: any) {
    let payload: any = {
      shared: true,
      sharedUrl: this.sharedViewUrl,
     ...event,
    }
    this.dashboardService.updateViewCell({payload: payload})
    .subscribe((response: any) => {
      let item = this.jobs.find((j: any) => j.id == response.id);
      item.images = response.images;
      this.gridApi.refreshCells({force: true, columns: ['images']});
    })
  }

  onAttachmentChange(event: any) {
    let payload = {
      shared: true,
      sharedUrl: this.sharedViewUrl,
    }
    if (event.updateOrder) {
      payload = {...payload, ...event.updateOrder};
      this.dashboardService.updateOrderDocument({payload: payload})
      .subscribe((response) => {
        let up = event.updateOrder;
        let supEdit = {id: up.document_id, document_type: up.document_type,
          number: up.number, order_document_date: up.order_document_date};
        this.jobs.forEach((j: any) => {
          if (!j.supplier_documents_numbers) return;
          j.supplier_documents_numbers = j.supplier_documents_numbers.map((s: any) => {
            return s.id == up.document_id ? supEdit: s;
          });
        });
        this.gridApi.refreshCells({force: true, columns: ['supplier_documents_numbers']});
      });
    } else if (event.addOrder) {
      payload = {...payload, ...event.addOrder};
      this.dashboardService.addOrderDocument({payload: payload})
      .subscribe((response: any) => {
        let supDocs = deepCopy(response.supplier_documents_numbers);
        let supAtts = deepCopy(response.supplier_documents_attachments);
        let orders = this.jobs.filter((j: any) => j.order_id == response.order_id);
        orders.forEach((o: any) => {
          o.supplier_documents_numbers = supDocs;
          o.supplier_documents_attachments = supAtts;
        });
        this.gridApi.refreshCells({force: true,
          columns: ['supplier_documents_numbers', 'supplier_documents_attachments']});
      });
    }
  }

  mousePosition = { x: 0, y: 0 };
  currentRow!: any;
  formattedCell!: any;
  showPopupComment: boolean = false;

  updateMousePosition(event: MouseEvent) {
    this.mousePosition.x = event.clientX;
    this.mousePosition.y = event.clientY;
  }

  getContextMenuItems = (params: any) => {
    document.addEventListener('mousemove', this.updateMousePosition.bind(this));
    if(params == undefined || params == null|| params.node == null || params.node == undefined){
      return [];
    }
    return [
      {
        name: "Add a comment",
        icon: "<i class=\"pi bi-chat-right-text\"></i>",
        action: () => {
          this.showCommentPopup(params);
        }
      },
    ];
  }

  showCommentPopup(params: any) {
    this.formattedCell = this.processCellCallback(params);
    this.currentRow = params;
    this.showPopupComment = true;
    this.cdr.detectChanges();
    document.removeEventListener('mousemove', this.updateMousePosition.bind(this));
  }

  isEditable(params: any) {
    return false;
  }

  applySearchTerm2(key: string, value: any, searchTerm: string) {
    if (searchTerm && value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())) {
      return true;
    }
    return false;
  }

  searchItems(): void {
    this.gridApi.onFilterChanged();
  }

  getPlaceHolderText() {
    return this.searchTerm ? '': 'Search...';
  }

  setFilterToAGgrid(){
    //set hide display
    let arrayOfFields:any[] = [];
    if(this.selectedView?.fields_selected_to_display) {
      arrayOfFields = this.selectedView?.fields_selected_to_display
        ?.filter((column: any) => column.visible)
        .map((col: any) => {
          return col.field;
        });
    }else{
      arrayOfFields = TableConstants.jobItemsTableColumns
        ?.filter((column: any) => column.visible)
        .map((col: any) => {
          return col.field;
        });
    }

    if (this.selectedView?.selectedJobId === "uncategorized") {
      this.columns?.map((item: any) => {
        item.hide = false;
        item.filter =  true; //defalt to allow filtering on all
      });
    } else {
      this.columns?.map((item: any) => {
        item.hide = !arrayOfFields?.includes(item.field);
        item.filter =  true; //default to allow filtering on all
      });
    }

    if (this.selectedView) {
      let group_fields = this.selectedView.fields_selected_to_group;
      let gfields_array = group_fields ? group_fields.map((x: any) => x.key): null;
  
      let sort_fields = this.selectedView.fields_selected_to_sort_json;
      let sfields_array = sort_fields ? sort_fields.map((x: any) => x.key): null;
      let columndef = this.gridApi.getColumnDefs();
      columndef?.forEach((item: any) => {
        if (!sfields_array || sfields_array.includes(item.field)) {
          item.sort = null;
        }
      });
      let newColState: any = [];
      let index = 0;
      if (sort_fields) {
        sort_fields.forEach((item: any) => {
          let dir = item.direction ? 'asc': 'desc';
          newColState.push({ colId: item.key, sort: dir, sortIndex: index++ });
        });
      }
      if (group_fields) {
        group_fields.forEach((groupby: any, _: number) => {
          let gfield = groupby.field;
          if (sfields_array && sfields_array.includes(gfield)) {
            newColState.forEach((x: any) => {
              if (x.colId === gfield) {
                x.rowGroup = true;
              }
            });
          } else {
            newColState.push({colId: gfield, rowGroup: true, sortIndex: newColState.length});
          }
        });
      }

      this.columns.forEach((item: any) => {
        let found = newColState.find((search: any) => search.colId == item.field);
        if (found) {
          item.rowGroup = found.rowGroup ? true: false;
          item.sortIndex = found.sortIndex;
          item.sort = found.sort ? found.sort : null;
        }
      })
  
      if (gfields_array) {
        this.columnApi.setRowGroupColumns(gfields_array);
      }
      this.columnApi.applyColumnState(
        {
          state: newColState,
          defaultState: { sort: null },
      });
      /*
      if (this.selectedView?.filter?.length) {
        let mainOp = this.selectedView.main_operator
        let allfilters = this.selectedView.filter;
        if (allfilters.length == 1) {
          this.gridApi.setAdvancedFilterModel(allfilters[0]);
        } else {
          let operationtype: 'AND'|'OR' = mainOp == 0 ? "AND": "OR";
          let advanced: any = { type: operationtype, filterType: "join", conditions: allfilters };
          this.gridApi.setAdvancedFilterModel(advanced);
        }
        this.gridApi.onFilterChanged();
      }
      */
      this.resetIndex();
    }
  }

  resetIndex() {
    let customCounter = 1;
    if (this.gridApi) {
      this.gridApi.forEachNodeAfterFilterAndSort(function(node) {
        if (node.displayed && !node.group) {
          node.rowIndex = customCounter++;
        }
      });
      this.gridApi.refreshCells({ force: true });
    }
  }

  internalCompar(a:any,b:any,isDescending:boolean){
    const nullPosition = isDescending ? -100000 : 100000
    // if a is null, push it towards whichever end null elements should end up
    if (a === null) return nullPosition
    if (b === null) return -nullPosition


    // OTHERWISE, both elements are non-null, so sort normally.
    // if a < b AND
    //     if ascending, a comes first, so return -1
    //     if descending, a comes after, so return 1
    if (a < b) return -1;

    // return the opposite of the previous condition
    if (a > b) return 1;

    // return 0 if both elements are equal
    return 0
  }
  internalDateCompar(a:any,b:any,isDescending:boolean){
    const nullPosition = isDescending ? -100000 : 100000
    // if a is null, push it towards whichever end null elements should end up
    if (a === null) return nullPosition
    if (b === null) return -nullPosition

    a = new Date(a);
    b = new Date(b);
    // OTHERWISE, both elements are non-null, so sort normally.
    // if a < b AND
    //     if ascending, a comes first, so return -1
    //     if descending, a comes after, so return 1
    if (a < b) return -1;

    // return the opposite of the previous condition
    if (a > b) return 1;

    // return 0 if both elements are equal
    return 0
  }


  //https://stackoverflow.com/questions/29829205/sort-an-array-so-that-null-values-always-come-last
  doColumnCompare(valueA: any, valueB: any, isDescending:boolean,field:string){
    let a = (valueA===null||valueA===undefined)?null:this.dropdownOptions[field]?.find(
      (dropdownItem: any) => dropdownItem?.id === valueA
    )?.value;
    let b = (valueB===null||valueB===undefined)?null:this.dropdownOptions[field]?.find(
      (dropdownItem: any) => dropdownItem?.id === valueB
    )?.value;
    return this.internalCompar(a,b,isDescending);
  }

  //some columns don't use 0 value to display so lets treat as null
  doColumnCompareNonZero(valueA: any, valueB: any, isDescending:boolean,field:string,){
    let a = (valueA===null||valueA==0||valueA===undefined)?null:this.dropdownOptions[field]?.find(
      (dropdownItem: any) => dropdownItem?.id === valueA
    )?.value;
    let b = (valueB===null||valueB==0||valueB===undefined)?null:this.dropdownOptions[field]?.find(
      (dropdownItem: any) => dropdownItem?.id === valueB
    )?.value;
    return this.internalCompar(a,b,isDescending);
  }

  doColumnCompareNullLast(a: any, b: any, isDescending:boolean,field:string,){
    const nullPosition = isDescending ? -100000 : 100000
    // if a is null, push it towards whichever end null elements should end up
    if (a === null||a==undefined) return nullPosition
    if (b === null||b==undefined) return -nullPosition
    return this.internalCompar(a,b,isDescending);
  }

  doColumnCompareNullLastNonZero(a: any, b: any, isDescending:boolean,field:string,){
    const nullPosition = isDescending ? -100000 : 100000
    // if a is null, push it towards whichever end null elements should end up
    if (a === null|| a==0||a==undefined) return nullPosition
    if (b === null||b==0||b==undefined) return -nullPosition
    return this.internalCompar(a,b,isDescending);
  }

  doDateColumnCompareNullLast(a: any, b: any, isDescending:boolean,field:string,){
    const nullPosition = isDescending ? -100000 : 100000
    // if a is null, push it towards whichever end null elements should end up
    if (a === null|| a==0||a==undefined) return nullPosition
    if (b === null||b==0||b==undefined) return -nullPosition
    return this.internalDateCompar(a,b,isDescending);
  }

  doTextColumnCompareNullLast(a: any, b: any, isDescending:boolean){
    const nullPosition = isDescending ? -100000 : 100000
    // if a is null, push it towards whichever end null elements should end up
    if (a === null||a==undefined) {return nullPosition;}
    if (b === null||b==undefined) {return -nullPosition}

    a = a.toString().toLowerCase().trim();
    b = b.toString().toLowerCase().trim();

    if (a.length < 1) {return nullPosition;}
    if (b.length < 1) {return -nullPosition;}

    const startsWithNumber = (str: any) => !isNaN(str.charAt(0)) && str !== '';

    const aStartsWithNumber = startsWithNumber(a);
    const bStartsWithNumber = startsWithNumber(b);

    // Handle cases where one starts with a number and the other with a letter
    if (aStartsWithNumber !== bStartsWithNumber) {
      return aStartsWithNumber ? 1 : -1;
    }

    // Normal string comparison if both start with the same type (number or letter)
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;

  }

  moneyValueFormatter(params: any) {
    return params.value ? "$ " + params.value : params.value;
  }

  numberValueGetter(params:any){
    let field: any = params.colDef.field;
    //need to allow for 0
    if(params.data && params?.data[field] != undefined && params?.data[field]  != null ) {
      return parseFloat(params?.data[field]);
    }
    return null;
  }

  numberValueFormatter = function (params: ValueFormatterParams) {
    if(params&& params.column && params.column.getColId() && TableConstants.wholenumberColumns.includes(params.column.getColId())){
      return params.value;
    }
    if(params.value != null && params.value != undefined && typeof params.value === 'number') {
      return params.value.toFixed(2);
    }
    return params.value;
  };

  dateComparator(valueA: any, valueB: any, nodeA: any, nodeB: any, isDescending: boolean) {
    return this.doDateColumnCompareNullLast(valueA, valueB, isDescending, '');
  }

}
