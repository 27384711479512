import { Component, OnInit, ViewChild, Input, Output } from '@angular/core';
import { SelectedViewModel } from '../../models/selected-view.model';
import { EventEmitter } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';
import { ElementRef, HostListener } from '@angular/core';
import { MailBoxCommunicationService } from '../../services/mailbox_comm.service';
import { selectComments, selectViews} from '../../state/selectors/dashboard.selector';
import { selectActiveJobs } from '../../state/selectors/dashboard.selector';
import { IStoreApiItem } from 'src/app/common/models/store-api-item.model';
import { IApplicationState } from 'src/app/common/state/models/app.state.model';
import { CommentsService } from '../../services/comment.service';
import { DashboardActions } from '../../state/actions/dashboard.actions';
import { MentionModule } from 'angular-mentions';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { deepCopy } from 'src/app/common/utils/general';
import { DashboardJobView } from '../../services/dashboard_job_view.service';
import { ActiveJobModel } from '../../models/active-job.model';
import { OptionsService } from '../../services/options.service';


@Component({
  selector: 'cardview-filter',
  templateUrl: './cardview-filter.component.html',
  styleUrls: ['./cardview-filter.component.css'],
})
export class CardViewFilter implements OnInit {
  @Input() selectedView?: SelectedViewModel;
  @Input() mailBoxVisible: boolean = false;
  @Input() activeJob!: ActiveJobModel;
  @Input() isLoading!: boolean;
  @Output() searchFormsChange = new EventEmitter<string>();
  @Input() sidePanelOpen: boolean = true;


  closeCards: boolean = false;
  search: string = '';

  constructor(
    private store: Store<IApplicationState>,
    private commentsService: CommentsService,
    private dashboardActions: DashboardActions,
    private elRef: ElementRef,
    private sanitizer: DomSanitizer,
    private djv: DashboardJobView,
    readonly optionsService: OptionsService,

    ) {
      (window as any)['cvf'] = this;
    }

  ngOnInit() {
    let searchTerm = localStorage.getItem('searchPhrase') as string;
    if (this.selectedView?.activeView?.id) {
      this.search = searchTerm;
    }

    this.djv.searchFilterObs$.subscribe((data: any) => {
      this.search = '';
    });
  }


  toggleCloseCard() {
    this.closeCards = !this.closeCards;
    this.djv.toggle_close_cards(this.closeCards);
  }

  clearSearch(){
    this.search = '';
    this.searchFormsChange.emit( this.search );

  }
  onFilterTextBoxChanged():void {
    this.searchFormsChange.emit(this.search );
  }

  get filterWidth() {
    let base = '100vw';
    let extra = this.sidePanelOpen ? '- 400px' : '- 170px';
    return `calc(${base} ${extra})`
  }

}