<div class="workflow-box-col" style="cursor: default;">
    <div class="org-ai-option-text" style="margin-bottom: 12px;"
        [ngClass]="{
            'workflow-active-text': !graphView,
            'wf-slug-active-text': graphView
        }"
        [ngStyle]="{
            'font-size': graphView ? '11px' : '12px',
            'margin-top': graphView ? '6px' : '24px'
        }"
    >
        Meti Forwarding Email
    </div>
    <div class="wf-choose-small-text"
        [ngStyle]="{
            'font-size': graphView ? '11px' : '12px',
        }"
    >
        CC this email address on any POs you issue to pull them into the system.
    </div>
    <div class="workflow-box-row" style="align-items: center; gap: 4px;"
        [ngStyle]="{
            'margin-top': graphView ? '6px' : '12px'
        }"
    >
        <div class="wf-choose-small-text">
            orders@
        </div>
        <input class="wf-slug-box workflow-close-box-done"
            [ngStyle]="{'max-width': slugWidth}"
            [ngClass]="{
                'workflow-close-box-empty': graphView
            }"
            [(ngModel)]="slug" 
            [placeholder]="'SLUG'"
            (keydown.enter)="onSlugKeyDown($event)"
            (blur)="onSlugBlur($event)"
        />
        <div class="wf-choose-small-text">
            .getmeti.com
        </div>
        <i *ngIf="!copying" class="bi bi-copy wf-copy-slug" (click)="sendToClipboard()"></i>
        <i *ngIf="copying" class="bi bi-check2 wf-copy-slug"></i>
    </div>
    <div *ngIf="slugError && showSlugError" class="wf-choose-small-text wf-slug-error">
        {{slugError}}
    </div>
    <div *ngIf="showSlugSuccess" class="wf-choose-small-text wf-slug-error" style="color: #53AE61">
        Successfully changed your slug!
    </div>
    <div *ngIf="gmailText" class="wf-choose-xsmall-text" style="margin-top: 24px">
        If you selected “gmail extension” or “forward emails” as the method for getting new orders into Meti (the previous set up step), you will have to use the same method for sharing updates. 
    </div>
    <div class="wf-choose-xsmall-text" style="margin-top: 16px"
        [ngStyle]="{
            'color': graphView ? '#828282' : '#616161',
            'font-size': graphView ? '11px' : '12px',
        }"
    >
        Note: No matter the method selected, you can always manually add new orders in the app.
    </div>
</div>