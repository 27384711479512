<div class="assistants-top-container" *ngIf="!isSliderOpen">
    <div class="workflow-top-text">
        Virtual assistants for everyone on your team
    </div>
    <div class="org-ai-employee-subtext">
        Try our most popular pre-built AI employees for driving increased margins for your business. You can always customize these for your team.
    </div>
    <div class="workflow-rows-pics">
        <div class="org-ai-profile" style="position: relative;" *ngFor="let setting of settings"
            [ngClass]="{
                'workflow-chosen-girl': setting.name == chosenPic
            }"
        >
            <div class="org-user-profile-picture"
            [ngStyle]="{
                'background-color': backgroundForName(setting.name)
            }"
            >
                <img *ngIf="sales_girl && ops_ben" [src]="picForName(setting.name)">
                <i *ngIf="!(sales_girl && ops_ben)" class="bi bi-person org-user-no-image-profile"></i>
            </div>
            <div class="workflow-pic-name"
                [ngStyle]="{'color': setting.name == chosenPic ? '#000': ''}"
            >
                {{setting.name}}
            </div>
            <div class="org-ai-extra-name" *ngIf="setting.extra">
                {{setting.extra}}
            </div>
        </div>
    </div>
    <div class="grid-container custom-scrollbar-close">
        <div *ngFor="let choice of choices" class="grid-item"
            [ngClass]="{
                'grid-item-hover': !choice.nohover
            }">
            <div class="grid-item-overlay" *ngIf="choice.nohover"></div>
            <div class="grid-icon-holder" [ngStyle]="{'background-color': choice.background}">
                <i class="bi grid-icon-main"
                    [ngClass]="[choice.icon]"
                    [ngStyle]="{'color': choice.color}"
                ></i>
            </div>
            <div class="grid-main-text">
                <div class="grid-main-text-head">
                    <div class="grid-main-text-name">
                        {{choice.name}}
                    </div>
                    <div *ngIf="!choice.nohover" class="grid-main-head-icons">
                        <i class="bi bi-sliders grid-slider" *ngIf="orgData[choice.org_data_value]"
                                (click)="onChooseSlider(choice)"></i>
                        <p-inputSwitch 
                            [(ngModel)]="orgData[choice.org_data_value]"
                            name="choiceon"
                            (onChange)="doUpdateChoiceOn()">
                      </p-inputSwitch>
                    </div>
                    <div class="org-ai-extra-name" style="font-size: 10px" *ngIf="choice.nohover">
                        Coming soon!
                    </div>
                </div>
                <div class="grid-main-text-sub">
                    {{choice.subtext}}
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="isSliderOpen" class="flow-graph-container flow-graph-bounds"
    [ngStyle]="{'max-width': maxWidth, 'min-width': maxWidth}"
>
    <div class="flow-banner">
        <div class="flow-banner-left">
            <i class="bi bi-chevron-left flow-clickable" (click)="onFlowBack()"></i>
            <p-inputSwitch 
                [(ngModel)]="orgData[activeChoice.org_data_value]"
                name="choiceon"
                (onChange)="doUpdateChoiceOn()">
            </p-inputSwitch>
            <div class="grid-main-text-name">
                {{activeChoice.name}}
            </div>
            <!--<i class="bi bi-exclamation-circle flow-warning"></i>-->
        </div>
        <div class="flow-save" (click)="flowOnSave()">
            {{flowSaveText}}
            <i class="bi bi-check-lg" *ngIf="flowSaving"></i>
        </div>
    </div>
    <div class="flow-container-split"
        [ngStyle]="{
            'min-width': maxWidth, 'min-height': 'calc(100vh - 75px)',
            'max-width': maxWidth, 'max-height': 'calc(100vh - 75px)',
        }"
        >
        <div class="flow-container-out custom-scrollbar-close" #workflowContainer
                [ngStyle]="{'width': maxWidth, 'height': 'calc(100vh - 75px)'}">
            <div *ngIf="flushGraph">
                <div *ngFor="let switch of switches" class="issues-on-flow"
                    [ngStyle]="{
                        'top': switch.top || 0, 'left': switch.left || 0,
                        'display': 'flex',
                        'width.px': switch.width ? switch.width: 100,
                    }">
                    {{switch.text}}
                </div>
                <flow-node *ngFor="let node of nodes" (click)="onNodeClick(node)"
                    [data]="node"
                    [selected]="lastNodeClicked?.id"
                    (gotoPageClick)="gotoPageClick($event)"
                ></flow-node>
                <flow-arrow *ngIf="arrowCount"
                    [arrowCount]="arrowCount"
                    [useLines]="true"
                    [dashedCount]="dashedCount"
                ></flow-arrow>
            </div>            

        </div>
        <flow-sidebar *ngIf="showSideBar"
            [data]="lastNodeClicked"
            [orgData]="orgData"
            [orgOptions]="orgOptions"
        ></flow-sidebar>
    </div>

</div>
