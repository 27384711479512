<div class="materlog-shiplink-container" #shipLinkEl
[ngStyle]="{'min-width': maxPixelWidth}">
    <div class="materlog-shiplink-single" *ngFor="let link of shipLinks"
    [ngStyle]="{'max-width': maxPixelWidth}">
        <input class="materlog-ship-input" type="text"
            *ngIf="!(link.isurl && !link.editing) && active"
            [ngClass]="{
            'materlog-ship-input-active': link.editing,
            }"
            [(ngModel)]="link.value" [placeholder]="link.placeholder"
            (focus)="onShipFocus(link)" (blur)="onShipBlur(link)"
            (keydown)="shipKeydown($event, link)"/>
        <a *ngIf="(link.isurl && !link.editing) || !active" [href]="link.value"
            target="_blank" rel="noopener noreferrer"
            class="materlog-placeholder-link">{{link.placeholder}}
        </a>
        <div *ngIf="active && !link.editing" class="materlog-ship-tools">
            <i class="materlog-link-actions bi bi-pencil" (click)="editLink(link)"></i>
            <i class="materlog-link-actions bi bi-x-lg" (click)="removeLink(link)"></i>
        </div>
    </div>
    <i *ngIf="canAddUrl && active" class="materlog-link-add bi bi-plus-lg" (click)="addLink()"></i>
    <div *ngIf="!active && !shipLinks.length" class="materlog-ship-input"> -- </div>
</div>