export function connectNodesWithSteps(nodeFrom: any, nodeTo: any, arrow: any, container: any,
                                        cut: any = undefined,
                                        space: boolean = true) {
    let containerRect = container.getBoundingClientRect();
    const rect1 = nodeFrom.getBoundingClientRect();
    const rect2 = nodeTo.getBoundingClientRect();
  
    // Start at the bottom center of the "from" node relative to the container
    const startX = rect1.left + rect1.width / 2 - containerRect.left;
    const startY = rect1.bottom - containerRect.top + (space ? 5: 0);

    // End at the top center of the "to" node relative to the container
    const endX = rect2.left + rect2.width / 2 - containerRect.left;
    const endY = rect2.top - containerRect.top - (space ? 10: 0);

    // Calculate a mid-point for the stepwise connection
    let midY = (startY + endY) / 2;
    midY = Math.round(midY);
    if (cut) {
        midY = (startY + cut);
    }

    // Create the path data for a stepwise line
    const pathData = `
    M ${startX} ${startY} 
    L ${startX} ${midY} 
    L ${endX} ${midY} 
    L ${endX} ${endY}
    `;

    arrow.setAttribute("d", pathData);
}


export function getArrowArray(arrowCount: any): number[] {
    return Array.from({ length: arrowCount }, (_, i) => i + 1);
}