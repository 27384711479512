<div class="materlog-add-order-tooltip"
  [ngStyle]="{
    'top': top,
    'right': right,
    'left': left,
    'font-size': font_size,
    'padding': padding,
    'width': width,
    'height': height,
    'line-height': line_height,
    'font-weight': font_weight,
    'border-radius': border_radius,
    'background-color': background_color,
  }">
  <div>{{text}}</div>
</div>