import { ChangeDetectorRef, Component, OnInit, ViewChild, Input } from '@angular/core';
import { ElementRef, HostListener } from '@angular/core';
import { PermissionsService } from 'src/app/root/services/permissions.service';
import { DashboardService } from '../../services/dashboard.service';
import { DashboardJobView } from '../../services/dashboard_job_view.service';


@Component({
  selector: 'materlog-meta-settings',
  templateUrl: './meta-settings.component.html',
  styleUrls: [
    './meta-settings.component.css',
    '../side-panel/side-panel.component.css'
  ]
})
export class MetaSettingsComponent implements OnInit {
    @Input() dropdownOptions: any;
    @Input() allMailData: any;
    @Input() whichWorkflowOption: any;
    @Input() settingsTypePage: any = '';

    generalSetttingsActive: boolean = true;
    preferencesActive: boolean = false;
    personalSettingsActive: boolean = false;


    workflowsConfirmed: boolean = true;

    settingOptions: any = [
        {name: 'General'},
        {name: 'Preferences'},
        {name: 'Personal'},

    ]

    constructor(
        private permissionService: PermissionsService,
        private dashboardService: DashboardService,
        private djv: DashboardJobView,
    ) {
    }

    ngOnInit(): void {
        (window as any)['metas'] = this;
        this.retrieveNotifications();

        this.djv.sidePanelNotifGet$.subscribe(() => {
            this.retrieveNotifications();
          });
    }

    ngOnChanges(changes: any) {
        if (this.whichWorkflowOption) {
            this.generalSetttingsActive = false;
            this.preferencesActive = true;
        }
        if (this.settingsTypePage) {
            let setting = {name: this.settingsTypePage};
            this.changeSettingsType(setting);
        }

    }


    changeSettingsType(setting: any) {
        this.generalSetttingsActive = this.preferencesActive = this.personalSettingsActive = false;
        this.isSettingsActive(setting, true);
    }

    isSettingsActive(setting: any, set: boolean = false) {
        if (setting.name == 'General') {
            if (set) this.generalSetttingsActive = true;
            return this.generalSetttingsActive;
        } else if (setting.name == 'Preferences') {
            if (set) this.preferencesActive = true;
            return this.preferencesActive;
        } else {
            if (set) this.personalSettingsActive = true;
            return this.personalSettingsActive
        }
    }

    logoutClicked() {
        this.permissionService.logout();
    }

    retrieveNotifications() {
        this.dashboardService.getNotificationsSidePanel().subscribe((result: any) => {
            this.workflowsConfirmed = result.confirmed;
          })
    }

    canShowAlert(setting: any) {
        return setting.name == 'Preferences' && !this.workflowsConfirmed
    }
}
