import { Component, Input, ChangeDetectorRef, ApplicationRef, inject, Output, EventEmitter} from '@angular/core';
import { ViewChild, ElementRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'materlog-tooltip',
    templateUrl: './materlog-tooltip-component.component.html',
    styleUrls: ['./materlog-tooltip-component.component.css'],
  })
export class MaterLogToolTip {
    @Input() text: string = '';
    @Input() top: string = '0px';
    @Input() right: string = '';
    @Input() left: string = '';
    @Input() font_size: string = '11px';
    @Input() padding: string = '10px';
    @Input() width: string = '100px';
    @Input() height: string = '16px';
    @Input() line_height: string = '16px';
    @Input() font_weight: string = '600';
    @Input() border_radius: string = '10px';
    @Input() background_color: string = '#FAFAFA';
  
    constructor(
    ) {
    }
  
    ngOnInit() {
    }
  
  }