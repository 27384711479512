import { Component, EventEmitter, Input, ChangeDetectorRef, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ViewChild, ElementRef } from '@angular/core';
import { CommonConstants } from 'src/app/common/constants/common.constants';
import { TableConstants } from 'src/app/common/constants/table.constants';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { DashboardActions } from 'src/app/dashboard/state/actions/dashboard.actions';
import { SelectedViewModel } from '../../models/selected-view.model';
import { Observable } from 'rxjs';
import { DashboardJobView } from '../../services/dashboard_job_view.service';
import { HostListener } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'view-tab-list',
  templateUrl: './view-tab-list.component.html',
  styleUrls: [
    './view-tab-list.component.sass',
    './view-tab-list.component.css'
  ]
})
export class ViewTabListComponent extends OnDestroyMixin() implements OnChanges {
  @Input() selectedView?: SelectedViewModel;
  @Input() activePage: any;
  @Input() viewTabMarginLeft: string = '8px';
  @Input() viewTabPaddingLeft: string = '8px';

  @ViewChild('viewToolTip') viewToolTip!: ElementRef;
  @Output() scrollDown = new EventEmitter<void>();

  viewMultiple = 21;
  viewHeight: string = `${this.viewMultiple}px`;

  @Input() isLoading: any = true;
  @Input() isLoadingViews: any = true;

  private newViewSubscription!: Subscription;
  private destroyView$ = new Subject<void>();

  activeSettingsPanel?: number;
  newViewName?: string;
  isAddingView: boolean = false;
  isChangingView: boolean = false;

  viewNameSubmitted: boolean = false;
  settingsModal: boolean = false;
  addViewNameModal: boolean = false;
  editViewNameModal: boolean = false;

  views: any[] = CommonConstants.defaultView;
  selectedViewId: any = undefined;

  viewPosX: any = 0;
  viewPosY: any = 0;

  constructor(
    private dashboardActions: DashboardActions,
    private djv: DashboardJobView,
    private cdr: ChangeDetectorRef) {
    super();
    (window as any)['view'] = this;
  }

  ngOnInit() {
    this.djv.loading$.subscribe((loading: boolean) => {
      this.isLoading = loading;
    });
    this.djv.loadingViews$.subscribe((loading: boolean) => {
      this.isLoadingViews = loading;
    });
    this.djv.newView$.pipe(
      takeUntil(this.destroyView$)
    ).subscribe(() => {
      this.addNewView();
    });
  }

  override ngOnDestroy(): void {
    this.destroyView$.next();
    this.destroyView$.complete();
  }

  @HostListener('document:click', ['$event'])
  clickOutside(event: any) {
    this.settingsModal = false;
  }


  ngOnChanges(changes: SimpleChanges): void {

    if (changes.selectedView) {
      this.isAddingView = false;
      this.views = this.selectedView ? this.selectedView.views : CommonConstants.defaultView;
      this.viewHeight = `${this.views.length * this.viewMultiple}px`;
    }
  }

  get viewChange() {
    return this.isChangingView || this.isAddingView;
  }

  skeletonRows() {
    if (this.views) return this.views;
    return [1,2,3];
  }

  viewIdRename: any = undefined;
  renameView(view: any) {
    if (this.isAnyLoading) return;
    this.viewIdRename = view.id;
    this.newViewName = view.name;
    this.settingsModal = false;
    this.editViewNameModal = true;
  }

  deleteView(view: any) {
    if (this.isAnyLoading) return;
    this.settingsModal = false;
    this.isAddingView = true;
    this.isChangingView = true;
    let current_view = JSON.parse(localStorage.getItem(`viewIdFor${this.selectedView?.selectedJobId}`) || '');
    if (current_view != view.id) {
      this.djv.get_view_data({'deleted': view.id});
      this.dashboardActions.requestDeleteViewReturn({payload: {id: view.id}})
        .subscribe((result: any) => {
          this.isAddingView = false;
          this.isChangingView = false;
        });
    } else {
      // delete a view and retrieve a view
      let new_sel_views = this.selectedView?.views.filter((v: any) => v.id != view.id);
      if (new_sel_views && new_sel_views?.length) {
        let new_id = (new_sel_views as any)[0].id
        let data = {delete_view: view.id, new_id: new_id};
        this.dashboardActions.requestDeleteViewAndNewView({payload: data})
        .subscribe((result: any) => {
          this.handleNewViewDelete(result, view.id);
          this.setLocalStorageView(result);
        });
      } else {
        // delete a view and create a view
        let data = {delete_view: view.id};
        this.addNewViewObs(data).subscribe((result: any) => {
          this.isAddingView = false;
          this.handleNewViewDelete(result, view.id);
          this.setLocalStorageView(result);
        })
      }
    }
  }


  handleEmit(result: any) {
    let data = {'view': result.views[0]};
    this.djv.get_view_data({'retrieve': data});
  }

  selectView(id: string) {
    if (this.isAnyLoading) return;
    this.djv.view_clicked();
    if (id == this.selectedView?.activeView.id && this.activePage == 'Items') return;
    this.selectedViewId = id;
    this.settingsModal = false;
    this.isChangingView = true;
    let data = {view_id: id};
    this.dashboardActions.requestSingleView(data)
      .subscribe((result) => {
        this.handleEmit(result);
        this.isChangingView = false;
        this.selectedViewId = undefined;
      })
  }

  handleNewViewDelete(result: any, view_id: any) {
    this.handleEmit(result);
    this.djv.get_view_data({'deleted': view_id});
    this.isAddingView = false;
    this.isChangingView = false;
  }

  setLocalStorageView(result: any) {
    if (this.selectedView?.selectedJobId && result?.views?.length) {
      let view_id = result.views[0];
      let title = `viewIdFor${this.selectedView?.selectedJobId}`;
      localStorage.setItem(title, JSON.stringify(view_id));
    }
  }

  addNewView() {
    if (this.isAddingView) return;
    this.isAddingView = true;
    this.viewHeight = `${(this.views.length + 1)* this.viewMultiple}px`;
    let data = {};
    this.addNewViewObs(data).subscribe((result: any) => {
      this.handleEmit(result);
      setTimeout(() => {
        this.isAddingView = false;
      }, 300);
    })
  }

  addNewViewObs(data: any): Observable<any> {
    const colors = [
      "Red", "Green", "Blue", "Yellow", "Purple",
      "Orange", "Pink", "Brown", "Black", "White",
      "Gray", "Cyan", "Magenta", "Lime", "Maroon",
      "Olive", "Navy", "Teal", "Aqua", "Fuchsia",
      "Silver", "Gold", "Beige", "Ivory", "Coral",
      "Turquoise", "Indigo", "Violet", "Plum", "Orchid",
      "Lavender", "Tan", "Chocolate", "Peach", "Mint",
      "Burgundy", "Chartreuse", "Emerald", "Amethyst", "Ruby",
      "Sapphire", "Crimson", "Jade", "Azure", "Cerulean"
    ];
    this.newViewName = colors[Math.floor(Math.random() * colors.length)];

    const objToSend: any = {
      name: this.newViewName,
      id: this.activeSettingsPanel !== undefined ? this.views[this.activeSettingsPanel]?.id : '',
    };
    return this.dashboardActions.requestAddViewReturn({
      ...data,
      name: objToSend.name,
      job: this.selectedView?.selectedJobId === 'all' || this.selectedView?.selectedJobId == 'uncategorized'
            ? undefined : this.selectedView?.selectedJobId,
      fields_selected_to_display: JSON.parse(JSON.stringify(TableConstants.jobItemsTableColumns)),
      uncategorized: this.selectedView?.selectedJobId == 'uncategorized',
    })
  }

  viewSettings(event: any, index: number) {
    event.preventDefault();
    event.stopPropagation();
    if (this.isAnyLoading) return;
    this.activeSettingsPanel = index;
    if (!this.settingsModal) {
      this.viewPosX = event.clientX - 60;
      this.viewPosY = event.clientY + 10;
    }
    this.settingsModal = !this.settingsModal;
  }

  closeAddOrEditViewModal() {
    this.resetForm();
  }

  saveViewName(isFormUpdated: boolean) {
    this.viewNameSubmitted = true;

    if (this.newViewName?.trim()) {
      const objToSend: any = {
        name: this.newViewName,
        id: this.viewIdRename,
      };

      if (isFormUpdated) {
        let out = this.dashboardActions.requestUpdateViewReturn({ selectedViewId: objToSend.id, name: objToSend.name });

        if (this.selectedView) {
          let found = this.selectedView.views.find((v: any) => v.id == this.viewIdRename);
          if (found) found.name = this.newViewName;
        }
      }
      this.resetForm();
    }
  }

  clickOutsideModal() {
    this.settingsModal = false;
    this.activeSettingsPanel = undefined as any;
  }

  private resetForm() {
    this.addViewNameModal = false;
    this.editViewNameModal = false;
    this.viewNameSubmitted = false;
    this.newViewName = '';
  }


  get canHighlightView() {
    let activePage = localStorage.getItem('activePage');
    return activePage == 'Items';
  }

  get isAnyLoading() {
    return this.isLoading || this.isLoadingViews || this.isChangingView || this.isAddingView;
  }
}
