import { ChangeDetectorRef, Input, Output, EventEmitter} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import { Component, ViewChild, ElementRef, Renderer2, AfterViewInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { DashboardActions } from 'src/app/dashboard/state/actions/dashboard.actions';
import { PayloadService } from "../../../services/payload.service";
import { HttpClient } from "@angular/common/http";
import { JobTableService } from "../../../services/job-table.service";
import { OptionsService } from 'src/app/dashboard/services/options.service';
import tippy from 'tippy.js';
import { DashboardRoutes } from 'src/app/dashboard/enums/dashboard-routes.enum';
import { FormsModule } from '@angular/forms';
import { ListboxModule } from 'primeng/listbox';
import { DialogModule } from 'primeng/dialog';
import { AddRoomModalComponent } from '../add-room-dialog/add-room-modal.component';
import { AddSupplierContactModalComponent } from '../../add-supplier-contact-modal/add-supplier-contact-modal.component';
import { AddSupplierModalComponent } from '../../add-supplier-modal/add-supplier-modal.component';


@Component({
  selector: 'dropdown-cell-renderer',
  templateUrl: './dropdownv2.component.html',
  styleUrls: ['./dropdownv2.component.sass',
              '../../templates/templates.component.sass'
  ],
  imports: [CommonModule, FormsModule, ListboxModule, DialogModule, AddSupplierModalComponent,
            AddRoomModalComponent, AddSupplierContactModalComponent],
  standalone: true
})

export class DropDownCellRenderer implements ICellRendererAngularComp, AfterViewInit {
  @Input() maxWidth: number = 110;
  backgroundColor: string = '';
  textToShow: string = '';
  selectedOption: string = '';
  // options!: any;
  @Input() options: any;
  @Input() isAgGrid: boolean = true;
  @Input() jobsPage: boolean = false;
  @Input() approvalsPage: boolean = false;
  @Input() supplierPage: boolean = false;
  @Input() credPage: boolean = false;
  @Input() submittalView: boolean = false;
  @Input() isMail: boolean = false;
  @Input() cardView: boolean = false;
  @Input() cardViewWidth: number = 180;
  @Input() customStyles: any = {};
  @Input() blankBackup: boolean = false;
  @Input() backupText: string = '--';
  @Input() noPadding: boolean = false;

  @Input() data: any;
  @Input() colField: any;
  @Input() isMultiple: boolean = false;
  @Input() selectedUsers: any = [];
  @Output() statusChanged = new EventEmitter<any>();

  newSupplierText = "+ Add new supplier";
  newSupplier: any = {value: this.newSupplierText};
  newSupplierContactText = '+ Add New Contact'
  newSupplierContact: any = {value: this.newSupplierContactText};
  newRoomText: string = '+ Add New Room';
  newRoom: any = {value: this.newRoomText};
  newReceiverContactText = '+ New Contact';
  newReceiverContact: any = {value: this.newReceiverContactText}; 
  newItemText: string = '';
  addItemModal: boolean = false;
  addModalCards: boolean = false;

  @ViewChild('content') content!: ElementRef;
  @ViewChild('trigger') trigger!: ElementRef;
  @ViewChild('buttonText') buttonText!: ElementRef;
  @ViewChild('listbox', { read: ElementRef }) listboxElementRef!: ElementRef;

  private tippyInstance!: any;
  isOpen: boolean = false;
  params: any;

  constructor(
    private httpClient: HttpClient,
    private dashboardActions: DashboardActions,
    private jobTableService: JobTableService,
    private payloadService: PayloadService,
    readonly optionsService: OptionsService,
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2,
    public router: Router,
  ) {
    (window as any)['drop'] = this;
  }

  ngOnChanges(changes: any) {
    if (this.jobsPage && this.colField == 'receiver') {
      this.createViewsJobsPage();
      this.setButtonText(this.textToShow);
    } 
    if (this.cardView && this.colField == 'status') {
      this.reinitValue();
    } 
    if (this.credPage && this.colField == 'credChoices') {
      this.reinitValue();
    } 
  }

  ngOnInit() {
    this.maxWidth = this.approvalsPage || this.cardView
      ? this.cardViewWidth
      : this.maxWidth;
  }
  ngAfterViewInit() {
    this.reinitValue();
  }

  reinitValue() {
    if (this.jobsPage) {
      this.createViewsJobsPage();
    } else if (this.isMail || this.cardView) {
      let backup_text = this.backupText;
      if (this.colField == 'job_id') backup_text = 'Select job';
      else if (this.colField == 'supplier_id') backup_text = 'Select supplier';
      else if (this.colField == 'order_id') backup_text = 'Select order';
      if (this.cardView) {
        if (this.data?.id != undefined) {
          this.selectedUsers = this.options.find((item: any) => item.id == this.data.id);
          this.textToShow = this.selectedUsers ? this.selectedUsers.value: '';
          if (!this.textToShow) {
            this.textToShow = backup_text ? backup_text : '--';
          }
        } else {
          this.textToShow = backup_text ? backup_text : '--';
        }
        if (this.submittalView && !this.textToShow) {
          this.textToShow = '--';
        }
        if (this.blankBackup && this.textToShow == '--') {
          this.textToShow = '';
        }
      } else {
        if (this.data && this.data.info &&
          this.data.info[this.data.index] != undefined &&
          this.data.info[this.data.index][this.colField] != undefined) {
          let val = this.data.info[this.data.index][this.colField];
          this.selectedUsers = this.options.find((item: any) => item.id == val);
          this.textToShow = this.selectedUsers ? this.selectedUsers.value: '';
        } else {
          this.textToShow = backup_text;
        }
      }

    } else if (this.supplierPage) {
      this.setSupplierPageText();
    }
    this.setButtonText(this.textToShow);
  }

  supplierPageTrackMap() {
    return {'No': 'N', 'Yes': 'Y', 'Yes Online Only': 'Y*'};
  }

  setSupplierPageText() {
    let id = this.data[this.colField];
    let matchingItem = this.options.find((item: any) => item.id === id);
    let track_map = this.supplierPageTrackMap() as any;
    this.textToShow = track_map[matchingItem.value] ? track_map[matchingItem.value] : ''; 
  }

  createViewsJobsPage(): void {
    let id = '';
    if (this.colField == 'receiver_contacts') {
      if (this.options) {
        this.options = this.options.map((item: any) => (
          { id: item.id, value: item.name || item.value}
          ));
        let rc = this.data['receiver_contact'];
        if (rc && rc.id) {
          let matchingItem = this.options.find((item: any) => item.id === rc.id);
          this.textToShow = matchingItem ? matchingItem.value : '';
        }
      } else {
        this.options = [];
      }
      if (this.data.receiver && this.data.receiver.id) {
        this.options.push(this.newReceiverContact);
      }

    } else if (this.colField == 'receiver'){
      let matchingItem = this.options.find((item: any) => item.id === this.data?.id);
      this.textToShow = matchingItem ? matchingItem.value : '';
    } else if (this.colField == 'users' && !this.isOpen){
      this.selectedUsers = (this.data || [])
        .filter((item: any) => this.options?.find((o: any) => o.id == item.user_id))
        .map((item: any) => ({id: item.user_id, value: item.email})); 
      this.setSelectedUsersText(this.selectedUsers);
    } else {
      let matchingItem = this.options.find((item: any) => item.id === id);
      this.textToShow = matchingItem ? matchingItem.value : '';
    }
  }

  setSelectedUsersText(users: any) {
    if (!users?.length) {
      this.textToShow = 'Select Users';
    } else {
      this.textToShow = `${users.length} Users`;
    }
  }

  setButtonText(text: string){
    if (this.buttonText && this.buttonText.nativeElement) {
      this.buttonText.nativeElement.textContent = text;
    }
  }

  onStatusChange(event: any) {
    let value = event.value;
    if (!this.isMultiple) {
      if (!value || value.value == this.textToShow) {
        this.isOpen = false;
        this.tippyInstance.destroy();
        this.cdr.detectChanges();
        return;
      }
      if (value.value == this.newRoomText) {
        this.addItemModal = true;
        this.togglePopup(undefined);
        this.newItemText = this.newRoomText;
        this.cdr.detectChanges();
        return;
      } else if (value.value == this.newSupplierText) {
        if (this.cardView) {
          this.addModalCards = true;
        } else {
          this.addItemModal = true;
        }
        this.togglePopup(undefined);
        this.newItemText = this.newSupplierText;
        this.cdr.detectChanges();
        return;
      } else if (value.value == this.newSupplierContactText) {
        this.addItemModal = true;
        this.togglePopup(undefined);
        this.newItemText = this.newSupplierContactText;
        this.cdr.detectChanges();
        return;
      } else if (value.value == this.newReceiverContactText) {
        this.router.navigate(
          [{ outlets: { addJob: [DashboardRoutes.AddJob] } }], 
          { 
            queryParams: { 
              onlyReceiverContact: true, receiverId: this.data.receiver.id
            },
            skipLocationChange: true
          }
        );
      }
      if (value.value != this.newReceiverContactText) {
        if (this.supplierPage) {
          let track_map = this.supplierPageTrackMap() as any;
          this.textToShow = track_map[value.value] ? track_map[value.value] : ''; 
        } else {
          this.textToShow = value.value;
        }
        this.setButtonText(this.textToShow);
      }
    }

    if (this.jobsPage || this.isMail || this.supplierPage || this.cardView) {
      if (this.colField == 'users') {
        this.setSelectedUsersText(event.value);
        this.setButtonText(this.textToShow);
      } else if (this.colField == 'receiver' && event.value.id == -1) {
        this.textToShow = '';
        this.setButtonText(this.textToShow);
      }
      this.statusChanged.emit({'event': event, 'data': this.data,
                               'colField': this.colField});

    } else {
      let colkey = this.params.column.colId;
      if (colkey == 'status') {
        let colors = this.params.context.faster['status_colors'];
        this.backgroundColor = colors[value.id];
      }
      this.params.colDef.editable = true;
      this.params.node.setDataValue(colkey, event.value.id); 
    }

    if (!this.isMultiple) {
      this.togglePopup(undefined);
      this.cdr.detectChanges();
    }
  }

  onCardViewSupplierOpen() {
    this.options = this.options.filter((o: any) => o.value != this.newSupplierText);
    const seenIds = new Set();
    this.options = this.options.filter((o: any) => {
      if (seenIds.has(o.id)) {
        return false;
      } else {
        seenIds.add(o.id);
        return true;
      }
    });
    this.options.sort((a: any, b: any) => a.value.toLowerCase().localeCompare(b.value.toLowerCase()));
    this.options = [...this.options, this.newSupplier];
  }

  togglePopup(event: any) {
    (window as any)['drop'] = this;
    this.isOpen = !this.isOpen;
    if (this.isAgGrid) {
      if (this.params.colDef.field == 'supplier_contact_id') {
        this.setSupplierContact();
      } else if (this.params.colDef.field == 'room_id') {
        this.setRoomId();
      }
    }
    if (this.isMail) {
      this.setOrderInfo();
    }
    if (this.cardView && this.colField == 'supplier_id') {
      this.onCardViewSupplierOpen();
    }
    this.cdr.detectChanges();

    if (this.isOpen) {
      this.configureTippyInstance();
      this.tippyInstance.setContent(this.content.nativeElement);
      const listboxNativeElement = this.listboxElementRef.nativeElement;
      const listboxElement = listboxNativeElement.querySelector('.p-listbox-filter');
      this.setDropDownCss();
      if (listboxElement) {
        setTimeout(() => listboxElement.focus(), 0);
      }
    } else {
      this.tippyInstance.destroy();
    }
  }

  _setDropDownCss() {
    const listBoxItems = this.listboxElementRef.nativeElement.querySelectorAll('.p-listbox-item');
    if (listBoxItems && listBoxItems.length > 0) {
      const lastItem = listBoxItems[listBoxItems.length - 1];
      this.renderer.setStyle(lastItem, 'background-color', '#f0f0f0');
      this.renderer.setStyle(lastItem, 'color', '#4a4a4aa6');
    }
  }

  setDropDownCss() {
    if (this.isAgGrid) {
      let coldef = this.params.colDef.field;
      if (coldef == 'room_id' || coldef == 'supplier_contact_id' || coldef == 'supplier_id') {
        this._setDropDownCss();
      }
    } else {
      if (this.colField == 'receiver_contacts' || this.colField == 'receiver') {
        this._setDropDownCss();
      } else if (this.cardView && this.colField == 'supplier_id') {
        this._setDropDownCss();
      }
    }
  }

  onCellClicked(params: any) {
    // ag grid callback on cell
  }

  onDropdownHide() {
    this.isOpen = false;
  }

  configureTippyInstance() {
    let x = 50;
    let y = -40;
    if (this.approvalsPage) {
      x = -70;
      y = 5;
    }
    this.tippyInstance = tippy(this.trigger.nativeElement);
    this.tippyInstance.enable();
    this.tippyInstance.show();
    this.tippyInstance.setProps({
      trigger: 'manual',
      placement: 'bottom',
      popperOptions: {
        placement: 'bottom-end',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [x, y],
            },
          },
        ],
      },
      arrow: true,
      interactive: true,
      hideOnClick: false,
      offset: 0,
      onClickOutside: (instance: any, event: any) => {
        this.isOpen = false;
        instance.destroy();
      },
    });
  }

  setRoomId() {
    let cur_job = this.params.data.job_id;
    let sortedArray = [...this.options];
    sortedArray = sortedArray.filter((item: any) => item.job == cur_job);
    sortedArray = sortedArray.filter((item: any) => item.value != this.newRoomText);
    sortedArray.sort((a: any, b: any) => {
      a = a.value.toLowerCase().trim();
      b = b.value.toLowerCase().trim();
      if (a < b) { return -1; }
      if (a > b) { return 1; }
      return 0;
    });
    sortedArray.push(this.newRoom);
    this.options = sortedArray;
  }

  setSupplierContact() {
    // delay until button clicked cause requires iteration
    let target_supplier = this.params.data.supplier_id;
    let all_scontacts = (this.optionsService.dropdownOptions as any).supplier_contact_id;
    let sortedArray = all_scontacts.filter((item: any) => item.supplier_id == target_supplier);
    sortedArray.sort((a: any, b: any) => {
      a = a.value.toLowerCase().trim();
      b = b.value.toLowerCase().trim();
      if (a < b) { return -1; }
      if (a > b) { return 1; }
      return 0;
    });
    sortedArray.push(this.newSupplierContact);
    this.options = sortedArray;
  }

  setOrderInfo() {
    // move selected orders to top      
    const isSelected = (option: any) => this.selectedUsers.id === option.id;
    const sortedOptions = [...this.options].sort((a, b) => {
        const aSelected = isSelected(a);
        const bSelected = isSelected(b);
        if (aSelected && !bSelected) {
          return -1;
        }
        if (!aSelected && bSelected) {
          return 1;
        }
        return 0;
    });
    this.options = sortedOptions;
  }

  setCell(params: ICellRendererParams) {
    this.params = params;
    let coldef = params.colDef?.field as any;
    let fastlookup = params.context.faster[coldef];
    let text = fastlookup[params.value];

    if (coldef === 'status') {
      let colors = params.context.faster['status_colors'];
      this.backgroundColor = colors[params.value];
    }

    if (coldef == 'job_id') {
      this.options = params.context.faster['job_array'];
      this.textToShow = text ? text : '--';
    } else {
      if (coldef == 'supplier_id') {
        let suppliers = params.context[coldef];
        this.options = [...suppliers, this.newSupplier];
      } else {
        this.options = params.context[coldef];
      }
      this.textToShow = text ? text : '';
      this.setButtonText(this.textToShow);
    }
    this.maxWidth = this.calculateMaxWidth(this.textToShow);
  } 


  calculateMaxWidth(text: any) {
    let base = 90;
    let strlen = text?.length;
    if (strlen > 9) {
      base += 6 * (strlen - 9);
    }
    return base;
  }

  agInit(params: ICellRendererParams) {
    this.setCell(params);
  }

  refresh(params: ICellRendererParams<any, number>): boolean {
    this.setCell(params);
    return true;
  }

  getGui(): any {
  }

  closeModal() {
    this.addItemModal = false;
    this.cdr.detectChanges();
  }

  closeRoomModalAndSave(event: any) {
    this.addItemModal = false;
    (this.optionsService.dropdownOptions as any).faster.room_id[event.id] = event.value;
    this.params.node.setDataValue('room_id', event.id);
  }

  supplierContactFormChangeEvent(event: any) {
    (this.optionsService.dropdownOptions as any).faster.supplier_contact_id[event.id] = event.name;
    (this.optionsService.dropdownOptions as any).supplier_contact_id.push(event);
    this.addItemModal = false;
    this.params.node.setDataValue('supplier_contact_id', event.id);
    this.dashboardActions.requestSuppliers();
  }

  supplierFormChangeEvent(event: any) {
    this.addItemModal = false;
    this.addModalCards = false;
    (this.optionsService.dropdownOptions as any).faster.supplier_id[event.id] = event.value;
    let payload = {contacts: [], id: event.id, name: event.name, value: event.name};
    (this.optionsService.dropdownOptions as any).supplier_id.push(payload);
    if (this.cardView) {
      this.textToShow = event.name;
      this.setButtonText(this.textToShow);
      this.options.push({...event, value: event.name});
      this.statusChanged.emit({'event': {value: event},
                                'data': this.data,
                                'colField': this.colField});
    } else {
      this.params.node.setDataValue('supplier_id', event.id);
    }
  }
}
