import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { EventEmitter, Output, ChangeDetectorRef} from '@angular/core';
import { ElementRef, HostListener } from '@angular/core';
import { setScrollBar } from 'src/app/common/utils/general';
import { isPDF, isJPEG, isPNG, generateRandomString } from 'src/app/common/utils/general';

@Component({
    selector: 'materlog-text-component',
    templateUrl: './materlog-text-component.component.html',
    styleUrls: ['./materlog-text-component.component.css'],
})
export class MaterLogText implements OnInit {
    @Input() order: any = {};
    @Input() enbold: boolean = false;
    @Input() chromeURL: any = '';
    @Input() maxWidth: number = 200;
    @Input() minHeight: string = '';
    @Output() onChange = new EventEmitter<any>();
    @Input() fieldName: string = '';
    @Input() isNumber: boolean = false;
    @Input() isCost: boolean = false;
    @Input() isDecimal: boolean = false;
    @Input() maxInput: any = null;
    @Input() vgrowth: boolean = false;
    @Input() active: boolean = true;
    @Input() placeholder: any = '--';
  
    @ViewChild('inputElement') inputElement!: ElementRef;
  
  
    isEditing: boolean = false;
    value: any = '';
    showError: boolean = false;
    errorString: any = '';
  
    constructor(
        private cdr: ChangeDetectorRef
    ) {
    }
  
    ngOnChanges(changes: any) {
      if (this.order) {
        this.value = this.order[this.fieldName] || '';
        if (this.value && this.isCost) {
          let value: any = parseFloat(this.value);
          value = isNaN(value) ? '0' : value.toFixed(2);
          this.value = this.formatCost(value);
        }
      }
      if (this.isDecimal) {
        this.errorString = 'Needs to be a valid decimal';
      } else if (this.isNumber) {
        this.errorString = 'Needs to be a valid number';
      }
    }
  
  
    ngOnInit() {
      (window as any)['mtext'] = this;
      
    }
  
    get getMaxWidth() {
      return `${this.maxWidth}px`;
    }
  
    onFocus(event: any) {
      (window as any)['mdt'] = this;
      if (!this.active) {
        event.target.blur();
        return;
      }
      this.isEditing = true;
      if (this.isCost && this.value && this.value[0] == '$') {
        this.value = this.value.slice(1,);
      }
      this.cdr.detectChanges();
    }
  
    onBlur(event: any) {
      if (this.value && this.hasError()) {
        this.value = '';
        if (this.inputElement?.nativeElement) {
          this.inputElement.nativeElement.value = '';
        }
        this.showError = true;
        this.cdr.detectChanges();
        setTimeout(() => {
          this.showError = false;
          this.cdr.detectChanges();
        }, 1300);
        event?.target?.focus();
        return;
      }
      this.isEditing = false;
      if (!this.value) {
        this.value = '';
        this.onChange.emit({order: this.order, value: '', field: this.fieldName});
        this.cdr.detectChanges();
        return;
      }
      let value = this.value;
      if (this.isDecimal || this.isCost) {
        value = this.removeMoney(value);
        value = parseFloat(value);
        value = isNaN(value) ? '0': value.toFixed(2);
      } else if (this.isNumber) {
        value = parseInt(value);
      }
      this.onChange.emit({order: this.order, value: value, field: this.fieldName});
      if (this.isCost) {
        value = this.formatCost(value);
      }
      this.value = value;
      this.cdr.detectChanges();
    }
  
    onKeydown(event: any) {
      if (this.vgrowth) return;
      if (event.key == 'Enter') {
        event?.target?.blur();
      }
    }
  
    hasError() {
      if ((this.isDecimal || this.isCost) && !this.validDecimal(this.value)) return true;
      if (this.isNumber && !this.validInteger(this.value)) return true;
      return false;
    }
  
    removeMoney(value: any) {
      return value?.replace(',', '')?.replace('$', '') || '';
    }
  
    formatCost(value: any) {
      if (!value) return ''
      if (value[0] == '$') {
        value = value.slice(1,);
      }
      value = Number(value);
      if (isNaN(value)) return '';
      return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }
  
    validDecimal(value: any) {
      value = this.removeMoney(String(value));
      return !isNaN(parseFloat(value));
    }
  
    validInteger(value: any) {
      value = this.removeMoney(String(value));
      return !isNaN(parseInt(value));
    }
  
    get useHeight() {
      if (!this.minHeight) return '16px';
      if (this.value && this.value.length > 20) return '32px';
      return '16px';
    }
}