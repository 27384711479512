import { Component, OnInit, ViewChild, Input, Renderer2 } from '@angular/core';
import { EventEmitter, Output, ChangeDetectorRef} from '@angular/core';
import { Router } from '@angular/router';
import { SelectedViewModel } from '../../models/selected-view.model';
import { select, Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';
import { DashboardRoutes } from '../../enums/dashboard-routes.enum';
import { ElementRef, HostListener } from '@angular/core';
import { MailBoxCommunicationService } from '../../services/mailbox_comm.service';
import { selectComments, selectViews} from '../../state/selectors/dashboard.selector';
import { selectActiveJobs } from '../../state/selectors/dashboard.selector';
import { IStoreApiItem } from 'src/app/common/models/store-api-item.model';
import { IUserManagement } from 'src/app/user-settings/models/user.model';
import { IApplicationState } from 'src/app/common/state/models/app.state.model';
import { CommentsService } from '../../services/comment.service';
import { DashboardActions } from '../../state/actions/dashboard.actions';
import { MentionModule } from 'angular-mentions';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { deepCopy } from 'src/app/common/utils/general';
import { ActiveJobModel } from '../../models/active-job.model';
import { selectUserProfile } from 'src/app/user-settings/state/selectors/user.selector';
import { UserActions } from 'src/app/user-settings/state/actions/user.actions';
import { DashboardJobView } from '../../services/dashboard_job_view.service';
import { PermissionsService } from 'src/app/root/services/permissions.service';
import { environment } from 'src/environments/environment';
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map, switchMap } from 'rxjs/operators';
import {unzipByteData, decodeMsgPackSimple} from 'src/app/dashboard/services/do_msgpack';
import { initData } from '../mail-view/utils';
import { JobsService } from '../../services/job.service';
import { DashboardService } from '../../services/dashboard.service';


@Component({
  selector: 'approvals',
  templateUrl: './approvals-component.html',
  styleUrls: [
    './approvals-component.css',
    '../notifications/notifications.component.css',
  ],
})
export class ApprovalsComponent implements OnInit {
  @ViewChild('emailContainer') emailContainer!: ElementRef<HTMLDivElement>;
  @ViewChild('approvalContainer') approvalContainer!: ElementRef<HTMLDivElement>;

  @Input() selectedView?: SelectedViewModel;
  @Input() dropdownOptions?: any;
  @Input() allMailData: any;
  @Input() sidePanelOpen: boolean = true;

  private baseUrl: string = environment.ENV.BASE_URL;
  approvals: any = [];
  is_approvals: boolean = true;
  selectedId: any = '';
  fetchingBody: boolean = true;
  mailResultsMap: Map<string, string> = new Map<string, string>();
  mailResultsMapAtts: Map<string, any> = new Map<string, any>();
  itemInnerHtml: any = '';

  skeletonItems: any[] = [];
  skeletonEmails: any[] = [];

  documentModal: boolean = false;
  selectedAttachment: any;

  approvalsLoading: boolean = true;

  ngOnInit() {
    this.generateItems();
    this.getApprovalMessages();
  }

  ngAfterViewInit() {
  }

  constructor(
    public router: Router,
    private dashboardActions: DashboardActions,
    private dashboardService: DashboardService,
    private store: Store<IApplicationState>,
    private cdr: ChangeDetectorRef,
    private userActions: UserActions,
    private djv: DashboardJobView,
    private mailBoxComm: MailBoxCommunicationService,
    private permissionService: PermissionsService,
    private httpClient: HttpClient,
    private sanitizer: DomSanitizer,
    private jobService: JobsService,
    private renderer: Renderer2
  ) {
    (window as any)['appr'] = this;
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    if (this.approvals.length) {
      this.approvals.forEach((a: any) => { a.markedRead = false; });
    }
  }

  get trackingData() {
    if (!this.approvals.length || !this.selectedId) return undefined;
    let found = this.approvals.find((a: any) => a.id == this.selectedId);
    if (!found) return undefined;
    if (!this.allMailData?.jobsArray || !this.allMailData?.supArray || !this.allMailData?.curOrders) return undefined;
    return {
      isVisible: true,
      item: found,
      id: this.selectedId,
      jobs: this.allMailData.jobsArray,
      suppliers: this.allMailData.supArray,
      orders: this.allMailData.curOrders,
    };
  }

  generateItems() {
    for (let i = 0; i < 10; i++) {
      const randomWidth = Math.floor(Math.random() * 20) + 30;
      this.skeletonItems.push({ width: `${randomWidth}rem` });
    }

    for (let i = 0; i < 10; i++) {
      const randomWidth = Math.floor(Math.random() * 8) + 10;
      this.skeletonEmails.push({ width: `${randomWidth}rem` });
    }
  }

  formatDate(item: any): string {
    let date: any = new Date(item.created_datetime);
    date = date.toISOString();
    let cur = date.split('T')[0];
    cur = cur.split('-');
    cur = cur.slice(-2).join('/');
    return cur;
  }

  formatEmail(name: boolean) {
    let item = this.approvals.find((a: any) => a.id == this.selectedId);
    if (!item) return '';
    let email = item.infos?.from;
    if (!email) return '';
    if (name) {
      let cleanedText = email.replace(/<[^>]*>/g, '');
      cleanedText = cleanedText.replace(/[^A-Za-z0-9 .-]+/g, '')
      cleanedText = cleanedText.trim();
      return cleanedText.charAt(0).toUpperCase() + cleanedText.slice(1);
    } else {
      const match = email.match(/<([^>]*)>/);
      return match ? `<${match[1]}>`: '';
    }
  }

  formatField(which: any) {
    let item = this.approvals.find((a: any) => a.id == this.selectedId);
    if (!item) return '';
    let email = '';
    if (which == 'subject') email = item.infos?.subject;
    else if (which == 'to') email = item.infos?.to;
    else if (which == 'cc') email = item.infos?.cc;
    return email;
  }

  setItemInnerHtml() {
    let item = this.approvals.find((a: any) => a.id == this.selectedId);
    if (!item) {
      this.itemInnerHtml = '';
      return;
    }
    let safeHtml = this.mailResultsMap.get(item.id) || '';
    this.itemInnerHtml = this.sanitizer.bypassSecurityTrustHtml(safeHtml);
    this.setLinksTarget();
  }

  get itemAttsCount() {
    let item = this.approvals.find((a: any) => a.id == this.selectedId);
    if (!item) return 0;
    let atts = this.mailResultsMapAtts.get(item.id)
    return atts ? atts.length: 0;
  }

  get itemAtts() {
    let item = this.approvals.find((a: any) => a.id == this.selectedId);
    if (!item) return [];
    return this.mailResultsMapAtts.get(item.id) || [];
  }

  onMailConfirm(event: any) {
    let confirmed_id = this.selectedId;
    this.approvals = this.approvals.filter((a: any) => a.id != this.selectedId);
    this.djv.set_notification_count(this.approvals.length);
    this.selectedId = this.approvals.length ? this.approvals[0].id : '';
  }
  
  bodyForItem() {
    let item = this.approvals.find((a: any) => a.id == this.selectedId);
    if (!item) {
      this.setItemInnerHtml();
      this.fetchingBody = false;
      setTimeout(() => {
        this.setScrollbar('.mail-body');
      }, 700)
      return;
    }
    this.loadInputMessageParts(item.id).subscribe((result: any) => {
      this.mailResultsMap.set(item.id, result);
      this.setItemInnerHtml();
      this.fetchingBody = false;
      setTimeout(() => {
        this.setScrollbar('.mail-body');
      }, 700)
    });
    this.loadInputMessagePartsAtts(item.id).subscribe((result: any) => {
      this.mailResultsMapAtts.set(item.id, result);
    });
  }

  markReadTooltip(event: any, item: any) {
    event.stopPropagation();
    let mark = !item.markedRead;
    this.approvals.forEach((a: any) => { a.markedRead = false; });
    if (mark) item.markedRead = true;
  }

  markItemAsRead(event: any, item: any) {
    event.stopPropagation();
    item.markedRead = false;
    item.viewed = !item.viewed;
    this.updateApprovals({id: item.id, viewed: item.viewed});
  }

  emailClicked(item: any) {
    this.selectedId = item.id;
    this.fetchingBody = true;
    item.markedRead = false;
    item.viewed = true;
    this.bodyForItem();
    this.updateApprovals({id: item.id, viewed: item.viewed});
  }

  getApprovalMessages() {
    this.loadInputMessages().subscribe((result: any) => {
      this.approvals = result;
      this.selectedId = this.approvals.length ? this.approvals[0].id : '';
      this.approvalsLoading = false;
      this.setScrollbar('.approval-list-mail-short');
      this.setScrollbar('.approvals-email-order-container');
      this.bodyForItem();
    })
  }

  onTrackFieldChange(event: any) {
    let item = this.approvals.find((a: any) => a.id == this.selectedId);
    if (!item) return;
    let search = undefined; let field = undefined; let field2 = undefined;
    if (event.field == 'job_id') { search = this.allMailData.jobsArray; field = 'job';}
    else if (event.field == 'supplier_id') { search = this.allMailData.supArray; field = 'supplier'; }
    else if (event.field == 'order_id') { search = this.allMailData.curOrders; field = 'order'; field2 = 'order_id';}
    if (!search || !field) return;
    let found: any = search.find((s: any) => s.id == event.data);
    if (!found) return;
    item[field] = found.value;
    if (!field2) item[field2 as any] = found.id;
  }

  loadInputMessageParts(msg_id: any): Observable<any> {
    return this.httpClient.get<any>(`${this.baseUrl}input_messages/view/`+ msg_id +`/`, {
      headers: new HttpHeaders({ 'Accept': 'application/octet-stream'}),
      responseType: 'arraybuffer' as any,
    }).pipe(map((response: any) => unzipByteData(response, true)));
  }

  loadInputMessagePartsAtts(msg_id: any): Observable<any> {
    return this.httpClient.get<any>(`${this.baseUrl}input_messages/view/`+ msg_id +`/`, {
      params: {mail_attachments: true},
      headers: new HttpHeaders({'Content-Type': 'application/json; charset=UTF-8'})
    });
  }

  loadInputMessages(): Observable<any> {
    return this.httpClient.get(`${this.baseUrl}input_messages/approvals/`, {
      headers: new HttpHeaders({ 'Accept': 'application/x-msgpack' }),
      responseType: 'arraybuffer'
    }).pipe(map(response => decodeMsgPackSimple(response)));
  }

  updateApprovals(payload: any): void {
    this.httpClient.put(`${this.baseUrl}input_messages/approvals/`,
    payload,
    { headers: new HttpHeaders({'Content-Type': 'application/json; charset=UTF-8'})}
    ).subscribe(() => {});
  }


  setLinksTarget() {
    setTimeout(() => {
      const mailBodyElement = this.emailContainer?.nativeElement?.querySelector('.mail-body');
      if (mailBodyElement) {
        const links = mailBodyElement.querySelectorAll('a');
        links.forEach(link => {
          this.renderer.setAttribute(link, 'target', '_blank');
        });
      }
    }, 400);
  }

  mailClicked(event: any) {
    event.stopPropagation();
  }


  //////

  get dropdownRetrieved() {
    return this.dropdownOptions.document_types  &&
    this.dropdownOptions.job_id  &&
    this.dropdownOptions.status  &&
    this.dropdownOptions.status_colors  &&
    this.dropdownOptions.supplier_id  &&
    this.dropdownOptions.unit_of_measure &&
    this.allMailData?.curOrders &&
    this.allMailData?.supArray;
  }

  get currentApproval() {
    return this.approvals?.find((i: any) => i.id == this.selectedId);
  }

  get currentOrder() {
    let curAppr = this.currentApproval;
    if (!curAppr) return undefined;
    let curOrder = this.allMailData?.curOrders?.find((o: any) => curAppr.order_id == o.id);
    return curOrder;
  }

  cardIsOpenChange(event: any) {

  }

  orderDeleted() {

  }
  
  get cardViewWidth() {
    let base = '100vw - 300px - 50px';
    let extra = this.sidePanelOpen ? '- 300px' : '- 70px';
    return `calc(${base} ${extra})`
  }


  setScrollbar(v: string) {
    setTimeout(() => {
      let jdisplays = this.approvalContainer.nativeElement.querySelectorAll(v);
      if (jdisplays) {
        jdisplays.forEach((jdisplay: any) => {
          jdisplay.addEventListener('mouseenter', function() {
            (jdisplay as any).classList.add('custom-scrollbar');
            (jdisplay as any).classList.remove('custom-scrollbar-close');
          });
          jdisplay.addEventListener('mouseleave', function() {
            (jdisplay as any).classList.remove('custom-scrollbar');
            (jdisplay as any).classList.add('custom-scrollbar-close');
          });
        })
      }
    }, 500);
  }

}