<div class="filters">
  <div class="filters__container">
    <hide-fields [isLoading]="isLoading" [selectedView]="selectedView"> </hide-fields>
    <mlogfilters
      style="margin-left: 12px"
      [isLoading]="isLoading"
      [selectedView]="selectedView"
      [activeJob]="activeJob"
      [mailBoxVisible]="showingSideBar"
      [viewsOptions]="optionsService.viewsOptionsObs | async"
      [dropdownOptions]="optionsService.dropdownOptionsObs | async"
    >
    </mlogfilters>
    <sort
      style="margin-left: 12px"
      [selectedView]="selectedView"
      [mailBoxVisible]="showingSideBar"
      [isLoading]="isLoading"
    ></sort>
    <group-fields
      style="margin-left: 12px"
      [selectedView]="selectedView"
      [mailBoxVisible]="showingSideBar"
      [isLoading]="isLoading">
    </group-fields>

   <resizer
    style="margin-left: 12px"   
    [isLoading]="isLoading"
    [mailBoxVisible]="showingSideBar"
    [selectedView]="selectedView">
    </resizer>
   <subitems
    style="margin-left: 12px"   
    [isLoading]="isLoading"
    [mailBoxVisible]="showingSideBar"
    [selectedView]="selectedView">
    </subitems>

  <!-- <pin-fields [isLoading]="isLoading" [selectedView]="selectedView"> </pin-fields>-->

    <templates
      style="margin-left: 12px"   
      *ngIf="activeJob.name !== 'Uncategorized items'"
      [selectedView]="selectedView"
      [mailBoxVisible]="showingSideBar"
      [dropdownOptions]="optionsService.privacyOptionsObs | async"
      [isLoading]="isLoading"
    >
    </templates>
    <share
      style="margin-left: 12px; border-right: 1px solid #d9d9d9; margin-right: 4px;"   
      *ngIf="activeJob.name !== 'Uncategorized items'"
      [dropdownOptions]="optionsService.dropdownOptionsObs | async"
      [viewId]="selectedView?.activeView?.id"
      [isShared]="selectedView?.activeView?.is_shared"
      [isLoading]="isLoading"
      [selectedView]="selectedView"
      [mailBoxVisible]="showingSideBar"
    >
    </share>
  </div>
  <div class="filters__container" #dashboardSearchAndHistory >
    <div style="display: flex; flex-direction: row">
      <div *ngIf="activeJob.name !== 'Uncategorized items'"
          [ngClass]="{'jobs-comment-on': commentBoxVisible}"
          class="jobs-comments-icon-box" (click)="showComments()">
          <i class="jobs-comment-icon bi bi-chat"></i>
          <div *ngIf="!showingSideBar" style="display: inline;">
            Comments
          </div>
      </div>
    </div>
    <div *ngIf="activeJob.name !== 'Uncategorized items'"
          [ngClass]="{'jobs-comment-on': mailBoxVisible}"
          style="margin-left: 12px; margin-right: 10px"   
          class="jobs-comments-icon-box" (click)="showEmail()">
      <i class="jobs-comment-icon bi bi-envelope"></i>
      <div *ngIf="!showingSideBar" style="display: inline;">
        Email
      </div>
    </div>
    <div>
      <input
        class="px-2 py-1 rounded-full text-xs border border-neutral-300 input-element"
        type="text"
        id="filter-text-box"
        placeholder="Search..."
        [(ngModel)] = "search"
        (input)="onFilterTextBoxChanged()"
      />
    </div>
    <!--
    <div *ngIf="activeJob.name !== 'Uncategorized items'">
      <history
        [isLoading]="isLoading"
        [selectedView]="selectedView"
        [dropdownOptions]="optionsService.historyOptionsObs | async"
        (onUpdateLoadingOverlay)="onUpdateLoadingOverlay()"
      >
      </history>
    </div>
    -->

  </div>
</div>
