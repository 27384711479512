<div class="h-full flex flex-col top-level"
  [ngStyle]="{'display': makeVisible || cardView ? 'block' : 'none'}">
  <div class="comment-container top-mail-container" *ngIf="makeVisible || cardView" #emailContainer>
    <div class="comment-header" *ngIf="!cardView">
      <div class="comment-header-title">
        Mail
      </div>
      <div class="close-comments" (click)="closeToggle()">
        <i class="bi bi-x-lg" style="font-size: 0.8rem; color: #202124"></i>
      </div>
    </div>
    <div class="dropdown-container">
      <div class="default-filters mail-filter-width" #emailDiv [class.active]="emailDropDownVisible">
        <div class="dropdown-view" (click)="toggleEmailTypeDropdown()">
          <div class="default-filters-caption">
                {{ activeEmailType }}
          </div>
          <div class="default-filters-dropdown">
            <i class="bi bi-caret-down-fill" style="font-size: 0.8rem;"></i>
          </div>
        </div>
        <ng-container *ngIf="emailDropDownVisible">
          <div class="dropdown-filters-container dropdown-options-email">
            <div class="custom-scrollbar dropdown-options dropdown-options-container">
              <div class="filter-options ellipsis-text"
                    (click)="setEmailTypeFilter(et)"
                    *ngFor="let et of allEmailsOption">
                    <i class="bi bi-check-lg"
                        [style.visibility]="isEmailTypeVisible(et) ? 'visible' : 'hidden'"></i>
                    <span> {{ formatName(et) }} </span>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <div *ngIf="!cardView"
            class="default-filters default-filter-width" #jobDiv [class.active]="jobDropDownVisible"
          [ngClass]="activeJobName != 'All Jobs' ? 'active-filter-bg': ''
          ">
        <div class="dropdown-view" (click)="toggleJobDropdown()">
          <div class="default-filters-caption">
                {{ activeJobName }}
          </div>
          <div class="default-filters-dropdown">
            <i class="bi bi-caret-down-fill" style="font-size: 0.8rem;"></i>
          </div>
        </div>
        <ng-container *ngIf="jobDropDownVisible">
          <div class="dropdown-filters-container dropdown-options-job dropdown-width-job">
            <div class="email-filter-textarea" #emailFilterJob
              contenteditable="true"
              [attr.data-placeholder]="getPlaceholderText()"
              (input)="updateEmailFilter($event)">
            </div>
            <div class="custom-scrollbar dropdown-options dropdown-options-container"
                 *ngIf="filterActiveJobs && filterActiveJobs.length">
              <div class="filter-options ellipsis-text"
                    (click)="setJobFilter(job)"
                    *ngFor="let job of filterActiveJobs">
                    <i class="bi bi-check-lg"
                        [style.visibility]="isJobVisible(job) ? 'visible' : 'hidden'"></i>
                    <span> {{ formatName(job) }} </span>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <div  *ngIf="!cardView"
            class="default-filters default-filter-width" #supplierDiv [class.active]="supplierDropDownVisible"
            [ngClass]="activeSupplierName != 'All Suppliers' ? 'active-filter-bg': ''">
        <div class="dropdown-view" (click)="toggleSupplierDropdown()">
           <div class="default-filters-caption">
                {{ activeSupplierName }}
           </div>
           <div class="default-filters-dropdown">
                <i class="bi bi-caret-down-fill" style="font-size: 0.8rem;"></i>
           </div>
        </div>
        <ng-container *ngIf="supplierDropDownVisible">
          <div class="dropdown-filters-container dropdown-width-supplier"
              [ngClass]="{
                'dropdown-options-job': savedJobId != 'all',
                'dropdown-options-supplier': savedJobId == 'all'
              }">
            <div class="email-filter-textarea dropdown-options"
              #emailFilterSupplier
              contenteditable="true"
              [attr.data-placeholder]="getPlaceholderText()"
              (input)="updateEmailFilter($event)">
            </div>
            <div class="custom-scrollbar dropdown-options dropdown-options-container"
                 *ngIf="filterActiveSuppliers && filterActiveSuppliers.length">
              <div class="filter-options ellipsis-text" (click)="setSupplierFilter(supplier)"
                    *ngFor="let supplier of filterActiveSuppliers">
                <i class="bi bi-check-lg" [style.visibility]="isSupplierVisible(supplier) ? 'visible' : 'hidden'"></i>
                <span>{{ formatName(supplier) }}</span>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <div *ngIf="!cardView" class="default-filters default-filter-width" #orderDiv [class.active]="orderDropDownVisible"
            [ngClass]="activeOrderName != 'All Orders' ?'active-filter-bg': ''">
        <div class="dropdown-view" (click)="toggleOrderDropdown()">
           <div class="default-filters-caption">
                {{ activeOrderName }}
           </div>
           <div class="default-filters-dropdown">
                <i class="bi bi-caret-down-fill" style="font-size: 0.8rem;"></i>
           </div>
        </div>
        <ng-container *ngIf="orderDropDownVisible">
          <div class="dropdown-filters-container dropdown-width-supplier"
              [ngClass]="{
                'dropdown-options-supplier': savedJobId != 'all',
                'dropdown-options-order': savedJobId == 'all'
              }">
            <div class="email-filter-textarea dropdown-options"
              #emailFilterOrder
              contenteditable="true"
              [attr.data-placeholder]="getPlaceholderText()"
              (input)="updateEmailFilter($event)">
            </div>
            <div class="custom-scrollbar dropdown-options dropdown-options-container"
                 *ngIf="filterActiveOrders && filterActiveOrders.length">
              <div class="filter-options ellipsis-text" (click)="setOrderFilter(order)"
                    *ngFor="let order of filterActiveOrders">
                <i class="bi bi-check-lg" [style.visibility]="isOrderVisible(order) ? 'visible' : 'hidden'"></i>
                <span>{{ formatName(order) }}</span>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <ng-container>
        <div class="default-filters mail-filter-width" style="padding-right: 8px; max-width: 90px">
          <div class="dropdown-view default-filters-caption compose-draft" (click)="createDraft()">
            Compose
          </div>
        </div>
      </ng-container>

    </div>
    <div class="checkin-email-header" *ngIf="this.selectedEmailOption.id != 'inbox'">
      <div class="checkin-email-header-text">
        <div class="checkin-header-icon">
          <i class="bi bi-send" style="font-size: 1.0rem"></i>
        </div>
        <div class="checkin-header-text">
          <strong>Scheduled</strong> emails will go out at 10:15am EST on the date listed. <strong>Draft</strong> emails require approval to be scheduled; they will not be sent out until they are scheduled.
        </div>
      </div>

    </div>
    <div class="comment-display custom-scrollbar-close" #emailViewer
        [ngStyle]="{'max-height': curHeight}">
      <materlog-loader class="loader-center" style="top: 100px;" *ngIf="!dataLoaded"></materlog-loader>
      <ng-container *ngIf="dataLoaded">
        <div class="single-mail-item" (click)="selectMail(item)"
              *ngFor="let item of filtered_items; let fi = index;">
          <div class="flex w-full flex-col">
            <div class="flex items-center">
              <div class="flex items-center w-full">
                <div class="font-semibold font-medium">
                  {{ formatEmail(item) }}
                </div>
              </div>
              <div class="ml-auto" style="margin-right: 10px" *ngIf="this.selectedEmailOption.id != 'inbox' && isOverDo(item) && !item.marked_send">
                <i class="bi bi-clock-fill" style="font-size: 1.0rem; color: #C9C9C9"></i>
              </div>
              <div class="ml-auto" style="margin-right: 10px" *ngIf="this.selectedEmailOption.id != 'inbox' && item.marked_send">
                <i class="bi bi-send" style="font-size: 1.0rem; color: #5FC64C"></i>
              </div>
              <div class="ml-auto reply-email" style="margin-right: 10px" *ngIf="this.selectedEmailOption.id == 'inbox' && cardView">
                <span class="reply-tooltip">Reply</span>
                <i class="bi bi-reply" (click)="replyToEmail($event, item)"></i>
              </div>
              <div class="ml-auto text-xs date-layout"
                  [ngStyle]="item.marked_send ?
                      {'color': '#5FC64C', 'font-weight': 'bold'}:
                      {}"
                  [ngClass]="{ 'text-foreground': selectedMailId === item.id, 'text-muted-foreground': selectedMailId !== item.id }"
                  (click)="openCalendar($event, item)">
                  {{ formatDate(item) }}
                  <div class="calendar-wrapper" style="display: none"
                        *ngIf="this.selectedEmailOption.id != 'inbox'">
                    <p-calendar #calendar class="max-w-full"
                        [style]="{'width':'400px', 'height': '420px', 'z-index': '10'}"
                        dateFormat="yy/mm/dd"
                        (ngModelChange)="checkDate($event, item)" 
                        [(ngModel)]="item.send_date" [inline]="true">
                    </p-calendar>
                  </div>
              </div>
            </div>
            <div class="font-medium" style="margin-top: 2px; margin-bottom: 4px;">{{ formatSubject(item) }}</div>
            <div *ngIf="this.selectedEmailOption.id != 'inbox'" class="email-display-container">
              <div class="email-mdata-container">
                <div *ngFor="let _ of itemOrders(item); let i = index; let isLast=last"
                class="email-display-container items-center gap-2">
                <div class="email-value-display">
                  <div class="badge-default status-color-cell">
                    <span class="inner-text">{{ getCheckinNameForId(item, i, 'job') }}</span>
                  </div>
                </div>
                <div class="email-value-display">
                  <div class="badge-outline status-color-cell">
                    <span class="inner-text">{{ getCheckinNameForId(item, i, 'supplier') }}</span>
                  </div>
                </div>
                <dropdown-cell-renderer *ngIf="!item.changed"
                  [options]="getOptions('order')"
                  [isAgGrid]="false"
                  [data]="{info: item.orders_info, index: i}"
                  [isMail]="true"
                  [colField]="'order_id'"
                  (statusChanged)="draftOrderChange($event, item)"
                  [customStyles]="getLabelClasses('order')"
                ></dropdown-cell-renderer>
                </div>
              </div>
              <div style="flex: 1"></div>
            </div>
            <div *ngIf="this.selectedEmailOption.id == 'inbox'">
              <div *ngFor="let _ of itemOrders(item); let i = index; let isLast=last"
                class="flex items-center gap-2" style="margin-top: 3px;">
                <dropdown-cell-renderer
                  [options]="getOptions('job')"
                  [isAgGrid]="false"
                  [data]="{info: item.orders_info, index: i}"
                  [isMail]="true"
                  [colField]="'job_id'"
                  (statusChanged)="changeMailInfo($event)"
                  [customStyles]="getLabelClasses('job')"
                ></dropdown-cell-renderer>

                <dropdown-cell-renderer
                  [options]="getOptions('supplier')"
                  [isAgGrid]="false"
                  [data]="{info: item.orders_info, index: i}"
                  [isMail]="true"
                  [colField]="'supplier_id'"
                  (statusChanged)="changeMailInfo($event)"
                  [customStyles]="getLabelClasses('supplier')"
                ></dropdown-cell-renderer>

                <dropdown-cell-renderer
                  [options]="getOptions('order')"
                  [isAgGrid]="false"
                  [data]="{info: item.orders_info, index: i}"
                  [isMail]="true"
                  [colField]="'order_id'"
                  (statusChanged)="changeMailInfo($event)"
                  [customStyles]="getLabelClasses('order')"
                ></dropdown-cell-renderer>
                <button
                  *ngIf="isLast"
                  type="button"
                  class="add-row-button"
                  (click)="addNewRow($event, item)">
                  <i class="bi bi-plus-lg"></i>
                </button>
                <div class="spacer"></div>
                <div class="attachment-icon-list" *ngIf="isLast">
                  <div class="ml-auto text-xs attachment-icon"
                      (click)="retrieveAttachment($event, item, att)"
                      *ngFor="let att of formatAttachments(item.message_info, true)">
                      <div class="attachment-tooltip">{{ att }}</div>
                      <i *ngIf="!loadingStates[item.id + att]; else loadingSpinner"
                          class="pi pi-paperclip" style="font-size: 1rem; color: #8A898E"></i>
                      <ng-template #loadingSpinner>
                        <i class="pi pi-spinner pi-spin" style="font-size: 1rem; color: #8A898E"></i>
                      </ng-template>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="this.selectedEmailOption.id == 'inbox'">
            <div *ngIf="fetchingBody && curId === item.id">
              <svg class="animate-spin h-8 w-8 text-gray-800 m-auto absolute top-1/4 left-1/2 transform -translate-x-1/2 -translate-y-1/4"
                    xmlns="http://www.w3.org/2000/svg" fill="none"
                    viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
              </path>
              </svg>
            </div>
            <div class="flex items-center w-full" *ngIf="selectedMailId === item.id && !fetchingBody">
              <div style="white-space: nowrap; color: #8A898E; font-size: 10px; margin-top: 7px;">
                {{ formatEmailFields(item.message_info.to, 'To') }}
              </div>
            </div>
            <div class="flex items-center w-full" *ngIf="selectedMailId === item.id && !fetchingBody">
              <div style="white-space: nowrap; color: #8A898E; font-size: 10px; ; margin-bottom: 7px;">
                {{ formatEmailFields(item.message_info.Cc, 'Cc') }}
              </div>
            </div>
            <div *ngIf="selectedMailId === item.id && !fetchingBody"
                  class="mail-body custom-scrollbar-close"
                  [ngClass]="cardView ? 'card-mail-body-width' : 'grid-mail-body-width'"
                  (click)="mailClicked($event)"
                  [innerHtml]="mailResultsMap.get(item.id)">
            </div>
          </div>
          <div *ngIf="this.selectedEmailOption.id != 'inbox'" (click)="dontPropogate($event)">
            <div *ngIf="curId === item.id" class="email-body-container"
                [ngClass]="!cardView ? 'email-body-max-grid': 'email-body-max-card'">
              <div class="email-body-to email-body-from information-footer">
                <div class="email-body-from">
                  From
                </div>
                <div class="email-body-to-current materlog-from">
                  orders@getmeti.com
                </div>
              </div>
              <div class="email-body-to information-footer">
                <div class="email-body-to-text">
                  To
                </div>
                <div class="email-body-to-current">
                  <div class="email-body-textarea email-body-textarea-regular" #emailBodyTextareaTo
                    contenteditable="true"
                    (click)="setEmailBodyFilter($event, item, 'to')"
                    (keydown)="handleKeyDown($event)"
                    (blur)="saveDraft($event, item);"
                    (input)="updateEmailBody($event, item, 'to')">
                  </div>
                </div>
              </div>
              <div class="email-body-cc email-body-upper information-footer">
                <div class="email-body-to-text">
                  Cc
                </div>
                <div class="email-body-cc-current">
                  <div class="email-body-textarea email-body-textarea-cc custom-scrollbar" #emailBodyTextareaCc>
                    <div *ngFor="let cc of item.email.cc; let isLast=last">
                      <div class="cc-email-field">
                        <input class="cc-input" type="text" [(ngModel)]="cc.value" [placeholder]="cc.placeholder"
                              (focus)="onCcFocus(item)" (blur)="onCcBlur($event, item)" (keydown.enter)="ccKeyDown($event)"/>
                        <i class="bi bi-x remove-cc" style="position: relative; top: 2px;" (click)="removeCcField($event, item, cc)"></i>
                      </div>
                      <div class="new-cc" *ngIf="!ccActive && isLast">
                        <i *ngIf="isBlankCc(item)" class="bi bi-plus-lg" (click)="addCc(item)" style="position: relative; top: 1px"></i>
                      </div>
                    </div>
                    <div class="new-cc" *ngIf="!ccActive && !item.email.cc.length">
                      <i *ngIf="isBlankCc(item)" class="bi bi-plus-lg" (click)="addCc(item)" style="position: relative; top: 4px;"></i>
                    </div>
                  </div>

                </div>
              </div>
              <div class="email-body-to information-footer email-body-upper">
                <div class="email-body-to-text-subject">
                  Subject
                </div>
                <div class="email-body-to-current">
                  <div class="email-body-textarea email-body-textarea-regular" #emailBodyTextareaSubject
                    contenteditable="true"
                    (click)="setEmailBodyFilter($event, item, 'subject')"
                    (keydown)="handleKeyDown($event)"
                    (blur)="saveDraft($event, item);"
                    (input)="updateEmailBody($event, item, 'subject')">
                  </div>
                </div>
              </div>
              <div class="email-body-body email-body-upper" (click)="editorClick($event)">
                <angular-editor name="body-email" id="body-email" [config]="editor_config"
                  (ngModelChange)="onModelChange($event, item)"
                  style="list-style: disc; list-style-type: disc; outline-width: 0px;"
                  [(ngModel)]="item.email.body"
                  class="block  col-span-9 w-full text-base leading-6 list-disc">
                </angular-editor>
              </div>
              <div *ngIf="item.thread_reply_id || item.thread_id">
                <div *ngIf="!item.loading_reply" class="see-reply-email" (click)="showReplyEmail($event, item)">
                  <i class="bi bi-three-dots"></i>
                  <div class="three-dot-tooltip">
                    <div class="three-dot-text">
                      {{ item.show_reply ? 'Hide content' : 'Show content'}}
                    </div>
                  </div>
                </div>
                <div *ngIf="item.loading_reply">
                  <p-skeleton [width]="'100px'" [height]="'14px'" styleClass="mb-2" borderRadius="16px"></p-skeleton>
                  <p-skeleton [width]="'40px'" [height]="'14px'" styleClass="mb-2" borderRadius="16px"></p-skeleton>
                  <p-skeleton [width]="'70px'" [height]="'14px'" styleClass="mb-2" borderRadius="16px"></p-skeleton>
                </div>
              </div>
              <div *ngIf="(item.thread_reply_id || item.thread_id) && item.show_reply"
                  class="mail-body thread-reply-email custom-scrollbar-close"
                  [ngClass]="cardView ? 'card-mail-body-width' : 'grid-mail-body-width'"
                  (click)="mailClicked($event)"
                  [innerHtml]="mailResultsMap.get(item.thread_reply_id || item.thread_id)">
              </div>
              <div class="email-attachments email-body-upper">
                <div *ngIf="item.email.attachments && item.email.attachments.length > 0" class="file-names-container custom-scrollbar">
                  <div *ngFor="let file of item.email.attachments; let i = index" class="single-file-name">
                    <span class="file-name-wrapper">
                      <span class="file-names"
                            [ngClass]="fileIsReady(item, file) ? 'file-ready' : 'file-not-ready'"
                            (click)="attachmentClicked($event, item, file)">{{ file.filename }}</span>
                      <i class="bi bi-x file-name-delete" (click)="removeAttachment($event, item, i)"></i>
                    </span>
                  </div>
                </div>
              </div>
              <div class="save-and-send">
                <div class="save-send-button" *ngIf="!item.marked_send" (click)="markSend($event, item)"
                      style="background-color: #1942DC;">
                  <div class="cancel-inner-text-div">Schedule</div>
                </div>
                <div class="save-send-button" *ngIf="item.marked_send" (click)="cancelSend($event, item)"
                    style="background-color: #D6D7DA;">
                  <div class="cancel-inner-text-div">Unschedule</div>
                </div>
                <div class="save-send-button" (click)="sendNow($event, item)"
                      style="background-color: #D6D7DA;">
                  <div class="cancel-inner-text-div">Send now</div>
                </div>
                <div class="delete-draft">
                  <i class="bi bi-trash3-fill" style="padding: 0px 4px; font-size: 16px; color: #838383"
                      (click)="deleteDraft($event, item)"></i>
                </div>
                <div class="attachment-upload">
                  <div class="rotate-45">
                    <label (click)="uploadFile($event)">
                      <i class="bi bi-paperclip paperclip-style" style="padding: 0px 4px; font-size: 17px; color: #838383"></i>
                      <input type="file" #fileInput (change)="onFileSelected($event, item)">
                    </label>
                  </div>
                </div>
                <div style="flex: 1"></div>
              </div>
            </div>
          </div>
        </div>
        <div style="text-align: center; padding: 10px;"
              *ngIf="this.selectedEmailOption.id == 'inbox' &&
                     !dropdownLoading && selectedView?.selectedJobId != 'uncategorized'">
          <p-button class="more-mail-button"
                    [label]="moreMailButtonText" [loading]="moreMailLoading"
                    (onClick)="loadMoreMail()">
          </p-button>
        </div>
        <div *ngIf="this.selectedEmailOption.id != 'inbox'" style="padding: 10px"></div>
      </ng-container>
    </div>
  </div>
</div>


<p-dialog
  [(visible)]="documentModal"
  [style]="{ marginTop: '50px', width: '90vw', height: '90vh' }"
  [closeOnEscape]="true"
  [modal]="true"
  [resizable]="false"
  [draggable]="false"
  [position]="'top'"
  [closable]="false"
  [maskStyleClass]="'dialog-mask'"
  appendTo="body"
>
  <ng-template pTemplate="header">
    <div class="dialog__header">
      <span></span>
    </div>
    <button class="dialog-close" type="button" (click)="closeDocumentModal()"><i class="bi bi-x-lg"></i></button>
  </ng-template>
  <ng-template pTemplate="content">
    <iframe [src]="selectedAttachment | sanitize" allow="fullscreen" width="100%" height="98%"></iframe>
    <!-- <iframe id="iframe-document" allow="fullscreen" width="100%" height="98%"></iframe> -->
  </ng-template>
</p-dialog>