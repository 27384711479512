import { ChangeDetectorRef, Input, Output, EventEmitter} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import { Component, ViewChild, ElementRef, Renderer2, AfterViewInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { DashboardActions } from 'src/app/dashboard/state/actions/dashboard.actions';
import { PayloadService } from "../../../services/payload.service";
import { HttpClient } from "@angular/common/http";
import { JobTableService } from "../../../services/job-table.service";
import { OptionsService } from 'src/app/dashboard/services/options.service';
import tippy from 'tippy.js';
import { FormsModule } from '@angular/forms';
import { ListboxModule } from 'primeng/listbox';
import { DialogModule } from 'primeng/dialog';
import { OrderService } from 'src/app/dashboard/services/order.service';
import { deepCopy } from 'src/app/common/utils/general';


@Component({
  selector: 'split-item',
  templateUrl: './split-item.component.html',
  styleUrls: ['./split-item.component.sass'],
  imports: [CommonModule, FormsModule, ListboxModule, DialogModule],
  standalone: true
})
export class SplitItemComponent {
  @Input() rowNode!: any;
  @Input() mousePosition = { x: 0, y: 0 };
  @Output() newSubItems = new EventEmitter<object>();
  @Output() closePopup = new EventEmitter<void>();
  splitCount!: any;
  doCustomize: boolean = false;
  splitError: string = '';
  quantityError: string = '';

  userSplits!: any;
  textSplits: string[] = [];

  @ViewChild('content') content!: ElementRef;
  @ViewChild('textarea') textarea!: ElementRef;
  waitTime: number = 2000;

  private tippyInstance!: any;
  isOpen: boolean = false;

  constructor(
    private httpClient: HttpClient,
    private dashboardActions: DashboardActions,
    private jobTableService: JobTableService,
    private payloadService: PayloadService,
    readonly optionsService: OptionsService,
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2,
    public router: Router,
    private orderService: OrderService,
  ) {
  }

  ngAfterViewInit() {
    this.isOpen = true;
    this.cdr.detectChanges();

    this.configureTippyInstance();
    this.tippyInstance.setContent(this.content.nativeElement);
  }

  configureTippyInstance() {
    this.tippyInstance = tippy(this.content.nativeElement);
    this.tippyInstance.enable();
    this.tippyInstance.show();
    const y_add = 30;
    this.tippyInstance.setProps({
      trigger: 'manual',
      placement: 'bottom',
      getReferenceClientRect: () => ({
        width: 0,
        height: 0,
        top: this.mousePosition.y - y_add,
        bottom: this.mousePosition.y - y_add,
        left: this.mousePosition.x,
        right: this.mousePosition.x,
      }),
      arrow: true,
      interactive: true,
      hideOnClick: false,
      offset: 0,
    });
  }

  onCancel() {
    this.destroyTippy();
  }

  destroyTippy() {
    if (this.isOpen) {
      this.isOpen = false;
      this.tippyInstance.destroy();
    }
  }

  getPlaceholderText() {
    return '2';
  }

  updateCommentText(event: Event): void {
    const target = event.target as HTMLElement;
    target.dataset.placeholder = target.innerText ? '': this.getPlaceholderText();
    this.splitCount = target.innerText || '';
    if (this.doCustomize) {
      let splits = this.getSplitCount();
      if (this.isError(splits)) {
        setTimeout(() => {
          this.setTextArea(this.userSplits.length);
        }, this.waitTime);
      } else {
        const newSize = this.splitCount;
        const currentSize = this.textSplits.length;
        if (newSize > currentSize) {
          this.textSplits = [...this.textSplits, ...new Array(newSize - currentSize).fill('')];
        } else if (newSize < currentSize) {
          this.textSplits = this.textSplits.slice(0, newSize);
        }
        this.userSplits = new Array(this.splitCount) as any;
      }
    }
  }

  calculateSplits(quantity: number) {
    let item_quantity = this.getRowQuantity();
    let item_quant = parseInt(item_quantity, 10);
    let sub_quant = quantity;
    let split = Math.floor(item_quant / sub_quant);
    let x = Array(sub_quant).fill(split);
    x[x.length - 1] += item_quant % sub_quant;
    return x;
  }

  getSplitCount() {
    let splits = 0;
    let textarea = this.textarea.nativeElement;
    let placeholder = textarea.getAttribute('data-placeholder');
    if (placeholder) {
      splits = Number(placeholder);
    } else if (textarea.innerText.trim()) {
      splits = Number(textarea.innerText.trim());
    }
    this.splitCount = splits ? splits: 0;
    return splits;
  }

  setTextArea(value: number) {
    let textarea = this.textarea.nativeElement;
    textarea.dataset.placeholder = '';
    textarea.innerText = String(value);
  }

  getRowQuantity() {
    return this.rowNode.node.data.has_subitems.q;
  }


  isError(splits: number) {
    let cur_error = '';
    if (isNaN(splits)) cur_error = 'Input must be valid number';
    else if (splits <= 1) cur_error = 'Input must be greater than 1';
    else if (splits > this.getRowQuantity()) cur_error = 'Input must be less than item quantity';
    if (cur_error) {
      if (this.doCustomize) this.quantityError = cur_error;
      else this.splitError = cur_error;
      setTimeout(() => {
        this.splitError = '';
        this.quantityError = '';
        this.cdr.detectChanges();
      }, this.waitTime);
      return true;
    }
    return false;
  }

  onAcceptDefault(event: any) {
    let splits = this.getSplitCount();
    if (!this.isError(splits)) {
      let payload = {
        id: this.rowNode.node.data.id,
        data: this.calculateSplits(splits)
      };
      this.orderService.splitItem(payload).subscribe((results: any) => {
        let newItems = this.handleSplitItemResults(results);
        this.newSubItems.emit({'node': this.rowNode.node, results: newItems});
        this.onClosePopup();
      });
    }
  }

  handleSplitItemResults(results: any) {
    let newItems: any = [];
    let newQuantity = 0;
    results.forEach((r: any) => {
      if (r.has_subitems) {
        newQuantity = r.quantity;
        return;
      }
      let hsubitem = {has: false, q: r.quantity};
      let cur = {...this.rowNode.node.data, ...r, has_subitems: hsubitem};
      newItems.push(cur);
    })
    return {newItems: newItems, newQuantity: newQuantity};
  }

  onClosePopup() {
    this.destroyTippy();
    this.closePopup.emit();
  }

  onCustomize() {
    let splits = this.getSplitCount();
    if (this.isError(splits)) return;
    this.setTextArea(splits);
    this.userSplits = new Array(this.splitCount) as any;
    this.textSplits = new Array(this.splitCount).fill('') as any;
    this.doCustomize = true;
  }

  get userSplitCount() {
    return this.splitCount;
  }

  onAcceptCustomize(event: any) {
    let bad_request = false;
    this.textSplits.forEach((val: any) => {
      if (bad_request) return;
      if (this.isError(Number(val))) {
        bad_request = true;
      }
    });
    if (bad_request) return;
    let numbers = this.textSplits.map((val: any) => Number(val));
    const sum = numbers.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    if (sum != this.getRowQuantity()) {
      this.quantityError = 'Sub items must sum to item quantity';
      setTimeout(() => {
        this.quantityError = '';
        this.cdr.detectChanges();
      }, 2000);
      return false;
    }
    let payload = { id: this.rowNode.node.data.id, data: numbers };
    this.orderService.splitItem(payload).subscribe((results: any) => {
      let newItems = this.handleSplitItemResults(results);
      this.newSubItems.emit({'node': this.rowNode.node, results: newItems});
      this.onClosePopup();
    });
    return true;
  }

  onCloseCustomize(event: any) {
    this.doCustomize = false;
    this.userSplits = [];
    this.textSplits = [];
  }

  updateQuantityText(event: any, i: number): void {
    this.textSplits[i] = event.target.innerText;
  }

}