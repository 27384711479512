import { ChangeDetectorRef, Component, OnInit, ViewChild, Input } from '@angular/core';
import { ElementRef, HostListener } from '@angular/core';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { OrganizationService } from '../../services/organization.service';
import { faLessThanEqual } from '@fortawesome/free-solid-svg-icons';
import { PermissionsService } from 'src/app/root/services/permissions.service';
import { generateRandomString } from 'src/app/common/utils/general';

@Component({
  selector: 'materlog-company-settings',
  templateUrl: './company-settings.component.html',
  styleUrls: [
    './company-settings.component.css',
    '../../../user-settings/components/user-settings/user-settings.component.css',
    '../company-management/company-management.component.sass'
  ]
})
export class CompanySettingsComponent extends OnDestroyMixin() implements OnInit {
  @Input() dropdownOptions: any;
  @Input() allMailData: any;

  @ViewChild('orgSettingsContainer') orgSettingsContainer!: ElementRef<HTMLDivElement>;

  isLoading: boolean = true;
  orgData: any = {};
  users: any = [];
  addresses: any = [];
  userId: any = '';
  userRole: any = undefined;

  statesList: any = [];

  addUsersModal: boolean = false;
  newUsersLoading: boolean = false;
  newUsersString: string = '';

  chosenAddress: any = undefined;
  showAddressModal: boolean = false;
  addressSubmitted: boolean = false;

  sales_girl: any = undefined;
  ops_girl: any = undefined;

  hasName: boolean = false;
  editingName: boolean = false;

  orderDataEntry: string = 'Order data entry';
  autoTrack: string = 'Auto track order links';
  draftEmails: string = 'Draft and send check in emails';

  picLoading: boolean = false;

  trackPOText: string = 'Track POs';
  trackPOs: any = [
    {id: 0, value: "Don't track POs"},
    {id: 1, value: 'Track POs'},
  ];

  settings: any = [
    {
      name: 'Sales',
      extra: 'Coming soon!',
      options: [
        {name: 'Create quotes'},
        {name: 'Quote to PO'},
        {name: 'Reorder'},
      ]
    },
    {
      name: 'Operations',
      options: [
        {
          name: this.orderDataEntry,
          subtext: 'Order monitoring',
          /*
          subvalues: {
            name: 'Share orders via extension or email',
            is_open: false,
            options: [
              {id: 0, value: 'Email'},
              {id: 1, value: 'Extension'},
            ]
          }
          */
        },
        {
          name: this.autoTrack,
          subtext: 'Order monitoring',
        },
        {
          name: this.draftEmails,
          subtext: 'Order monitoring',
          subvalues:  {
            name: this.trackPOText,
            options: this.trackPOs,
            is_open: false,
          }
        },
      ]
    }
  ];

  constructor(
    private orgService: OrganizationService,
    private permissionService: PermissionsService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.generateItems();
    this.getOrgData();
    (window as any)['org'] = this;
  }

  ngOnChanges(changes: any) {
    if (changes.dropdownOptions?.currentValue) {
      this.dropdownOptions = changes.dropdownOptions.currentValue;
      this.statesList = this.dropdownOptions.state;
    }
  }


  getOrgData() {
    this.orgService.getOrganization().subscribe((result: any) => {
      this.isLoading = false;
      this.ops_girl = result.imgs?.ops;
      this.sales_girl = result.imgs?.sales;
      this.orgData = result.result;
      this.userId = result.user_id;
      let allUsers = this.orgData.users || [];
      this.sortUsers(allUsers);
      let curUser = allUsers.find((u: any) => u.id == this.userId);
      this.userRole = curUser?.role;
      this.sortAddresses(this.orgData.addresses || []);
      this.statesList = this.dropdownOptions.state;

      this.setScrollBar('.org-member-users');
      this.setScrollBar('.org-member-addresses');
      this.setScrollBar('.org-settings-box');
    })
  }

  skeletonItems: any[] = [];
  generateItems() {
    for (let i = 0; i < 15; i++) {
      const randomWidth = Math.floor(Math.random() * 25) + 5;
      this.skeletonItems.push({ width: `${randomWidth}rem` });
    }
  }

  sortAddresses(addresses: any) {
    addresses.forEach((u: any) => {
      u.name = u.name || '';
    })
    addresses.sort((a: any, b: any) => {
      const nameA = a.name?.toLowerCase() || '';
      const nameB = b.name?.toLowerCase() || '';
    
      if (nameA === "") return 1;
      if (nameB === "") return -1;
    
      return nameA.localeCompare(nameB);
    });
    
    this.addresses = addresses;
  }

  sortUsers(allUsers: any) {
    allUsers.forEach((u: any) => {
      if (!u.user_settings) return;
      u.user_settings.first_name = u.user_settings?.first_name || '';
    })
    allUsers.sort((a: any, b: any) => {
      const nameA = a.user_settings?.first_name?.toLowerCase() || '';
      const nameB = b.user_settings?.first_name?.toLowerCase() || '';
    
      if (nameA === "") return 1;
      if (nameB === "") return -1;
    
      return nameA.localeCompare(nameB);
    });
    
    this.users = allUsers;
  }

  updateOrgName(event: any) {
    this.editingName = false;
    this.updateOrgData();
  }

  orgInputClicked(username: boolean = true) {
    let value = '[id=org-settings-username]';
    this.editingName = true;
    setTimeout(() => {
      let el: any = this.orgSettingsContainer?.nativeElement?.querySelector(value);
      if (el) {
        el.focus();
      }
    }, 30);
  }

  onFileSelected(event: any) {
    const input = event.target as HTMLInputElement;
    const formData = new FormData();
    let newfile = false;
    if (input.files) {
      for (let i = 0; i < input.files.length; i++) {
        let cur = input.files[i];
        formData.append('file', cur, cur.name);
        newfile = true;
      }
    }
    if (newfile) {
      this.picLoading = true;
      this.orgService.updateOrgProfilePicture(formData).subscribe((result: any) => {
        this.picLoading = false;
        if (result?.link) {
          this.orgData.logo = result.link;
        }
      })
    }
    event.target.value = '';
  }

  get orgName() {
    return this.orgData.name || 'Organization name';
  }

  uploadFile(event: any) {
    event.stopPropagation();
  }

  inputEnter(event: any) {
    event.target.blur();
  }

  makeUserName(user: any) {
    let names = [user.user_settings?.first_name, user.user_settings?.last_name]
    names = names.filter((n: any) => n)
    return names.join(' ') || '';
  }

  deleteUser(user: any) {
    this.users = this.users.filter((u: any) => u.id != user.id);
    this.orgService.deleteOrgUser({user_id: user.id}).subscribe((result: any) => {
      if (result?.fail) {
        this.sortUsers(this.orgData.users)
      } else {
        this.orgData.users = this.users;
        if (user.id == this.userId) {
          // removed self
          this.permissionService.logout();
        }
      }
    });
  }

  setScrollBar(v: string) {
    setTimeout(() => {
      let jdisplays = this.orgSettingsContainer.nativeElement.querySelectorAll(v);
      if (jdisplays) {
        jdisplays.forEach((jdisplay: any) => {
          jdisplay.addEventListener('mouseenter', function() {
            (jdisplay as any).classList.add('custom-scrollbar');
            (jdisplay as any).classList.remove('custom-scrollbar-close');
            setTimeout(() => {
              (jdisplay as any).classList.remove('custom-scrollbar');
              (jdisplay as any).classList.add('custom-scrollbar-close');
            }, 1500)
          });
          jdisplay.addEventListener('wheel', function() {
            (jdisplay as any).classList.add('custom-scrollbar');
            (jdisplay as any).classList.remove('custom-scrollbar-close');
            setTimeout(() => {
              (jdisplay as any).classList.remove('custom-scrollbar');
              (jdisplay as any).classList.add('custom-scrollbar-close');
            }, 1500)
          });
          jdisplay.addEventListener('mouseleave', function() {
            (jdisplay as any).classList.remove('custom-scrollbar');
            (jdisplay as any).classList.add('custom-scrollbar-close');
          });
        })
      }
    }, 500);

  }

  addMember() {
    this.addUsersModal = true;
  }

  processNewUsers() {
    let ustring = this.newUsersString.trim();
    if (!ustring) {
      this.closeAddressDialog();
      return;
    }
    this.newUsersLoading = true;
    this.orgService.orgAddUsers({users: ustring}).subscribe((result: any) => {
      this.newUsersLoading = false;
      this.addUsersModal = false;
    })
  }

  addLocation() {
    let newAddress = {
      id: generateRandomString(),
      name: '',
      address: '',
      city: '',
      state_id: undefined,
      zip: '',
      phone: '',
      is_default: false,
      new: true,
    }
    this.chosenAddress = newAddress;
    this.showAddressModal = true;
  }

  deleteAddress(event: any, address: any) {
    event.stopPropagation();
    let data = {payload: address.id};

    this.addresses = this.addresses.filter((a: any) => a.id != address.id);
    if (address.is_default && this.addresses.length == 1) {
      this.addresses[0].is_default = true;
    }
    this.orgService.deleteOrganizationAddress(data).subscribe(() => {});
  }

  addressClicked(address: any) {
    this.chosenAddress = {...address};
    this.showAddressModal = true;
  }

  createAddressMeta(address: any) {
    let state: any = '';
    if (address.state_id) {
      state = this.dropdownOptions.state?.find((s: any) => s.id == address.state_id);
      state = state ? state.value : '';
    }
    let output = '';
    if (address.city && state) {
      output = `${address.city}, ${state}`;
    } else if (address.city) {
      output = address.city;
    } else if (state) {
      output = state;
    }
    if (address.zip) {
      output = `${output} ${address.zip}`
    }
    return output;
  }

  editAddress(address: any) {
    this.showAddressModal = false;
    if (address.is_default) {
      this.addresses.forEach((a: any) => {
        a.is_default = false;
      })
    }
    let addrs = this.addresses;
    const index = addrs.findIndex((a: any) => a.id === address.id);
    if (index != -1) {
      addrs[index] = address;
    } else {
      let newAddr = {...address};
      addrs.push(newAddr);
    }
    this.sortAddresses(addrs);
    if (address.new) {
      this.orgService.addOrganizationAddress({payload: address}).subscribe((result: any) => {
        this.sortAddresses(result);
      })
    } else {
      this.orgService.updateOrganizationAddress({payload: address}).subscribe((result: any) => {
        this.sortAddresses(result);
      })
    }
    this.chosenAddress = undefined;
  }

  closeAddressDialog() {
    this.showAddressModal = false;
    this.addUsersModal = false;
    this.chosenAddress = undefined;
    this.newUsersString = '';
  }

  placeSelected(event: any, address: any) {
    if (event) {
      address.zip = event.properties.postcode ?? null;
      address.address = event.properties.address_line1;
      this.statesList.forEach((state: any) => {
        if (state.value?.toLowerCase() == event.properties.state_code?.toLowerCase()) {
          address.state = state.id;
          return;
        }
      });
      address.city = event.properties.city ?? null;
    } else {
      address.state = null;
      address.city = null;
      address.zip = null;
      address.country = null;
      address.address = null;
    }
  }

  picForName(name: any) {
    if (name == 'Sales') {
      return this.sales_girl;
    } else if (name == 'Operations') {
      return this.ops_girl;
    }
    return '';
  }


  backgroundForName(name: any) {
    if (name == 'Sales') {
      return '#28B6EC';
    } else if (name == 'Operations') {
      return '#F3527B';
    }
    return '';
  }

  toggleForName(setting_name: any, option_name: any, toggle: boolean = false) {
    if (setting_name == 'Sales') {
      return false;
    } else if (setting_name == 'Operations') {
      switch (option_name) {
        case this.orderDataEntry:
          if (toggle) {
            this.orgData.order_data_entry = !this.orgData.order_data_entry;
          }
          return this.orgData.order_data_entry;
        case this.autoTrack:
          if (toggle) {
            this.orgData.track_links = !this.orgData.track_links;
          }
          return this.orgData.track_links;
        case this.draftEmails:
          if (toggle) {
            this.orgData.send_checkin_emails = !this.orgData.send_checkin_emails;
          }
          return this.orgData.send_checkin_emails;
        default:
          return false;
      }
    }
    return false
  }

  toggleOption(setting_name: any, option_name: any) {
    if (!this.isToggleable(setting_name)) {
      return;
    }
    this.toggleForName(setting_name, option_name, true);
    this.updateOrgData();
  }

  updateOrgData() {
    let data = {
      name: this.orgData.name.trim(),
      order_data_entry: this.orgData.order_data_entry,
      track_links: this.orgData.track_links,
      send_checkin_emails: this.orgData.send_checkin_emails,
      po_follow_up: this.orgData.po_follow_up,
    };
    this.orgService.updateOrganization({payload: data}).subscribe(() => {});
  }

  isToggleable(setting_name: any) {
    if (setting_name == 'Sales') {
      return false;
    } else if (setting_name == 'Operations') {
      return true;
    }
    return false;
  }

  optionValueForOption(setting_name: any, sub_name: any, options: any) {
    let value: any = undefined;
    if (setting_name == 'Operations') {
      switch (sub_name) {
        case this.trackPOText:
          value = Number(this.orgData.po_follow_up);
          break;
        default:
          value = undefined;
      }
    }
    if (value == undefined) {
      return '';
    }
    let found = options.find((o: any) => o.id == value);
    return found ? found.value: 'Choose option';
  }

  onToggleSubvalues(subvalues: any) {
    subvalues.is_open = !subvalues.is_open;
  }

  onSetSubvalue(setting_name: any, sub_value: any, value: any) {
    sub_value.is_open = false;
    if (setting_name == 'Operations') {
      switch (sub_value.name) {
        case this.trackPOText:
          this.orgData.po_follow_up = Boolean(value.id);
          break;
        default:
          value = undefined;
      }
    }
    this.updateOrgData();
  }

}
