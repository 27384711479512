import { Component, EventEmitter, Input, Output, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { filter, takeUntil } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { MenuItem } from 'primeng/api';
import { ContextMenu } from 'primeng/contextmenu';
import { Renderer2 } from '@angular/core';

import { faFilter, faPlus } from '@fortawesome/free-solid-svg-icons';

import { IApplicationState } from 'src/app/common/state/models/app.state.model';
import { DashboardActions } from 'src/app/dashboard/state/actions/dashboard.actions';
import { selectUncategorizedView } from 'src/app/dashboard/state/selectors/dashboard.selector';
import { IStoreApiItem } from 'src/app/common/models/store-api-item.model';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { DashboardRoutes } from '../../enums/dashboard-routes.enum';
import { ActiveJobModel } from '../../models/active-job.model';
import { CommonConstants } from 'src/app/common/constants/common.constants';
import { TemplatesDialogComponent } from 'src/app/templates/components/templates/templates-dialog.component';
import { SelectedViewModel } from '../../models/selected-view.model';
import { JobsService } from '../../services/job.service';

@Component({
  selector: 'active-job-list',
  templateUrl: './active-job-list.component.html',
  styleUrls: ['./active-job-list.component.css'],
})
export class ActiveJobListComponent extends OnDestroyMixin() implements OnInit {
  @Input() activeJobs: ActiveJobModel[] = [];
  @Input() selectedView?: SelectedViewModel;
  @Input() panelHeight!: 750;
  @Input() isLoading: any;
  @Input() isLoadingViews: any;
  @Input() activePage: any;
  @Input() hasUncat: boolean = false;
  @Input() isPanelOpen: boolean = true;
  @Input() searchClicked: boolean = false;
  @Output() searchClickClose: EventEmitter<any> = new EventEmitter();

  filterSearchText: string = '';
  curJobList: any = [];
  @ViewChild('jobSearchEl') jobSearchEl!: ElementRef;

  @Output() selectedJob: EventEmitter<any> = new EventEmitter();
  @ViewChild('cm') public contextMenu!: ContextMenu;
  @ViewChild('jobContainer') jobContainer!: ElementRef;
  @ViewChild('jobContainerScroll') jobContainerScroll!: ElementRef;

  private documentClickListener!: () => void;
  contextMenuJob!: ActiveJobModel;
  uncatViewReceived: boolean = false;

  isLoadingUncategorizedView: any = true;
  filtersIcon = faFilter;
  plusIcon = faPlus;

  collapsed: boolean = false;

  DashboardRoutes = DashboardRoutes;
  showUncategorizedView: boolean = false;

  showTemplates = false;
  jobMenu: MenuItem[] = [
    { label: 'Hide job', icon: 'pi bi bi-eye-slash', command: () => this.turnOffTracking()},
    { separator: true},
    { label: 'Archive job', icon: 'pi bi-archive-fill', command: () => this.archiveJob()},
  ];

  constructor(
    public router: Router,
    private dashboardActions: DashboardActions,
    private store: Store<IApplicationState>,
    private renderer: Renderer2,
    private jobsService: JobsService) {
    super();
    (window as any)['active'] = this;
  }

  ngAfterViewInit() {
    this.setScrollBar('.job-list-container');
  }

  ngOnChanges(changes: any) {
    if (changes?.isLoading?.currentValue == false && !this.uncatViewReceived) {
      // defer initing until view data receieved
      this.uncatViewReceived = true;
      this.dashboardActions.requestUncategorizedViewBool({ include_items: CommonConstants.include_items.uncategorized });
      this.isLoadingUncategorizedView = true;
    }
    if (changes.isPanelOpen) {
      this.setScrollBar('.job-list-container');
    }
    this.curJobList = this.activeJobsList();
    if (changes.searchClicked && this.searchClicked) {
      setTimeout(() => {
        this.jobSearchEl?.nativeElement?.focus();
      }, 40);
    }
  }

  ngOnInit(): void {
  }

  onSearchChange() {
    this.curJobList = this.activeJobsList();
  }

  jobSearchBlur() {
    this.searchClicked = false;
    this.searchClickClose.emit();
  }

  onBlurJobs(event: any) {
    this.jobSearchEl?.nativeElement?.blur();
  }

  activeJobsList() {
    let curJobs: any = [];
    if (this.activeJobs?.length) {
      curJobs = [...this.activeJobs]
    }
    if (curJobs.length > 1) {
      curJobs.sort((a: any, b: any) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      });
    }
    if (this.hasUncat) {
      let uncat = 'Uncategorized'
      curJobs.unshift({id: uncat, name: uncat});
    }

    if (this.filterSearchText) {
      let lfilter = this.filterSearchText.toLowerCase();
      curJobs = curJobs.filter((j: any) => {
        let text = j.name.toLowerCase();
        return text.includes(lfilter);
      });
    }

    return curJobs;
  }


  shortenedName(job: any) {
    let name = job.name.slice(0, 2);
    if (!name) {
      return '?'
    }
    if (name.length == 1) {
      return name;
    }
    return name[0].toUpperCase() + name[1].toLowerCase();
  }


  trackByFn(index: number, job: any): string {
    return index + job.id;
  }

  isActive(name: string) {
    return this.selectedJobId === name && this.activePage == 'Items';
  }


  openAddOrder() {
    this.router.navigate([{ outlets: { addOrder: DashboardRoutes.AddOrder } }], { skipLocationChange: true }).then(() => {
      localStorage.removeItem('itemBreadcrumb');
    })
  }

  formatJobName(name: string) {
    let max_length = 20;
    return name.length > max_length ? name.substring(0, max_length) + '...' : name;
  }

  scrollDown() {
    this.jobContainerScroll?.nativeElement?.scrollBy(0, 50);
  }

  toggleTemplatesDisplay() {
    this.showTemplates = !this.showTemplates;
  }

  selectJob(id: any): void {
    if (this.isAnyLoading) return;
    if (id == this.selectedJobId && this.activePage == 'Items') {
      return;
    }
    localStorage.setItem('selectedJob', JSON.stringify(id));
    this.selectedJob.emit(id);
  }

  openContextMenu(event: any, job: any) {
    this.contextMenuJob = job;
    event.preventDefault();
    this.contextMenu.show(event);
    setTimeout(() => {
      const contextMenuElement = this.contextMenu.containerViewChild.nativeElement as HTMLElement;
      if (contextMenuElement) {
        const newYPosition = event.clientY + 10;
        contextMenuElement.style.position = 'fixed';
        contextMenuElement.style.left = `${event.clientX}px`;
        contextMenuElement.style.top = `${newYPosition}px`;
        contextMenuElement.style.fontWeight = '200';
      }
    }, 1);

    this.documentClickListener = this.renderer.listen('document', 'click', (e: MouseEvent) => {
      if (this.contextMenu) {
        this.contextMenu.hide();
      }
    });
  }

  getPanelHeight() {
    return `${this.panelHeight - 140 - 25 * 3}px`;
  }

  removeJobFromActiveJobs(job: ActiveJobModel) {
    this.activeJobs = this.activeJobs.filter((j: any) => j.id != job.id);
  }

  turnOffTracking() {
    let payload = {...this.contextMenuJob, user_tracking: false, context_menu: true};
    this.removeJobFromActiveJobs(this.contextMenuJob);
    this.jobsService.updateJob({payload: payload}).subscribe((result: any) => {});
  }

  archiveJob() {
    let payload = {...this.contextMenuJob, is_archived: true, context_menu: true};
    this.removeJobFromActiveJobs(this.contextMenuJob);
    this.jobsService.updateJob({payload: payload}).subscribe((result: any) => {});
  }

  get selectedJobId(): string | null {
    const savedJobId = localStorage.getItem('selectedJob') as any;
    return !!savedJobId ? JSON.parse(savedJobId) : null;
  }

  setScrollBar(v: string) {
    let self = this;
    setTimeout(() => {
      let jdisplays = this.jobContainer.nativeElement.querySelectorAll(v);
      if (jdisplays) {
        jdisplays.forEach((jdisplay: any) => {
          jdisplay.addEventListener('mouseenter', function() {
            if (!self.isPanelOpen) return;
            (jdisplay as any).classList.add('custom-scrollbar');
            (jdisplay as any).classList.remove('custom-scrollbar-close');
            setTimeout(() => {
              (jdisplay as any).classList.remove('custom-scrollbar');
              (jdisplay as any).classList.add('custom-scrollbar-close');
            }, 1500)
          });
          jdisplay.addEventListener('wheel', function() {
            if (!self.isPanelOpen) return;
            (jdisplay as any).classList.add('custom-scrollbar');
            (jdisplay as any).classList.remove('custom-scrollbar-close');
            setTimeout(() => {
              (jdisplay as any).classList.remove('custom-scrollbar');
              (jdisplay as any).classList.add('custom-scrollbar-close');
            }, 1500)
          });
          jdisplay.addEventListener('mouseleave', function() {
            if (!self.isPanelOpen) return;
            (jdisplay as any).classList.remove('custom-scrollbar');
            (jdisplay as any).classList.add('custom-scrollbar-close');
          });
        })
      }
    }, 500);
  }

  get isItemsPageActive() {
    let activePage = localStorage.getItem('activePage');
    return activePage == 'Items';
  }

  canShowViewTabs(job: any) {
    return this.isItemsPageActive &&
            this.isPanelOpen &&
            this.selectedJobId === job.id &&
            job.name != 'Uncategorized'
  }

  isJobActive(job: any) {
    if (!this.isItemsPageActive) {
      return false;
    }
    if (this.isPanelOpen) {
      return this.selectedJobId == 'Uncategorized' && job.id == 'Uncategorized';
    } else {
      return this.selectedJobId == job.id;
    }
    
  }

  get isAnyLoading() {
    return this.isLoading || this.isLoadingViews;
  }
}
