<div class="meta-settings-container">
    <div class="meta-settings-options">
        <div class="meta-settings-bold" style="padding-left: 8px; margin-bottom: 12px;">
            Settings
        </div>
        <div *ngFor="let setting of settingOptions"
                class="meta-single-option notification-box notifications-text"
                (click)="changeSettingsType(setting)"
            [ngClass]="{
                'notification-value-active': isSettingsActive(setting)
            }"
        >
            <i *ngIf="canShowAlert(setting)" class="bi bi-dot sp-alert-dot pref-needs-dot"></i>
            {{setting.name}}
        </div>
        <div style="display: flex; height: 100%"></div>
        <div class="logout-settings" style="font-size: 14px; margin-left: 8px;"
            (click)="logoutClicked()">
            <i class="'bi bi-box-arrow-right" style="font-size: 18px"></i>
            Log out
        </div>
    </div>
    <materlog-user-settings *ngIf="personalSettingsActive"
        [dropdownOptions]="dropdownOptions"
        [allMailData]="allMailData"
    ></materlog-user-settings>
    <materlog-company-settings *ngIf="generalSetttingsActive"
        [dropdownOptions]="dropdownOptions"
        [allMailData]="allMailData"
    ></materlog-company-settings>
    <materlog-settings-prefs *ngIf="preferencesActive"
        [dropdownOptions]="dropdownOptions"
        [whichWorkflowOption]="whichWorkflowOption"
        [allMailData]="allMailData"
    ></materlog-settings-prefs>
</div>
