import { Router } from '@angular/router';
import { Component, OnInit, Output, EventEmitter, Input,
  OnChanges, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { takeUntil, take} from 'rxjs/operators';
import { filter } from 'rxjs';

import { MessageService } from 'primeng/api';

import { IStoreApiItem } from './../../../common/models/store-api-item.model';
import { IApplicationState } from 'src/app/common/state/models/app.state.model';
import { OnDestroyMixin } from './../../../common/mixins/destroy-mixin';
import { CommonConstants } from 'src/app/common/constants/common.constants';
import { ISuppliers } from 'src/app/dashboard/models/dashboard.model';
import { DashboardActions } from 'src/app/dashboard/state/actions/dashboard.actions';
import { selectSuppliers } from 'src/app/dashboard/state/selectors/dashboard.selector';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { selectOptions } from 'src/app/dashboard/state/selectors/dashboard.selector';
import { RadioButtonModule } from 'primeng/radiobutton';
import { AddSupplierContactModalComponent } from '../add-supplier-contact-modal/add-supplier-contact-modal.component';
import { SupplierService } from '../../services/supplier.service';
import { ButtonModule } from 'primeng/button';


@Component({
  selector: 'materlog-add-supplier-modal',
  templateUrl: './add-supplier-modal.component.html',
  styleUrls: ['./add-supplier-modal.component.sass'],
  standalone: true,
  imports: [CommonModule, FormsModule, RadioButtonModule, AddSupplierContactModalComponent, ButtonModule],
})
export class AddSupplierModalComponent extends OnDestroyMixin() implements OnChanges {
  @Input() resetSupplier: boolean = false;
  @Input() sidePanelAdd: boolean = false;
  @Input() credPage: boolean = false;
  @Output() supplierFormChange = new EventEmitter<any>();
  @ViewChild(AddSupplierContactModalComponent) child!: AddSupplierContactModalComponent;
  @ViewChild('nameElement') nameElement!: ElementRef;

  showContact: boolean = false;
  suppliers: any;
  filteredSuppliers: any;
  contactRoles: any;

  showSuppliers: boolean = false;
  dropdownSuppliers: any;

  addSupplierContactModal: boolean = false;
  wrongName: boolean = false;
  isLoading: boolean = false;
  supplierForm: any = {
    name: null,
    website: null,
    email: null,
    phone: null,
  };

  constructor(
    public router: Router,
    private store: Store<IApplicationState>,
    private dashboardActions: DashboardActions,
    private messageService: MessageService,
    private supplierService: SupplierService,
  ) {
    super();
    (window as any)['sup'] = this;
    this.prepareSuppliers();
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      const change = changes[propName];

      if (change.previousValue !== change.currentValue) {
        this.resetForm();
      }
    }
  }

  get isSupplierEmail() {
    if (this.supplierForm.email) {
      return CommonConstants.emailRegex.test(this.supplierForm.email);
    } else { return true; }
  }

  closeSupplier() {
    this.supplierFormChange.emit({close: true});
  }

  filterSuppliers() {
    if (!this.supplierForm.name) {
      this.dropdownSuppliers = this.filterSuppliers;
    } else {
      this.dropdownSuppliers = this.suppliers.filter((supplier: any) =>
        supplier.name.toLowerCase().includes(this.supplierForm.name.toLowerCase())
      );
    }
  }

  blurSelectSupplier(event: any) {
    setTimeout(() => {
      this.showSuppliers = false;
    }, 200);
  }

  selectedSupplier: any = undefined;
  selectSupplier(supplier: any) {
    this.showSuppliers = false;
    this.nameElement.nativeElement.blur();
    this.supplierForm.website = supplier.website;
    this.supplierForm.phone = supplier.phone;
    this.supplierForm.email = supplier.email;
    this.supplierForm.name = supplier.name;
    this.selectedSupplier = supplier;
  }

  resetForm() {
    this.supplierForm = {
      name: null,
      website: null,
      email: null,
      phone: null,
    };
    this.wrongName = false;
  }

  onNewSupplierSubmit(newSupplier: any) {
    this.isLoading = false;
    if (newSupplier?.isSuccess) {
      if (this.sidePanelAdd && this.showContact && newSupplier?.data?.id) {
        this.child.supplierContactForm.supplier_id = this.child.supplierId = newSupplier.data.id;
        this.child.submitSupplierContact();
      }
      this.dashboardActions.resetSuppliersCRUDState();
      setTimeout(() => {
        this.supplierFormChange.emit(newSupplier.data);
        this.resetForm();
      }, 100);
    }

    if (newSupplier?.errors) {
      this.messageService.clear();
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Something went wrong. Please try again later. If this issue persists, contact support@getmeti.com.',
        key: 'errorAddSupplier',
      });
    }
  }

  isSupplierSubmitted: boolean = false;
  submitSupplier() {
    if (this.supplierForm.name !== null && this.supplierForm.name.trim() !== '' && this.isSupplierEmail) {
      if (this.sidePanelAdd && this.showContact && !this.child.isContactReadyNoSupplier()) {
        this.child.isSubmitted = true;
        return;
      }
      this.isSupplierSubmitted = true;
      this.isLoading = true;
      if (this.selectedSupplier && this.supplierForm.name == this.selectedSupplier.name) {
        let data: any = {
          name: this.selectedSupplier.name,
          website: this.selectedSupplier.website || this.supplierForm.website,
          email: this.selectedSupplier.email || this.supplierForm.email,
          phone: this.selectedSupplier.phone || this.supplierForm.phone,
        };
        let payload = {payload: data, id: this.selectedSupplier.id};
        this.supplierService.editSupplier(payload).subscribe((result: any) => {
          this.supplierFormChange.emit(result);
        });
        return;
      } else {
        this.dashboardActions.requestAddSuppliers({ ...this.supplierForm });
      }

      this.store
        .pipe(
          select(selectSuppliers),
          filter((newSupplier: IStoreApiItem<ISuppliers>) => !newSupplier.isLoading),
          takeUntil(this.destroy)
        )
        .subscribe((newSupplier: any) => {
          this.onNewSupplierSubmit(newSupplier);
        });
      this.wrongName = false;
    } else {
      this.wrongName = true;
    }
  }

  prepareSuppliers() {
    this.supplierService.getSuppliersAll().subscribe((suppliers: any) => {
      this.filteredSuppliers = suppliers.results;
      this.suppliers = suppliers.results;
      this.dropdownSuppliers = suppliers.results;
    });
    this.contactRoles = [
      {id: 0, value: 'Not selected'},
      {id: 1, value: 'Purchasing/Procurement'},
      {id: 2, value: 'Tracking/Logistics'},
    ]
  }

  supplierContactFormChangeEvent(event: any) {
  }

}
