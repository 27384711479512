// eslint-disable-next-line no-shadow
export enum DashboardActionTypes {
  REQUEST_JOBS = 'REQUEST_JOBS',
  RECEIVE_JOBS = 'RECEIVE_JOBS',
  ERROR_RECEIVE_JOBS = 'ERROR_RECEIVE_JOBS',

  REQUEST_ACTIVE_JOBS = 'REQUEST_ACTIVE_JOBS',
  RECEIVE_ACTIVE_JOBS = 'RECEIVE_ACTIVE_JOBS',
  ERROR_RECEIVE_ACTIVE_JOBS = 'ERROR_RECEIVE_ACTIVE_JOBS',

  REQUEST_RECEIVERS = 'REQUEST_RECEIVERS',
  RECEIVE_RECEIVERS = 'RECEIVE_RECEIVERS',
  ERROR_RECEIVE_RECEIVERS = 'ERROR_RECEIVE_RECEIVERS',

  REQUEST_ADD_RECEIVER = 'REQUEST_ADD_RECEIVER',
  RECEIVE_ADD_RECEIVER = 'RECEIVE_ADD_RECEIVER',
  ERROR_RECEIVE_ADD_RECEIVER = 'ERROR_RECEIVE_ADD_RECEIVER',
  RESET_CRUD_RECEIVER_STATE = 'RESET_CRUD_RECEIVER_STATE',

  REQUEST_ADD_RECEIVER_CONTACT = 'REQUEST_ADD_RECEIVER_CONTACT',
  RECEIVE_ADD_RECEIVER_CONTACT = 'RECEIVE_ADD_RECEIVER_CONTACT',
  ERROR_RECEIVE_ADD_RECEIVER_CONTACT = 'ERROR_RECEIVE_ADD_RECEIVER_CONTACT',

  REQUEST_OPTIONS = 'REQUEST_OPTIONS',
  RECEIVE_OPTIONS = 'RECEIVE_OPTIONS',
  ERROR_RECEIVE_OPTIONS = 'ERROR_RECEIVE_OPTIONS',

  REQUEST_ADD_JOB = 'REQUEST_ADD_JOB',
  RECEIVE_ADD_JOB = 'RECEIVE_ADD_JOB',
  ERROR_RECEIVE_ADD_JOB = 'ERROR_RECEIVE_ADD_JOB',

  REQUEST_JOB_LIST = 'REQUEST_JOB_LIST',
  RECEIVE_JOB_LIST = 'RECEIVE_JOB_LIST',
  ERROR_JOB_LIST = 'ERROR_JOB_LIST',

  REQUEST_INPUT_MESSAGES_LIST = 'REQUEST_INPUT_MESSAGES_LIST',
  RECEIVE_INPUT_MESSAGES_LIST = 'RECEIVE_INPUT_MESSAGES_LIST',
  ERROR_INPUT_MESSAGES_LIST = 'ERROR_INPUT_MESSAGES_LIST',

  REQUEST_SUPPLIERS_SMALL = 'REQUEST_SUPPLIERS_SMALL',
  RECEIVE_SUPPLIERS_SMALL = 'RECEIVE_SUPPLIERS_SMALL',
  ERROR_RECEIVE_SUPPLIERS_SMALL = 'ERROR_RECEIVE_SUPPLIERS_SMALL',


  REQUEST_UPDATE_JOB = 'REQUEST_UPDATE_JOB',
  RECEIVE_UPDATE_JOB = 'RECEIVE_UPDATE_JOB',
  ERROR_RECEIVE_UPDATE_JOB = 'ERROR_RECEIVE_UPDATE_JOB',
  RESET_CRUD_JOB_STATE = 'RESET_CRUD_JOB_STATE',

  REQUEST_ADD_ORDER = 'REQUEST_ADD_ORDER',
  RECEIVE_ADD_ORDER = 'RECEIVE_ADD_ORDER',
  ERROR_RECEIVE_ADD_ORDER = 'ERROR_RECEIVE_ADD_ORDER',

  REQUEST_SUPPLIERS = 'REQUEST_SUPPLIERS',
  RECEIVE_SUPPLIERS = 'RECEIVE_SUPPLIERS',
  ERROR_RECEIVE_SUPPLIERS = 'ERROR_RECEIVE_SUPPLIERS',


  REQUEST_SUPPLIER_CREDENTIALS = 'REQUEST_SUPPLIER_CREDENTIALS',
  RECEIVE_SUPPLIER_CREDENTIALS = 'RECEIVE_SUPPLIER_CREDENTIALS',
  ERROR_RECEIVE_SUPPLIER_CREDENTIALS = 'ERROR_RECEIVE_SUPPLIER_CREDENTIALS',

  REQUEST_ADD_SUPPLIER_CREDENTIALS = 'REQUEST_ADD_SUPPLIER_CREDENTIALS',
  RECEIVE_ADD_SUPPLIER_CREDENTIALS = 'RECEIVE_ADD_SUPPLIER_CREDENTIALS',
  ERROR_RECEIVE_ADD_SUPPLIER_CREDENTIALS = 'ERROR_RECEIVE_ADD_SUPPLIER_CREDENTIALS',
  RESET_CRUD_SUPPLIER_CREDENTIALS_STATE = 'RESET_CRUD_SUPPLIER_CREDENTIALS_STATE',

  REQUEST_ADD_SUPPLIERS = 'REQUEST_ADD_SUPPLIERS',
  RECEIVE_ADD_SUPPLIERS = 'RECEIVE_ADD_SUPPLIERS',
  ERROR_RECEIVE_ADD_SUPPLIERS = 'ERROR_RECEIVE_ADD_SUPPLIERS',

  REQUEST_ADD_SUPPLIER_CONTACT = 'REQUEST_ADD_SUPPLIERS_CONTACT',
  RECEIVE_ADD_SUPPLIER_CONTACT = 'RECEIVE_ADD_SUPPLIER_CONTACT',
  ERROR_RECEIVE_ADD_SUPPLIER_CONTACT = 'ERROR_RECEIVE_ADD_SUPPLIER_CONTACT',
  RESET_CRUD_SUPPLIERS_STATE = 'RESET_CRUD_SUPPLIERS_STATE',

  REQUEST_ADD_ITEM = 'REQUEST_ADD_ITEM',
  RECEIVE_ADD_ITEM = 'RECEIVE_ADD_ITEM',
  ERROR_RECEIVE_ADD_ITEM = 'ERROR_RECEIVE_ADD_ITEM',

  REQUEST_ACTIVE_ORDERS = 'REQUEST_ACTIVE_ORDERS',
  RECEIVE_ACTIVE_ORDERS = 'RECEIVE_ACTIVE_ORDERS',
  ERROR_RECEIVE_ACTIVE_ORDERS = 'ERROR_RECEIVE_ACTIVE_ORDERS',

  REQUEST_ORDERS = 'REQUEST_ORDERS',
  RECEIVE_ORDERS = 'RECEIVE_ORDERS',
  ERROR_RECEIVE_ORDERS = 'ERROR_RECEIVE_ORDERS',

  REQUEST_VIEWS = 'REQUEST_VIEWS',
  RECEIVE_VIEWS = 'RECEIVE_VIEWS',
  ERROR_RECEIVE_VIEWS = 'ERROR_RECEIVE_VIEWS',

  REQUEST_VIEWS_STATUS = 'REQUEST_VIEWS_STATUS',
  RECEIVE_VIEWS_STATUS = 'RECEIVE_VIEWS_STATUS',
  ERROR_RECEIVE_VIEWS_STATUS = 'ERROR_RECEIVE_VIEWS_STATUS',

  REQUEST_ADD_VIEW = 'REQUEST_ADD_VIEW',
  RECEIVE_ADD_VIEW = 'RECEIVE_ADD_VIEW',
  ERROR_RECEIVE_ADD_VIEW = 'ERROR_RECEIVE_ADD_VIEW',

  REQUEST_UPDATE_VIEW = 'REQUEST_UPDATE_VIEW',
  RECEIVE_UPDATE_VIEW = 'RECEIVE_UPDATE_VIEW',
  ERROR_RECEIVE_UPDATE_VIEW = 'ERROR_RECEIVE_UPDATE_VIEW',

  REQUEST_SAVE_VIEW = 'REQUEST_SAVE_VIEW',
  RECEIVE_SAVE_VIEW = 'RECEIVE_SAVE_VIEW',
  ERROR_RECEIVE_SAVE_VIEW = 'ERROR_RECEIVE_SAVE_VIEW',

  REQUEST_DELETE_VIEW = 'REQUEST_DELETE_VIEW',
  RECEIVE_DELETE_VIEW = 'RECEIVE_DELETE_VIEW',
  ERROR_RECEIVE_DELETE_VIEW = 'ERROR_RECEIVE_DELETE_VIEW',

  REQUEST_UPDATE_VIEW_CELL = 'REQUEST_UPDATE_VIEW_CELL',
  RECEIVE_UPDATE_VIEW_CELL = 'RECEIVE_UPDATE_VIEW_CELL',
  ERROR_RECEIVE_UPDATE_VIEW_CELL = 'ERROR_RECEIVE_UPDATE_VIEW_CELL',

  REQUEST_UNCATEGORIZED_VIEW = 'REQUEST_UNCATEGORIZED_VIEW',
  RECEIVE_UNCATEGORIZED_VIEW = 'RECEIVE_UNCATEGORIZED_VIEW',
  ERROR_RECEIVE_UNCATEGORIZED_VIEW = 'ERROR_RECEIVE_UNCATEGORIZED_VIEW',

  REQUEST_UNCATEGORIZED_VIEW_BOOL = 'REQUEST_UNCATEGORIZED_VIEW_BOOL',
  RECEIVE_UNCATEGORIZED_VIEW_BOOL = 'RECEIVE_UNCATEGORIZED_VIEW_BOOL',
  ERROR_RECEIVE_UNCATEGORIZED_VIEW_BOOL = 'ERROR_RECEIVE_UNCATEGORIZED_VIEW_BOOL',

  REQUEST_ATTACHMENT_DELETE = 'REQUEST_ATTACHMENT_DELETE',
  RECEIVE_ATTACHMENT_DELETE = 'RECEIVE_ATTACHMENT_DELETE',
  ERROR_RECEIVE_ATTACHMENT_DELETE = 'ERROR_RECEIVE_ATTACHMENT_DELETE',

  REQUEST_DELETE_ITEM = 'REQUEST_DELETE_ITEM',
  RECEIVE_DELETE_ITEM = 'RECEIVE_DELETE_ITEM',
  ERROR_RECEIVE_DELETE_ITEM = 'ERROR_RECEIVE_DELETE_ITEM',

  REQUEST_UPDATE_ITEM = 'REQUEST_UPDATE_ITEM',
  RECEIVE_UPDATE_ITEM = 'RECEIVE_UPDATE_ITEM',
  ERROR_RECEIVE_UPDATE_ITEM = 'ERROR_RECEIVE_UPDATE_ITEM',

  REQUEST_ADD_ROOM = 'REQUEST_ADD_ROOM',
  RECEIVE_ADD_ROOM = 'RECEIVE_ADD_ROOM',
  ERROR_RECEIVE_ADD_ROOM = 'ERROR_RECEIVE_ADD_ROOM',

  REQUEST_DELETE_ITEM_REASON = 'REQUEST_DELETE_ITEM_REASON',
  RECEIVE_DELETE_ITEM_REASON = 'RECEIVE_DELETE_ITEM_REASON',
  ERROR_RECEIVE_DELETE_ITEM_REASON = 'ERROR_RECEIVE_DELETE_ITEM_REASON',

  REQUEST_ACTIVE_TEMPLATES = 'REQUEST_ACTIVE_TEMPLATES',
  RECEIVE_ACTIVE_TEMPLATES = 'RECEIVE_ACTIVE_TEMPLATES',
  ERROR_RECEIVE_ACTIVE_TEMPLATES = 'ERROR_RECEIVE_ACTIVE_TEMPLATES',

  REQUEST_ADD_ACTIVE_TEMPLATE = 'REQUEST_ADD_ACTIVE_TEMPLATE',
  RECEIVE_ADD_ACTIVE_TEMPLATE = 'RECEIVE_ADD_ACTIVE_TEMPLATE',
  ERROR_RECEIVE_ADD_ACTIVE_TEMPLATE = 'ERROR_RECEIVE_ADD_ACTIVE_TEMPLATE',

  REQUEST_UPDATE_ACTIVE_TEMPLATE = 'REQUEST_UPDATE_ACTIVE_TEMPLATE',
  RECEIVE_UPDATE_ACTIVE_TEMPLATE = 'RECEIVE_UPDATE_ACTIVE_TEMPLATE',
  ERROR_RECEIVE_UPDATE_ACTIVE_TEMPLATE = 'ERROR_RECEIVE_UPDATE_ACTIVE_TEMPLATE',

  REQUEST_ORDER_DOCUMENT = 'REQUEST_ORDER_DOCUMENT',
  RECEIVE_ORDER_DOCUMENT = 'RECEIVE_ORDER_DOCUMENT',
  ERROR_RECEIVE_ORDER_DOCUMENT = 'ERROR_RECEIVE_ORDER_DOCUMENT',

  REQUEST_ADD_ORDER_DOCUMENT = 'REQUEST_ADD_ORDER_DOCUMENT',
  RECEIVE_ADD_ORDER_DOCUMENT = 'RECEIVE_ADD_ORDER_DOCUMENT',
  ERROR_RECEIVE_ADD_ORDER_DOCUMENT = 'ERROR_RECEIVE_ADD_ORDER_DOCUMENT',

  REQUEST_UPDATE_ORDER_DOCUMENT = 'REQUEST_UPDATE_ORDER_DOCUMENT',
  RECEIVE_UPDATE_ORDER_DOCUMENT = 'RECEIVE_UPDATE_ORDER_DOCUMENT',
  ERROR_RECEIVE_UPDATE_ORDER_DOCUMENT = 'ERROR_RECEIVE_UPDATE_ORDER_DOCUMENT',

  REQUEST_DELETE_ORDER_DOCUMENT = 'REQUEST_DELETE_ORDER_DOCUMENT',
  RECEIVE_DELETE_ORDER_DOCUMENT = 'RECEIVE_DELETE_ORDER_DOCUMENT',
  ERROR_RECEIVE_DELETE_ORDER_DOCUMENT = 'ERROR_RECEIVE_DELETE_ORDER_DOCUMENT',

  REQUEST_SHARE_VIEW = 'REQUEST_SHARE_VIEW',
  RECEIVE_SHARE_VIEW = 'RECEIVE_SHARE_VIEW',
  ERROR_RECEIVE_SHARE_VIEW = 'ERROR_RECEIVE_SHARE_VIEW',

  REQUEST_UPDATE_SHARE_VIEW = 'REQUEST_UPDATE_SHARE_VIEW',
  RECEIVE_UPDATE_SHARE_VIEW = 'RECEIVE_UPDATE_SHARE_VIEW',
  ERROR_RECEIVE_UPDATE_SHARE_VIEW = 'ERROR_RECEIVE_UPDATE_SHARE_VIEW',

  REQUEST_DELETE_SHARE_VIEW = 'REQUEST_DELETE_SHARE_VIEW',
  RECEIVE_DELETE_SHARE_VIEW = 'RECEIVE_DELETE_SHARE_VIEW',
  ERROR_RECEIVE_DELETE_SHARE_VIEW = 'ERROR_RECEIVE_DELETE_SHARE_VIEW',

  REQUEST_UPDATE_SHARE_VIEW_EMAIL = 'REQUEST_UPDATE_SHARE_VIEW_EMAIL',
  RECEIVE_UPDATE_SHARE_VIEW_EMAIL = 'RECEIVE_UPDATE_SHARE_VIEW_EMAIL',
  ERROR_RECEIVE_UPDATE_SHARE_VIEW_EMAIL = 'ERROR_RECEIVE_UPDATE_SHARE_VIEW_EMAIL',

  REQUEST_DELETE_SHARE_VIEW_EMAIL = 'REQUEST_DELETE_SHARE_VIEW_EMAIL',
  RECEIVE_DELETE_SHARE_VIEW_EMAIL = 'RECEIVE_DELETE_SHARE_VIEW_EMAIL',
  ERROR_RECEIVE_DELETE_SHARE_VIEW_EMAIL = 'ERROR_RECEIVE_DELETE_SHARE_VIEW_EMAIL',

  REQUEST_AUTOCOMPLETE_SUPPLIERS = 'REQUEST_AUTOCOMPLETE_SUPPLIERS',
  RECEIVE_AUTOCOMPLETE_SUPPLIERS = 'RECEIVE_AUTOCOMPLETE_SUPPLIERS',
  ERROR_RECEIVE_AUTOCOMPLETE_SUPPLIERS = 'ERROR_RECEIVE_AUTOCOMPLETE_SUPPLIERS',

  REQUEST_AUTOCOMPLETE_RECEIVERS = 'REQUEST_AUTOCOMPLETE_RECEIVERS',
  RECEIVE_AUTOCOMPLETE_RECEIVERS = 'RECEIVE_AUTOCOMPLETE_RECEIVERS',
  ERROR_RECEIVE_AUTOCOMPLETE_RECEIVERS = 'ERROR_RECEIVE_AUTOCOMPLETE_RECEIVERS',

  REQUEST_UPDATE_ORDER = 'REQUEST_UPDATE_ORDER',
  RECEIVE_UPDATE_ORDER = 'RECEIVE_UPDATE_ORDER',
  ERROR_RECEIVE_UPDATE_ORDER = 'ERROR_RECEIVE_UPDATE_ORDER',

  REQUEST_ADD_COMMENT = 'REQUEST_ADD_COMMENT',
  RECEIVE_ADD_COMMENT = 'RECEIVE_ADD_COMMENT',
  ERROR_RECEIVE_ADD_COMMENT = 'ERROR_RECEIVE_ADD_COMMENT',
}
