import { ChangeDetectorRef, Component, OnInit, ViewChild, Input } from '@angular/core';
import { ElementRef, HostListener } from '@angular/core';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { PermissionsService } from 'src/app/root/services/permissions.service';
import { DashboardJobView } from '../dashboard/services/dashboard_job_view.service';
import { DashboardService } from '../dashboard/services/dashboard.service';
import { createAllNotifications, openStatuses } from '../common/utils/general';
import { OrganizationService } from '../company-settings/services/organization.service';

@Component({
  selector: 'materlog-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: [
    './home-page.component.css',
  ]
})
export class HomePageComponent extends OnDestroyMixin() implements OnInit {
    @Input() dropdownOptions: any;
    @Input() allMailData: any;

    openOrders: number = 0;
    openJobs: number = 0;
    openSuppliers: number = 0;

    itemsPoIssued: number = 0;
    itemsSupConfirmed: number = 0;
    itemsInStock: number = 0;
    itemsShipped: number = 0;
    itemsReady: number = 0;
    itemsTracking: number = 0;
    itemsPendingPayment: number = 0;
    itemsDeliveryScheduled: number = 0;
    itemsReadyForPickup: number = 0;
    itemsNotOrdered: number = 0;
    itemsDefault: number = 0;

    invite_user_confirm: boolean = true;
    new_order_confirm: boolean = true;
    update_order_confirm: boolean = true;

    allAlerts: any = [];
    alertsLoading: boolean = true;

    @ViewChild('homePageContainer') homePageContainer!: ElementRef;

    row1Values = [
        {
            name: 'open orders',
            hover_pre: 'This the count of open orders for the jobs you are watching. Click',
            span: 'here',
            hover_post: 'to update the jobs you are watching.',
        },
        {
            name: 'jobs',
            hover_pre: 'This is count of jobs that you are watching, which have open orders. Click',
            span: 'here',
            hover_post: 'to update the jobs you are watching.',
        },
        {
            name: 'suppliers',
            hover_pre: 'This is the supplier count for jobs you are watching. Click ',
            span: 'here',
            hover_post: 'to update the jobs you are watching.',
        }
    ]
    itemStatuses = [
        {name: 'Tracking issues', color: '#FD3D39', statuses: [
            23, // tracking issues
        ]},
        {name: 'Pending payment', color: '#e44263', payment: true, statuses: [
            -1, // Pending payment
        ]},
        /*
        {name: 'Not ordered', color: '#FD3D39', statuses: [
            2, // not ordered
        ]},
        */
        {name: 'PO issued', color: '#D9D9D9', statuses: [
            26 // PO
        ]},
        {name: 'Supplier confirmed', color: '#FF9B05', statuses: [
            3, // Ordered
            16, // In production
            18, // Submittals
            29, // In transit
        ]},
        {name: 'In stock', color: '#FFCB2F', statuses: [
            4, // in stock
        ]},
        {name: 'Shipped', color: '#17E1D2', statuses: [
            5, // shipped
            24, // partially shipped
            15, // partially delivered
        ]},
        {name: 'Ready to be scheduled', color: '#17E1D2', statuses: [
            21, // ready to be scheduled
        ]},
        {name: 'Delivery scheduled', color: '#17E1D2', statuses: [
            22, // delivery scheduled
        ]},
        {name: 'Ready for pick up', color: '#53D86A', statuses: [
            7, // ready for pickup
        ]},
        {name: 'Default', color: '#D9D9D9', statuses: [
            1, // default
        ]},
    ]

    orgConfirms = [
        {
            text: '1. Connect your system to track new orders', confirm: true, option: 0,
        },
        {
            text: '2. Confirm how to share and request updates', confirm: true, option: 1,
        },
        {
            text: '3. Invite your teammates to collaborate faster', confirm: true, option: 2,
        },
    ]
    constructor(
        private djv: DashboardJobView,
        private dashboardService: DashboardService,
        private orgService: OrganizationService
    ) {
        super();
    }

    ngOnInit(): void {
        (window as any)['home'] = this;
        this.generateAlertSkeletons();
        this.getLatestAlerts();
        this.getOrgData();
    }

    ngAfterViewInit() {
        this.setScrollBar('.home-page-container-scroll');
        this.setScrollBar('.home-page-item-status-scroll');
    }

    ngOnChanges(changes: any) {
        if (changes.allMailData) {
            this.setOpenMetaOnOrg();
        }
    }

    get welcomeMessage() {
        let name = this.userName;
        if (name) {
            return `Hello, ${name}  👋`;
        } else {
            return `Welcome  👋`;
        }
    }

    get userName() {
        let profile: any = localStorage.getItem('profile');
        if (!profile) return ''
        profile = JSON.parse(profile);
        if (!profile) return ''
        return profile.first_name;
    }

    setOpenMetaOnOrg() {
        let jids = this.allMailData?.activeJobsList?.map((j: any) => (j.id)) || [];
        let openOrds = this.allMailData?.curOrders?.filter((o: any) => o.items && (!o.job_id || jids.includes(o.job_id)));
        if (!openOrds) {
            return;
        }
        let statuses = openStatuses();
        let found = openOrds.filter((o: any) => o?.items?.find((i: any) => {
            return statuses.includes(i.status);
        }));
        this.openOrders = found.length;

        let jobs = found.filter((o: any) => o.job_id).map((o: any) => o.job_id);
        jobs = new Set(jobs);
        this.openJobs = jobs.size;

        let suppliers = found.filter((o: any) => o.supplier_id).map((o: any) => o.supplier_id);
        suppliers = new Set(suppliers);
        this.openSuppliers = suppliers.size;

        this.setItemStatuses(openOrds, found);
    }

    setItemStatuses(allOrders: any, orders: any) {
        let payment_status = [
            1, // UNPAID = 1, 'Unpaid'
            2, // BLOCKER_DEPOSIT = 2, 'Payment as blocker - deposit'
            3, // PARTIAL_PAYMENT = 3, 'Partial payment'
            4, // BLOCKER_BALANCE = 4, 'Payment as blocker - balance'
        ]
        let istatuses = orders.map((o: any) => o.items).flat().map((i: any) => i.status);
        this.itemStatuses.forEach((s: any) => {
            if (s.payment) {
                let count = allOrders.filter((o: any) => payment_status.includes(o.payment_status));
                count = count.map((o: any) => o.items).flat().length;
                this.countForStatus(s, true, count);
            } else {
                let count = istatuses.filter((number: any) => s.statuses.includes(number)).length;
                this.countForStatus(s, true, count);
            }
        })
    }

    goToPage(row: any) {
        if (this.hoverClicked) return;
        if (row == 'open orders') {
            this.djv.do_home_page_clicked('order');
        } else if (row == 'jobs') {
            this.djv.do_home_page_clicked('job');
        } else if (row == 'suppliers') {
            this.djv.do_home_page_clicked('supplier');
        } else if (row == 'alerts') {
            this.djv.do_home_page_clicked('alerts');
        }
        return;
    }

    goToAlert(alert: any) {
        this.djv.do_alert_clicked(alert.id);
        this.goToPage('alerts');
    }

    numberForRow(row: any) {
        if (row == 'open orders') {
            return this.openOrders || '--';
        } else if (row == 'jobs') {
            return this.openJobs || '--';
        } else if (row == 'suppliers') {
            return this.openSuppliers || '--';
        }
        return '--';
    }

    countForStatus(status: any, setValue: boolean = false, value: number = -1) {
        switch (status.name) {
            case 'PO issued':
                if (setValue) {
                    this.itemsPoIssued = value;
                }
                return this.itemsPoIssued;
            case 'Supplier confirmed':
                if (setValue) {
                    this.itemsSupConfirmed = value;
                }
                return this.itemsSupConfirmed;
            case 'In stock':
                if (setValue) {
                    this.itemsInStock = value;
                }
                return this.itemsInStock;
            case 'Shipped':
                if (setValue) {
                    this.itemsShipped = value;
                }
                return this.itemsShipped;
            case 'Ready to be scheduled':
                if (setValue) {
                    this.itemsReady = value;
                }
                return this.itemsReady;
            case 'Tracking issues':
                if (setValue) {
                    this.itemsTracking = value;
                }
                return this.itemsTracking;
            case 'Pending payment':
                if (setValue) {
                    this.itemsPendingPayment = value;
                }
                return this.itemsPendingPayment;
            case 'Delivery scheduled':
                if (setValue) {
                    this.itemsDeliveryScheduled = value;
                }
                return this.itemsDeliveryScheduled;
            case 'Ready for pick up':
                if (setValue) {
                    this.itemsReadyForPickup = value;
                }
                return this.itemsReadyForPickup;
            case 'Not ordered':
                if (setValue) {
                    this.itemsNotOrdered = value;
                }
                return this.itemsNotOrdered;
            case 'Default':
                if (setValue) {
                    this.itemsDefault = value;
                }
                return this.itemsDefault;
            default:
                return 0;
        }
    }

    setScrollBar(v: string) {
        setTimeout(() => {
          let jdisplays = this.homePageContainer.nativeElement.querySelectorAll(v);
          if (jdisplays) {
            jdisplays.forEach((jdisplay: any) => {
              jdisplay.addEventListener('mouseenter', function() {
                (jdisplay as any).classList.add('custom-scrollbar');
                (jdisplay as any).classList.remove('custom-scrollbar-close');
                setTimeout(() => {
                  (jdisplay as any).classList.remove('custom-scrollbar');
                  (jdisplay as any).classList.add('custom-scrollbar-close');
                }, 1500)
              });
              jdisplay.addEventListener('wheel', function() {
                (jdisplay as any).classList.add('custom-scrollbar');
                (jdisplay as any).classList.remove('custom-scrollbar-close');
                setTimeout(() => {
                  (jdisplay as any).classList.remove('custom-scrollbar');
                  (jdisplay as any).classList.add('custom-scrollbar-close');
                }, 1500)
              });
              jdisplay.addEventListener('mouseleave', function() {
                (jdisplay as any).classList.remove('custom-scrollbar');
                (jdisplay as any).classList.add('custom-scrollbar-close');
              });
            })
          }
        }, 500);
    }

    get dropdownRetrieved() {
        return this.dropdownOptions.document_types  &&
        this.dropdownOptions.job_id  &&
        this.dropdownOptions.status  &&
        this.dropdownOptions.status_colors  &&
        this.dropdownOptions.supplier_id  &&
        this.dropdownOptions.unit_of_measure &&
        this.allMailData?.curOrders &&
        this.allMailData?.supArray;
    }

    formatAlerts(alerts: any) {
        let outputs: any = [];
        alerts = alerts.filter((a: any) => !a.seen);
        alerts.forEach((alert: any) => {
            let text = alert?.text ? alert.text.split(':')[0] : undefined;
            let order_name = alert?.order_name?.trim();
            let job_name = alert?.job_name?.trim();
            let supplier_name = alert?.supplier_name?.trim();
            let values = [order_name, job_name, supplier_name];
            values = values.filter((v: any) => v);
            let cur: any = {
                id: alert.id,
                text: text,
                info: values.join(' · '),
                emoji: alert.emoji_icon,
            }
            outputs.push(cur);
        })
        this.alertsLoading = false;
        this.allAlerts = outputs.slice(0, 6);
    }

    createAlerts(alerts: any) {
        let outer = this;
        var pollInterval = setInterval(function() {
            if (outer.dropdownRetrieved) {
                clearInterval(pollInterval);
                alerts.sort((a:any, b:any) => new Date(b.created_datetime).getTime() - new Date(a.created_datetime).getTime());
                alerts = createAllNotifications(alerts, outer.dropdownOptions, outer.allMailData);
                outer.formatAlerts(alerts);
            }
          }, 20);
    }

    getLatestAlerts() {
        this.dashboardService.getNotifications().subscribe((result: any) => {
            this.createAlerts(result);
        })
    }

    alertSkeletons: any[] = [];
    generateAlertSkeletons() {
        for (let i = 0; i < 4; i++) {
            const randomWidth = Math.floor(Math.random() * 20) + 20;
            this.alertSkeletons.push({ width: `${randomWidth}rem` });
        }
    }

    getOrgData() {
        this.orgService.getOrganization().subscribe((result: any) => {
          this.new_order_confirm = result?.result?.new_order_confirm;
          this.update_order_confirm = result?.result?.update_order_confirm;
          this.invite_user_confirm = result?.result?.invite_user_confirm;
          this.orgConfirms[0].confirm = this.new_order_confirm;
          this.orgConfirms[1].confirm = this.update_order_confirm;
          this.orgConfirms[2].confirm = this.invite_user_confirm;
        })
    }

    get orgNeedsConfirm() {
        return !this.invite_user_confirm || !this.new_order_confirm || !this.update_order_confirm
    }

    get createMaxHeight() {
        let subtract = this.orgNeedsConfirm ? '240px': '70px'
        return `calc(100vh - ${subtract})`
    }
    
    get createMarginTop() {
        return this.orgNeedsConfirm ? 44: 88;
    }

    onOrgConfirm(oc: any) {
        if (oc.option == 0) {
            this.djv.do_workflow_clicked(2);
            this.djv.do_home_page_clicked('settings');
        } else if (oc.option == 1) {
            this.djv.do_workflow_clicked(3);
            this.djv.do_home_page_clicked('settings');
        } else if (oc.option == 2) {
            let data = {invite_user_confirm: true,}
            this.orgService.updateOrganization({payload: data}).subscribe(() => {});
            this.djv.do_home_page_clicked('settings');
            this.djv.do_side_panel_notifs();
        }
    }

    clearAllAlerts() {
        this.allAlerts = [];
        this.dashboardService.updateAlertAllSeen().subscribe();
    }

    onRowClick(event: any, name: any) {
        event.stopPropagation();
        this.goToPage('jobs');
    }

    hoverClicked: boolean = false;
    onHoverClick() {
        this.hoverClicked = true;
        setTimeout(() => {
            this.hoverClicked = false;
        }, 10);
    }

    statusValueClicked(status: any) {
        this.djv.do_status_clicked(status.statuses);
    }
}