<div class="node" [id]="data.id"
    [ngClass]="{
        'node': !sidebar,
        'sidebar-node': sidebar,
        'selected-node': data.id == selected,
    }"
    [ngStyle]="{
        'width': data.node_width ? data.node_width: '200px',
    }"
>
    <div class="node-header"
        [ngStyle]="{'gap': sidebar ? '12px': '8px'}"
        >
        <img *ngIf="data.icon == 'meti'" src="/assets/img/meti_logo_small.svg"
            alt="logo" class="meti-logo">
        <i class="meti-logo bi"
            *ngIf="data.icon && data.icon != 'meti'"
            [ngClass]="[data.icon]"
            [ngStyle]="{
                'color': data.icon_color ? data.icon_color: '',
                'font-size': data.icon_size ? data.icon_size: '',
            }"
        ></i>
        <div *ngIf="!data.icon" class="meti-logo"></div>
        <div class="node-text-main" *ngIf="!data.multi_texts || sidebar"
            [ngStyle]="{
                'color': textColor
            }">
            <span *ngIf="preText" class="node-text-main dashed-underline"
                [ngStyle]="{
                    'color': textColor
                }">
                {{preText}}
            </span>
            {{mainText}}
            <span *ngIf="postText" class="node-text-main dashed-underline post-text-main"
                [ngStyle]="{
                    'color': textColor
                }">
                {{postText}}
                <materlog-tooltip class="tooltip-container" *ngIf="data.post_text_tooltip"
                    [text]="data.post_text_tooltip"
                    [width]="data.post_text_tooltip_width"
                    [height]="data.post_text_tooltip_height"

                ></materlog-tooltip>
            </span>
        </div>
        <div class="flow-main-texts" *ngIf="data.multi_texts && !sidebar">
            <div *ngFor="let text of data.multi_texts" class="flow-main-text-one" (click)="goToPage(text.where)"
                [ngClass]="{
                    'flow-main-text-underline': text.underline,
                    'flow-main-text-click': text.where
                }"
                [ngStyle]="{
                    'color': text.color ? text.color: '#000',
                    'font-size': text.font_size ? text.font_size: '12px',
                    'font-weight': text.font_weight ? text.font_weight: '400',
                }"
            >
                {{text.text}}
                <div *ngIf="text.break">
                    <br>
                </div>
            </div>
        </div>
    </div>
    <div class="node-body" *ngIf="data?.explain">
        {{data.explain}}
    </div>
</div>