import { AfterViewInit, Component, Injector, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';

import { FormControl, FormGroup, Validators } from '@angular/forms';

import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { CommonConstants } from '../../../common/constants/common.constants';
import { IApplicationState } from 'src/app/common/state/models/app.state.model';
import { AuthenticationActions } from '../../state/actions/authentication.actions';
import { IStoreApiItem } from 'src/app/common/models/store-api-item.model';
import { IRecoverPassword } from '../../models/authentication.model';
import { selectRecoverPassword } from '../../state/selectors/authentication.selector';
import { PermissionsService } from 'src/app/root/services/permissions.service';

@Component({
  selector: 'materlog-recover-password',
  templateUrl: './recover-password.component.html',
})
export class RecoverPasswordComponent extends OnDestroyMixin() implements OnInit, AfterViewInit {
  permissionService: PermissionsService;
  isSubmitted: boolean = false;
  formSubmitted: boolean = false;
  errorMessage: string | undefined = '';
  recoverPasswordForm: FormGroup = new FormGroup({
    passwordRecover: new FormControl(null, [Validators.required, Validators.pattern(CommonConstants.passwordRegex)]),
    passwordConfirmation: new FormControl(null, [Validators.required, Validators.pattern(CommonConstants.passwordRegex)]),
  });
  userInfo: any;

  constructor(
    public router: Router,
    private injector: Injector,
    private store: Store<IApplicationState>,
    private authenticationActions: AuthenticationActions,
  ) {
    super();
    this.permissionService = this.injector.get<PermissionsService>(PermissionsService);
  }

  ngOnInit(): void {
    if (this.permissionService.isLoggedIn()) {
      this.router.navigate(['']);
    }
    this.recoverPasswordForm.valueChanges.subscribe(() => {
      this.errorMessage = undefined;
    });
  }

  ngAfterViewInit(): void {
    if (this.router.url.split('/').length === 4) {
      const routerSegments: string[] = this.router.url.split('/');
      const token: string = routerSegments[routerSegments.length - 1];
      const uid: string = routerSegments[routerSegments.length - 2];
      const object: any = { token, uid };

      localStorage.setItem('user', JSON.stringify(object));
      this.router.navigate(['recover-password']);
    }
  }

  onSubmit() {
    this.recoverPasswordForm.markAllAsTouched();
    this.isSubmitted = true;

    if (this.recoverPasswordForm.valid && this.passwordMatch) {
      this.handleRecoverPassword();
    }
  }

  get passwordMatch(): boolean {
    return this.recoverPasswordForm.value.passwordRecover === this.recoverPasswordForm.value.passwordConfirmation;
  }

  handleRecoverPassword(): void {
    const user: any = localStorage.getItem('user');
    const objToUpdate: IRecoverPassword = {
      password: this.recoverPasswordForm.value.passwordRecover,
      token: JSON.parse(user)?.token.split('?')[0],
      uid: JSON.parse(user)?.uid
    };

    if (objToUpdate.token && objToUpdate.uid) {
      this.authenticationActions.requestRecoverPassword(objToUpdate);

      this.store.pipe(
        select(selectRecoverPassword),
        filter((login: IStoreApiItem<IRecoverPassword>) => !login.isLoading),
        take(1)
      ).subscribe((recoverPassword: IStoreApiItem<IRecoverPassword>) => {
        this.errorMessage = undefined;

        if (recoverPassword.isSuccess) {
          this.formSubmitted = true;
          localStorage.clear();
        } else {
          this.errorMessage = 'Something went wrong. Please try again later. If this issue persists, contact support@getmeti.com.';
          this.recoverPasswordForm.reset();
        }
      });
    } else {
      this.recoverPasswordForm.reset();
      this.errorMessage = 'Inaccurate password reset link. Please access the link from the email again.';
    }
  }

  onNavigateToSignIn() {
    this.router.navigate(['login']);
  }

  onNavigateHome() {
    if (this.permissionService.isLoggedIn()) {
      this.router.navigate(['']);
    } else {
      this.router.navigate(['login'])
    }
  }
}
