<svg class="arrows">
    <defs>
        <marker
        id="arrowhead"
        markerWidth="5"
        markerHeight="5"
        refX="2.5"
        refY="2.5"
        orient="auto"
        >
        <polygon points="0 0, 5 2.5, 0 5" fill="#828282" />
      </marker>
      
    </defs>
    <path *ngFor="let i of arrowArray" [id]="'arrow' + i"
    [ngClass]="{
        'arrow': !useLines || (useLines && i <= dashedCount),
        'no-arrow': useLines && i > dashedCount
    }"
    >
    </path>
</svg>