import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { EventEmitter, Output, ChangeDetectorRef} from '@angular/core';
import { ElementRef, HostListener } from '@angular/core';
import { OrderService } from '../../services/order.service';
import { generateRandomString } from 'src/app/common/utils/general';
import { isValidUUID } from 'src/app/common/utils/general';

@Component({
    selector: 'materlog-item-auto-upload',
    templateUrl: './item-auto-upload.component.html',
    styleUrls: ['./item-auto-upload.component.css'],
  })
export class ItemAutoUploadComponent implements OnInit {

    @Input() allMailData: any;
    @Input() dropdownOptions?: any;
    @Input() msgData: any = undefined;

    filterText: any = '';

    @ViewChild('materlogItemScroll') materlogItemScroll!: ElementRef;

    itemChosen: boolean = false;
    items: any = [];
    openWidth: number = 130;
    dropWidth: number = 170;

    forceChanger: any = '';
    createdOptions: boolean = false;
    roomOptions: any = [];

    statusField = {name: 'Status', field: 'status', type: 'dropdown', diff: true,
                  options: 'statuses'};

    fields: any = [
        {name: 'Description', field: 'description', type: 'text', vgrowth: true, minHeight: '32px'},
        {name: 'Unit price', field: 'cost_per_unit', type: 'number', cost: true, diff: true,},
        {name: 'Quantity', field: 'quantity', type: 'number', diff: true,},
        {name: 'Unit of Measure', field: 'unit_of_measure', type: 'dropdown', diff: true,
                options: 'unit_of_measure'},
        {name: 'SKU', field: 'sku', type: 'text', diff: true},
        // {name: 'Spec', field: 'spec', type: 'text'},
        {name: 'Room', field: 'room_id', type: 'dropdown', options: 'room_id',
                allow_null: true, diff: true, org_options: true},
        // {name: 'Category', field: 'category', type: 'dropdown', options: 'categories'},
        // {name: 'URL', field: 'link_to_material', type: 'text'},
        {name: 'In stock', field: 'in_stock_date', type: 'date', update: true, diff: true},
        {name: 'Ship', field: 'ship_date', type: 'date', update: true, diff: true},
        {name: 'Delivery', field: 'delivery_date', type: 'date', update: true, diff: true},
        {name: 'Shipper tracking url', field: 'shipment_tracking', type: 'link', update: true},
        {name: 'Notes', field: 'notes', type: 'text', vgrowth: true},
    ]

  constructor(
    private cdr: ChangeDetectorRef,
    private orderService: OrderService
  ) {
  }

  ngOnChanges(changes: any) {
    if (!this.createdOptions && this.hasOptionData) {
      this.createdOptions = true;
      this.createRoomOptions();
    }
  }

  ngOnInit() {
    (window as any)['mitem'] = this;
    this.createItemsArray();
  }

  get hasOptionData() {
    return this.msgData?.order && this.dropdownOptions?.room_id
  }

  createRoomOptions() {
      let roomOptions: any = [];
      let extra_rooms = this.msgData?.shadow?.option_extras?.filter((o: any) => o.room_id)
      if (!extra_rooms) {
          roomOptions = this.dropdownOptions?.room_id;
      } else {
        extra_rooms = extra_rooms.map((j: any) => ({id: j.room_id, value: j.room_name}));
        let allRooms = this.dropdownOptions?.room_id || [];
        roomOptions = extra_rooms.concat(allRooms)
      }
      let job_id = this.msgData?.order?.job_id;
      this.roomOptions = roomOptions.filter(
        (r: any) => (job_id && r.job == job_id) || !isValidUUID(r.id));
  }

  get itemImgUrl() {
    return ''
    // return `${this.chromeURL}browser/icons/item-view.png`;
  }

  optionsForField(item: any, field: any) {
    let fieldId = field.field;
    if (fieldId == 'room_id') {
      return this.roomOptions;
    } else if (fieldId == 'unit_of_measure') {
      return this.dropdownOptions?.unit_of_measure;
    } 
  }

  selectedForField(item: any, field: any) {
    let output = item[field.field];
    if (field.field == 'unit_of_measure' && output == 0) {
      return undefined;
    }
    return output;
  } 

  canCreateNewDropdown(field: any) {
    let allowed = ['room_id'];
    return allowed.includes(field.field);
  }

  getCreateNewText(field: any) {
    if (field.field == 'room_id') {
      return 'create new room';
    }
    return '';
  }

  openItem(item: any) {
    item.isOpen = !item.isOpen;
  }


  onShippingLinkChange(event: any, item: any, field: any) {
    let links = event.data.map((l: any) => (l.value))
    links = [...new Set(links.filter((l: any) => l))];
    item[event.field] = links;
    let payload = {field: event.field, value: links}
    this.writeConfirmValue(payload, item);
  }


  onStatusChange(event: any, item: any) {
    let nevent = {field: 'status', value: event.new};
    item.status = event.value;
    this.writeConfirmValue(nevent, item);
  }


  writeConfirmValue(event: any, item: any) {
    let payload = {[event.field]: event.value}
    this.orderService.updateItemExtract(item.id, payload).subscribe(() => {});
    let found = this.msgData?.order?.item_fields?.find((i: any) => i.id == item.id);
    if (found) {
      found[event.field] = event.value;
    }
  }

  onTextChange(event: any, item: any) {
    item[event.field] = event.value;
    this.writeConfirmValue(event, item);
  }

  onDateChange(event: any, item: any) {
    let nevent = {field: event.field, value: event.data || null}
    item[event.field] = event.data;
    this.writeConfirmValue(nevent, item);
    this.forceChanger = !this.forceChanger
  }

  onImageChange(event: any, item: any) {
  }

  checkOptionExtra(event: any, item: any, field: any) {
    let value = event.id
    if (isValidUUID(value)) {
        return false;
    }
    let newEvent = {chosen: event}
    let newField = {field: field}
    this.onOptionsChange(newEvent, item, newField);
    return true;
}

  onDropdownChange(event: any, item: any, field: any) {
    if (this.checkOptionExtra(event, item, field)) {
      return;
    }
    item[field] = event.id;
    let payload = {field: field, value: event.id};
    this.writeConfirmValue(payload, item);
    let ishadow = this.msgData?.shadow?.items;
    let sItem = ishadow?.find((i: any) => i.id == item.id);
    if (sItem && sItem[field]) {
        this.msgData.shadow.items = this.msgData.shadow.items.filter((i: any) => i.id != item.id);
        let payload = { msg_id: this.msgData.id, data: this.msgData?.shadow }
        this.orderService.updateOrderMsg(payload).subscribe((result: any) => {
        })
    }
  }


  onOptionsChange(event: any, item: any, field: any) {
    let newopt: any = {
      id: event.chosen.id,
      value: event.chosen.value,
    }

    let field_name = 'room_name';
    let chosen_name = event?.chosen?.value;

    if (!chosen_name) return;

    let chosen_id = event?.chosen?.id;
    let data = {[field_name]: chosen_name};
    
    let shadow = this.msgData.shadow || {};
    if (!shadow.option_extras) {
        shadow.option_extras = [];
    }
    if (!shadow.items) {
      shadow.items = [];
    }

    item[field.field] = chosen_id;
    let foundItem = this.msgData?.order?.item_fields?.find((i: any) => i.id == item.id);
    if (foundItem) {
      foundItem[field.field] = null;
    }

    shadow.option_extras = shadow.option_extras.filter((o: any) => o[field.field] != chosen_id)
    shadow.option_extras.push({
        [field_name]: chosen_name,
        [field.field]: chosen_id
    });
    let found = shadow.items.find((i: any) => i.id == item.id);
    if (found) {
      found[field.field] = chosen_id
    } else {
      shadow.items.push({id: item.id, [field.field]: chosen_id})
    }

    let payload = { msg_id: this.msgData.id, data: shadow, item: item.id, key: field.field }
    this.orderService.updateOrderMsg(payload).subscribe((result: any) => {
        this.createRoomOptions();
    })

  }

  dropClick(event: any) {
    event.stopPropagation();
  }


  createItemsArray(newItem: any = null) {
    let itemFields = this.msgData?.order?.item_fields || [];
    let shadow = this.msgData?.shadow?.items || [];
    let iout: any = [];

    itemFields.forEach((i: any) => {
      let cur = {...i};
      let found = shadow?.find((sitem: any) => sitem.id == i.id);
      if (found) {
        let scur = {...found}
        delete scur.id;
        cur = {...cur, ...scur};
      }
      iout.push(cur);
    });
    let iids = iout.map((i: any) => (i.id));

    iout.forEach((i: any) => {
      i.isOpen = false;
      if (!i.status) i.status = 1;
    })
    this.sortItems(iout);
    if (newItem) {
      iout.push(newItem);
    }
    this.items = iout;
  }

  sortItems(items: any) {
    if (!items || items.length == 1) return;
    items.sort((a: any, b: any) => {
      const nameA = a.name || '';
      const nameB = b.name || '';
      if (!nameA && nameB) return 1;
      if (nameA && !nameB) return -1;
      if (!nameA && !nameB) return 0;
      const nameComparison = nameA.toLowerCase().localeCompare(nameB.toLowerCase());
      if (nameComparison !== 0) {
          return nameComparison;
      }
      if (a.has_subitems === true && b.has_subitems === false) return -1;
      if (a.has_subitems === false && b.has_subitems === true) return 1;
      return 0;
    });
  }


  deleteItem(item: any) {
    let items = this.msgData?.order?.item_fields;
    items = items.filter((i: any) => i.id != item.id);
    this.msgData.order.item_fields = items;
    this.items = this.items.filter((i: any) => i != item);
    this.orderService.deleteItemExtract(item.id).subscribe(() => {});
  }

  createNewItem() {
    let rid = generateRandomString();

    let data: any = {
      id: rid,
      name: 'New Item',
      status: 1,
      unit_of_measure: 0,
      category: 0,
    };
    this.fields.forEach((f: any) => {
      if (f.type == 'text') {
        data[f.field] = '';
      } else if (f.type == 'number') {
        data[f.field] = 0;
      } else if (f.type == 'link') {
        data[f.field] = [];
      }
    });
    if (!this.msgData?.order?.item_fields?.length) {
      this.msgData.order.item_fields = [];
    }
    this.createItemsArray(data);
    let payload = {order_id: this.msgData.order.id, data: data}
    this.orderService.createItemExtract(payload).subscribe((result: any) => {
      if (result?.id) {
        let found = this.items.find((i: any) => i.id == rid);
        if (found) {
          found.id = result.id;
          data.id = result.id;
          this.msgData.order.item_fields.push(data);
        }
      }
    })
  }

  itemForID(item: any) {
    if (!item.id) return null;
    return this.msgData?.order?.item_fields?.find(
        (i: any) => i.id == item.id)
  }


  hasDescription(item: any, field: any) {
    return field.field == 'description' && item.description;
  }

  get filteredItems() {
    if (!this.filterText) {
      return this.items;
    }
    const lowerFilterText = this.filterText.toLowerCase();
    let fields = ['description', 'sku', 'name'];
    return this.items.filter((i: any) => {
      return fields.some((f: any) => i[f] && i[f].toLowerCase().includes(lowerFilterText));
    });
  }
}
