import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { environment } from './../../../environments/environment';
import { HttpHelper } from './../../common/services/http-helper/http-helper';
import { IActiveOrderList, INewItem, IOrders, INewOrder } from './../models/dashboard.model';
import { HttpHeaderResponse, HttpHeaders } from '@angular/common/http';
import {decodeMsgPackSimple, decodeMsgPack, unPackOrderItems} from 'src/app/dashboard/services/do_msgpack'
import { map } from 'rxjs/operators';
import { MessageService } from 'primeng/api';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  private baseUrl: string = environment.ENV.BASE_URL;
  private httpOptionsMsgPack = {
    headers: new HttpHeaders({
      'Accept': 'application/x-msgpack',
    }),
    responseType: 'arraybuffer' as any
  };
  constructor(
    private httpClient: HttpClient,
    private httpHelper: HttpHelper,
    private messageService: MessageService
    ) { }

  public addOrder(newOrder: INewOrder | any): Observable<INewOrder | HttpErrorResponse> {
    return this.httpClient.post<INewOrder>(`${this.baseUrl}orders/`, newOrder.payload, this.httpHelper.options);
  }

  public sendOrderSupplierCommunication(info: any): Observable<any | HttpErrorResponse> {
    return this.httpClient.post<any>(`${this.baseUrl}suppliers/emails/`, info);
  }


  public addItem(newItem: INewItem | any): Observable<INewItem | HttpErrorResponse> {
    return this.httpClient.post<INewItem>(`${this.baseUrl}orders/items/`, newItem.payload, this.httpHelper.options);
  }

  public itemToNewOrder(newItem: INewItem | any): Observable<INewItem | HttpErrorResponse> {
    return this.httpClient.post<INewItem>(`${this.baseUrl}orders/items/neworder/`, newItem.payload, this.httpHelper.options);
  }

  public addRoom(newRoom: any): Observable<any | HttpErrorResponse> {
    return this.httpClient.post<any>(`${this.baseUrl}orders/items/rooms/`, newRoom.payload, this.httpHelper.options);
  }

  public getActiveOrders(param?: any): Observable<IActiveOrderList | HttpErrorResponse> {
    return this.httpClient.get<IActiveOrderList>(`${this.baseUrl}orders/active/`, this.httpHelper.options);
  }

  public getOrders(param?: any): Observable<IOrders | HttpErrorResponse> {
    return this.httpClient.get<IOrders>(`${this.baseUrl}orders/${param.payload}/`, this.httpHelper.options);
  }
  public getOrderItems(param?: any): Observable<INewItem | HttpErrorResponse> {
    return this.httpClient.get<INewItem>(`${this.baseUrl}orders/items/${param.payload.id}/`, this.httpHelper.options);
  }

  public getOpenOrders(param?: any): Observable<INewItem | HttpErrorResponse> {
    return this.httpClient.get<INewItem>(`${this.baseUrl}orders/open/${param.id}/?supplier=`+param.id, this.httpHelper.options);
  }

  public deleteItem(itemId: any): Observable<any | HttpErrorResponse> {
    return this.httpClient.delete<any>(`${this.baseUrl}orders/items/${itemId.payload}`, this.httpHelper.options);
  }

  public updateItem(updatedItem: any): Observable<INewItem | HttpErrorResponse> {
    return this.httpClient.put<INewItem>(`${this.baseUrl}orders/items/${updatedItem.payload.id}/`, updatedItem.payload, this.httpHelper.options);
  }

  public deleteItemReason(param: any): Observable<any | HttpErrorResponse> {
    const payload = JSON.parse(JSON.stringify(param.payload));

    delete payload.id;

    return this.httpClient.put(`${this.baseUrl}orders/items/${param.payload.id}/`, payload, this.httpHelper.options);
  }

  public updateOrder(param: any): Observable<any | HttpErrorResponse> {
    return this.httpClient.put(`${this.baseUrl}orders/${param.payload.id}/`, param.payload, this.httpHelper.options)
  }

  public smallOrderUpdate(param: any): Observable<any | HttpErrorResponse> {
    return this.httpClient.put(`${this.baseUrl}orders/orderupdate/${param.payload.id}/`, param.payload, this.httpHelper.options)
  }

  public writeOrderItem(param: any): Observable<any | HttpErrorResponse> {
    return this.httpClient.put(`${this.baseUrl}orders/both/`, param.payload, this.httpHelper.options)
  }


  public splitItem(payload: any): Observable<INewItem | HttpErrorResponse> {
    return this.httpClient.post<INewItem>(`${this.baseUrl}orders/split/`, payload,
      this.httpHelper.options);
  }

  public deleteItemSplit(payload: any): Observable<INewItem | HttpErrorResponse> {
    return this.httpClient.post<INewItem>(`${this.baseUrl}orders/split/`, payload,
      this.httpHelper.options);
  }

  public showSubItem(payload: any): Observable<INewItem | HttpErrorResponse> {
    return this.httpClient.get<INewItem>(`${this.baseUrl}orders/split/${payload.id}`, this.httpHelper.options);
  }
  
  public updateSubItem(payload: any): Observable<any | HttpErrorResponse> {
    return this.httpClient.put(`${this.baseUrl}orders/split/${payload.id}/`, payload.data, this.httpHelper.options)
  }

  public showAllSubItems(payload: any): Observable<any | HttpErrorResponse> {
    return this.httpClient.get<any>(`${this.baseUrl}orders/split/`,
    {
      ...this.httpHelper.options,
      params: {...payload}
    });
  }

  public getItemUpdates(): Observable<INewItem | HttpErrorResponse> {
    return this.httpClient.get<INewItem>(`${this.baseUrl}orders/updates/`, this.httpOptionsMsgPack)
    .pipe(map((response: any) => {
      return decodeMsgPackSimple(response);
      })
    );
  }

  public getFullOrder(payload: any): Observable<INewItem | HttpErrorResponse> {
    return this.httpClient.get<INewItem>(`${this.baseUrl}orders/updates/order`, {
      ...this.httpOptionsMsgPack,
      params: {...payload}
    })
    .pipe(map((response: any) => {
      return decodeMsgPackSimple(response);
      })
    );
  }

  public getOrderHistory(payload: any): Observable<INewItem | HttpErrorResponse> {
    return this.httpClient.get<INewItem>(`${this.baseUrl}orders/updates/history`, {
      ...this.httpOptionsMsgPack,
      params: {...payload}
    })
    .pipe(map((response: any) => {
      return decodeMsgPackSimple(response);
      })
    );
  }

  public getOrderSubmittals(payload: any): Observable<INewItem | HttpErrorResponse> {
    return this.httpClient.get<INewItem>(`${this.baseUrl}orders/submittals`, {
      ...this.httpOptionsMsgPack,
      params: {...payload}
    })
    .pipe(map((response: any) => {
      return decodeMsgPackSimple(response);
      })
    );
  }

  public getOrderSubmittalsHistory(payload: any): Observable<INewItem | HttpErrorResponse> {
    return this.httpClient.get<INewItem>(`${this.baseUrl}orders/submittals/history`, {
      ...this.httpOptionsMsgPack,
      params: {...payload}
    })
    .pipe(map((response: any) => {
      return decodeMsgPackSimple(response);
      })
    );
  }

  public getSubmittalsOptions(): Observable<INewItem | HttpErrorResponse> {
    return this.httpClient.get<INewItem>(`${this.baseUrl}orders/submittals/options`, {
      ...this.httpOptionsMsgPack,
    })
    .pipe(map((response: any) => {
      return decodeMsgPackSimple(response);
      })
    );
  }

  public addSubmittalType(payload: any): Observable<any | HttpErrorResponse> {
    return this.httpClient.post<INewOrder>(`${this.baseUrl}orders/submittals/type/`,
            payload, this.httpOptionsMsgPack)
            .pipe(map((response: any) => {
              return decodeMsgPackSimple(response);
              })
            );
  }

  public addSubmittalValue(payload: any): Observable<any | HttpErrorResponse> {
    return this.httpClient.post<INewOrder>(`${this.baseUrl}orders/submittals/`,
            payload, this.httpOptionsMsgPack)
            .pipe(map((response: any) => {
              return decodeMsgPackSimple(response);
              })
            );
  }

  public updateSubmittalValue(payload: any): Observable<any | HttpErrorResponse> {
    return this.httpClient.put<INewOrder>(`${this.baseUrl}orders/submittals/${payload.id}/`,
            payload.payload, this.httpOptionsMsgPack)
            .pipe(map((response: any) => {
              return decodeMsgPackSimple(response);
              })
            );
  }

  public deleteSubmittalValue(sub_id: any): Observable<any | HttpErrorResponse> {
    return this.httpClient.delete<INewOrder>(`${this.baseUrl}orders/submittals/${sub_id}/`,
            this.httpOptionsMsgPack)
            .pipe(map((response: any) => {
              return decodeMsgPackSimple(response);
              })
            );
  }

  public addSubmittalAttachments(payload: any): Observable<any | HttpErrorResponse> {
    return this.httpClient.post<INewOrder>(`${this.baseUrl}orders/submittals/attachment/`,
            payload, this.httpOptionsMsgPack)
            .pipe(map((response: any) => {
              return decodeMsgPackSimple(response);
              })
            );
  }

  public deleteSubmittalAttachment(payload: any): Observable<any | HttpErrorResponse> {
    return this.httpClient.get<INewOrder>(`${this.baseUrl}orders/submittals/delete_attachment`, {
            ...this.httpOptionsMsgPack,
            params: {...payload}
          }).pipe(map((response: any) => {
              return decodeMsgPackSimple(response);
              })
            );
  }

  public massUpdateSubmittals(payload: any): Observable<any | HttpErrorResponse> {
    return this.httpClient.post<INewOrder>(`${this.baseUrl}orders/submittals/mass/`,
            payload, this.httpOptionsMsgPack)
            .pipe(map((response: any) => {
              return decodeMsgPackSimple(response);
              })
            );
  }

  public deleteSubmittalType(payload: any): Observable<any | HttpErrorResponse> {
    return this.httpClient.get<INewOrder>(`${this.baseUrl}orders/submittals/delete_type`, {
            ...this.httpOptionsMsgPack,
            params: {...payload}
          }).pipe(map((response: any) => {
              return decodeMsgPackSimple(response);
              })
            );
  }

  public updateSubmittalTypeName(payload: any): Observable<any | HttpErrorResponse> {
    return this.httpClient.put<INewOrder>(`${this.baseUrl}orders/submittals/edit_type/`,
            payload, this.httpOptionsMsgPack)
            .pipe(map((response: any) => {
              return decodeMsgPackSimple(response);
              })
            );
  }

  public getJobSubmittals(payload: any): Observable<any | HttpErrorResponse> {
    return this.httpClient.get<INewOrder>(`${this.baseUrl}orders/submittals/all_submittals`, {
            ...this.httpOptionsMsgPack,
            params: {...payload}
          }).pipe(map((response: any) => {
              return decodeMsgPackSimple(response);
              })
            );
  }

  public changeItemDependencies(payload: any): Observable<any | HttpErrorResponse> {
    return this.httpClient.post<INewOrder>(`${this.baseUrl}orders/dependencies/edit/`,
            payload,
            this.httpHelper.options)
            .pipe(
              catchError((error: HttpErrorResponse) => {
                let errorMessage = 'Something went wrong.';
                
                if (error.error.detail === 'Cannot create a circular dependency.') {
                  errorMessage = 'Cannot create a circular dependency.';
                }

                this.messageService.clear();
                this.messageService.add({
                  severity: 'error',
                  summary: 'Error',
                  detail: errorMessage,
                  key: 'error',
                });

                return of('ERROR');
              })
          );
  }

  public uploadAutoParseDoc(payload: any): Observable<any | HttpErrorResponse> {
    return this.httpClient.post<INewOrder>(`${this.baseUrl}orders/autoparse/attachment/`,
            payload,
            {
              headers: new HttpHeaders({})
            });
  }

  public getAllAutoUploads(): Observable<any | HttpErrorResponse> {
    return this.httpClient.get<IActiveOrderList>(`${this.baseUrl}orders/autoparse/`,
    {
      ...this.httpHelper.options
    });
  }


  public deleteOrder(payload: any): Observable<any | HttpErrorResponse> {
    return this.httpClient.get<IActiveOrderList>(`${this.baseUrl}orders/remove/`,
    {
      params: {...payload},
      ...this.httpHelper.options
    });
  }

  public multiItemOrderChange(payload: any): Observable<any | HttpErrorResponse> {
    return this.httpClient.post<INewOrder>(`${this.baseUrl}orders/manyitem/`,
            payload, this.httpHelper.options);
  }


  //// EXTRACT


  public updateOrderExtract(payload: any): Observable<any> {
    return this.httpClient.post<any>(`${this.baseUrl}extract/order/`,
      payload,
      this.httpHelper.options
    );
  } 

  public updateOrderItemExtract(payload: any): Observable<any> {
    return this.httpClient.post<any>(`${this.baseUrl}extract/orderitem/`,
      payload,
      this.httpHelper.options
    );
  }  

  public updateCredentialExtract(payload: any): Observable<any> {
    return this.httpClient.post<any>(`${this.baseUrl}extract/credential/`,
      payload,
      this.httpHelper.options
    );
  } 

  public updateOrderMsg(payload: any): Observable<any> {
    return this.httpClient.post<any>(`${this.baseUrl}extract/msgupdate/`,
      payload,
      this.httpHelper.options
    );
  } 

  public deleteItemExtract(item_id: any): Observable<any | HttpErrorResponse> {
    return this.httpClient.delete<any>(`${this.baseUrl}extract/item/${item_id}/`, this.httpHelper.options);
  }

  public updateItemExtract(item_id: any, payload: any): Observable<any> {
    return this.httpClient.put<any>(`${this.baseUrl}extract/item/${item_id}/`,
      payload,
      this.httpHelper.options
    );
  }

  public createItemExtract(payload: any): Observable<any> {
    return this.httpClient.post<any>(`${this.baseUrl}extract/item/`,
      payload,
      this.httpHelper.options
    );
  }

  public deleteMessageExtract(msgId: any): Observable<any | HttpErrorResponse> {
    return this.httpClient.delete<any>(`${this.baseUrl}extract/msgdelete/${msgId}/`, this.httpHelper.options);
  }

  public msgSaveExtract(payload: any): Observable<any | HttpErrorResponse> {
    return this.httpClient.post<any>(`${this.baseUrl}extract/msgsave/`,
            payload, this.httpOptionsMsgPack
            ).pipe(map((response: any) => {return decodeMsgPack(response);})
            ).pipe(map((response: any) => {return unPackOrderItems(response, false);})
            )
  }



  public updateOrderDocumentExtract(payload: any): Observable<any> {
    return this.httpClient.post<any>(`${this.baseUrl}extract/documents/`,
      payload,
      this.httpHelper.options
    );
  }

  public deleteOrderDocumentExtract(doc_id: any): Observable<any | HttpErrorResponse> {
    return this.httpClient.delete<any>(`${this.baseUrl}extract/documents/${doc_id}/`, this.httpHelper.options);
  }

  public updateOrderAttachmentExtract(payload: any): Observable<any> {
    return this.httpClient.post<any>(`${this.baseUrl}extract/attachments/`,
      payload,
      this.httpHelper.options
    );
  }

  public uploadDocumentExtract(payload: any): Observable<any> {
    return this.httpClient.post<any>(`${this.baseUrl}extract/upload/`, payload,
    );
  }

  public deleteOrderAttachmentExtract(att_id: any): Observable<any | HttpErrorResponse> {
    return this.httpClient.delete<any>(`${this.baseUrl}extract/attachments/${att_id}/`, this.httpHelper.options);
  }

  public getItemCalendarValues() {
    return this.httpClient.get<INewOrder>(`${this.baseUrl}orders/icalendar/`, {
      ...this.httpOptionsMsgPack,
    }).pipe(map((response: any) => {
        return decodeMsgPackSimple(response);
        })
      );
  }

  public writeItemImageExtract(payload: any): Observable<any> {
    return this.httpClient.post<any>(`${this.baseUrl}extract/writeimage/`,
      payload,
      this.httpHelper.options
    );
  }

  public deleteImageExtract(img_id: any): Observable<any | HttpErrorResponse> {
    return this.httpClient.delete<any>(`${this.baseUrl}extract/images/${img_id}/`, this.httpHelper.options);
  }
}
