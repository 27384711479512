let smallNodeWidth = '200px';
let nodeWidth = '330px';
let largeNodeWidth = '420px';

let lowGap = 15;
let gap = 22;
let highGap = 34;


let isShipperEmailOn: any = [
    {
        number: '1.',
        name: 'Should shippers be emailed?',
        main_texts: [
            {
                text: 'Changing whether or not shippers are emailed can be done in'
            },
            {
                text: 'company settings',
                underline: true,
                where: 'settings',
                page: true,
                subpage: 'Preferences',
            },
        ]
    },
];

let flagIssueCustoms: any = [
    {
        number: '1.',
        name: 'Who should be notified of issues?',
        type: 'dropdown1',
        org_data_value: 'shipper_draft_flag_issue',
        options: 'issue_notifiers',
    },
    {
        number: '2.',
        name: 'How should they be notified?',
        main_texts: [
            {
                text: 'Each user can update this in'
            },
            {
                text: 'their preferences',
                underline: true,
                page: true,
                where: 'settings',
                subpage: 'Personal',
            },
        ]
    },
];

let copyWhichUsers: any = [
    {
        number: '1.',
        name: 'Which users should be copied?',
        type: 'dropdown1',
        org_data_value: 'shipper_draft_include_users',
        options: 'issue_notifiers',
    }
];

export let shipperDraftConnections: any = [
    {from: 'node1', to: 'node2',  cut: undefined, space: true},
    {from: 'node2', to: 'node3',  cut: gap, space: false},
    {from: 'node2', to: 'node4',  cut: gap, space: false},
    {from: 'node3', to: 'node5',  cut: gap, space: false},
    {from: 'node3', to: 'node6',  cut: gap, space: false},
    {from: 'node6', to: 'node7',  cut: lowGap, space: false},
    {from: 'node6', to: 'node8',  cut: lowGap, space: false},
    {from: 'node6', to: 'node10',  cut: lowGap, space: false},
    {from: 'node6', to: 'node11',  cut: lowGap, space: false},
    {from: 'node11', to: 'node12',  cut: gap, space: false},
    {from: 'node10', to: 'node12',  cut: gap, space: false},
    {from: 'node12', to: 'node13',  cut: gap, space: false},

]

export let shipperDraftSwitches: any = [
    { id: 'switch1', text: 'Yes', from: 'node2', to: 'node3', above: 'node3', where: gap },
    { id: 'switch1', text: 'No', from: 'node2', to: 'node4', above: 'node4', where: gap },
    { id: 'switch1', text: 'Yes', from: 'node3', to: 'node6', above: 'node6', where: gap },
    { id: 'switch1', text: 'No', from: 'node3', to: 'node5', above: 'node5', where: gap },
    { id: 'switch1', text: 'Error', from: 'node6', to: 'node7', above: 'node7', where: highGap },
    { id: 'switch1', text: 'Clear', from: 'node6', to: 'node8', above: 'node8', where: highGap },
    { id: 'switch1', text: 'Unclear', from: 'node6', to: 'node10', above: 'node10', where: highGap },
    { id: 'switch1', text: 'Request', from: 'node6', to: 'node11', above: 'node11', where: highGap },

]

export let shipperDraftNodes: any = [
    {
        id: 'node1',
        icon: 'bi-box-arrow-down-left',
        icon_color: '#C2DBFF',
        subtext: 'Can’t track online',
        sidebar_main_text: 'Can’t track order online - via task ‘Auto track order links - shippers’',
        node_width: '240px',
        style_left: 250, row: 1,
    },
    {
        id: 'node2',
        icon: 'bi-funnel',
        icon_color: '#C2DBFF',
        subtext: 'Is shipper email turned on?',
        customizations: isShipperEmailOn,
        node_width: '240px',
        style_left: 250, row: 2,
    },
    {
        id: 'node3',
        icon: 'bi-funnel',
        icon_color: '#C2DBFF',
        subtext: 'Do we have shipper email?',
        node_width: '240px',
        style_left: 320, row: 3,
    },
    {
        id: 'node4',
        icon: 'meti',
        subtext: 'End',
        node_width: '120px',
        style_left: 140, row: 3,
    },
    {
        id: 'node5',
        icon: 'bi-flag',
        icon_color: '#C2DBFF',
        subtext: 'Flag issue',
        customizations: flagIssueCustoms,
        node_width: '130px',
        style_left: 190, row: 4,
    },
    {
        id: 'node6',
        icon: 'meti',
        subtext: 'Email shipper',
        customizations: copyWhichUsers,
        node_width: '160px',
        style_left: 420, row: 4,
    },
    {
        id: 'node7',
        icon: 'bi-flag',
        icon_color: '#C2DBFF',
        subtext: 'Flag issue',
        customizations: flagIssueCustoms,
        node_width: '130px',
        style_left: 60, row: 5,
    },
    {
        id: 'node8',
        icon: 'meti',
        subtext: 'Parse the data and update order details in Meti ',
        node_width: '220px',
        style_left: 220, row: 5,
    },
    {
        id: 'node10',
        icon: 'meti',
        subtext: 'Request clarification',
        node_width: '180px',
        style_left: 450, row: 5,
    },
    {
        id: 'node11',
        icon: 'meti',
        subtext: 'Await requested information',
        node_width: '220px',
        style_left: 650, row: 5,
    },
    {
        id: 'node12',
        icon: 'bi-clock',
        icon_color: '#C2DBFF',
        subtext: 'Wait 2 days',
        node_width: smallNodeWidth,
        toggle: 'shipper_draft_follow_up',
        row: 6, style_left: 550,
    },
    {
        id: 'node13',
        icon: 'meti',
        subtext: 'Send follow up request',
        toggle: 'shipper_draft_follow_up',
        node_width: '200px',
        row: 7, style_left: 550,
    },
];
