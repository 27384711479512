let smallNodeWidth = '200px';
let nodeWidth = '330px';
let largeNodeWidth = '420px';

let cut = 23;
let high_cut = 30;

let scheduleCustoms: any = [
    {
        number: '1.',
        name: 'How often should Meti track shipper links?',
        type: 'dropdown1',
        options: 'link_tracking',
        org_data_value: 'shipper_links_track_cadence',
    }
];

let flagIssueCustoms: any = [
    {
        number: '1.',
        name: 'How many times should Meti check the link after an error?',
        type: 'dropdown1',
        org_data_value: 'shipper_error_recheck',
        options: 'link_recheck',
    },
    {
        number: '2.',
        name: 'How do you want to be notified of errors?',
        main_texts: [
            {
                text: 'Each user can select how they are notified about updates in'
            },
            {
                text: 'their preferences',
                underline: true,
                page: true,
                where: 'settings',
                subpage: 'Personal',
            },
        ]
    },
];

let updateHowCustoms: any = [
    {
        number: '-',
        name: 'No customizations',
        main_texts: [
            {
                text: 'Each user can select how they are notified about updates in'
            },
            {
                text: 'their preferences',
                underline: true,
                page: true,
                where: 'settings',
                subpage: 'Personal',
            },
        ]
    },
]


export let shipperLinksConnections: any = [
    {from: 'node1', to: 'node2',  cut: undefined, space: true},
    {from: 'node2', to: 'node5',  cut: cut, space: false},
    {from: 'node2', to: 'node3',  cut: cut, space: false},
    {from: 'node3', to: 'node4',  cut: cut, space: false},
    {from: 'node3', to: 'node8',  cut: cut, space: false},
    {from: 'node4', to: 'node10', cut: cut, space: false},
    {from: 'node4', to: 'node9',  cut: cut, space: false},
    {from: 'node9', to: 'node5',  cut: high_cut, space: false},
    {from: 'node9', to: 'node8',  cut: high_cut, space: false},
    {from: 'node5', to: 'node6',  cut: undefined, space: false},
    {from: 'node5', to: 'node7',  cut: undefined, space: false},
]

export let shipperLinksSwitches: any = [
    { id: 'switch1', text: 'Yes', from: 'node9', to: 'node5', above: 'node5', where: high_cut },
    { id: 'switch2', text: 'No', from: 'node2', to: 'node3', above: 'node3', where: cut },
    { id: 'switch3', text: 'Yes', from: 'node3', to: 'node4', above: 'node4', where: cut },
    { id: 'switch3', text: 'Yes', from: 'node4', to: 'node9', above: 'node9', where: cut },
    { id: 'switch3', text: 'No', from: 'node4', to: 'node10', above: 'node10', where: cut },
    { id: 'switch4', text: 'No', from: 'node9', to: 'node8', above: 'node8', where: high_cut },
    { id: 'switch5', text: 'Successful', from: 'node5', to: 'node7', above: 'node7', where: cut },
    { id: 'switch6', text: 'Unsuccessful', from: 'node5', to: 'node6', above: 'node6', where: cut },
]

export let shipperLinksNodes: any = [
    {
        id: 'node1',
        icon: 'bi-plus-circle',
        icon_color: '#C2DBFF',
        subtext: 'Item status updated to',
        customizations: scheduleCustoms,
        sidebar_main_text: 'Schedule',
        post_text: 'any of these statuses',
        post_text_tooltip: 'Partially shipped, Shipped, Partially delivered, Ready to be scheduled, Delivery scheduled, Tracking issues',
        post_text_tooltip_width: '300px',
        post_text_tooltip_height: '64px',
        node_width: nodeWidth,
        style_left: 410, row: 1,
    },
    {
        id: 'node2',
        icon: 'bi-funnel',
        icon_color: '#C2DBFF',
        subtext: 'Is there a shipment link?',
        node_width: nodeWidth,
        style_left: 410, row: 2,
    },
    {
        id: 'node3',
        icon: 'bi-funnel',
        icon_color: '#C2DBFF',
        subtext: 'Is there a shipper provided?',
        sidebar_main_text: 'Is a shipper provided?',
        node_width: '240px',
        style_left: 150, row: 3,
    },
    {
        id: 'node4',
        icon: 'bi-funnel',
        icon_color: '#C2DBFF',
        subtext: 'Does the shipper have a tracking portal (that doesn’t require a log in)?',
        node_width: nodeWidth,
        style_left: 330, row: 4,
    },
    {
        id: 'node9',
        icon: 'bi-funnel',
        icon_color: '#C2DBFF',
        subtext: 'Do we have the details necessary to track the order?',
        sidebar_main_text: 'Do we have the details to track the order?',
        node_width: '220px',
        style_left: 470, row: 5,
    },
    {
        id: 'node10',
        icon: 'bi-box-arrow-up-right',
        sidebar_main_text: 'Go to draft shipper checkin',
        icon_color: '#C2DBFF',
        multi_texts: [
            {
                text: 'Go to', 
            },
            {
                text: 'Draft and send check in emails - shipper,', 
                underline: true,
                where: 'send_checkin_emails_shipper',
            },
            {
                text: 'if turned on; otherwise end.', 
            },
        ],
        node_width: '250px',
        style_left: 200, row: 5
    },
    {
        id: 'node5',
        icon: 'meti',
        subtext: 'Go to the link and retrieve data',
        sidebar_main_text: 'Go to link',
        node_width: '250px',
        style_left: 580, row: 6,
    },
    {
        id: 'node6',
        icon: 'bi-flag',
        icon_color: '#C2DBFF',
        subtext: 'Flag if error',
        sidebar_main_text: 'If error',
        customizations: flagIssueCustoms,
        node_width: smallNodeWidth,
        style_left: 680, row: 7,
    },
    {
        id: 'node7',
        icon: 'meti',
        subtext: 'Parse the data, update order details in Meti, and recalculate next check in date.',
        customizations: updateHowCustoms,
        sidebar_main_text: 'Parse the data and update order details in Meti',
        node_width: '260px',
        style_left: 380, row: 7
    },
    {
        id: 'node8',
        icon: 'bi-box-arrow-up-right',
        sidebar_main_text: 'Go to supplier tracking or draft supplier checkin',
        icon_color: '#C2DBFF',
        multi_texts: [
            {
                text: 'Go to', 
            },
            {
                text: 'Auto track order links - supplier,', 
                underline: true,
                where: 'track_links'
            },
            {
                text: 'if turned on; otherwise,', 
            },
            {
                break: true,
            },
            {
                text: 'Go to', 
            },
            {
                text: 'Draft and send check in emails - supplier,', 
                underline: true,
                where: 'send_checkin_emails'
            },
            {
                text: 'if turned on; otherwise, end', 
            },
        ],
        node_width: nodeWidth,
        style_left: 15, row: 6
    },

];