<div class="workflow-data-entry" style="padding-right: 16px; gap: 24px; margin-bottom: 12px">
    <div class="workflow-box-row">
        <div class="workflow-leftmost-small wf-data-entry-margin wf-counter-circle">
            1
        </div>
        <div class="workflow-box-col">
            <div class="org-ai-option-text workflow-active-text" style="margin-bottom: 20px;">
                Does your company use POs?
            </div>
            <div class="workflow-box-row wf-select-multi">
                <div *ngFor="let po of usePOs" class="wf-select-multi-one" (click)="setUsePO(po)"
                    [ngStyle]="{'background-color': po.active ? '#EDEDEC': ''}"
                >
                    {{po.name}}
                </div>
            </div>
        </div>
    </div>
    <div class="workflow-box-row">
        <div class="workflow-leftmost-small wf-data-entry-margin wf-counter-circle">
            2
        </div>
        <div class="workflow-box-col">
            <div class="org-ai-option-text workflow-active-text" style="margin-bottom: 16px;">
                Choose how to get orders into Meti
            </div>
            <div class="wf-choose-small-text">
                Meti tracks your new orders for you. Select how you want to get new orders into the system for tracking.
            </div>
            <div class="workflow-box-row wf-box-row-choosehow" >
                <div *ngFor="let how of chooseHow" class="wf-choose-how-one"
                    (click)="setChooseHowActive(how)"
                    [ngClass]="{
                        'workflow-close-box-not-done2': how.active && !box.complete,
                        'workflow-close-box-done2': how.active && box.complete,
                        'wf-choose-how-inactive': !how.active,
                    }"
                    [ngStyle]="{'cursor': how.soon ? 'default': 'pointer'}"
                >
                    <div class="workflow-box-row" style="align-items: center;">
                        <div class="wf-how-main-text"
                            [ngStyle]="{'color': how.soon ? '#828282': '#000'}">
                            {{how.name}}
                        </div>
                        <div *ngIf="how.rec" class="workflow-button-isdone wf-recommended">
                            Recommended
                        </div>
                        <div *ngIf="how.soon" class="wf-coming-soon">
                            Coming soon!
                        </div>
                    </div>
                    <div class="wf-how-sub-text">
                        {{how.text}}
                    </div>
                </div>
            </div>
            <div *ngIf="erpChosen" class="workflow-box-col">
                <div class="org-ai-option-text workflow-active-text" style="margin-bottom: 12px; margin-top: 24px">
                    Connect your ERP
                </div>
                <div class="wf-choose-small-text">
                    To connect Meti with your ERP, click the “Confirm” button. You’ll receive an email with instructions and next steps.
                </div>
            </div>
        </div>
    </div>
</div>