<div class="materlog-order-docs-container" #materlogDocsContainer>
    <div class="upload-docs-order-item">
        Order docs
    </div>
    <div class="materlog-order-docs-plus-container">
        <materlog-tooltip
            class="materlog-tooltip-container"
            top="2px"
            right="18px"
            font_size="10px"
            padding="5px"
            width="110px"
            text="Add order document?"
        ></materlog-tooltip>
        <i class="materlog-order-docs-plus bi bi-plus-lg materlog-cpointer"
            (click)="addNewDoc()"
        ></i>
    </div>
  
    <div class="materlog-doc-scrollable materlog-doc-scrollable-expand custom-scrollbar-close" #materlogScroll>
      <div *ngFor="let doc of docs" class="materlog-single-order-doc">
        <div class="materlog-odoc-single-col">
          <div class="materlog-odoc-single-row">
            <label *ngIf="!docHasId(doc)" (click)="uploadFile($event)">
              <i class="bi bi-paperclip materlog-file-icon materlog-cpointer"></i>
              <input type="file" #fileInput (change)="onFileSelected($event, doc)">
            </label>
            <i *ngIf="docHasId(doc)" class="materlog-file-icon bi bi-file-earmark-richtext"></i>
            <div class="materlog-odoc-type">
              <materlog-dropdown-component
                class="materlog-field-type"
                [options]="dropdownOptions?.document_types"
                [selected]="doc.document_type"
                [valueField]="'value'"
                (onChange)="onDropdownChange($event, doc)"
                [maxWidth]="110"
                [alignRight]="false"
                [inputText]="'Search...'"
                [defaultCSS]="false"
                [maxHeight]="120"
              >
              </materlog-dropdown-component>
            </div>
            <div>
              <div class="materlog-ship-input materlog-doc-text materlog-ship-input-max materlog-odoc-overflow"
                    *ngIf="!doc.editingNumber" (click)="onFocus(doc, true)"
                >
                {{doc.number || 'Document #'}}
              </div>
              <input *ngIf="doc.editingNumber"
                class="materlog-ship-input-max materlog-odoc-overflow materlog-ship-input materlog-ship-input-active"
                type="text" [(ngModel)]="doc.number" placeholder="Document #"
                (keydown.enter)="onEnter($event)"
                (blur)="onBlur(doc, null, true)"
              />
            </div>
            <div style="flex: 1"></div>
            <div *ngIf="!doc.editingNumber && !doc.editingFilename"
                  class="materlog-odoc-icons">
              <materlog-tooltip
                class="materlog-tooltip-container"
                top="-2px"
                right="-5px"
                font_size="10px"
                padding="5px"
                width="140px"
                height="16px"
                text="Delete order document?"
              ></materlog-tooltip>
              <i class="bi bi-trash3 materlog-odoc-icon" (click)="deleteDoc(doc)"></i>
            </div>
          </div>
          <div *ngFor="let att of attForDoc(doc); let isLast = last;"
                class="materlog-odoc-single-row materlog-oatt-single-row">
            <div class="materlog-ship-input materlog-doc-text materlog-ship-input-max-att materlog-odoc-overflow"
                    *ngIf="!att.editingFilename" (click)="onFocus(att, false)">
              {{att.filename || 'Filename'}}
            </div>
            <input *ngIf="att.editingFilename"
              class="materlog-ship-input materlog-ship-input-active materlog-ship-input-max-att materlog-odoc-overflow"
                type="text" [(ngModel)]="att.filename"
                (keydown.enter)="onEnter($event)"
                placeholder="Filename" (blur)="onBlur(doc, att, false)"
            />
            <div style="flex: 1"></div>
            <i *ngIf="att.aws_url" class="materlog-odoc-icon materlog-odoc-icon-att bi bi-download" (click)="downloadDoc(att)"></i>
            <div class="materlog-odoc-icon-att materlog-delete-order-attachment">
              <materlog-tooltip
                class="materlog-tooltip-container"
                top="0px"
                right="4px"
                font_size="10px"
                padding="5px"
                width="140px"
                text="Delete order attachment?"
              ></materlog-tooltip>
              <i class="bi bi-x-lg materlog-odoc-icon" (click)="deleteAtt(doc, att)"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>