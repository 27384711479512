import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { EventEmitter, Output, ChangeDetectorRef} from '@angular/core';
import { ElementRef, HostListener } from '@angular/core';
import { OrderService } from '../../services/order.service';
import { JobsService } from '../../services/job.service';
import { SuppliersService } from 'src/app/suppliers/services/suppliers.service';
import { DashboardActions } from '../../state/actions/dashboard.actions';
import { isValidUUID } from 'src/app/common/utils/general';

@Component({
  selector: 'materlog-order-auto-upload',
  templateUrl: './order-auto-upload.component.html',
  styleUrls: ['./order-auto-upload.component.css'],
})
export class OrderAutoUploadComponent implements OnInit {
    @Input() allMailData: any;
    @Input() dropdownOptions?: any;
    @Input() onlyProcessing: boolean = true;
    @Input() msgData: any = undefined;

    shadowItemLinks: any = [];

    createdOptions: boolean = false;
    jobOptions: any = [];
    supplierOptions: any = [];
    allSupplierOptions: any = [];

    fields: any = [
        {name: 'Job', field: 'job_id', model: 'Orders', type: 'dropdown',
              options: 'jobsArray', alignRight: true, allow_null: true},
        {name: 'Supplier', field: 'supplier_id', model: 'Orders', type: 'dropdown',
              options: 'supArray', alignRight: true},
        {name: 'Status', field: 'status', model: 'Items', type: 'status'},
        {name: 'Supplier tracking url', field: 'tracking_info', model: 'Items', type: 'link'},
        {name: 'In stock', field: 'in_stock_date', model: 'Items', type: 'date'},
        {name: 'Ship', field: 'ship_date', model: 'Items', type: 'date'},
        {name: 'Delivery', field: 'delivery_date', model: 'Items', type: 'date'},
        {name: 'Discount', field: 'discount', model: 'Orders', type: 'number',
                cost: true, diff: true},
        {name: 'Sales tax', field: 'sales_tax', model: 'Orders', type: 'number',
                cost: true, diff: true},
        {name: 'Shipping cost', field: 'shipping_cost', model: 'Orders', type: 'number',
                cost: true, diff: true},
        {name: 'Total cost', field: 'total_cost', model: 'Orders', type: 'number',
                cost: true, diff: true},
        {name: 'Payment method', field: 'payment_method', model: 'Orders', type: 'dropdown',
                options: 'payment_method', dropWidth: 130, alignRight: false, diff: true,
                remove_non: true},
        {name: 'Last four cc', field: 'last_4_card_digits', model: 'Orders',
                type: 'number', maxInput: 4, diff: true},
        {name: 'Account #', field: 'account', model: 'SupplierCredentials', type: 'text',
                diff: true},
        {name: 'Bill to name', field: 'billing_name', model: 'Orders', type: 'text',
                diff: true},
        {name: 'Bill to zip', field: 'billing_zip', model: 'Orders', type: 'text',
                maxInput: 10, diff: true},
        {name: 'Ship to', field: 'shipping_to', model: 'Orders', type: 'dropdown',
                options: 'shipping_to', dropWidth: 130, alignRight: false, diff: true,
                remove_non: true},
        {name: 'Ship to name', field: 'shipping_name', model: 'Orders', type: 'text',
                diff: true},
        {name: 'Ship to address', field: 'shipping_address', model: 'Orders', type: 'text',
                diff: true},
        {name: 'Ship to city', field: 'shipping_city', model: 'Orders', type: 'text',
                diff: true},
        {name: 'Ship to state', field: 'shipping_state_id', model: 'Orders', type: 'dropdown',
                options: 'state', dropWidth: 100, alignRight: false, diff: true, allow_null: true},
        {name: 'Ship to zip', field: 'shipping_zip', model: 'Orders', type: 'text', maxInput: 10,
                diff: true},
        {name: 'Notes', field: 'order_notes', model: 'Orders', type: 'text', maxInput: null, vgrowth: true},
       ]

    ngOnInit() {
    }
    
    ngOnChanges(changes: any) {
        if (changes.msgData) {
            this.shadowItemLinks = this.setShadowOrItemLinks('tracking_info');
        }
        if (!this.createdOptions && this.hasOptionData) {
            this.createdOptions = true;
            this.createBothOptionsForField();
            this.createJobOptions();
        }
    }
  
    ngAfterViewInit() {
    }

    constructor(
        private orderService: OrderService,
        private cdr: ChangeDetectorRef,
        private jobsService: JobsService,
        private dashboardActions: DashboardActions
    ) {
        (window as any)['orderup'] = this;
    }

    get hasOptionData() {
        return this.msgData?.order &&
                this.allMailData?.completeSuppliers &&
                this.allMailData?.supArray &&
                this.allMailData?.jobsArray;
    }

    payloadForOrder(field: any) {
        let data = {
            [field]: this.msgData.order[field]
        }
        let payload = {
            order_id: this.msgData.order.id,
            data: data
        }
        return payload
    }
    updateOrder(field: any) {
        let payload = this.payloadForOrder(field);
        this.orderService.updateOrderExtract(payload).subscribe((result: any) => {
        })
    }

    fieldValueForField(field: any) {
        if (this.onlyProcessing) return '-';
        return '';
    }


    onTextChange(event: any, field: any) {
        this.msgData.order[field.field] = event.value;
        if (field.model == 'Orders') {
            this.updateOrder(field.field);
        } else {
            let data = {account: event.value, order_id: this.msgData.order.id};
            this.orderService.updateCredentialExtract(data).subscribe(() => {});
        }
    }

    get itemsExist() {
        return this.msgData?.order?.item_fields?.length;
    }

    onDateChange(event: any) {
        let data = {
          dates: {[event.field]: event.data || null},
          order_id: this.msgData.order.id
        }
        this.msgData?.order?.item_fields?.forEach((item: any) => {
          item[event.field] = event.data;
        });
        this.setOrderItemData(data);
    }

    onShippingLinkChange(event: any) {
        let links = event.data.map((l: any) => (l.value))
        links = [...new Set(links.filter((l: any) => l))];
        let tdata = {tracking_info: {links: links}};
        this.msgData?.order?.item_fields?.forEach((item: any) => {
          item.tracking_info = links;
        })
        let data = {...tdata, order_id: this.msgData.order.id};
        this.setOrderItemData(data);
    }

    setOrderItemData(data: any) {
        this.orderService.updateOrderItemExtract(data).subscribe(() => {});
        this.msgData.order.item_fields = [...this.msgData.order.item_fields]
        this.cdr.detectChanges();
    }

    createJobOptions() {
        let extra_jobs = this.msgData?.shadow?.option_extras?.filter((o: any) => o.job_id)
        if (!extra_jobs) {
            this.jobOptions = this.allMailData?.jobsArray;
            return;
        }
        extra_jobs = extra_jobs.map((j: any) => ({id: j.job_id, value: j.job_name}));
        let allJobs = this.allMailData?.jobsArray || [];
        this.jobOptions = extra_jobs.concat(allJobs)
    }

    createSupplierOptions(all: boolean = false) {
        let supArray = all ? this.allMailData?.completeSuppliers : this.allMailData?.supArray;
        supArray = supArray.filter((s: any) => s.id != 'all');
        let extra_suppliers = this.msgData?.shadow?.option_extras?.filter((o: any) => o.supplier_id)
        if (!extra_suppliers) {
            return supArray
        }
        extra_suppliers = extra_suppliers.map((s: any) => ({id: s.supplier_id, value: s.supplier_name}));
        let allSuppliers = supArray;
        allSuppliers = allSuppliers || [];
        return extra_suppliers.concat(allSuppliers)
    }

    createBothOptionsForField() {
        this.allSupplierOptions = this.createSupplierOptions(true);
        this.supplierOptions = this.createSupplierOptions();
    }

    fullOptionsForField(field: any) {
        let fieldId = field.field;
        if (fieldId == 'supplier_id') {
          return this.allSupplierOptions;
        }
        return [];
    }

    optionsForField(field: any) {
        let fieldId = field.field;
        if (fieldId == 'job_id') {
          return this.jobOptions;
        } else if (fieldId == 'supplier_id') {
          return this.supplierOptions;
        } else if (fieldId == 'status') {
          return this.dropdownOptions?.status;
        } else {
          return this.dropdownOptions[field.options];
        }
      }

    valueFieldForField(field: any) {
        let names = ['supplier_id', 'job_id'];
        return names.includes(field.field) ? 'name': 'value';
    }

    onStatusChange(event: any) {
        this.msgData?.order?.item_fields?.forEach((item: any) => {
            if (item.status == event.old) {
                item.status = event.new;
            }
        })
        let data = {
            status_old: event.old, status_new: event.new,
            order_id: this.msgData.order.id
        };
        this.msgData.order.item_fields = [...this.msgData.order.item_fields];
        this.orderService.updateOrderItemExtract(data).subscribe(() => {});
    }

    idForSelectedField(field: any) {
        let orderInfo = this.msgData?.order;
        let shadow = this.msgData?.shadow?.order;
        
        let fieldId = field.field;
        let output = orderInfo ? orderInfo[fieldId]: undefined;

        let changeable = ['supplier_id', 'job_id'];

        if (!output && changeable.includes(field.field) && shadow && shadow[field.field]) {
            output = shadow[field.field];
        }

        let dashes = ['payment_method', 'shipping_to'];
        if (dashes.includes(field.field)) {
          return output == 0 ? undefined: output;
        } 
        return output;
    }

    inputTextForField(field: any) {
        switch (field.field) {
            case 'job_id':
                return 'Find a job';
            case 'supplier_id':
                return 'Find a supplier';
            case 'order':
                return 'Select order to update';
            case 'shipping_state_id':
            case 'status':
                return 'Search...';
            default:
                return '';
        }
      }

    canCreateNewDropdown(field: any) {
        let allowed = ['job_id', 'supplier_id'];
        return allowed.includes(field.field);
    }

    getCreateNewText(field: any) {
        if (field.field == 'job_id') {
            return 'create new job';
        } else if (field.field == 'supplier_id') {
            return 'create new supplier';
        }
        return '';
    }

    checkOptionExtra(event: any, field: any) {
        let value = event.id
        if (isValidUUID(value)) {
            return false;
        }
        let newEvent = {chosen: event}
        this.onOptionsChange(newEvent, field);
        return true;
    }

    onDropdownChange(event: any, field: any) {
        if (this.checkOptionExtra(event, field)) {
            return;
        }
        this.msgData.order[field.field] = event.id;
        this.updateOrder(field.field);
        let oshadow = this.msgData?.shadow?.order;
        if (oshadow && oshadow[field.field]) {
            delete oshadow[field.field];
            let payload = { msg_id: this.msgData.id, data: this.msgData?.shadow }
            this.orderService.updateOrderMsg(payload).subscribe((result: any) => {
            })
        }
    }

    onOptionsChange(event: any, field: any) {
        if (!this.canCreateNewDropdown(field)) return;
        let isjob = field.field == 'job_id';
        this.msgData.order[field.field] = null;
        let field_name = isjob ? 'job_name': 'supplier_name';
        let chosen_name = event?.chosen?.value;
        let chosen_id = event?.chosen?.id;
        let shadow = this.msgData.shadow || {};
        if (!chosen_name) return;
        if (!shadow.option_extras) {
            shadow.option_extras = [];
        }
        if (!shadow.order) {
            shadow.order = {}
        }
        shadow.option_extras = shadow.option_extras.filter((o: any) => o[field.field] != chosen_id)
        shadow.option_extras.push({
            [field_name]: chosen_name,
            [field.field]: chosen_id
        });
        shadow.order[field.field] = chosen_id
        let payload = { msg_id: this.msgData.id, data: shadow, key: field.field }
        this.orderService.updateOrderMsg(payload).subscribe((result: any) => {
            this.createBothOptionsForField();
            this.createJobOptions();
        })
      }

    setShadowOrItemLinks(field: any) {
        let items = this.msgData?.order?.item_fields || [];
        let links = items.map((i: any) => i[field]).flat() || [];
        links = links.filter((l: any) => l);
        links = [...new Set(links)];
        if (!links.length && this.msgData?.order?.supplier_tracking_link) {
            let stl = this.msgData.order.supplier_tracking_link;
            let slink = stl.split(',').filter((l: any) => l && !l.includes('*') && !l.includes(' '));
            if (slink.length) {
                links = [slink[0]];
            }
        }
        return [...new Set(links)];
    }
    
}