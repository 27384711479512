<div class="preferences-container">
    <div class="prefs-main-text">
        Preferences
    </div>
    <div class="prefs-sub-text">
        Your preferences will apply to your whole organization.
    </div>
    <div class="prefs-option-container pref-line-all">
        <div *ngFor="let option of prefOptions" class="pref-default" (click)="onChangePref(option)"
            [ngClass]="{
                'pref-selected-line': option.chosen,
                'pref-selected': option.chosen,
                'pref-non-select': option.nonselectable,
                'pref-selectable': !option.nonselectable,
            }"
        >
            {{option.name}}
        </div>
    </div>
    <materlog-workflows
        [dropdownOptions]="dropdownOptions"
        [allMailData]="allMailData"
        [whichWorkflowOption]="whichWorkflowOption"
  ></materlog-workflows>
</div>