<div class="materlog-item-picture-all"
  [ngStyle]="{'min-width': picWidth, 'max-width': picWidth}">
    <div class="materlog-img-iter" *ngFor="let img of images">
        <img class="materlog-item-picture" [src]="img.aws_url" *ngIf="img.aws_url">
        <i class="materlog-item-picture materlog-missing-img bi bi-image-fill"
            *ngIf="!img.aws_url"></i>
        <i *ngIf="img.aws_url" class="materlog-delete-picture bi bi-x-lg"
                (click)="deletePicture($event, item, img)"></i>
    </div>
  <div class="materlog-img-choice"
    [ngStyle]="{'justify-content': hasEmailPictures ? 'space-between': 'center'}"
  >
    <label (click)="addNewImg($event)" class="materlog-img-label">
      <i class="materlog-img-add bi bi-plus-lg" [ngStyle]="{'display': showImgDropdown ? 'flex': ''}"></i>
      <div class="materlog-img-add-text" *ngIf="!images?.length"
          [ngStyle]="{'display': showImgDropdown ? 'flex': ''}">Upload images</div>
      <input type="file" #fileInput (change)="onFileSelected($event, item)">
    </label>
    <div *ngIf="hasEmailPictures"
        (click)="toggleShowImgDropdown($event)" class="materlog-img-label">
        <i class="materlog-img-add bi bi-chevron-down"
            [ngStyle]="{'display': showImgDropdown ? 'flex': ''}"
        ></i>
        <div class="materlog-img-add-text" *ngIf="!images?.length"
          [ngStyle]="{'display': showImgDropdown ? 'flex': ''}">
          Choose
        </div>
    </div>
  </div>
  <div class="materlog-img-drop-container" #imgDropContainer
    *ngIf="showImgDropdown"
    [ngStyle]="{'max-height': '200px'}">
    <div *ngFor="let opt of filterOptions" class="materlog-dropdown-option"
        (click)="onOptionSelect($event, opt)">
        <i *ngIf="isSelected(opt)" class="bi bi-check2 materlog-check-selected"></i>
        <img class="materlog-item-picture" [src]="opt.aws_url">
    </div>
  </div>
</div>