import { ChangeDetectorRef, Component, OnInit, ViewChild, Input } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { ElementRef, HostListener } from '@angular/core';
import { getArrowArray } from '../flow-utils';
import { DashboardJobView } from 'src/app/dashboard/services/dashboard_job_view.service';


@Component({
  selector: 'flow-sidebar',
  templateUrl: './flow-sidebar.component.html',
  styleUrls: [
    './flow-sidebar.component.css',
]
})
export class MetiFlowSidebar implements OnInit {
    @Input() data: any = {}
    @Input() orgData: any = {}
    @Input() orgOptions: any = {}

    @ViewChild('customContainer') customContainer!: ElementRef;
    @Output() onChange = new EventEmitter<any>();

    toggle: boolean = false;
    customizations: any = [];

    constructor(
        private djv: DashboardJobView
    ) {
    }

    ngOnInit(): void {
        (window as any)['sidebar'] = this;
    }

    ngOnChanges() {
        this.customizations = this.makeCustomizations();
        if (this.data?.toggle) {
            this.toggle = Boolean(this.orgData[this.data.toggle])
        }
    }

    get dataForNode() {
        if (!this.data.diff_icon) {
            return this.data;
        }
        return {...this.data, icon: this.data.diff_icon}
    }

    makeCustomizations() {
        let customs: any = [];
        if (!this.data.customizations) {
            let cur = {
                name: 'No customizations',
                number: '-',
            }
            customs.push(cur);
            return customs
        } else {
            return this.data.customizations;
        }
    }

    nodeToggleEvent(event: any) {
        this.orgData[this.data.toggle] = +this.toggle;
        this.onChange.emit();
    }

    dropdown2Text(custom: any) {
        let options = custom.options;
        if (typeof options === "string") {
            options = this.orgOptions[options];
        }
        let value = -1;
        if ('default' in custom) {
            value = custom.default
        } else {
            value = this.orgData[custom.org_data_value];
        }
        return options.find((o: any) => o.value == value)?.name || 'Select option';
    }

    getCustomOptions(custom: any) {
        let options = custom.options;
        if (typeof options === "string") {
            options = this.orgOptions[options];
        }
        return options;
    }

    dropdown3Chosen(custom: any) {
        let options = custom.options;
        let value = this.orgData[custom.org_data_value];
        return options.find((o: any) => o.value == value);
    }

    showDropdownOptions(custom: any) {
        if (!this.isToggleEnabled) return;
        this.customizations.forEach((c: any) => {
            if (c != custom) {
                c.dropdown_open = false
            }
        })

        if (custom.dropdown_open) {
            custom.dropdown_open = false;
        } else {
            setTimeout(() => {
                custom.dropdown_open = true;
                setTimeout(() => {
                    let el = this.customContainer?.nativeElement?.querySelector('.flow-sb-dropdown-open');
                    if (!el) return;
                    if (!custom.top) {
                        let height = el.parentElement.clientHeight - 12
                        el.style.top = `${height}px`;
                    } else {
                        el.style.top = `${custom.top}px`;
                    }
                }, 0)
            }, 50);
        }
    }

    optionsForSelector(custom: any) {
        return this.orgOptions[custom.options];
    }

    optionSelectorChosen(custom: any, option: any) {
        let value = this.orgData[custom.org_data_value];
        return option.value == value;
    }

    minWidthForSelector(name: any) {
        let len = name.length;
        if (len < 10) return ''
        return `${len + 1}ch`;
    }

    settingOption: boolean = false;
    setCustomOption(custom: any, option: any) {
        this.settingOption = true;
        if (!option.coming_soon) {
            this.orgData[custom.org_data_value] = option.value;
            this.onChange.emit();
        }
        setTimeout(() => {
            this.settingOption = false;
            if (!option.coming_soon) {
                custom.dropdown_open = false;
            }
        }, 20);
    }

    goToPage(text: any) {
        if (text.subpage) {
            if (text.where == 'settings') {
                this.djv.do_workflow_setting_clicked(text.subpage);
            }
        }
        if (text.page) {
            this.djv.do_home_page_clicked(text.where);
        }
    }

    @HostListener('document:click', ['$event'])
    clickOutside(event: any) {
        if (!this.settingOption) {
            this.customizations.forEach((c: any) => c.dropdown_open = false)
        }
    }

    get isToggleEnabled() {
        return (this.toggle && this.data.toggle) || !this.data.toggle
    }

}
