<div class="user-settings-container org-top-container" #orgSettingsContainer>
  <div class="org-settings-box custom-scrollbar-close" >
    <ng-container *ngIf="isLoading">
      <div class="flex flex-col">
        <div style="margin-left: 20px"></div>
        <p-skeleton height="20px" width="200px" styleClass="mb-2" borderRadius="16px"></p-skeleton>
        <div style="margin-bottom: 10px"></div>
        <div class="flex flex-row" style="margin-top: 10px; align-items: center;">
          <p-skeleton height="80px" width="80px" styleClass="mb-2" borderRadius="50%"></p-skeleton>
          <div style="margin-right: 50px;"></div>
          <p-skeleton height="40px" width="200px" styleClass="mb-2" borderRadius="16px"></p-skeleton>
        </div>
        <div class="flex flex-col">
          <div style="margin-top: 40px"></div>
          <div *ngFor="let item of skeletonItems">
            <p-skeleton height="20px" [width]="item.width" styleClass="mb-4" borderRadius="16px"></p-skeleton>
          </div>
        </div>
      </div>
    </ng-container>
    <div *ngIf="!isLoading" class="user-settings-data-container org-settings-data-container">
      <div class="user-settings-user-profile">
        <div class="user-settings-account-overview">
          Workspace settings
        </div>
        <div class="user-settings-profile-container org-settings-container">
          <div class="user-settings-profile-picture"
            [ngStyle]="{'background-color': !picLoading && orgData.logo ? '#fff': 'rgba(182, 232, 243, .35)' }"
            >
            <img *ngIf="!picLoading && orgData.logo" [src]="orgData.logo">
            <i *ngIf="!picLoading && !orgData.logo" class="bi bi-person no-image-profile"></i>
            <div *ngIf="picLoading"
                  class="user-settings-password-loading-container">
              <div class="user-settings-password-loading"></div>
            </div>
          </div>
          <div class="user-settings-edit-picture" *ngIf="!picLoading">
            <label (click)="uploadFile($event)">
              <i class="bi bi-pencil user-settings-pencil"></i>
              <input type="file" #fileInput (change)="onFileSelected($event)">
            </label>
          </div>
          <div class="user-settings-profile-text-container">
            <div *ngIf="!editingName" class="user-settings-profile-name"
              (click)="orgInputClicked(true)"
              [ngStyle]="{'color': !orgData.name ? 'rgba(150, 150, 150, 1)': ''}">
              {{ orgName }}
            </div>
            <input *ngIf="editingName"
              class="user-settings-input-name user-settings-input-name-username"
              type="text"
              id="org-settings-username"
              placeholder="Organization name"
              [(ngModel)] = "orgData.name"
              (keydown.enter)="inputEnter($event)"
              (blur)="updateOrgName($event)"
            />
          </div>
        </div>
      </div>
      <div class="org-members">
        <div class="org-members-header">
          <div class="org-member-header-text">
            Members
          </div>
          <div class="org-member-invite" (click)="addMember()" *ngIf="userRole == 1 || userRole == 2">
            Invite member
          </div>
        </div>
        <div class="org-member-users custom-scrollbar-close">
          <div class="org-user-profile" *ngFor="let user of users">
            <div class="org-user-profile-left">
              <div class="org-user-profile-picture">
                <img *ngIf="user?.user_settings?.image_profile" [src]="user.user_settings.image_profile">
                <i *ngIf="!user?.user_settings?.image_profile" class="bi bi-person org-user-no-image-profile"></i>
              </div>
              <div class="org-user-name">
                <div class="org-user-name-name" *ngIf="makeUserName(user)">
                  {{makeUserName(user)}}
                </div>
                <div class="org-user-name-noname" *ngIf="!makeUserName(user)">
                  No name
                </div>
                <div class="org-user-name-email">
                  {{user.email}}
                </div>
              </div>
            </div>
            <div class="org-user-delete" *ngIf="userRole == 1 || userRole == 2">
              <i class="bi bi-trash3-fill" (click)="deleteUser(user)"
                  style="font-size: 12px; color: #ddd"></i>
              <div class="delete-user-tooltip">
                Delete user?
              </div>
            </div>
          </div>
        </div>
        <div class="org-members-header org-location-header">
          <div class="org-member-header-text">
            Office locations
          </div>
          <div class="org-member-invite org-location-add" (click)="addLocation()">
            Add location
          </div>
        </div>
        <div class="org-member-addresses custom-scrollbar-close">
          <div class="org-address" *ngFor="let address of addresses" (click)="addressClicked(address)">
            <div class="org-address-value">
              <div class="org-address-name">
                {{address.name}}
                <div *ngIf="address.is_default" class="org-address-default">
                  (Primary)
                </div>
              </div>
              <div class="org-address-address">
                {{address.address}}
              </div>
              <div class="org-address-address">
                {{createAddressMeta(address)}}
              </div>
            </div>
            <div class="org-address-delete">
              <i class="bi bi-trash3-fill" (click)="deleteAddress($event, address)"
                  style="font-size: 12px; color: #ddd"></i>
              <div class="delete-user-tooltip" style="left: -90px !important">
                Delete address?
              </div>
            </div>
          </div>
        </div>
        <div style="padding-bottom: 60px"></div>
      </div>
    </div>
  </div>
</div>


<form *ngIf="addUsersModal">
  <p-dialog
    [modal]="true"
    [visible]="true"
    [resizable]="false"
    [draggable]="false"
    [closable]="false"
    [closeOnEscape]="false"
    [position]="'top'"
    [style]="{ marginTop: '150px', width: '540px' }"
    [contentStyleClass]="'job-dialog'"
    [maskStyleClass]="'dialog-mask'"
  >
  <ng-template pTemplate="header">
    <div class="dialog__header">
      <span>Add users</span>
    </div>
    <button class="dialog-close" type="button" (click)="closeAddressDialog()"><i class="bi bi-x-lg"></i></button>
  </ng-template>
  <ng-template pTemplate="content">
    <div >
      <textarea class="org-user-textarea"
        [(ngModel)]="newUsersString" 
        name="usersInput" 
        placeholder="Enter emails separated by commas or spaces" 
        rows="4"
        style="width: 100%;">
      </textarea>
    </div>
  </ng-template>

  <ng-template pTemplate="footer">
    <button pButton type="button" (click)="processNewUsers()"
        class="ui-button-primary org-add-user-button" [disabled]="newUsersLoading || !newUsersString">
        <span *ngIf="!newUsersLoading">Add Users</span>
        <i *ngIf="newUsersLoading" class="pi pi-spin pi-spinner" style="font-size: 1.2em;"></i>
    </button>
  </ng-template>
</p-dialog>
</form>



<form *ngIf="showAddressModal">
  <p-dialog
    [modal]="true"
    [visible]="true"
    [resizable]="false"
    [draggable]="false"
    [closable]="false"
    [closeOnEscape]="false"
    [position]="'top'"
    [style]="{ marginTop: '150px', width: '540px' }"
    [contentStyleClass]="'job-dialog'"
    [maskStyleClass]="'dialog-mask'"
  >
    <ng-template pTemplate="header">
      <div class="dialog__header">
        <span>{{ chosenAddress.id ? 'Edit address' : 'Add address' }}</span>
      </div>
      <button class="dialog-close" type="button" (click)="closeAddressDialog()"><i class="bi bi-x-lg"></i></button>
    </ng-template>

    <div class="dialog-supplier">
      <div class="dialog-supplier__content">
        <div class="dialog-supplier__content-item">
          <input
            class="form-controls"
            name="address"
            type="text"
            placeholder="Address name"
            minlength="1"
            [(ngModel)]="chosenAddress.name"
            [required]="true"
          />
          <p *ngIf="chosenAddress.name.length <= 1 && addressSubmitted" class="textfield__error-message">Add an address name</p>
        </div>
        <div class="dialog-supplier__content-item">
          <geoapify-geocoder-autocomplete
            [value]="chosenAddress.address"
            [debounceDelay]="50"
            [limit]="5"
            [lang]="'en'"
            [biasByCountryCode]="['us']"
            [filterByCountryCode]="['us']"
            placeholder="Address"
            [skipIcons]="true"
            [skipDetails]="true"
            (placeSelect)="placeSelected($event, chosenAddress)"
            (userInput)="chosenAddress.address = $event"
          >
          </geoapify-geocoder-autocomplete>
          <p *ngIf="!chosenAddress.address && addressSubmitted" class="textfield__error-message">Enter an address</p>
        </div>
        <div class="dialog-supplier__content-item">
          <input class="form-controls" name="city" type="text" placeholder="City" [(ngModel)]="chosenAddress.city" />
        </div>
        <div class="group">
          <div class="dialog-supplier__content-item">
            <p-dropdown
              appendTo="body"
              [options]="statesList"
              id="state_id"
              optionValue="id"
              optionLabel="value"
              placeholder="State"
              name="state"
              [(ngModel)]="chosenAddress.state_id"
              [showClear]="true"
            >
            </p-dropdown>
          </div>
          <div class="dialog-supplier__content-item">
            <input
              class="form-controls"
              name="zip"
              type="text"
              placeholder="Zip"
              [(ngModel)]="chosenAddress.zip"
              maxlength="10"
            />
          </div>
        </div>
        <div class="dialog-supplier__content-item">
          <input
            class="form-controls"
            name="phone"
            type="tel"
            placeholder="Phone"
            [(ngModel)]="chosenAddress.phone"
            maxlength="20"
          />
        </div>
        <div class="dialog-supplier__content-item">
          <p-checkbox
            label="Is default address"
            [(ngModel)]="chosenAddress.is_default"
            name="is_default"
            [binary]="true"
          ></p-checkbox>
        </div>
      </div>
    </div>

    <ng-template pTemplate="footer">
      <div class="dialog-supplier__content-action">
        <button class="dialog-supplier__content-action-button" type="submit" (click)="editAddress(chosenAddress)">
          Save
        </button>
      </div>
    </ng-template>
  </p-dialog>
</form>