import { ChangeDetectorRef, Component, OnInit, ViewChild, Input } from '@angular/core';
import { ElementRef, HostListener } from '@angular/core';


@Component({
  selector: 'materlog-settings-prefs',
  templateUrl: './settings-preferences.component.html',
  styleUrls: [
    './settings-preferences.component.css',
  ]
})
export class MaterlogSettingsPreferences implements OnInit {
    @Input() dropdownOptions: any;
    @Input() allMailData: any;
    @Input() whichWorkflowOption: any;

    prefOptions: any = [
        {name: 'Set up', chosen: true,},
        {name: 'Orders', chosen: false, nonselectable: true},
        {name: 'Jobs', chosen: false, nonselectable: true},
        {name: 'Suppliers', chosen: false, nonselectable: true},
        {name: 'Receivers', chosen: false, nonselectable: true},
    ]

    constructor(
    ) {
    }

    ngOnInit(): void {
        (window as any)['spref'] = this;
    }

    ngOnChanges(changes: any) {

    }

    onChangePref(option: any) {
        if (option.name == 'Receivers') return;
        this.prefOptions.forEach((opt: any) => {
            opt.chosen = opt == option;
        })
    }
}
