import { Component, OnInit } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { filter, take, takeUntil } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';

import { ConfirmationService, MessageService } from 'primeng/api';

import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { DashboardActions } from '../../state/actions/dashboard.actions';
import { IApplicationState } from 'src/app/common/state/models/app.state.model';
import { IStoreApiItem } from 'src/app/common/models/store-api-item.model';
import { selectAutocompleteReceivers, selectJobs, selectOptions, selectReceivers, selectReceiversContact } from '../../state/selectors/dashboard.selector';
import { IJobs, IReceiver } from '../../models/dashboard.model';
import { IOptions } from 'src/app/authentication/models/authentication.model';
import { IReceiverContact } from './../../models/dashboard.model';
import { INewJob } from 'src/app/dashboard/models/dashboard.model';
import { CommonConstants } from 'src/app/common/constants/common.constants';
import { Input } from '@angular/core';
import { JobsService } from '../../services/job.service';
import { DashboardJobView } from '../../services/dashboard_job_view.service';


@Component({
  selector: 'materlog-create-update-job',
  templateUrl: './create-update-job.component.html',
  styleUrls: ['./create-update-job.component.sass'],
})
export class CreateUpdateJobComponent extends OnDestroyMixin() implements OnInit {
  @Output() close = new EventEmitter<void>();
  @Output() jobBeenAdded = new EventEmitter<any>();
  @Output() receiverBeenAdded = new EventEmitter<any>();
  @Output() receiverContactBeenAdded = new EventEmitter<any>();
  
  @Input() onlyReceiver: boolean = false;
  @Input() sidePanelAdd: boolean = false;
  @Input() fromJobsPage: boolean = false;
  @Input() onlyReceiverContact: boolean = false;
  @Input() receiverId: string = '';
  @Input() fromGetTracking: boolean = true;

  showDialog: boolean = false;
  addReceiverModal: boolean = false;
  addReceiverContactModal: boolean = false;
  showReceiver: any = null;
  isSubmitted: boolean = false;
  isAddReceiverSubmitted: boolean = false;
  isAddReceiverContactSubmitted: boolean = false;
  isLoading: boolean = false;
  receivers!: any;
  filteredReceivers: any;
  filteredReceiversContacts: any;
  states!: any;
  countries!: any;
  selectedJob!: any;

  faTriangle = faExclamationTriangle;
  faXMark = faXmark;

  addJobForm: any = {
    name: null,
    address: null,
    city: null,
    state: null,
    country: 1,
    zip: null,

    receiver: {
      id: null,
      warehouse_name: null,
      address: null,
      city: null,
      state: null,
      country: null,
      zip: null,
      phone: null,
      email: null,
      contacts: null,
    },

    receiver_contact: {
      id: null,
      name: null,
      email: null,
      phone: null,
    },
  };

  addReceiverForm: any = {
    warehouse_name: null,
    address: null,
    city: null,
    state: null,
    country: null,
    zip: null,
    phone: null,
    email: null,
  };

  addReceiverContactForm: any = {
    name: null,
    email: null,
    phone: null,
  };

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private store: Store<IApplicationState>,
    private dashboardActions: DashboardActions,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private jobService: JobsService,
    private djv: DashboardJobView
  ) {
    super();
    (window as any)['update']= this;
  }

  override ngOnDestroy(): void {
      this.showDialog = false;
  }

  ngOnInit(): void {
    this.showDialog = true;
    this.onRequestReceivers();
    this.onPrepareData();
    const navigation = this.router.getCurrentNavigation();
    this.route.queryParams.subscribe(params => {
      this.onlyReceiver = this.onlyReceiver || params.onlyReceiver === 'true';
      this.addReceiverModal = this.onlyReceiver ;
      this.onlyReceiverContact = params.onlyReceiverContact === 'true';
      this.addReceiverContactModal = this.onlyReceiverContact;

      if (this.onlyReceiverContact) {
        this.receiverId = params.receiverId;
        this.addJobForm.receiver.id = this.receiverId;
      }
    });
  }

  onPrepareData() {
    this.store
      .pipe(
        select(selectOptions),
        filter((options: IStoreApiItem<IOptions>) => !options.isLoading),
        take(1)
      )
      .subscribe((options: IStoreApiItem<IOptions>) => {
        this.states = options?.data?.states;
        this.countries = options?.data?.countries;
      });
  }

  onRequestReceivers() {
    this.dashboardActions.requestReceivers();
    this.store
      .pipe(
        select(selectReceivers),
        filter((receivers: IStoreApiItem<IReceiver[]>) => !receivers.isLoading),
        take(1)
      )
      .subscribe((receivers: IStoreApiItem<any>) => {
        this.receivers = receivers?.data.results;
        this.filteredReceivers = receivers?.data.results;
      });
  }

  onRequestAutocompleteReceivers(query: any) {
    this.dashboardActions.requestAutocompleteReceivers({ search_text: query });
    this.store
      .pipe(
        select(selectAutocompleteReceivers),
        filter((receivers: IStoreApiItem<IReceiver>) => !receivers.isLoading),
        take(1)
      )
      .subscribe((receivers: IStoreApiItem<IReceiver>) => {
        this.receivers = receivers?.data;
        this.filteredReceivers = receivers?.data;

      });
  }

  onSelectReceiver(event: any) {
    this.addJobForm.receiver = {
      id: event.id,
      warehouse_name: event,
      address: event.address,
      city: event.city,
      state: event.state,
      country: event.country,
      zip: event.zip,
      phone: event.phone,
      email: event.email,
      contacts: event.contacts,
    };

  }

  onSelectReceiverContact(event: any) {
    this.addJobForm.receiver_contact = {
      id: event.id,
      name: event.name,
      email: event.email,
      phone: event.phone,
    };
  }

  onClearReceiverContact() {
    this.addJobForm.receiver_contact = {
      id: null,
      name: null,
      email: null,
      phone: null,
    };
  }

  onClearReceiver() {
    this.addJobForm.receiver = {
      id: null,
      warehouse_name: null,
      address: null,
      city: null,
      state: null,
      country: null,
      zip: null,
      phone: null,
      email: null,
      contacts: null,
    };

    this.addJobForm.receiver_contact = {
      id: null,
      name: null,
      email: null,
      phone: null,
    };
  }

  filterReceivers(event: any) {
    let query = event.query;
    if (query.trim()?.length >= 1) {
      this.onRequestAutocompleteReceivers(query);
    }
  }

  filterReceiversContacts(event: any) {
    let receiverContacts: any[] = [];
    const filtered: any[] = [];
    const query = event.query;

    this.receivers?.forEach((element: any) => {
      if (element.id === this.addJobForm.receiver.id) { receiverContacts = element.contacts; }
    });

    for (let i = 0; i < receiverContacts?.length; i++) {
      const receiver = receiverContacts[i];

      if (receiver.name.toLowerCase().indexOf(query.toLowerCase()) !== -1) {
        filtered.push(receiver);
      }
    }

    this.filteredReceiversContacts = filtered;
  }

  get isReceiverNameRequired() {
    const receiver = { ...this.addJobForm.receiver };

    delete receiver?.warehouse_name;

    return !this.addJobForm.receiver?.warehouse_name?.warehouse_name && !(Object.values(receiver).every(element => element === null));
  }

  get isReceiverContactNameRequired() {
    const receiverContact = { ...this.addJobForm.receiver_contact };

    delete receiverContact?.name;

    return !this.addJobForm.receiver_contact?.name && !(Object.values(receiverContact).every(element => element === null));
  }

  get isJobZip() {
    if (this.addJobForm.zip) {
      return this.addJobForm.zip?.length <= 10;
    } else { return true; }
  }

  get isSelectedReceiverZip() {
    if (this.addJobForm.receiver?.zip) {
      return this.addJobForm.receiver?.zip?.length <= 10;
    } else { return true; }
  }

  get isSelectedReceiverEmail() {
    if (this.addJobForm.receiver.email) {
      return CommonConstants.emailRegex.test(this.addJobForm.receiver.email);
    } else { return true; }
  }

  get isSelectedReceiverPhone() {
    if (this.addJobForm.receiver.phone) {
      return this.addJobForm.receiver?.phone?.length <= 15;
    } else { return true; }
  }

  get isSelectedReceiverContactEmail() {
    if (this.addJobForm.receiver_contact.email) {
      return CommonConstants.emailRegex.test(this.addJobForm.receiver_contact.email);
    } else { return true; }
  }

  get isSelectedReceiverContactPhone() {
    if (this.addJobForm.receiver_contact.phone) {
      return this.addJobForm.receiver_contact.phone?.length <= 15;
    } else { return true; }
  }

  get isReceiverZip() {
    if (this.addReceiverForm.zip) {
      return this.addReceiverForm.zip?.length <= 10;
    } else { return true; }
  }

  get isReceiverPhone() {
    if (this.addReceiverForm.phone) {
      return this.addReceiverForm.phone?.length <= 15;
    } else { return true; }
  }

  get isReceiverContactPhone() {
    if (this.addReceiverContactForm.phone) {
      return this.addReceiverContactForm.phone?.length <= 15;
    } else { return true; }
  }

  get isReceiverEmail() {
    if (this.addReceiverForm.email) {
      return CommonConstants.emailRegex.test(this.addReceiverForm.email);
    } else { return true; }
  }

  get isReceiverContactEmail() {
    if (this.addReceiverContactForm.email) {
      return CommonConstants.emailRegex.test(this.addReceiverContactForm.email);
    } else { return true; }
  }

  get isAddressRequired() {
    if (this.addJobForm.address) {
      return this.addJobForm.address?.length <= 1;
    } else { return true; }
  }

  get isCityRequired() {
    if (this.addJobForm.city) {
      return this.addJobForm.address?.length <= 1;
    } else { return true; }
  }

  get isStateRequired() {
    if (this.addJobForm.state) {
      return false;
    } else { return true; }
  }

  checkZipLength() {
    if (this.addJobForm.zip?.length > 10) {
      this.addJobForm.zip = this.addJobForm.zip.slice(0, 10);
    }
  }

  onCreateJob() {
    if (this.showReceiver === null) { return; }

    this.isSubmitted = true;

    if (this.addJobForm.name && !this.isReceiverNameRequired && !this.isReceiverContactNameRequired && this.isJobZip && this.isSelectedReceiverContactEmail && this.isSelectedReceiverContactPhone && this.isSelectedReceiverEmail && this.isSelectedReceiverPhone && this.isSelectedReceiverZip && !this.isAddressRequired && !this.isCityRequired && !this.isStateRequired) {
      this.isLoading = true;
      this.handleAddJob();
    }
  }

  handleAddJob() {
    const objToAdd: INewJob = {
      name: this.addJobForm.name,
      address: this.addJobForm.address,
      city: this.addJobForm.city,
      state: this.addJobForm.state,
      country: this.addJobForm.country,
      zip: this.addJobForm.zip,
      receiver: this.showReceiver && this.addJobForm.receiver?.warehouse_name?.warehouse_name?

        {
          id: this.addJobForm.receiver?.id,
          warehouse_name: this.addJobForm.receiver?.warehouse_name?.warehouse_name,
          phone: this.addJobForm.receiver?.phone,
          email: this.addJobForm.receiver?.email,
          address: this.addJobForm.receiver?.address,
          city: this.addJobForm.receiver?.city,
          state: this.addJobForm.receiver?.state,
          country: this.addJobForm.receiver?.country,
          zip: this.addJobForm.receiver?.zip
        }
        : null,
      receiver_contact: this.showReceiver && this.addJobForm.receiver_contact?.name ?
        {
          id: this.addJobForm.receiver_contact?.id,
          name: this.addJobForm.receiver_contact?.name,
          email: this.addJobForm.receiver_contact?.email,
          phone: this.addJobForm.receiver_contact?.phone,
        }
        : null,
      track_job: this.addJobForm.track_job,
      weekly_checkin: this.addJobForm.weekly_checkin
    };
    this.jobService.addJob({ payload: objToAdd }).subscribe((result: any) => {
      localStorage.setItem('selectedJob', JSON.stringify(result.id));
      if (this.fromJobsPage) {
        this.jobBeenAdded.emit();
      }
      this.djv.get_new_views();
      this.dashboardActions.requestActiveJobs();
      this.close.emit();
      if (this.fromGetTracking) {
        this.router.navigate(['']);
      }
    })

  }

  onOpenModalAddReceiver() {
    this.addReceiverModal = true;
  }

  onOpenModalAddReceiverContact() {
    this.addReceiverContactModal = true;
  }

  closeDialog() {
    this.close.emit();
    this.showDialog = false;
  }

  closeReceiverDialog() {
    this.addReceiverModal = false;
    this.close.emit();
  }

  closeReceiverContactDialog() {
    this.addReceiverContactModal = false;
  }

  isReceiverReady() {
    return this.addReceiverForm.warehouse_name && this.isReceiverEmail && this.isReceiverPhone && this.isReceiverZip;
  }

  onSaveReceiver() {
    this.isAddReceiverSubmitted = true;
    if (this.sidePanelAdd && this.wantReceiverContact) {
      this.isAddReceiverContactSubmitted = true;
      if (!this.isRecContactReady()) {
        return;
      }
    }
    if (this.isReceiverReady()) {
      this.isLoading = true;
      this.dashboardActions.requestAddReceiver({ ...this.addReceiverForm });

      this.store
        .pipe(
          select(selectReceivers),
          filter((receiver: IStoreApiItem<IReceiver[]>) => !receiver.isLoading),
          takeUntil(this.destroy)
        )
        .subscribe((receiver: any) => {
          this.isLoading = false;
          let newRecvId: any = undefined;
          if (receiver?.isSuccess) {
            if (this.sidePanelAdd && this.wantReceiverContact && receiver?.data?.id) {
              newRecvId = receiver.data.id;
              this.addJobForm.receiver.id = newRecvId;
              this.onSaveReceiverContact();
            }
            this.onReceiveNewReceiversList(receiver.data);
            this.addReceiverModal = false;
            this.resetReceiverForm();
          }
          if (receiver?.errors) {
            this.messageService.clear();
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Something went wrong. Please try again later. If this issue persists, contact support@getmeti.com.',
              key: 'error',
            });
          }
          this.addReceiverModal = false;
          if (this.onlyReceiver) {
            if (this.fromJobsPage) {
              this.receiverBeenAdded.emit(receiver);
            } else {
              this.close.emit();
            }
          }
        });
    }
  }

  onReceiveNewReceiversList(data: any) {
    this.onSelectReceiver(data);
    this.dashboardActions.resetReceiverCRUDState();
    setTimeout(() => {
      this.onRequestReceivers();
    },);
  }

  isRecContactReady() {
    return this.addReceiverContactForm.name && this.isReceiverContactEmail && this.isReceiverContactPhone;
  }

  wantReceiverContact: boolean = false;
  recContactChange(event: any) {
    if (event.value) {
      this.addReceiverContactModal = true;
    }
  }
  onSaveReceiverContact() {
    this.isAddReceiverContactSubmitted = true;

    if (this.isRecContactReady()) {
      this.isLoading = true;
      let recv_id = this.addJobForm.receiver.id;
      this.dashboardActions.requestAddReceiverContact({ id: this.addJobForm.receiver.id, ...this.addReceiverContactForm });
      this.store
        .pipe(
          select(selectReceiversContact),
          filter((receiver: IStoreApiItem<IReceiverContact>) => !receiver.isLoading),
          takeUntil(this.destroy)
        )
        .subscribe((receiver: any) => {
          this.isLoading = false;

          this.receiverContactBeenAdded.emit({receiver: recv_id, contact: receiver});
          if (receiver?.isSuccess) {
            this.onReceiveNewReceiverContactList(receiver.data);
            this.resetReceiverContactForm();
          }

          if (receiver?.errors) {
            this.messageService.clear();
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Something went wrong. Please try again later. If this issue persists, contact support@getmeti.com.',
              key: 'error',
            });
          }
          this.addReceiverContactModal = false;
          if (this.onlyReceiverContact) this.close.emit();

        });
    }
  }

  onReceiveNewReceiverContactList(data: any) {
    this.onRequestReceivers();
    this.onSelectReceiverContact(data);
  }

  resetReceiverForm() {
    this.isAddReceiverSubmitted = false;
    this.addReceiverForm = {
      warehouse_name: null,
      address: null,
      city: null,
      state: null,
      country: null,
      zip: null,
      phone: null,
      email: null,
    };
  }

  resetReceiverContactForm() {
    this.isAddReceiverContactSubmitted = false;
    this.addReceiverContactForm = {
      name: null,
      email: null,
      phone: null,
    };
  }
  // autocompletes the form with the address values from the Geoapify request
  placeSelected(event: any) {
    if (event) {
      this.addJobForm.zip = event.properties.postcode ?? null;
      this.addJobForm.address = event.properties.address_line1;
      this.states.forEach((state: any) => {
        if (state.value.toLowerCase() == event.properties.state_code.toLowerCase()) { this.addJobForm.state = state.id; return; }
      });
      if (event.properties.country_code === 'us') {
        this.addJobForm.country = 1;
      } else if (event.properties.country_code === 'ca') {
        this.addJobForm.country = 2;
      }
      this.addJobForm.city = event.properties.city ?? null;
    } else {
      this.addJobForm.city = null;
      this.addJobForm.state = null;
      this.addJobForm.zip = null;
      this.addJobForm.country = null;
      this.addJobForm.address = null;
    }
  }

  placeSelectedReceiver(event: any) {
    if (event) {
      this.addJobForm.receiver.zip = event.properties.postcode ?? null;
      this.addJobForm.receiver.address = event.properties.address_line1;
      this.states.forEach((state: any) => {
        if (state.value.toLowerCase() == event.properties.state_code.toLowerCase()) { this.addJobForm.receiver.state = state.id; return; }
      });
      if (event.properties.country_code === 'us') {
        this.addJobForm.receiver.country = 1;
      } else if (event.properties.country_code === 'ca') {
        this.addJobForm.receiver.country = 2;
      }
      this.addJobForm.receiver.city = event.properties.city ?? null;
    } else {
      this.addJobForm.receiver.city = null;
      this.addJobForm.receiver.state = null;
      this.addJobForm.receiver.zip = null;
      this.addJobForm.receiver.country = null;
      this.addJobForm.receiver.address = null;
    }
  }

  placeSelectedReceiverModal(event: any) {
    if (event) {
      this.addReceiverForm.zip = event.properties.postcode ?? null;
      this.addReceiverForm.address = event.properties.address_line1;
      this.states.forEach((state: any) => {
        if (state.value.toLowerCase() == event.properties.state_code.toLowerCase()) { this.addReceiverForm.state = state.id; return; }
      });
      if (event.properties.country_code === 'us') {
        this.addReceiverForm.country = 1;
      } else if (event.properties.country_code === 'ca') {
        this.addReceiverForm.country = 2;
      }
      this.addReceiverForm.city = event.properties.city ?? null;
    } else {
      this.addReceiverForm.city = null;
      this.addReceiverForm.state = null;
      this.addReceiverForm.zip = null;
      this.addReceiverForm.country = null;
      this.addReceiverForm.address = null;
    }
  }

}
