<div class="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
  <div class="auth-container sm:mx-auto sm:w-full sm:max-w-sm">
  <div class="logo" (click)="onNavigateHome()" style="cursor: pointer">
    <img src="/assets/img/meti_logo.svg" alt="materlog-logo" />
  </div>

  <div class="form">
    <div class="title">Password reset</div>
    <form [formGroup]="forgotPasswordForm" class="form-group" *ngIf="errorMessage !== undefined; else successMessage">
      <div class="subtitle">Enter your email address and we’ll send you a link to reset your password.</div>
      <div class="textfield">
        <input
          (focus)="errorMessage = ''"
          type="text"
          id="email"
          formControlName="email"
          class="form-controls"
          placeholder="name@company.com"
        />
        <div
          class="textfield__error-message"
          *ngIf="forgotPasswordForm.controls.email.invalid && forgotPasswordForm.controls.email.touched && isSubmitted"
        >
          Enter a valid email address
        </div>
        <div class="textfield__error-message" *ngIf="errorMessage">
          {{ errorMessage }}
        </div>
      </div>
      <button
        pButton
        type="submit"
        label="Send reset link"
        class="button-primary p-button-raised"
        (click)="onSubmit()"
      ></button>
    </form>

    <ng-template #successMessage>
      <div class="subtitle">Please check your email.</div>
      <div class="info">
        We sent an email to {{ forgotPasswordForm.controls.email.value }} that contains a link to reset your Meti
        password.
      </div>
    </ng-template>
  </div>
</div>
</div>
