<div class="materlog-calendar-container"
    [ngStyle]="{'margin-left': fromView ? '16px': ''}">
    <div class="materlog-calendar-header">
        <div class="materlog-calendar-main-text">
            Calendar
        </div>
        <div class="materlog-calendar-dropdowns">
            <materlog-dropdown-component
                class="materlog-field-type materlog-cal-single-drop"
                [options]="dateTypesDrops"
                [selected]="setDateType"
                [dropWidth]="150"
                [maxWidth]="146"
                [alignRight]="true"
                [alphaSort]="false"
                [fixed]="false"
                [leftPixels]="-1"
                [topPixels]="26"
                [dropheaderClass]="materlogCalDropHeader()"
                [dropheaderTextClass]="materlogCalDroptextHeader()"
                [dropIconClass]="materlogCalDropIcon()"
                (onChange)="onDropdownChange($event)"
            >
            </materlog-dropdown-component>
            <div class="materlog-calendar-date">
                <div class="materlog-date-text">
                    {{calDate}}
                </div>
                <div class="materlog-calendar-buttons">
                    <div *ngFor="let mb of monthButtons" class="materlog-month-button" (click)="onDateChange(mb)">
                        <i [ngClass]="[mb.style]" class="materlog-icon-month-button"></i>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div class="materlog-calendar-grid">
        <div class="materlog-calendar-day" *ngFor="let day of daysInMonth"
                [ngClass]="{
                    'materlog-current-day-box': isCurrentDay(day.value),
                    'materlog-calendar-day-border-top': (day.id > 6) && !isLastWeekToday(day.value),
                    'materlog-calendar-day-border-right': (((day.id + 1) % 7) != 0) && !isTomorrowToday(day.value),
                }"
            >
            <div class="materlog-calendar-day-header">
                <div *ngIf="day.id < 7" class="materlog-cal-named-day">
                    {{weekForDay(day.id)}}
                </div>
                <div class="materlog-cal-day-numeric"
                    [ngClass]="{'materlog-current-day': isCurrentDay(day.value)}"
                >
                    {{getCellHeader(day)}}
                </div>
                <div class="materlog-cal-info-container"
                    (click)="showAllDates($event, date, day.value)"
                    *ngFor="let date of currentMonthDates[day.value] || []; let i = index; let isLast = last"
                    [ngClass]="{
                        'materlog-cal-info-smoosh-height': day.id < 7,
                        'materlog-cal-info-container-item': i != 4
                    }"
                    >
                    <i *ngIf="i < 4" class="bi bi-dot materlog-cal-early-status"
                        [ngStyle]="{'color': date.status_color}"
                    ></i>
                    <div *ngIf="i < 4 && date.job" class="materlog-cal-date-text materlog-cal-date-text-job"
                            [ngStyle]="{'color': date.missed_deadline ? '#F31616': '#000'}">
                        {{formatJobName(date.job)}}
                    </div>
                    <div *ngIf="i < 4 && date.supplier" class="materlog-cal-date-text" style="font-weight: 600"
                            [ngStyle]="{'color': date.missed_deadline ? '#F31616': '#000'}">
                        {{date.supplier}}
                    </div>
                    <div *ngIf="i >= 4 && isLast" class="materlog-dates-more" (click)="seeMoreDates($event, day)">
                        {{formatAndMore(date.count)}}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="red-text-explain">
        <div>
            <span style="color: #F31616">Red</span> text signals an unconfirmed or missed deadline that may need attention.
        </div>
        <span style="color: #828282; margin-top: 8px">For example, if today is the 6th and an order's estimated ship date was the 4th but<br>no shipment confirmation was provided, the order will appear in red.</span>
    </div>
</div>

<div *ngIf="showMoreDates" class="materlog-show-more-dates"
    [ngStyle]="{'top.px': showMoreDatesPos.y, 'left.px': showMoreDatesPos.x}">
    <div class="materlog-show-more-header">
        <div class="materlog-show-more-close materlog-show-more-close-align" (click)="onCloseShowMore()">
            <i class="bi bi-x-lg"></i>
        </div>
        <div class="materlog-show-more-name">
            {{showMoreDatesPos.name}}
        </div>
        <div class="materlog-cal-number-day">
            {{showMoreDatesPos.day}}
        </div>
    </div>
    <div class="materlog-cal-more-container custom-scrollbar">
        <div class="materlog-cal-info-container materlog-cal-info-container-item materlog-more-item-container"
            (click)="showAllDates($event, date, showMoreDatesPos.day, true)"
            *ngFor="let date of showMoreDatesPos.values || []; let i = index; let isLast = last"
            >
            <i class="bi bi-dot materlog-cal-early-status"
                [ngStyle]="{'color': date.status_color}"
            ></i>
            <div *ngIf="date.job" class="materlog-cal-date-text materlog-cal-date-text-job"
                [ngStyle]="{'color': date.missed_deadline ? '#F31616': '#000'}">
                {{formatJobName(date.job)}}
            </div>
            <div *ngIf="date.supplier" class="materlog-cal-date-text" style="font-weight: 600"
                [ngStyle]="{'color': date.missed_deadline ? '#F31616': '#000'}">
                {{date.supplier}}
            </div>
        </div>
    </div>
</div>

<div *ngIf="showAllOrderDates" class="materlog-show-all-dates"
    [ngStyle]="{'top.px': showAllDatesVal.y, 'left.px': showAllDatesVal.x}">
    <div class="materlog-show-all-header">
        {{showAllDatesVal.date}}
        <div class="materlog-show-more-close materlog-show-all-close-align" (click)="onCloseShowAll()">
            <i class="bi bi-x-lg"></i>
        </div>
    </div>
    <div class="materlog-show-all-meta">
        <i class="bi bi-box-seam materlog-show-all-meta-box"></i>
        <div class="materlog-show-all-meta-names">
            <div class="materlog-show-all-meta-names-main">
                {{showAllDatesVal.job}}
                <i class="bi bi-dot materlog-show-all-dot"></i>
                {{showAllDatesVal.supplier}}
            </div>
            <div class="materlog-show-all-meta-names-sub">
                {{showAllDatesVal.order}}
            </div>
        </div>
    </div>
    <div class="materlog-show-all-item-headers">
        <div *ngFor="let hdr of itemHeaders" class="materlog-show-all-item-hdr"
            [ngStyle]="{
                'min-width': hdr.width, 'max-width': hdr.width, 'font-weight': hdr.bold ? 600: 500
            }">
            <i *ngIf="hdr.icon" class="bi bi-receipt materlog-show-all-item-hdr-icon"></i>
            {{hdr.name}}
        </div>
    </div>
    <div class="materlog-show-all-items-container custom-scrollbar" #itemCalendar>
        <div *ngFor="let item of showAllDatesVal.items; let isLast = last; let isFirst= first;"
            class="materlog-show-all-item-rows"
            [ngClass]="{
                'materlog-show-all-bg': isAnyBold(item),
                'materlog-show-all-item-border-row': !isLast,
                'materlog-show-all-first': isFirst,
                'materlog-show-all-bottom': item.border
            }"
            >
            <div *ngFor="let hdr of itemHeaders; let i = index; let isLastHdr = last"
                class="materlog-show-all-item-hdr"
                (click)="onItemEditClick($event, item, hdr)"
                [ngClass]="{
                    'materlog-item-hdr-border': !isLastHdr && i > 0,
                    'item-is-cursor': i > 0
                }"
                [ngStyle]="styleForItem(item, hdr)">
                <i *ngIf="hdr.icon" class="bi bi-dot materlog-cal-early-status materlog-item-status"
                        [ngStyle]="{'color': item.color}"></i>
                <div class="materlog-show-all-item-hdr-text" *ngIf="!isItemEditing(item, hdr)"
                    [ngStyle]="{'color': isItemMissedDeadline(item, hdr) ? '#F31616': ''}">
                    {{valueForItemHdr(item, hdr)}}
                </div>
                <input *ngIf="isItemEditing(item, hdr) && hdr.type == 'text'"
                    class="item-edit-input"
                    type="text"
                    placeholder="Item name"
                    [(ngModel)]="item.name_new"
                    (keydown.enter)="onItemEnter($event)"
                    (blur)="onItemNameChange($event, item, hdr)"
                />
                <materlog-dropdown-component *ngIf="isItemEditing(item, hdr) && hdr.type == 'dropdown'"
                    class="materlog-show-all-item-hdr-text" style="font-weight: 400"
                    [options]="dropdownOptions?.status"
                    [selected]="item.status_id"
                    [dropWidth]="160"
                    [maxWidth]="156"
                    [forceSetLocation]="true"
                    [alignRight]="false"
                    [alphaSort]="false"
                    [inputText]="'Search...'"
                    [leftPixels]="214"
                    [topPixels]="itemEditY"
                    [dropheaderClass]="itemStatusHeader()"
                    [openOnInit]="true"
                    [dropheaderTextClass]="itemStatusDropHeader()"
                    [dropIconClass]="materlogCalDropIcon()"
                    (onChange)="onItemStatusChange($event, item, hdr)"
                    (onToggleDropdown)="onToggleDropdown($event, item, hdr)"
                >
                </materlog-dropdown-component>
                <materlog-date-component *ngIf="isItemEditing(item, hdr) && hdr.type == 'date'"
                    class="materlog-field-type"
                    [items]="dateForItem(item, hdr)"
                    [is_item]="true"
                    [fieldName]="hdr.iname"
                    [openOnInit]="true"
                    [og_format]="true"
                    [forceChanger]="forceChanger"
                    [extraFormat]="itemDateFormatting()"
                    (onChange)="onItemDateChange($event, item, hdr)"
                    >
                </materlog-date-component>
            </div>
        </div>
    </div>
</div>