import { Store, select } from '@ngrx/store';
import { Router } from '@angular/router';
import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, TemplateRef } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { filter } from 'rxjs';

import { MessageService } from 'primeng/api';

import { IStoreApiItem } from './../../../common/models/store-api-item.model';
import { IApplicationState } from 'src/app/common/state/models/app.state.model';
import { OnDestroyMixin } from './../../../common/mixins/destroy-mixin';
import { CommonConstants } from 'src/app/common/constants/common.constants';
import { DashboardActions } from 'src/app/dashboard/state/actions/dashboard.actions';
import { ISuppliers } from 'src/app/dashboard/models/dashboard.model';
import { selectSupplierContact } from 'src/app/dashboard/state/selectors/dashboard.selector';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';


@Component({
  selector: 'materlog-add-supplier-contact-modal',
  templateUrl: './add-supplier-contact-modal.component.html',
  styleUrls: ['./add-supplier-contact-modal.component.sass'],
  standalone: true,
  imports: [CommonModule, FormsModule, DropdownModule],
})
export class AddSupplierContactModalComponent extends OnDestroyMixin() implements OnChanges {
  @Input() supplierId!: any;
  @Input() supplierList: any;
  @Input() contactRoles: any;
  @Input() resetContact: boolean = false;
  @Input() sidePanelAdd: boolean = false;
  @Input() credPage: boolean = false;
  @Output() supplierContactFormChange = new EventEmitter<any>();

  isSubmitted: boolean = false;
  isLoading: boolean = false;

  supplierContactForm = {
    supplier_id: '',
    first_name: '',
    last_name: '',
    title: null,
    role: null,
    email: '',
    phone: null,
  };

  constructor(
    public router: Router,
    private store: Store<IApplicationState>,
    private dashboardActions: DashboardActions,
    private messageService: MessageService,
  ) {
    super();
    (window as any)['scpop'] = this;
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      const change = changes[propName];

      if (change.previousValue !== change.currentValue) {
        this.resetForm();
      }
    }
  }

  get isEmailInputed() {
    if (this.supplierContactForm.email) {
      return this.supplierContactForm.email?.length < 1;
    } else { return true; }
  }

  get isSupplierContactEmail() {
    if (this.supplierContactForm.email) {
      return CommonConstants.emailRegex.test(this.supplierContactForm.email);
    } else { return true; }
  }

  get isSupplierContactFirstName() {
    if (this.supplierContactForm.first_name) {
      return this.supplierContactForm.first_name?.length < 1;
    } else { return true; }
  }

  get isSupplierContactLastName() {
    if (this.supplierContactForm.last_name) {
      return this.supplierContactForm.last_name?.length < 1;
    } else { return true; }
  }

  get isSupplierSelected() {
    if (this.supplierContactForm.supplier_id) {
      return this.supplierContactForm.supplier_id?.length < 1;
    } else { return true; }
  }

  resetForm() {
    this.supplierContactForm = {
      supplier_id: this.supplierId,
      first_name: '',
      last_name: '',
      title: null,
      role: null,
      email: '',
      phone: null,
    };
    this.isSubmitted = false;
  }

  isContactReady() {
    return this.isContactReadyNoSupplier() && !this.isSupplierSelected;
  }

  isContactReadyNoSupplier() {
    return !this.isSupplierContactFirstName && !this.isEmailInputed;
  }

  closeSupplierContact() {
    this.supplierContactFormChange.emit({close: true});
  }

  submitSupplierContact() {
    this.isSubmitted = true;
    const objToAdd: any = {
      supplier: this.supplierId,
      first_name: this.supplierContactForm.first_name,
      last_name: this.supplierContactForm.last_name,
      title: this.supplierContactForm.title,
      email: this.supplierContactForm.email,
      phone: this.supplierContactForm.phone,
      role: this.supplierContactForm.role !== null ? this.supplierContactForm.role : 0,
    };

    if (this.isContactReady()) {
      this.isLoading = true;
      this.dashboardActions.requestAddSupplierContact({ ...objToAdd });

      this.store.pipe(
        select(selectSupplierContact),
        filter((newSupplier: IStoreApiItem<ISuppliers>) => !newSupplier.isLoading),
        takeUntil(this.destroy)
      ).subscribe((newSupplierContact: any) => {
        this.isLoading = false;
        if (newSupplierContact?.isSuccess) {
          this.supplierContactFormChange.emit(newSupplierContact.data);
          this.resetForm();
        }

        if (newSupplierContact?.errors) {
          this.messageService.clear();
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Something went wrong. Please try again later. If this issue persists, contact support@getmeti.com.',
            key: 'error',
          });
        }
      });
    }
  }
}
