import { ChangeDetectorRef, Component, OnInit, ViewChild, Input , Output} from '@angular/core';
import { ElementRef, HostListener, EventEmitter } from '@angular/core';


@Component({
  selector: 'materlog-workflows-confirm',
  templateUrl: './workflow-confirm.component.html',
  styleUrls: [
    '../../workflows/workflows.component.css',
    '../../company-settings/company-settings.component.css',
  ]
})
export class MaterlogWorkflowsConfirm implements OnInit {
    @Input() dropdownOptions: any;
    @Input() allMailData: any;
    @Input() orgData: any = {};
    @Input() box: any = {};
    @Input() chosenPic: string = '';
    @Output() applyUpdate = new EventEmitter<any>();

    trackPOText: string = 'Track POs';
    trackPOs: any = [
      {id: 0, value: "Don't track POs"},
      {id: 1, value: 'Track POs'},
    ];

    orderDataEntry: string = 'Automate your data entry';
    autoTrack: string = 'Check in on orders - via tracking links';
    draftEmails: string = 'Check in on orders to suppliers - by email';
    draftEmailsShipper: string = 'Check in on shipped orders - by email';
    somethingElse: string = 'Looking to automate something else?';

    settings: any = [
        {
          name: 'Sales',
          extra: 'Coming soon!',
          options: [
            {name: 'Create quotes'},
            {name: 'Quote to PO'},
            {name: 'Reorder'},
            {name: 'Demand forecasting'},
          ]
        },
        {
          name: 'Operations',
          options: [
            {
              name: this.orderDataEntry,
              is_active: true,
              subtext: 'Extract details from emails, uploads and other sources on an on-going business and match to orders to keep all your data up to date.',
              /*
              subvalues: {
                name: 'Share orders via extension or email',
                is_open: false,
                options: [
                  {id: 0, value: 'Email'},
                  {id: 1, value: 'Extension'},
                ]
              }
              */
            },
            {
              name: this.autoTrack,
              is_active: true,
              subtext: 'Retrieves order data from tracking links and supplier portals.',
            },
            {
              name: this.draftEmails,
              is_active: true,
              subtext: 'Checks in and follows up on orders with suppliers over email',
              subvalues:  {
                name: this.trackPOText,
                options: this.trackPOs,
                is_open: false,
              }
            },
            {
              name: this.draftEmailsShipper,
              is_active: true,
              subtext: 'Checks in and follows up on orders with shippers over email',
            },
          ]
        }
      ];
      setting = this.settings[1];

    ngOnChanges(changes: any) {

    }

    ngOnInit(): void {
        (window as any)['wf_confirm'] = this;
    }

    toggleForName(setting_name: any, option_name: any, toggle: boolean = false) {
        if (setting_name == 'Sales') {
          return false;
        } else if (setting_name == 'Operations') {
          switch (option_name) {
            case this.orderDataEntry:
              if (toggle) {
                this.orgData.order_data_entry = !this.orgData.order_data_entry;
              }
              return this.orgData.order_data_entry;
            case this.autoTrack:
              if (toggle) {
                this.orgData.track_links = !this.orgData.track_links;
              }
              return this.orgData.track_links;
            case this.draftEmails:
              if (toggle) {
                this.orgData.send_checkin_emails = !this.orgData.send_checkin_emails;
              }
              return this.orgData.send_checkin_emails;
            case this.draftEmailsShipper:
                if (toggle) {
                  this.orgData.send_checkin_emails_shipper = !this.orgData.send_checkin_emails_shipper;
                }
                return this.orgData.send_checkin_emails_shipper;
            default:
              return false;
          }
        }
        return false
    }

    isToggleable(setting_name: any) {
        if (setting_name == 'Sales') {
          return false;
        } else if (setting_name == 'Operations') {
          return true;
        }
        return false;
    }

    toggleOption(setting_name: any, option_name: any) {
        if (!this.isToggleable(setting_name)) {
          return;
        }
        this.toggleForName(setting_name, option_name, true);
        this.applyUpdate.emit();
    }

    onToggleSubvalues(subvalues: any) {
      if (subvalues.is_open) {
        subvalues.is_open = false;
      } else {
        setTimeout(() => {
          subvalues.is_open = true;
        }, 0);
      }
    }

    onSetSubvalue(setting_name: any, sub_value: any, value: any) {
        sub_value.is_open = false;
        if (setting_name == 'Operations') {
          switch (sub_value.name) {
            case this.trackPOText:
              this.orgData.po_follow_up = Boolean(value.id);
              break;
            default:
              value = undefined;
          }
        }
        this.applyUpdate.emit();
    }

    optionValueForOption(setting_name: any, sub_name: any, options: any) {
        let value: any = undefined;
        if (setting_name == 'Operations') {
          switch (sub_name) {
            case this.trackPOText:
              value = Number(this.orgData.po_follow_up);
              break;
            default:
              value = undefined;
          }
        }
        if (value == undefined) {
            return '';
        }
        let found = options.find((o: any) => o.id == value);
        return found ? found.value: 'Choose option';
    }

    letUsKnowClick() {
      (window as any).Intercom('show');
    }


    @HostListener('document:click', ['$event'])
    clickOutside(event: any) {
      this.settings.forEach((setting: any) => {
        setting.options.forEach((o: any) => {
          if (o.subvalues) {
            o.subvalues.is_open = false;
          }
        })
      })
    }
}
