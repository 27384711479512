<div class="materlog-date-container" #materlogDateContainer>
  <div *ngIf="!dateVisible" (click)="onDateClick()"
    [ngClass]="{
      'materlog-date-clickable': dates?.length == 1,
      'materlog-date-dash': dates?.length == 0
    }"
    >
    {{formatDate()}}
  </div>
  <input *ngIf="dateVisible" class="materlog-date-holder"
    [ngStyle]="extraFormat"
    type="date"
    [value]="createdDate"
    placeholder="--" 
    [(ngModel)]="createdDate"
    (blur)="onDateBlur($event)"
    (change)="onDateSelect($event)"
    #materlogDate>
</div>