<div class="materlog-order-info-header">
    <div class="materlog-single-order-value" *ngFor="let field of fields">
      <div class="materlog-single-order-text">
        {{field.name}}
      </div>
      <div class="materlog-single-order-fieldvalue">
        <div *ngIf="onlyProcessing"> {{ fieldValueForField(field) }}</div>
        <div class="materlog-compound-diff" *ngIf="!onlyProcessing && (field.type == 'text' || field.type == 'number')">
          <materlog-text-component
            class="materlog-field-type"
            [order]="msgData?.order"
            [fieldName]="field.field"
            [isNumber]="field.type == 'number'"
            [isCost]="field.cost"
            [maxInput]="field.maxInput || 255"
            [vgrowth]="field.vgrowth"
            (onChange)="onTextChange($event, field)"
          >
          </materlog-text-component>
        </div>
        <materlog-date-component *ngIf="!onlyProcessing && itemsExist && field.type == 'date'"
          class="materlog-field-type"
          [items]="msgData?.order?.item_fields"
          [fieldName]="field.field"
          (onChange)="onDateChange($event)"
        ></materlog-date-component>
        <div class="materlog-compound-diff" *ngIf="!onlyProcessing && field.type == 'dropdown'">
            <materlog-dropdown-component
              class="materlog-field-type"
              [options]="optionsForField(field)"
              [fullOptions]="fullOptionsForField(field)"
              [selected]="idForSelectedField(field)"
              [inputText]="inputTextForField(field)"
              [allowCreateNew]="canCreateNewDropdown(field)"
              [createNewText]="getCreateNewText(field)"
              [onTop]="field.top"
              [dropWidth]="field.dropWidth || 200"
              [alignRight]="field.alignRight"
              [allowNull]="field.allow_null"
              (onChange)="onDropdownChange($event, field)"
              (onOptionsChange)="onOptionsChange($event, field)"
            >
            </materlog-dropdown-component>
        </div>
        <materlog-shiplink-component
          *ngIf="!onlyProcessing && field.type == 'link' && itemsExist"
          class="materlog-field-type"
          [items]="shadowItemLinks"
          [fieldName]="'tracking_info'"
          (onChange)="onShippingLinkChange($event)"
        >
        </materlog-shiplink-component>
        <materlog-status-component
          *ngIf="!onlyProcessing && field.type == 'status' && itemsExist"
          class="materlog-field-type"
          [options]="optionsForField(field)"
          [colors]="dropdownOptions?.status_colors"
          [items]="msgData?.order?.item_fields"
          (onChange)="onStatusChange($event)"
        >
        </materlog-status-component>
      </div>
    </div>
  </div>